import { checkIsAlertEscalated } from 'src/helpers/alertHelpers';
import { NotificationService } from 'src/services';
import { rootStore } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import {
  NavigationTabs,
  tabsByStore,
  tabsStores,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';

const { navigationStore } = rootStore;

export const startListeners = () => {
  WsConnector.addListener(
    WebsocketMessageType.New,
    WebsocketEntityType.Alert,
    incomeNewAlert,
  );
};

export const incomeNewAlert = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const isAlertEscalated = checkIsAlertEscalated(data);

  if (!isTabActive && !isAlertEscalated) {
    sendNotificationForTab();
  }
};

const sendNotificationForTab = () => {
  NotificationService.sendNotification(
    'New video',
    "Hey there! You've been notified!",
    tabsByStore[tabsStores.ALERTS],
  );
};

const checkIsCurrentTabActive = () =>
  navigationStore.currentTab === NavigationTabs.ALERTS.path;
