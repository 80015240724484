import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import css from 'classnames';
import React from 'react';
import './style.less';

export class CommonModal extends React.Component<ICommonModalProps> {
  public render() {
    const { children, className, onClickElement, ...restProps } = this.props;

    return (
      <>
        {onClickElement}
        <Modal {...restProps} className={css(className, 'CommonModal')}>
          {children}
        </Modal>
      </>
    );
  }
}

export interface ICommonModalProps extends ModalProps {
  children: any;
  onClickElement?: any;
}
