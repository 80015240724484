import { orderBy } from 'lodash';
import { action, computed, flow, observable } from 'mobx';
import moment from 'moment';
import type { Moment } from 'moment';
import {
  getMessageTypeString,
  removeInlineSymbols,
  replaceQuotesBySingleQuotes,
} from 'src/helpers';
import { AdminService } from 'src/pages/admin/services/adminService';
import { IAlert } from 'src/restApi/alertsService';
import {
  AdminContentMessageType,
  FilterDirection,
  FilterValue,
} from '../constants';
import { hasAlertCaseInfo } from '../helpers/alertHelpers';
import {
  IComment,
  IModerationHistory,
  IStoredAlert,
  IStoredComment,
  RobDecision,
} from '../restApi/interfaces';
import { InjectRootStore } from './injectRootStore';

export interface IFilterUser {
  label: string;
  value: string;
}

interface IAlertIdCache {
  [alertId: string]: true;
}

interface ICategoryFilterOptions {
  id: number | string;
  title: string;
  status?: string;
  subcategory?: ICategoryFilterOptions[];
}

interface ISelectedFilters {
  comments: string[];
  alerts: string[];
  flagged: boolean;
  deleted: boolean;
  isAlertsWithCaseInfo: boolean;
  isAlertsWithoutCaseInfo: boolean;
  isLEOAlerts: boolean;
  isLEOComments: boolean;
  onRSU: boolean;
  resolved: boolean;
}

const initialFilters: ISelectedFilters = {
  alerts: [],
  comments: [],
  deleted: false,
  flagged: false,
  isAlertsWithCaseInfo: false,
  isAlertsWithoutCaseInfo: false,
  isLEOAlerts: false,
  isLEOComments: false,
  onRSU: false,
  resolved: false,
};

const itemsPerPage = 5;

export class AdminStore extends InjectRootStore {
  @observable public generateCSVModeOn = false;
  @observable public _categoryFilters =
    observable.array<ICategoryFilterOptions>([]);

  @observable public unchekedAlertIds = observable.array<number>([]);
  public contentMessageType = AdminContentMessageType.Initial;
  @observable public currentPage = 1;
  @observable public from: Moment | undefined;
  @observable public to: Moment | undefined;
  @observable public comments = observable.array<IStoredComment>([]);
  @observable public selectedFilters = initialFilters;
  @observable public sortFilters = {
    direction: FilterDirection.Desc,
    value: FilterValue.TimeCreated,
  };
  @observable private _users = new Set<string>();
  @observable public isRingUserChecked = false;
  @observable public isOnRSUChecked = false;
  @observable public selectedUsers = [];
  @observable public loading = false;
  @observable public alertId = '';
  @observable public commentId = '';
  @observable public words = '';

  // TODO: make private once we exclude tests from build compilation
  public alerts = observable.array<IStoredAlert>([]);

  @observable public isLoaderVisible = false;

  @observable.ref public currentRemoderationAlert = null;

  @observable isRemoderationAlertProcessing = false;

  private _mergeAlertsWithComments = (
    alerts: IStoredAlert[],
  ): IStoredAlert[] => {
    const alertComments = {};

    // Group comments by alert id
    for (const comment of this.comments) {
      const alertId = comment.alert_id;

      if (!alertComments[alertId]) {
        alertComments[alertId] = [];
      }

      alertComments[alertId].push(comment);
    }

    // Create alerts clone as mobx do not allow any observable modifications inside computed methods
    const clonedAlerts = [];

    // Merge alerts with comments
    for (const alert of alerts) {
      clonedAlerts.push({
        ...alert,
        comments: alertComments[alert.alert_id] || [],
      });
    }

    return clonedAlerts;
  };

  private _sortEntities = (entities: IStoredAlert[]) => {
    return orderBy(
      entities,
      [this.sortFilters.value],
      [this.sortFilters.direction],
    );
  };

  private _getUniqueAlertIds = (
    acs: (IStoredAlert | IStoredComment)[],
  ): number[] =>
    acs
      .map((ac: IStoredAlert | IStoredComment): number => ac.alert_id)
      .filter((alertId, index, arr) => arr.indexOf(alertId) === index);

  private _getAlertsFromFilteredComments = (
    filteredAlerts: IStoredAlert[],
    comments: IStoredComment[],
  ): IStoredAlert[] => {
    const commentAlertIds = this._getUniqueAlertIds(comments);
    const alertIds = this._getUniqueAlertIds(filteredAlerts);

    // Get alert ids related to selected comments, which have not been already selected
    const missingAlertIds = commentAlertIds.filter(
      (commentAlertId) => !alertIds.includes(commentAlertId),
    );

    return [
      ...filteredAlerts,
      ...this.alerts.filter((a) => missingAlertIds.includes(a.alert_id)),
    ];
  };

  // Check whether variable implements IStoredComment interface
  private _isComment = (
    ac: IStoredComment | IStoredAlert,
  ): ac is IStoredComment => {
    return (ac as IStoredComment).comment_id !== undefined;
  };

  private _isAlert = (
    ac: IStoredComment | IStoredAlert,
  ): ac is IStoredAlert => {
    return !this._isComment(ac);
  };

  private _isUserSelected = () => Boolean(this.selectedUsers.length);

  private _isAlertsCategorySelected = () =>
    Boolean(this.selectedFilters.alerts.length);

  private _isCommentsCategorySelected = () =>
    Boolean(this.selectedFilters.comments.length);

  private _isSuperUserSelected = () => this.isRingUserChecked;

  private _isOnRSUSelected = () => this.selectedFilters.onRSU;

  private _isLEOAlertsSelected = () => this.selectedFilters.isLEOAlerts;

  private _isLEOCommentsSelected = () => this.selectedFilters.isLEOComments;

  private _isAlertsWithCaseInfoSelected = () =>
    this.selectedFilters.isAlertsWithCaseInfo;

  private _isAlertsWithoutCaseInfoSelected = () =>
    this.selectedFilters.isAlertsWithoutCaseInfo;

  private _andFilterByRingUser = (ac: IStoredComment) =>
    !this._isSuperUserSelected() || ac.is_rsu;

  private _andFilterOnRSUReview = (ac: IStoredComment | IStoredAlert) =>
    !this._isOnRSUSelected() || ac.on_rsu;

  private _andFilterBySelectedUsers = <U extends IStoredComment | IStoredAlert>(
    ac: U,
  ) => {
    return !this._isUserSelected() || this.selectedUsers.includes(ac.email);
  };

  private _andFilterByDeleted = (ac: IStoredComment | IStoredAlert) =>
    !this.selectedFilters.deleted || Boolean(ac.is_deleted);

  private _andFilterByFlagged = (ac: IStoredComment | IStoredAlert) =>
    !this.selectedFilters.flagged ||
    (this._isComment(ac) ? Boolean(ac.flag) : Boolean(ac.is_flagged));

  private _andFilterByResolved = (ac: IStoredComment | IStoredAlert) =>
    !this.selectedFilters.resolved || (ac as IStoredAlert).resolved;

  private _andFilterByLEO = (ac: IStoredComment | IStoredAlert) => {
    if (!this._isLEOAlertsSelected() && !this._isLEOCommentsSelected())
      return true;

    // If is Comment
    if (this._isComment(ac))
      return this._isLEOCommentsSelected() && ac.cop_id !== 0;

    // If is Alert
    return this._isLEOAlertsSelected() && Boolean(ac.cop);
  };

  private _andFilterByCaseInfoPresence = (
    ac: IStoredComment | IStoredAlert,
  ) => {
    const result =
      !this.selectedFilters.isAlertsWithCaseInfo ||
      (this._isAlert(ac) && hasAlertCaseInfo(ac));

    return result;
  };

  private _andFilterByCaseInfoAbscence = (
    ac: IStoredComment | IStoredAlert,
  ) => {
    const result =
      !this.selectedFilters.isAlertsWithoutCaseInfo ||
      (this._isAlert(ac) && !hasAlertCaseInfo(ac));

    return result;
  };

  private _getCategoriesForReasons = (
    type: string,
    status: number,
    reasons: (string | number)[] | null,
    robDecision: RobDecision,
  ) => {
    const categoryFilterPrefix = `${type},${status}`;
    const reasonsCopy = (reasons || []).slice();
    let category = reasonsCopy.shift() || '';

    return reasonsCopy.length > 0
      ? reasonsCopy.map(
          (reason) => `${categoryFilterPrefix},${category},${reason}`,
        )
      : [`${categoryFilterPrefix}${category ? `,${category}` : ''}`];
  };

  private _andFilterByCategories = (ac: IStoredComment | IStoredAlert) => {
    if (
      !this._isCommentsCategorySelected() &&
      !this._isAlertsCategorySelected()
    ) {
      return true;
    }

    const isAlert = !this._isComment(ac);
    const type = isAlert ? 'alerts' : 'comments';
    const filters = this.selectedFilters[type];

    if (!filters.length || (isAlert && !ac.reason)) return false;

    const categories = this._getCategoriesForReasons(
      type,
      ac.status,
      ac.reason || null,
      (ac as IStoredComment).rob_decision,
    );

    return categories.some((category) => filters.includes(category));
  };

  private _isNoFiltersSelected = () => {
    return (
      !this._isUserSelected() &&
      !this.isRingUserChecked &&
      !this.selectedFilters.resolved &&
      !this.selectedFilters.deleted &&
      !this.selectedFilters.flagged &&
      !this._isAlertsCategorySelected() &&
      !this._isCommentsCategorySelected() &&
      !this._isOnRSUSelected() &&
      !this._isLEOAlertsSelected() &&
      !this._isLEOCommentsSelected() &&
      !this._isAlertsWithCaseInfoSelected() &&
      !this._isAlertsWithoutCaseInfoSelected()
    );
  };

  private _getFilteredAlertsWithCache = (
    acs: IStoredAlert[],
    cache: IAlertIdCache = {},
  ): IStoredAlert[] => {
    return acs.filter((ac) => {
      if (cache[ac.alert_id]) return true;

      let found = this._isNoFiltersSelected();

      if (!found) {
        // Filters logic "OR"
        // If filtered by deleted OR selectedUser
        // const foundByFilters = this._orFilterByDeleted(ac) || this._orFilterBySelectedUsers(ac);
        // If flag filter was not selected AND category was found
        // const foundNotFlaggedFilter = !this.selectedFilters.flagged && this._orFilterByCategories(ac);
        // If flag filter was selected AND category was not selected OR category was found
        // const foundFlaggedFilter = this._orFilterByFlagged(ac) && ((!this._isCommentsCategorySelected() && !this._isAlertsCategorySelected()) || this._orFilterByCategories(ac));
        // found = foundByFilters || foundNotFlaggedFilter || foundFlaggedFilter;

        // Filters logic "AND"
        // If filtered by deleted AND selectedUser AND flagged AND category
        found =
          !this._isSuperUserSelected() &&
          this._andFilterByDeleted(ac) &&
          this._andFilterBySelectedUsers(ac) &&
          this._andFilterByFlagged(ac) &&
          this._andFilterByLEO(ac) &&
          this._andFilterByCategories(ac) &&
          this._andFilterOnRSUReview(ac) &&
          this._andFilterByResolved(ac) &&
          this._andFilterByCaseInfoPresence(ac) &&
          this._andFilterByCaseInfoAbscence(ac);
      }

      if (found) {
        cache[ac.alert_id] = true;
      }

      return found;
    });
  };

  private _getFilteredCommentsUpdatingCache = (
    acs: IStoredComment[],
    cache: IAlertIdCache = {},
  ): IStoredComment[] => {
    return acs.filter((ac) => {
      if (cache[ac.alert_id]) {
        return true;
      }

      let found = this._isNoFiltersSelected();

      if (!found) {
        // Filters logic "OR"
        // If filtered by deleted OR ringUser(RSU) OR selectedUser
        // const foundByFilters = this._orFilterByDeleted(ac) || this._orFilterByRingUser(ac) || this._orFilterBySelectedUsers(ac);
        // If flag filter was not selected AND category was found
        // let foundNotFlaggedFilter = !this.selectedFilters.flagged && this._orFilterByCategories(ac);
        // If flag filter was selected AND category was not selected OR category was found
        // let foundFlaggedFilter = this._orFilterByFlagged(ac) && ((!this._isCommentsCategorySelected() && !this._isAlertsCategorySelected()) || this._orFilterByCategories(ac));
        // found = foundByFilters || foundNotFlaggedFilter || foundFlaggedFilter;

        // Filters logic "AND"
        // If filtered by deleted AND ringUser AND selectedUser AND flagged AND category
        found =
          this._andFilterByDeleted(ac) &&
          this._andFilterByRingUser(ac) &&
          this._andFilterBySelectedUsers(ac) &&
          this._andFilterByFlagged(ac) &&
          this._andFilterByLEO(ac) &&
          this._andFilterByCategories(ac) &&
          this._andFilterOnRSUReview(ac) &&
          this._andFilterByResolved(ac) &&
          this._andFilterByCaseInfoPresence(ac) &&
          this._andFilterByCaseInfoAbscence(ac);
      }

      if (found) {
        cache[ac.alert_id] = true;
      }
      return found;
    });
  };

  public getFilteredAlertsAndFilteredComments = (): IStoredAlert[] => {
    const cache: IAlertIdCache = {};
    let alerts = this._getFilteredAlertsWithCache(this.alerts, cache);
    const comments = this._getFilteredCommentsUpdatingCache(
      this.comments,
      cache,
    );
    alerts = this._getAlertsFromFilteredComments(alerts, comments);
    alerts = this._mergeAlertsWithComments(alerts);

    return alerts;
  };

  @action public setAlerts = (alerts: IAlert[]) => {
    this.alerts.replace(alerts);
  };

  @action public setComments = (comments: IComment[]) => {
    this.comments.replace(comments);
  };

  @action public setDirectionSortFilter = (sortDirection: FilterDirection) => {
    if (sortDirection && sortDirection !== this.sortFilters.direction) {
      this.sortFilters.direction = sortDirection;
    }
  };

  @action public setValueSortFilter = (sortValue) => {
    if (sortValue && sortValue !== this.sortFilters.value) {
      this.sortFilters.value = sortValue;
    }
  };

  @action public setCommentsFilter = (value: string[]) => {
    this.selectedFilters.comments = value;
  };

  @action public setAlertsFilter = (value: string[]) => {
    this.selectedFilters.alerts = value;
  };

  @action public setDeletedFilter = (value: boolean) => {
    this.selectedFilters.deleted = value;
  };

  @action public setFlaggedFilter = (value: boolean) => {
    this.selectedFilters.flagged = value;
  };

  @action public setResolvedFilter = (value: boolean) => {
    this.selectedFilters.resolved = value;
  };

  @action public setRingUserFilter = (value: boolean) => {
    this.isRingUserChecked = value;
  };

  @action public setOnRSUFilter = (value: boolean) => {
    this.selectedFilters.onRSU = value;
  };

  @action public setIsAlertsWithCaseInfoFilter = (value: boolean) => {
    this.selectedFilters.isAlertsWithCaseInfo = value;
  };

  @action public setIsAlertsWithoutCaseInfoFilter = (value: boolean) => {
    this.selectedFilters.isAlertsWithoutCaseInfo = value;
  };

  @action public setIsLEOAlertsFilter = (value: boolean) => {
    this.selectedFilters.isLEOAlerts = value;
  };

  @action public setIsLEOCommentsFilter = (value: boolean) => {
    this.selectedFilters.isLEOComments = value;
  };

  @action public setSelectedUsersFilter = (users: string[]) => {
    this.selectedUsers = users;
  };

  @action public toogleCSVMode = () => {
    this.unchekedAlertIds.clear();
    this.generateCSVModeOn = !this.generateCSVModeOn;
  };

  // TODO: Remove after changing categories format on the server
  private _normalizeCategory = (category): ICategoryFilterOptions => {
    category.id = category.title;

    if (category.subcategory) {
      category.subcategory = category.subcategory.map(this._normalizeCategory);
    }

    return category;
  };

  // TODO: Remove after changing categories format on the server
  private _normalizeCategories = (categoriesRaw): ICategoryFilterOptions[] => {
    let ret;

    try {
      const categories = JSON.parse(JSON.stringify(categoriesRaw));
      // Remove comments approve subcategory
      delete categories['1']['0'][0].subcategory;
      // Add statuses for Approved and Deny categories
      categories['1']['0'][0].status = 1;

      ret = [
        {
          id: 'alerts',
          subcategory: [
            {
              status: 1,
              subcategory: categories['0']['0'].map(this._normalizeCategory),
              title: 'Approve',
            },
            {
              status: 3,
              subcategory: categories['0']['1'].map(this._normalizeCategory),
              title: 'Deny',
            },
            {
              status: 4,
              statusReason: 'auto_approve',
              title: 'Auto Approve',
            },
            {
              status: 5,
              statusReason: 'auto_decline',
              title: 'Auto Decline',
            },
          ],
          title: 'Alerts',
        },
        {
          id: 'comments',
          subcategory: [
            ...categories['1']['0'].map(this._normalizeCategory),
            {
              status: 3,
              title: 'Decline',
            },
            {
              status: 4,
              statusReason: 'auto_approve',
              title: 'Auto Approve',
            },
            {
              status: 5,
              statusReason: 'auto_decline',
              title: 'Auto Decline',
            },
          ],
          title: 'Comments',
        },
      ];
    } catch (e) {
      console.error(e);
    }

    return ret;
  };

  @action public setFilterCategories = (categories) => {
    this._categoryFilters.replace(this._normalizeCategories(categories));
  };

  get categoryFilters() {
    return this._categoryFilters;
  }

  @action public addNewComment = (comment: IStoredComment) => {
    const isCommentInList =
      this.comments.findIndex((e) => e.comment_id === comment.comment_id) >= 0;
    if (!isCommentInList) {
      this.comments.unshift(comment);
    }
  };

  @computed get rawAlertsCount() {
    return this.alerts.length;
  }

  @computed get alertsToDisplay() {
    const alerts = this.getFilteredAlertsAndFilteredComments();
    return this._sortEntities(alerts);
  }

  @computed get getTreeForUsers() {
    return this.getUsers
      .map(
        (e): IFilterUser => ({
          label: e,
          value: e,
        }),
      )
      .sort((a: IFilterUser, b: IFilterUser) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      });
  }

  @computed get getUsers() {
    const commentsAndAlerts = [...this.alerts, ...this.comments];

    commentsAndAlerts
      .filter((e) => Boolean(e))
      .map((e) => e.email)
      .filter((e) => e)
      .forEach((email) => this._users.add(email));

    return [...this._users];
  }

  public alertsToReport = () => {
    if (this.unchekedAlertIds.length > 0) {
      return this.alertsToDisplay.filter(
        (e) => this.unchekedAlertIds.indexOf(e.alert_id) < 0,
      );
    }

    return this.alertsToDisplay;
  };

  @action public checkAlert = (alertId: number) => {
    this.unchekedAlertIds.replace(
      this.unchekedAlertIds.filter((e) => e !== alertId),
    );
  };

  @action public uncheckAlert = (alertId: number) => {
    this.unchekedAlertIds.push(alertId);
  };

  @action public uncheckAllAlerts = () => {
    this.unchekedAlertIds.replace(this.alertsToDisplay.map((e) => e.alert_id));
  };

  @action public checkAllAlerts = () => {
    this.unchekedAlertIds.clear();
  };

  @action public resetStore = (clearSelectedDates = true) => {
    this.resetDataValues();
    this.selectedFilters = initialFilters;
    this.selectedUsers = [];
    this._users.clear();
    this.sortFilters.value = FilterValue.TimeCreated;
    this.sortFilters.direction = FilterDirection.Desc;

    if (clearSelectedDates) {
      // this.setInitialDates();
      this.from = undefined;
      this.to = undefined;
    }

    this.setContentMessageType(AdminContentMessageType.Initial);
  };

  @action public resetDataValues = () => {
    this.alertId = '';
    this.currentPage = 1;
    this.alerts.clear();
    this.comments.clear();
    this.unchekedAlertIds.clear();
    this.generateCSVModeOn = false;
    this.setContentMessageType(AdminContentMessageType.NewRequest);
  };

  @action public setContentMessageType(type: AdminContentMessageType) {
    this.contentMessageType = type;
  }

  @action public updateAlert = (
    alertId: number,
    properties: Partial<IStoredAlert>,
  ) => {
    const alert = this.alerts.find(({ alert_id }) => alert_id === alertId);

    if (!alert) return;

    Object.assign(alert, properties);
  };

  @action public setAlertCommentHistory = (
    commentId: string,
    history: IModerationHistory[],
  ) => {
    this.comments.some((comment) => {
      if (comment.comment_id !== commentId) {
        return false;
      }

      comment.history = history;
      return true;
    });
  };

  @action public deleteCommentHistory = (commentId: string) => {
    const commentToUpdate = this.comments.find(
      (comment) => comment.comment_id === commentId,
    );
    delete commentToUpdate.history;
  };

  @action public setCurretPage = (pageNumber: number) => {
    this.currentPage = pageNumber;
  };

  @action public deleteComment = (commentId: string) => {
    this.comments.replace(
      this.comments.filter((e) => e.comment_id !== commentId),
    );
  };

  @action public updateComment = (updatedComment: IComment) => {
    this.comments.replace(
      this.comments.map((comment) =>
        comment.comment_id === updatedComment.comment_id
          ? { ...comment, ...updatedComment }
          : comment,
      ),
    );
  };

  @action public updateCommentText = (commentId: string, text?: string) => {
    this.comments.replace(
      this.comments.map((e) =>
        e.comment_id === commentId
          ? {
              ...e,
              text,
            }
          : e,
      ),
    );
  };

  @action public generateCsv = (alerts: IStoredAlert[]) => {
    const rows = [
      'Time Created',
      'Alert ID',
      'Title',
      'Description',
      'Email',
      'Time Per Review',
      'Status',
      'Category',
      'Subcategory',
      'Ding ID',
      'Address',
      'Flagged',
      'Interesting for Ads',
      'Free Note',
      'Claimed',
      'Police post',
    ];

    let csv = rows.join(',');

    const generateRow = (alert: IStoredAlert) => {
      const createTime = moment(alert.time_created * 1000);
      const updateTime = moment(alert.time_updated * 1000);
      const timePerReview = moment(updateTime.diff(createTime)).format('mm:ss');
      const timeCreated = moment
        .unix(alert.time_created)
        .format('hh:mm A MMM D, YYYY');
      const [category, ...subcategory] = (alert.reason || []) as
        | string
        | string[];
      const alertDescription = replaceQuotesBySingleQuotes(alert.description);
      const alertTitle = replaceQuotesBySingleQuotes(alert.title);
      const alertAddress = replaceQuotesBySingleQuotes(alert.address);

      return [
        `"${timeCreated}"`,
        `"${alert.alert_id}"`,
        `"${removeInlineSymbols(alertTitle)}"`,
        `"${removeInlineSymbols(alertDescription)}"`,
        `"${alert.email ? alert.email : 'False'}"`,
        `"${timePerReview}"`,
        `"${(alert.status && getMessageTypeString(alert.status)) || 'False'}"`,
        `"${category || 'False'}"`,
        `"${subcategory || 'False'}"`,
        `"${alert.ding_id || 'False'}"`,
        `"${removeInlineSymbols(alertAddress) || 'False'}"`,
        `"${alert.is_flagged ? 'True' : 'False'}"`,
        `"${alert.interesting ? 'True' : 'False'}"`,
        `"${alert.free_note ? alert.free_note : 'False'}"`,
        `"${alert.is_claimed ? 'True' : 'False'}"`,
        `"${!!alert.cop ? alert.cop : 'False'}"`,
      ].join(',');
    };

    alerts.forEach((alert) => {
      csv = `${csv}\r\n${generateRow(alert)}`;
    });

    const blob = new Blob([csv], { type: 'text/csv' });
    const href = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('href', href);
    a.setAttribute(
      'download',
      `${moment(this.from).format('YYYY-MM-DD')}-${moment(this.to).format(
        'YYYY-MM-DD',
      )}.csv`,
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  public pagesCount = (alerts: IStoredAlert[]) => {
    return Math.ceil(alerts.length / itemsPerPage);
  };

  public alertsByPage(alerts: IStoredAlert[]) {
    const startItemIndex = (this.currentPage - 1) * itemsPerPage;
    const endItemIndex = itemsPerPage * this.currentPage;

    return alerts.slice(startItemIndex, endItemIndex);
  }

  public remoderateAlert = flow(
    function* (
      this: AdminStore,
      params: Parameters<typeof AdminService.remoderateAlert>[0],
    ) {
      try {
        const { alert_id } = params;

        this.isRemoderationAlertProcessing = true;

        const { reason, category_ids, status }: IAlert =
          yield AdminService.remoderateAlert(params);

        this.updateAlert(alert_id, {
          reason,
          category_ids,
          status,
        });
      } catch {
        // ignored
      } finally {
        this.isRemoderationAlertProcessing = false;
      }
    }.bind(this),
  );
}
