import React, { FC } from 'react';
import { Badge, BadgeVariants } from '../Badge';

export { BadgeVariants as BadgeColors };

export interface IFlagBadgeProps {
  borderOnly?: boolean;
  className?: string;
  color?: BadgeVariants;
  text: string;
}

export const FlagBadge: FC<IFlagBadgeProps> = ({
  borderOnly,
  className,
  color,
  text,
}) => (
  <Badge
    borderOnly={borderOnly}
    className={className}
    color={color}
    text={text}
  />
);
