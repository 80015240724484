import { observer } from 'mobx-react';
import React from 'react';
import AlertFreeNote from 'src/components/AlertFreeNote';
import { Flex } from 'src/components/Flex';
import { ModerationHistory } from 'src/componentsNew/Moderation/ModerationHistory';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import { SearchAlertHistoryDetails } from '../search-alert-history-details';

export const SearchAlertHistory = observer(({ alertId, ...props }) => {
  const { searchStore } = useStores();

  const alert = searchStore.alerts.get(alertId);
  const history = alert.history ?? [];

  return (
    <Flex {...props}>
      <ModerationHistory
        className="AdminHistoryTimeline"
        history={history}
        intro={<SearchAlertHistoryDetails {...alert} />}
      />
      {alert.moderation?.free_note && (
        <StyledAlertFreeNote freeNote={alert.moderation.free_note} />
      )}
    </Flex>
  );
});

const StyledAlertFreeNote = styled(AlertFreeNote)`
  margin-left: 0;

  > div {
    background: #f2f4f5;
  }
`;
