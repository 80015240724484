import { observer } from 'mobx-react';
import React from 'react';
import { Flex } from 'src/components/Flex';
import { useStores } from 'src/stores';
import { SearchResultAlert } from '../search-result-alert';

export const SearchResultList = observer(() => {
  const { searchStore } = useStores();

  if (!searchStore.hasAlerts && !searchStore.isLoading) {
    return (
      <Flex
        justifyContent="center"
        mt={3}
        fontSize="16px"
        data-test-id="searchResultListEmpty"
      >
        {searchStore.hasSearched ? 'No results' : 'Press "Search" to begin'}
      </Flex>
    );
  }

  return (
    <>
      {[...searchStore.alerts.keys()].map((alertId) => (
        <SearchResultAlert key={`search-result-${alertId}`} alertId={alertId} />
      ))}
    </>
  );
});
