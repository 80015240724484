import React from 'react';
import { Form, Select } from 'antd';

import { useStores } from 'src/stores';

const { Item } = Form;
const { Option } = Select;

export const ManageUsersFormSelectSubcategory = (props) => {
  const { manageUsersStore } = useStores();
  const {
    getFieldDecorator,
    handleSelect,
    index,
    selectedCategory,
    required,
  } = props;

  let initialSubcategoryValue = [],
    categoryId;

  if (manageUsersStore.isManageUsersFormSelectSubcategoryEditing(index)) {
    const editingCategories = manageUsersStore.editingUserCategories.slice();
    initialSubcategoryValue = editingCategories[index].subcategories;
    categoryId = editingCategories[index].id;
  }

  if (typeof selectedCategory === 'number') {
    categoryId = selectedCategory;
  }

  return (
    <>
      <Item
        label="Sub-Category"
        style={{ marginBottom: '15px', width: '100%' }}
      >
        {getFieldDecorator(`userViolationsSubcategory${index}`, {
          rules: [{ required }],
          initialValue: initialSubcategoryValue,
        })(
          <Select
            mode="multiple"
            placeholder="Select Sub-Category"
            style={{ width: '90%' }}
            onChange={handleSelect(index)}
          >
            {manageUsersStore.categoryEntities[categoryId]?.subcategories?.map(
              (subcategoryId, idx) => (
                <Option
                  key={`subcategory-${subcategoryId}-${idx}`}
                  value={
                    manageUsersStore.subcategoryEntities[subcategoryId]?.id
                  }
                >
                  {manageUsersStore.subcategoryEntities[subcategoryId].name}
                </Option>
              ),
            )}
          </Select>,
        )}
      </Item>
    </>
  );
};
