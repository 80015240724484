const {
  BACKEND_PORT,
  BACKEND_URL,
  BUILD_DATE,
  BUILD_HASH,
  BUILD_VERSION,
  DATADOG_TOKEN,
  NODE_ENV,
} = process.env;

export enum IModes {
  Development = 'development',
  Production = 'production',
}

export const config = {
  backend: {
    port: BACKEND_PORT as string | undefined,
    url: BACKEND_URL as string | undefined,
  },
  buildInfo: {
    date: BUILD_DATE as string | undefined,
    hash: BUILD_HASH as string | undefined,
    mode: NODE_ENV as IModes,
    version: BUILD_VERSION as string | undefined,
  },
  datadog: {
    token: DATADOG_TOKEN as string | undefined,
  },
  sentry: 'https://a0b7232e4e5c449aa00d5784dee64a33@sentry.io/189768',
};
