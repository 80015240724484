import cx from 'classnames';
import React, { ReactNode } from 'react';
import type { Theme } from 'src/theme';
import styled from 'styled-components';
import { variant } from 'styled-system';

export enum BadgeVariants {
  Grey = 'grey',
  Blue = 'blue',
  Orange = 'orange',
  Green = 'green',
  Purple = 'purple',
  Red = 'red',
}

export interface BadgeProps {
  borderOnly?: boolean;
  className?: string;
  color?: BadgeVariants;
  light?: boolean;
  text: ReactNode;
}

export const Badge = ({
  borderOnly = false,
  className,
  color = BadgeVariants.Grey,
  light,
  text,
}: BadgeProps) => {
  const testID =
    typeof text === 'string'
      ? `badge-${text.replace(' ', '').toLowerCase()}`
      : `badge-${text}`;

  return (
    <StyledBadge
      className={cx(className, { borderOnly, light })}
      variant={color}
      data-test-id={testID}
    >
      {text}
    </StyledBadge>
  );
};

const getVariant = (base: string) => {
  const color = `${base}100` as keyof Theme['colors'];
  const colorLight = `${base}65` as keyof Theme['colors'];

  return {
    bg: color,
    borderColor: color,
    '&.borderOnly': {
      color,
      bg: 'transparent',
    },
    '&.light': {
      bg: colorLight,
      borderColor: colorLight,
      '&.borderOnly': {
        color: colorLight,
        bg: 'transparent',
      },
    },
  };
};

const StyledBadge = styled.div<{ variant: string }>(
  ({ theme: { colors, antd } }) => ({
    backgroundColor: colors.grey100,
    border: `1px solid ${colors.grey100}`,
    borderRadius: antd['@border-radius-base'],
    color: colors.white100,
    display: 'inline-block',
    fontFamily: antd['@font-family-no-number'],
    fontSize: '10px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.3px',
    lineHeight: '1.6',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
  }),
  variant({
    variants: Object.values(BadgeVariants).reduce((acc, c) => {
      acc[c] = getVariant(c);
      return acc;
    }, {}),
  }),
);
