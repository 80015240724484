import css from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useStores } from 'src/stores';
import { ActionType } from '../../constants';
import s from './SearchInput.module.css';

interface SearchInputProps {
  store: any;
}

export const SearchInput = observer<FC<SearchInputProps>>(({ store }) => {
  const { uiStore, configStore } = useStores();

  const handleOnClose = (selectedReason) => {
    const { moderateOptions } = store;
    const { reasons } = moderateOptions;

    const idx = reasons.indexOf(selectedReason);

    if (idx === 0) {
      uiStore.toggleModal(false);
      moderateOptions.reasons = [];
    }

    if (reasons.length === 3) {
      moderateOptions.reasons = [reasons[0]];
    } else if (idx > 1) {
      moderateOptions.reasons = reasons.filter(
        (reason) => reason !== selectedReason,
      );
    } else {
      moderateOptions.reasons = reasons.slice(0, idx);
    }
  };

  const {
    moderateOptions: { reasons, entityType },
  } = store;

  const [status, category] = reasons;

  const [
    hasSubCategories,
    categories,
  ] = configStore.getApprovalCategoriesWithType(entityType, status);

  return (
    <div className={s.cnt}>
      <div className={s.slcCnt}>
        {reasons.map((reason, idx) => {
          const isStatus = idx === 0;
          const isCategory = idx === 1;
          const isReason = idx > 1;

          const neededCategory = hasSubCategories
            ? categories[category]
            : categories.find((e) => e.id === category);

          const reasonToRender =
            isReason && neededCategory.subcategory.find((e) => e.id === reason);

          return (
            <div
              key={idx}
              className={css(
                s.el,
                s[ActionType[status].toLowerCase()],
                { [s.fst]: isStatus },
                { [s.scd]: idx > 0 },
              )}
            >
              {isStatus && ActionType[status]}
              {isCategory && neededCategory.title}
              {isReason && reasonToRender && reasonToRender.title}

              <div className={s.close} onClick={() => handleOnClose(reason)} />
            </div>
          );
        })}
      </div>
    </div>
  );
});
