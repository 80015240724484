import styled from 'styled-components';
import { Flex } from 'src/components/Flex';

export interface BadgeAlignerProps {
  multiLine?: boolean;
}

export const BadgeAligner = styled(Flex)<BadgeAlignerProps>`
  max-width: none;
  margin: -8px 0 0 -8px;

  ${({ multiLine }) => multiLine && 'flex-wrap: wrap;'}

  & > * {
    margin: 8px 0 0 8px;
  }
`;
