import React from 'react';
import styled from 'styled-components';

export interface NoMediaViewProps {
  /** Additional css class */
  className?: string;
  /** Flag to show message */
  hideNoVisualContentText: boolean;
  /** Message to show in the placeholder*/
  message?: string;
  testID?: string;
}

export const NoMediaView = ({
  className,
  hideNoVisualContentText = false,
  message = 'No visual content',
  testID,
}: NoMediaViewProps) => (
  <Wrapper className={className} data-test-id={testID}>
    {noMedia}
    {!hideNoVisualContentText && <Text>{message}</Text>}
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey10};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const Text = styled.div`
  user-select: none;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey100};
`;

const noMedia = (
  <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="2.0-—-Investigations-Copy-5"
        transform="translate(-504.000000, -282.000000)"
        fillRule="nonzero"
        fill="#C5D0DE"
      >
        <g
          id="ic_videocam_off_48px"
          transform="translate(504.000000, 282.000000)"
        >
          <path
            d="M38,9 L30,17 L30,10 C30,8.9 29.1,8 28,8 L15.64,8 L38,30.36 L38,9 Z M2.55,0 L0,2.55 L5.45,8 L4,8 C2.9,8 2,8.9 2,10 L2,30 C2,31.1 2.9,32 4,32 L28,32 C28.41,32 28.77,31.85 29.09,31.63 L35.46,38 L38,35.45 L2.55,0 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
