import { Form } from 'antd';
import type { FormItemProps } from 'antd/lib/form';
import css from 'classnames';
import React from 'react';
import s from './field.module.css';

interface FieldProps extends FormItemProps {
  children?: JSX.Element[] | JSX.Element | string;
  className?: string;
}

export const FormItem = ({ className, children, ...restProps }: FieldProps) => (
  <Form.Item className={css(s.leftColumnField, className)} {...restProps}>
    {children}
  </Form.Item>
);
