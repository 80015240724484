import { Button } from 'antd';
import React from 'react';
import { BlockLayout } from '../BlockLayout';

interface SigninButtonProps {
  onClick: () => void;
}

export const SigninButton = ({ onClick }: SigninButtonProps) => (
  <BlockLayout
    right={
      <Button onClick={onClick} type="primary">
        Sign In
      </Button>
    }
  />
);
