import { observable } from 'mobx';
import { INavigationList, IPermissions } from 'src/interfaces';
import { NavigationTabs } from '../constants';
import { PermissionLevel } from '../restApi/interfaces';
import { InjectRootStore } from './injectRootStore';

export class NavigationStore extends InjectRootStore {
  private _currentTab = observable.box('');

  public links: INavigationList[] = Object.values(NavigationTabs).filter(
    (e: INavigationList) => e.showOnTab,
  );

  public permittedPaths: INavigationList[] = [];

  public pathsPermissions: IPermissions = {
    [NavigationTabs.ALERTS.path]: this.defaultPermissionsChecker,
    [NavigationTabs.ESCALATED.path]: [PermissionLevel.L1, PermissionLevel.L2],
    [NavigationTabs.RSU.path]: [PermissionLevel.Rsu],
    [NavigationTabs.COMMENTS.path]: this.defaultPermissionsChecker,
    [NavigationTabs.CONFIG_LIST_ALL_WORDS.path]: [PermissionLevel.Root], // fixme change permission level when ready
    [NavigationTabs.ADMIN.path]: this.defaultPermissionsChecker,
    [NavigationTabs.PERMISSIONS.path]: [PermissionLevel.Root],
    [NavigationTabs.SEARCH.path]: this.defaultPermissionsChecker,
    [NavigationTabs.MANAGE_USERS.path]: this.defaultPermissionsChecker,
    [NavigationTabs.CURATION_V2.path]: [PermissionLevel.Curation],
    [NavigationTabs.CURATION_V2_NEW.path]: [PermissionLevel.Curation],
    [NavigationTabs.CURATION_V2_EDIT.path]: [PermissionLevel.Curation],
  };

  public defaultPermissionsChecker(permissions: string[]) {
    if (
      !permissions.includes(PermissionLevel.Curation) ||
      permissions.includes(PermissionLevel.Rsu) ||
      permissions.includes(PermissionLevel.L1) ||
      permissions.includes(PermissionLevel.L2)
    ) {
      return [PermissionLevel.Default];
    }

    return [];
  }

  public getIndexOfPath = (path: string): number =>
    this.permittedPaths
      .map((navigationPath) => navigationPath.path)
      .indexOf(path);

  get navigationList() {
    const { user } = this.rootStore.authStore;

    if (user && !this.permittedPaths.length) {
      this.permittedPaths = this.links.filter((link) => {
        const linkPermission = this.pathsPermissions[link.path];

        const permissions =
          (typeof linkPermission === 'function'
            ? linkPermission(user.scope)
            : linkPermission) ?? [];

        return (
          permissions &&
          user.scope.some((permission) => permissions.includes(permission))
        );
      });
    }

    return this.permittedPaths;
  }

  get currentTab() {
    return this._currentTab.get();
  }

  public setCurrentTab = (currentTab: string) => {
    this._currentTab.set(currentTab);
  };

  public get isAlertsTabActive() {
    return this.currentTab === NavigationTabs.ALERTS.path;
  }

  public get isCommentsTabActive() {
    return this.currentTab === NavigationTabs.COMMENTS.path;
  }

  public get isEscalatedTabActive() {
    return this.currentTab === NavigationTabs.ESCALATED.path;
  }
}
