import { IAlert } from 'src/restApi/alertsService';
import React from 'react';
import { useMountedState } from 'react-use';
import { IModerationPayload } from './AlertModerationDialog';
import { DecisionType } from './constants';

export interface UseAlertModerationProps {
  alert: IAlert | null;
  onDecisionMade: (
    type: DecisionType,
    payload: IModerationPayload,
  ) => Promise<void>;
}

export const useAlertModeration = ({
  alert,
  onDecisionMade,
}: UseAlertModerationProps) => {
  const [decisionType, setDecisionType] = React.useState<DecisionType | null>(
    null,
  );
  const isMounted = useMountedState();
  // const prevAlert = usePrevious(alert);

  const isAlertModerationModalVisible = [
    DecisionType.Approve,
    DecisionType.Decline,
    DecisionType.SendToRsu,
    DecisionType.EscalateL2Approve,
    DecisionType.EscalateL2Decline,
  ].includes(decisionType);

  const isEscalateL1ModalVisible = decisionType === DecisionType.EscalateL1;
  const isEscalateL2ModalVisible = decisionType === DecisionType.EscalateL2;
  const nextEscalationLevel = getNextEscalationLevel(alert);
  const canEscalate = nextEscalationLevel !== null;

  const closeModals = () => setDecisionType(null);

  const handleCancelClick = () => closeModals();

  const handleApproveClick = () => setDecisionType(DecisionType.Approve);

  const handleDeclineClick = () => setDecisionType(DecisionType.Decline);

  const handleSendToRSUClick = () => setDecisionType(DecisionType.SendToRsu);

  const handleEscalateClick = () => setDecisionType(nextEscalationLevel);

  const handleDecisionSubmit = async (payload: IModerationPayload) => {
    await onDecisionMade(decisionType, payload);

    // TODO: fix this
    // AlertsPage use case, alert gets unmounted and hook is used inside each alert
    if (isMounted()) {
      closeModals();
    }
  };

  const handleEscalateL1Submit = () => handleDecisionSubmit(undefined);

  const handleEscalateL2ApproveClick = () => {
    setDecisionType(DecisionType.EscalateL2Approve);
  };

  const handleEscalateL2DeclineClick = () => {
    setDecisionType(DecisionType.EscalateL2Decline);
  };

  // React.useEffect(() => {
  //   if (!prevAlert || !alert) return;
  //   if (prevAlert.alert_id === alert.alert_id) return;

  //   closeModals();
  // }, [prevAlert, alert]);

  return {
    decisionType,
    canEscalate,
    isAlertModerationModalVisible,
    isEscalateL1ModalVisible,
    isEscalateL2ModalVisible,
    handleCancelClick,
    handleDeclineClick,
    handleApproveClick,
    handleSendToRSUClick,
    handleEscalateClick,
    handleEscalateL2ApproveClick,
    handleEscalateL2DeclineClick,
    handleEscalateL1Submit,
    handleDecisionSubmit,
  };
};

const getNextEscalationLevel = (alert: UseAlertModerationProps['alert']) => {
  if (!alert) return null;

  const { is_l1, is_l2 } = alert;

  if (is_l2) return null;
  if (is_l1) return DecisionType.EscalateL2;

  return DecisionType.EscalateL1;
};
