import Tippy from "@tippyjs/react";
import React from "react";
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';


export const Tooltip = ({content, children}) => (
  <Tippy
    content={content}
    theme="light"
    interactive={true}
    placement="right"
  >
    {children}
  </Tippy>
);
