import { Button, Form, Modal } from 'antd';
import type { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import {
  contextCleanupOnUnmount,
  MainLayoutContext,
} from '../../../../components/layouts/MainLayout';
import type { IShareMethods } from '../../components/NewsAlertForm';

interface IFormWrapperProps {
  cancelButtonText?: string;
  componentProps?: { [key: string]: any };
  formComponent: React.ComponentClass<FormComponentProps>;
  initialValues: { [key: string]: any };
  onCancel: () => void;
  onFormChange?: (currentValues: any) => void;
  onSave: (params) => void;
  renderFooterControls?: (onSubmit: Function) => React.ReactNode;
  renderHeaderControls?: (onCancel: Function) => React.ReactNode;
  submitButtonText?: string;
}

interface IFormWrapperState {
  showLeaveDialog: boolean;
}

export class FormWrapper extends React.Component<
  IFormWrapperProps,
  IFormWrapperState
> {
  constructor(props) {
    super(props);
    this.wrappedForm = Form.create({
      onValuesChange: this.handleFieldsChange,
    })(this.props.formComponent);
  }

  public _cachedFooter;
  public _cachedHeader;
  public childActions = {
    expectedMethods: ['onSubmit'],
    onSubmit: undefined,
  };
  public state = {
    showLeaveDialog: false,
  };
  public component: React.ReactNode;
  public isDirty = false;
  public unloadHandler;
  public wrappedForm;

  public componentDidMount() {
    this.childActions.expectedMethods.forEach((method) => {
      if (!this.childActions[method]) {
        throw new Error(
          `Method "${method}" was not defined in child component.`,
        );
      }
    });
  }

  public componentWillUnmount() {
    contextCleanupOnUnmount(this);
    this._restoreGlobalUnloadHandler();
  }

  public handleFieldsChange = (props, field, currentValues) => {
    const isDirty = this._isDirty(this.props.initialValues, currentValues);
    if (!this.isDirty && isDirty) {
      this._setGlobalUnloadHandler();
    } else if (this.isDirty && !isDirty) {
      this._restoreGlobalUnloadHandler();
    }
    this.isDirty = isDirty;
    this.props.onFormChange && this.props.onFormChange(currentValues);
  };

  public _setGlobalUnloadHandler = () => {
    this.unloadHandler = window.onbeforeunload;
    window.onbeforeunload = (e) => {
      const dialogText =
        'Would you like to continue working on the article or discard changes?';
      e.returnValue = dialogText;
      return dialogText;
    };
  };

  public _restoreGlobalUnloadHandler = () => {
    window.onbeforeunload = this.unloadHandler;
  };

  public _isDirty = (initialValues, currentFormValues) => {
    return !Object.keys(currentFormValues).every((field) => {
      return initialValues[field] === currentFormValues[field];
    });
  };

  public _hideModal = () => {
    this.setState({
      showLeaveDialog: false,
    });
  };

  public _showModal = () => {
    this.setState({
      showLeaveDialog: true,
    });
  };

  public callHandleCancelCallback = () => {
    this.props.onCancel();
  };

  public handleCancel = () => {
    if (!this.isDirty) {
      this.callHandleCancelCallback();
      return;
    }
    this._showModal();
  };

  public handleSave = async (values) => {
    return this.props.onSave(values);
  };

  public acceptMethods = ({ onSubmit }: IShareMethods) => {
    this.childActions.onSubmit = onSubmit;
  };

  public _renderDefaultHeaderControls = () => {
    if (!this._cachedFooter) {
      this._cachedFooter = (
        <Button key={'cancel'} onClick={this.handleCancel}>
          Cancel
        </Button>
      );
    }
    return this._cachedFooter;
  };

  public renderHeaderControls() {
    if (this.props.renderHeaderControls) {
      return this.props.renderHeaderControls(this.handleCancel);
    }
    return this._renderDefaultHeaderControls();
  }

  public submitClickHandler = (e) => {
    this.childActions.onSubmit(e);
  };

  public _renderDefaultFooterControls = () => {
    if (!this._cachedHeader) {
      this._cachedHeader = (
        <div key={'footerControls'} className={'nha-block-align-right'}>
          <Button
            type="primary"
            htmlType={'submit'}
            onClick={this.submitClickHandler}
          >
            {this.props.submitButtonText || 'Save'}
          </Button>
        </div>
      );
    }
    return this._cachedHeader;
  };

  public renderFooterControls = () => {
    if (this.props.renderFooterControls) {
      return this.props.renderFooterControls(this.submitClickHandler);
    }
    return this._renderDefaultFooterControls();
  };

  public renderCancelModal() {
    return (
      <Modal
        key={'cancelModalDialog'}
        title="Discard article?"
        visible={this.state.showLeaveDialog}
        onCancel={this._hideModal}
        footer={[
          <Button
            key="back"
            onClick={this.callHandleCancelCallback}
            type="danger"
          >
            Discard changes
          </Button>,
          <Button key="submit" onClick={this._hideModal} type="primary">
            Continue editing
          </Button>,
        ]}
      >
        Would you like to continue working on the article or discard changes?
      </Modal>
    );
  }

  public render() {
    const FormComponent = this.wrappedForm;
    return (
      <>
        <MainLayoutContext.Consumer>
          {({ setById, types }) => {
            setById(types.header, this, this.renderHeaderControls());
            setById(types.footer, this, this.renderFooterControls());
          }}
        </MainLayoutContext.Consumer>
        {this.renderCancelModal()}
        <FormComponent
          {...this.props.componentProps}
          onSave={this.props.onSave}
          shareMethods={this.acceptMethods}
          initialValues={this.props.initialValues}
        />
      </>
    );
  }
}
