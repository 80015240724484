import { api } from 'src/api';
import { IResponseItem } from 'src/restApi/restApi';
import {
  IComment,
  IModerationHistory,
  IStoredAlert,
} from '../restApi/interfaces';

export class CommentService {
  public static getComment = async (commentId: string) => {
    if (!commentId) return undefined;

    const response = await api
      .get(`comment/${commentId}`, {
        errorMessage: 'Request to get comment has failed.',
      })
      .json<IResponseItem<IComment>>();

    CommentService.convertCommentIdToString(response.data);

    return response.data;
  };

  public static getMissedComment = async (commentId: string) => {
    if (!commentId) return undefined;

    try {
      return await CommentService.getComment(commentId);
    } catch (error) {
      return undefined;
    }
  };

  public static getCommentHistory = async (commentId: string) => {
    if (!commentId) return [];

    try {
      const response = await api
        .get(`comment/${commentId}/history`, {
          errorMessage: 'Request to get comment history has failed.',
        })
        .json<IResponseItem<IModerationHistory[]>>();

      return response.data ?? [];
    } catch (e) {
      return [];
    }
  };

  public static convertCommentIdToString = (comment: IComment) => {
    if (comment) {
      comment.comment_id = comment.comment_id.toString();
    }
  };

  public static convertCommentIdsToString = (comments: IComment[]) => {
    if (comments) {
      for (let comment of comments) {
        CommentService.convertCommentIdToString(comment);
      }
    }
  };

  public static convertAlertCommentIdsToString = (alert: IStoredAlert) => {
    if (alert) {
      CommentService.convertCommentIdsToString(alert.comments);
    }
  };

  public static convertAlertsCommentIdsToString = (alerts: IStoredAlert[]) => {
    if (alerts) {
      for (let alert of alerts) {
        CommentService.convertAlertCommentIdsToString(alert);
      }
    }
  };
}
