import css from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useRef } from 'react';
import { useEvent } from 'react-use';
import { useStores } from 'src/stores';
import { SearchTypeKeys, SearchTypes } from '../../../../constants';
import s from './style.module.css';

export const Selector = observer(() => {
  const { adminHeaderSearchStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    adminHeaderSearchStore.toggleTypeSelector();
  }, [adminHeaderSearchStore]);

  const handleMousedown = useCallback(
    (e: MouseEvent) => {
      if (!wrapperRef.current?.contains(e.target as Node)) {
        adminHeaderSearchStore.toggleTypeSelector(false);
      }
    },
    [adminHeaderSearchStore],
  );

  useEvent('mousedown', handleMousedown);

  return (
    <div
      ref={wrapperRef}
      className={css(s.container, {
        [s.focus]: adminHeaderSearchStore.inputFocus,
        [s.invalid]: !adminHeaderSearchStore.isValueValid,
      })}
      onClick={handleClick}
    >
      Search by {SearchTypes[adminHeaderSearchStore.type]}
      <div className={s.dropdownImage}>{caret}</div>
      <div
        className={css(s.dropdown, {
          [s.open]: adminHeaderSearchStore.typeSelectorStateOpen,
        })}
      >
        {Object.entries(SearchTypeKeys).map(([type, value]) => (
          <div
            key={type}
            className={s.dropdownElement}
            onClick={() => adminHeaderSearchStore.changeType(value)}
          >
            Search by {SearchTypes[value]}
          </div>
        ))}
      </div>
    </div>
  );
});

const caret = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="10"
    height="7"
    viewBox="0 0 10 7"
  >
    <defs>
      <path
        id="a"
        d="M11.157 10.157a.997.997 0 0 1-.707.293h-5a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v5a.997.997 0 0 1-.293.707z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-3 -5)">
      <use transform="rotate(45 7.95 6.95)" xlinkHref="#a" id="arrow" />
      <g mask="url(#b)">
        <path d="M0 0h16v16H0z" />
      </g>
    </g>
  </svg>
);
