import css from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.css';

class LightBox extends React.Component<LightBoxProps> {
  public componentDidMount() {
    document.body.addEventListener('click', this.handleClick);
  }

  public componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClick);
  }

  public handleClick = (e) => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.props.onClose();
    }
  };

  public render() {
    return (
      <div className={css(this.props.className, styles.lightBoxContainer)}>
        {this.props.children}
      </div>
    );
  }
}

interface LightBoxProps {
  className?: string;
  onClose?: Function;
}

export { LightBox };
