import css from 'classnames';
import { inject } from 'mobx-react';
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { URLService } from 'src/services/urlService';
import { Button } from 'src/components/button';
import { Checkbox } from 'src/components/checkbox';
import { AdminStore, IFilterUser } from 'src/stores/adminStore';
import s from './AdminUserSelector.module.css';

enum CheckAllModes {
  None = 0,
  Partial = 1,
  All = 2,
}

@inject('adminStore')
export class AdminUserSelector extends React.Component<
  IAdminUserSelectorProps
> {
  public state = {
    checked: [],
    checkedAllMode: CheckAllModes.None,
    checkedRingUser: false,
    expanded: [],
  };

  public wrapperRef: null | HTMLElement;

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      checked: this.props.adminStore.selectedUsers,
      checkedAllMode: this._getSelectAllState(
        this.props.adminStore.selectedUsers,
      ),
      checkedRingUser: this.props.adminStore.isRingUserChecked,
    });
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public onCheck = (checked) => {
    this.setState({
      checked,
      checkedAllMode: this._getSelectAllState(checked),
    });
  };

  public onExpand = (expanded) => {
    this.setState({ expanded });
  };

  public onClickOk = () => {
    URLService.addSearchParams({
      rsu: this.state.checkedRingUser,
      user: this.state.checked.join(','),
    });
    this.props.adminStore.selectedUsers = this.state.checked;
    this.props.adminStore.isRingUserChecked = this.state.checkedRingUser;
    this.props.acceptButtonHandler();
  };

  public handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeElement();
    }
  };

  public onCheckRingUser = () => {
    this.setState({
      checkedRingUser: !this.state.checkedRingUser,
    });
  };

  public toggleSelectAllHandler = () => {
    if (this.state.checkedAllMode === CheckAllModes.All) {
      this.setState({
        checked: [],
        checkedAllMode: CheckAllModes.None,
      });
    } else {
      this.setState({
        checked: this.props.adminStore.getTreeForUsers.map(
          (item: IFilterUser) => item.value,
        ),
        checkedAllMode: CheckAllModes.All,
      });
    }
  };

  protected _getSelectAllState = (selectedUsers) => {
    const usersLength = this.props.adminStore.getTreeForUsers.length;

    if (selectedUsers.length) {
      if (selectedUsers.length === usersLength) {
        return CheckAllModes.All;
      }
      return CheckAllModes.Partial;
    }

    return CheckAllModes.None;
  };

  public render() {
    const { checked, checkedAllMode, expanded } = this.state;
    const users = this.props.adminStore.getTreeForUsers;
    return (
      <div className={s.container} ref={(e) => (this.wrapperRef = e)}>
        <div>
          <Checkbox
            checked={checkedAllMode !== CheckAllModes.None}
            checkmarkClassName={s.selectAllCheckmark}
            className={s.selectAllCheckbox}
            labelClassName={s.selectAllLabel}
            onChange={this.toggleSelectAllHandler}
            optional={checkedAllMode === CheckAllModes.Partial}
            titleClassName={s.selectAllTitleClassName}
            title={[
              'All',
              <span key="selectedUsersCount" className={s.usersSelectedCounter}>
                {checked.length}/{users.length}
              </span>,
            ]}
          />

          {this.props.adminStore.getUsers && (
            <CheckboxTree
              checked={checked.slice()}
              expanded={expanded}
              nodes={users}
              onCheck={this.onCheck}
              onExpand={this.onExpand}
            />
          )}
        </div>
        <div className={css('react-checkbox-tree', s.ringUserContainer)}>
          <span className="rct-text">
            <label>
              <input
                checked={this.state.checkedRingUser}
                type="checkbox"
                value="on"
                onChange={this.onCheckRingUser}
              />
              <span className="rct-checkbox">
                <span className="rct-icon rct-icon-uncheck" />
              </span>
              <span className={css('rct-title', s.rsuTitle)}>
                <span className={css('rsu-label left-row')}>RING</span>
                <span>Superuser</span>
              </span>
            </label>
          </span>
        </div>
        <Button
          action={this.onClickOk}
          additionalClassName={css(s.submitButton, 'row top-column')}
        >
          <div>Confirm</div>
        </Button>
      </div>
    );
  }
}

interface IAdminUserSelectorProps {
  adminStore?: AdminStore;
  acceptButtonHandler: Function;
  closeElement: Function;
}
