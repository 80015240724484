import moment from 'moment';
import { DATE_FORMAT, TODAY_DATE_FORMAT } from '../constants';

export const isTheSameDay = (timestamp: string | number | Date) => {
  return new Date(timestamp).getTime() > +moment().startOf('day').format('x');
};

export const formatDateShortenedToday = (timestamp: string | number | Date) => {
  return moment(timestamp).format(
    isTheSameDay(timestamp) ? TODAY_DATE_FORMAT : DATE_FORMAT,
  );
};
