import { message } from 'antd';
import { ConfigOptions } from 'antd/lib/message';
import { action, observable } from 'mobx';
import { InjectRootStore } from './injectRootStore';

export class NotificationStore extends InjectRootStore {
  @observable public notifications = [];

  @action
  public addNotification = (notification: INotification) => {
    alertT(notification);
  };
}

const alertT = (params: INotification) => {
  const { type, message: messageStr, ...restParams } = params;
  message.config(restParams);
  message[type](messageStr);
};

export interface INotification extends ConfigOptions {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
}
