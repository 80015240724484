import { Timeline } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useTheme } from 'styled-components';

export interface IHistoryProps<T> {
  /** Additional class name. */
  className?: string;
  /** An array of history items. */
  history: T[];
  /** Render method which renders Timeline.Item component. */
  historyItemRender: (props: T) => React.ReactNode;
  intro?: React.ReactNode;
  testID?: string;
}

export const History = <T extends {}>({
  className,
  history,
  historyItemRender,
  intro,
  testID,
}: IHistoryProps<T>) => {
  const {
    colors: { blue65 },
  } = useTheme();

  return (
    <Timeline className={classNames('History', className)}>
      {intro && (
        <Timeline.Item
          className="History__intro"
          color={blue65}
          data-test-id={testID}
        >
          {intro}
        </Timeline.Item>
      )}

      {history.map((item: T, index: number) =>
        historyItemRender({ ...item, key: index }),
      )}
    </Timeline>
  );
};
