import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { AlertModerateModalContent } from 'src/components/alert-moderate-modal-content';
import { AlertModerateModalFooter } from 'src/components/alert-moderate-modal-footer';
import { AlertModerateModalHeader } from 'src/components/alert-moderate-modal-header';
import { useStores } from 'src/stores';
import s from './AlertModerateModal.module.css';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
} as const;

export interface IModalProps {
  store?: any;
  className?: string;
  header?: JSX.Element;
  content?: JSX.Element;
  footer?: JSX.Element;
  show: boolean;
  isButtonEnable?: boolean;
}

export const AlertModerateModal = observer<FC<IModalProps>>(({ className }) => {
  const { uiStore, moderateModalStore } = useStores();
  const { isModalShow } = uiStore;
  const { moderateOptions } = moderateModalStore;

  const onCloseModal = useCallback(() => {
    const {
      footer: { cancelAction },
    } = uiStore.modal;

    uiStore.toggleModal(false);
    uiStore.modal.content.element = null;
    uiStore.modal.content.showSelect = true;

    cancelAction();
  }, [uiStore]);

  useEffect(() => {
    return onCloseModal;
  }, [onCloseModal]);

  if (!isModalShow) return null;

  return (
    <div style={styles}>
      <Modal
        open={isModalShow}
        onClose={onCloseModal}
        classNames={{
          overlay: s.overlay,
          modal: `${s.modal} ${className}`,
          closeIcon: s.close,
        }}
      >
        <AlertModerateModalHeader />
        <AlertModerateModalContent />
        <AlertModerateModalFooter
          alertsListStore={moderateModalStore}
          isButtonEnable={
            moderateOptions.reasons && moderateOptions.reasons.length > 1
          }
        />
      </Modal>
    </div>
  );
});
