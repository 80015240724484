import classNames from 'classnames';
import React from 'react';
import './ToggleIconButton.less';

export interface IToggleButton {
  /** Additional class name */
  className?: string;
  /** Selector for e2e testing */

  /** Flag whether opened icon should be shown */
  isOpened?: boolean;
  /** Toggle handler will be called on click. The value will contain inverted isOpened state. */
  onToggle: (isOpened: boolean) => void;
  /** Used for automation */
  testID?: string;
}

export const ToggleIconButton = ({
  className,
  isOpened = false,
  onToggle,
  testID,
}: IToggleButton) => (
  <div
    className={classNames(
      'ToggleIconButton',
      { ToggleIconButton__opened: isOpened },
      className,
    )}
    onClick={() => onToggle(!isOpened)}
    data-test-id={testID}
  />
);
