import ReconnectingWebSocket from 'reconnecting-websocket';
import { wssLocation } from 'src/helpers';
import { IWebsocket } from 'src/interfaces';

export class WsConnector {
  private static connection = null;
  private static ignoredMessages = {};
  private static listeners: IEventsList = {};

  private static listenerHandler = (listenerKey: string, data: object) => {
    const listeners = WsConnector.listeners[listenerKey];

    if (!listeners) return false;

    const shouldBeIgnored = WsConnector.checkIsMessageShouldIgnored(
      listenerKey,
      data,
    );

    if (shouldBeIgnored) return false;

    listeners.forEach((cb) => cb(data));

    return true;
  };

  private static messageReciever = (message: IWebsocket) => {
    const messages = JSON.parse(message.data);

    messages.forEach((message) => {
      const listenerKey = `${message.type_msg}_${message.type_entity}`;

      WsConnector.listenerHandler(listenerKey, message.data);
    });
  };

  private static checkIsMessageShouldIgnored = (
    listenerKey: string,
    data: {},
  ): boolean => {
    const messages = WsConnector.ignoredMessages[listenerKey];

    if (!messages) return false;

    const dataKeys = Object.keys(messages);

    const blockResult = {
      shouldCount: dataKeys.length,
      foundCount: 0,
    };

    dataKeys.forEach((key) => {
      if (key in data && messages[key] === data[key]) {
        blockResult.foundCount += 1;
      }
    });

    return blockResult.foundCount === blockResult.shouldCount;
  };

  private static addMessageForIgnore = (listenerKey: string, data: {}) => {
    if (!(listenerKey in WsConnector.ignoredMessages)) {
      WsConnector.ignoredMessages[listenerKey] = data;
    }
  };

  private static removeMesageFromIgnore = (listenerKey: string) => {
    if (listenerKey in WsConnector.ignoredMessages) {
      delete WsConnector.ignoredMessages[listenerKey];
    }
  };

  public static ignoreMessage = (
    messageType: number,
    entityType: number,
    withData = {},
  ) => {
    if (messageType && entityType) {
      const listenerKey = `${messageType}_${entityType}`;

      WsConnector.addMessageForIgnore(listenerKey, withData);
    }
  };

  public static passMessage = (messageType: number, entityType: number) => {
    if (messageType && entityType) {
      const listenerKey = `${messageType}_${entityType}`;

      WsConnector.removeMesageFromIgnore(listenerKey);
    }
  };

  public static addListener = (
    messageType: number,
    entityType: number,
    listener: Function,
  ) => {
    const key = `${messageType}_${entityType}`;

    if (!(key in WsConnector.listeners)) {
      WsConnector.listeners[key] = [];
    }

    WsConnector.listeners[key].push(listener);
  };

  public static removeListener = (
    messageType: number,
    entityType: number,
    listener?: Function,
  ) => {
    const listenerKey = `${messageType}_${entityType}`;

    if (listenerKey in WsConnector.listeners) {
      if (
        listener !== undefined &&
        WsConnector.listeners[listenerKey] !== undefined
      ) {
        WsConnector.listeners[listenerKey] = WsConnector.listeners[
          listenerKey
        ].filter((handler: Function) => handler !== listener);
      } else {
        delete WsConnector.listeners[listenerKey];
      }
    }
  };

  public static runWebsocket = () => {
    if (WsConnector.connection) return;

    WsConnector.connection = new ReconnectingWebSocket(
      `${wssLocation()}/api/v1/websocket_handler`,
    );

    WsConnector.connection.addEventListener(
      'message',
      WsConnector.messageReciever,
    );
  };

  public static stopWebsocket = () => {
    if (!WsConnector.connection) return;

    WsConnector.listeners = {};

    WsConnector.connection.removeEventListener(
      'message',
      WsConnector.messageReciever,
    );

    WsConnector.connection = null;
  };
}

interface IEventsList {
  [eventName: string]: Function[];
}
