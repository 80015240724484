import { INewsSearchOptions, NewsStatus } from '../../restApi/newsService';

export const CLEAR_TIMEOUT: number = 500;
export const FETCH_NEW_NEWS_INTERVAL: number = 60000; // 1 min
export const INFINITE_SCROLL_TIMEOUT: number = 500;

export const NEWS_FILTER_PROPS = [
  'categories',
  'keywords',
  'src/services',
  'statuses',
  'types',
];

export const NEWS_INIT_OPTIONS: INewsSearchOptions = {
  categories: [],
  editors: [],
  keywords: '',
  services: [],
  statuses: [],
  types: [],
};

export const NEWS_HISTORY_LIMIT: number = 50;

export const NEWS_SERVICES_DICT: { [key: string]: string } = {
  Colbert: 'Colbert',
  NC4_RISK_CENTER: 'NC4 Risk Center',
  Social: 'Social',
  STREET_SMART: 'Street Smart',
  TW: 'Talk Walker',
  BNN: 'BNN',
  ShotSpotter: 'ShotSpotter',
  NewsWhip: 'NewsWhip',
  other: 'Other',
};

export const SHOW_NEW_NEWS_COUNT: number = 5;

export const NEWS_STATUSES_DICT: { [key in NewsStatus]: string } = {
  [NewsStatus.Deleted]: 'Deleted',
  [NewsStatus.InReview]: 'In Review',
  [NewsStatus.New]: 'New',
  [NewsStatus.Published]: 'Published',
};
