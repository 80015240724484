import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { AlertModerateCheckbox } from 'src/components/alert-moderate-checkbox';
import { AlertNoteInput } from 'src/components/alert-note-input';
import { SearchInput } from 'src/components/selected-items';
import { useStores } from 'src/stores';
import s from './AlertModerateModalContent.module.css';

export const AlertModerateModalContent = observer<FC>(() => {
  const { alertsListStore, moderateModalStore, uiStore } = useStores();

  const {
    reasons: [group],
  } = moderateModalStore.moderateOptions;

  const {
    modal: {
      content: { showSelect, element },
    },
  } = uiStore;

  const checkSubCategory = (checkedCategories, hasSubcategory) => {
    const {
      reasons: [, category, ...sub],
    } = moderateModalStore.moderateOptions;

    const [
      groupChecked,
      categoryChecked,
      subcategoryChecked,
    ] = checkedCategories;
    const subcategories = sub.some((e) => e === subcategoryChecked)
      ? sub.filter((e) => e !== subcategoryChecked)
      : [...sub, subcategoryChecked];

    if (!hasSubcategory) {
      moderateModalStore.moderateOptions.reasons = [
        groupChecked,
        categoryChecked,
      ];
    } else if (categoryChecked !== category) {
      moderateModalStore.moderateOptions.reasons = [
        groupChecked,
        categoryChecked,
        subcategoryChecked,
      ];
    } else if (subcategories.length > 0) {
      moderateModalStore.moderateOptions.reasons = [
        groupChecked,
        categoryChecked,
        ...subcategories,
      ];
    } else {
      moderateModalStore.moderateOptions.reasons = [groupChecked];
    }
  };

  return (
    <div className={s.modalContainer}>
      {element ? (
        <span key="element">{element}</span>
      ) : (
        <>
          {showSelect && (
            <SearchInput store={alertsListStore} key="selectedItems" />
          )}
          <div className={s.categoryContainer} key="modal_content_container">
            {moderateModalStore.moderateOptions.reasonsToRender.map(
              ({ subcategory, title, id }: any, idx) => (
                <div className={s.category} key={`sub_category_${id}`}>
                  {subcategory ? (
                    <>
                      <h3 className={s.categoryTitle} key={`title_${id}`}>
                        {title}
                      </h3>
                      {subcategory.map((el) => (
                        <AlertModerateCheckbox
                          id={el.id}
                          reasons={moderateModalStore.moderateOptions.reasons}
                          handler={() =>
                            checkSubCategory([group, idx, el.id], true)
                          }
                          hasSubcategory
                          title={el.title}
                          type="checkbox"
                          category={idx}
                          key={el.id}
                        />
                      ))}
                    </>
                  ) : (
                    <AlertModerateCheckbox
                      reasons={moderateModalStore.moderateOptions.reasons}
                      handler={() => checkSubCategory([group, id], false)}
                      category={id}
                      title={title}
                      key={`category-checkbox-${id}`}
                      type="radio"
                      id={id}
                    />
                  )}
                </div>
              ),
            )}
          </div>
          <div className={s.inputContainer} key="alert-modal-content-input">
            <AlertNoteInput
              value={moderateModalStore.moderateOptions.freeNote}
              onChange={(e) => {
                moderateModalStore.moderateOptions.freeNote = e;
              }}
              classNames="column top-column-double"
              key="AlertNoteInput"
            />
          </div>
        </>
      )}
    </div>
  );
});
