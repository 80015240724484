import css from 'classnames';
import React from 'react';
import styles from './style.module.css';

export class Checkbox extends React.Component<IAlertModerateOptionsProps> {
  public onChangeHandler = (e) => {
    this.props.onChange && this.props.onChange(e.target.checked as boolean);
  };

  public render() {
    return (
      <div className={css(styles.container, this.props.className)}>
        <label
          className={css(
            styles.checkboxContainer,
            this.props.optional && styles.optionalCheckboxContainer,
            this.props.labelClassName,
          )}
        >
          {this.props.title && (
            <div
              className={css(styles.checkboxText, this.props.titleClassName)}
            >
              {this.props.title}
            </div>
          )}
          <input
            type="checkbox"
            className={styles.checkbox}
            onChange={this.onChangeHandler}
            checked={this.props.checked}
          />
          <span
            className={css(styles.checkmark, this.props.checkmarkClassName)}
          />
        </label>
      </div>
    );
  }
}

export interface IAlertModerateOptionsProps {
  checked?: boolean;
  onChange?: Function;
  title?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  titleClassName?: string;
  checkmarkClassName?: string;
  optional?: boolean;
  disable?: boolean;
}
