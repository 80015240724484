import moment from 'moment';
import { PermissionLevel } from '../restApi/interfaces';

export const moderationStatsPollingInterval = 30000;
export const alertPollingInterval = 30000;
export const TimeForChangeDecision = 120; // in milliseconds
export const removingAlertDelay = 2000;
export const REMOVE_ALERT_RSU_DELAY = 120000;
export const moderationCommentStatsPollingInterval = 2000;

export const UnsupportBrowserText =
  'Unsupported browser. You are using a browser we do not support yet. Please use Chrome to improve your experience.';

export const APP_TITLE = 'Neighbors Admin';

export enum ApprovalContentType {
  Alert = 0,
  Comment = 1,
}

export enum ApproveCategoryReason {
  Approve = 0,
  Deny = 1,
  Escalate = 2,
}

export enum SubmitAlertType {
  Approved = 'APPROVED',
  Declines = 'DECLINED',
}

export enum TopLevelAlertApprovalCategory {
  Crime,
  SuspiciousActivity,
  Safety,
  Solicitor,
  LostPets,
  NeighborlyMoment,
}

export enum FilterDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum FilterValue {
  TimeCreated = 'time_created',
  TimePerReview = 'moderation_time_s',
}
export enum Authorization {
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  Unauthorized = 'UNAUTHORIZED',
}

export enum AdminContentMessageType {
  Initial = 'INITIAL',
  NewRequest = 'NEW_REQUEST',
  NotFoundByDate = 'NOTFOUNDBYDATE',
  NotFoundByAlertId = 'NOTFOUNDBYALERTID',
  NotFoundByCommentId = 'NOTFOUNDBYCOMMENTID',
  NotFoundByKeyword = 'NOTFOUNDBYKEYWORD',
}

export const UserEscalationLevels = {
  [PermissionLevel.L1]: 'l1',
  [PermissionLevel.L2]: 'l2',
};

export const EscalationLevelProperties = {
  [PermissionLevel.L1]: 'is_l1',
  [PermissionLevel.L2]: 'is_l2',
} as const;

export const escalationLevelPropertiesArray = [
  EscalationLevelProperties[PermissionLevel.L1],
  EscalationLevelProperties[PermissionLevel.L2],
];

export enum APIStatus {
  Ok = 0,
  Failed = 1,
  ValidationError = 2,
  InvalidScope = 3,
}

export const ModerationStatusDictionary = {
  0: 'NEW',
  1: 'APPROVED',
  2: 'PENDING',
  3: 'DECLINED',
  4: 'AUTO_APPROVED',
  5: 'AUTO_DECLINED',
};

export enum WebsocketEntityType {
  Alert = 0,
  Comment = 1,
}

export enum WebsocketMessageType {
  New = 0,
  Approved = 1,
  Rejected = 2,
  Assigned = 3,
  L1Escalated = 4,
  L2Escalated = 5,
  AutoAssigned = 6,
  Deleted = 7,
  AutoRejected = 8,
  Edited = 9,
  SendToRsu = 10,
  RemovedFromRsu = 11,
  AutoApproved = 12,
  AutoAssignSessionOpened = 13,
  AutoAssignSessionClosed = 14,
  Flagged = 15,
}

export const ActionType = {
  0: 'Approve',
  1: 'Decline',
  2: 'Escalate',
};

// ! Navigation component is rendering tabs in the same order as they appear in the following object!
export const NavigationTabs = {
  ALERTS: { title: 'Alerts', path: '/', showOnTab: true },
  COMMENTS: { title: 'Comments', path: '/comments', showOnTab: true },
  ESCALATED: { title: 'Escalated', path: '/escalated', showOnTab: true },
  RSU: { title: 'RSU', path: '/rsu', showOnTab: true },
  ADMIN: { title: 'Admin', path: '/admin', showOnTab: true },
  SEARCH: {
    title: 'Search',
    path: '/search',
    showOnTab: true,
  },
  CURATION: { title: 'News Curation', path: '/curation', showOnTab: true },
  CURATION_NEW: {
    title: 'New News Alert',
    path: '/curation/create',
    showOnTab: false,
  },
  CURATION_EDIT: {
    title: 'Edit News Alert',
    path: '/curation/:id/edit',
    showOnTab: false,
  },
  CURATION_V2: {
    title: 'News Curation V2',
    path: '/curationV2',
    showOnTab: true,
  },
  CURATION_V2_NEW: {
    title: 'New News Alert',
    path: '/curationV2/create',
    showOnTab: false,
  },
  CURATION_V2_EDIT: {
    title: 'Edit News Alert',
    path: '/curationV2/:id/edit',
    showOnTab: false,
  },
  SIGNIN: { title: 'Signin', path: '/signin', showOnTab: false },
  PERMISSIONS: { title: 'Permissions', path: '/permissions', showOnTab: false },
  CONFIG_LIST_ALL_WORDS: {
    title: 'Config List',
    path: '/config-list/words',
    showOnTab: true,
  },
  MANAGE_USERS: {
    title: 'User Management',
    path: '/manage-users',
    showOnTab: true,
  },
} as const;

export const tabsStores = {
  ALERTS: 'alertsTab',
  COMMENTS: 'commentsTab',
  ESCALATED: 'escalateTab',
  RSU: 'rsuTab',
} as const;

export const CommentsAssignFlags = {
  DEFAULT: 'default',
  L1: 'is_l1',
  L2: 'is_l2',
};

export const tabsByStore = {
  [tabsStores.ALERTS]: NavigationTabs.ALERTS.path,
  [tabsStores.ESCALATED]: NavigationTabs.ESCALATED.path,
  [tabsStores.COMMENTS]: NavigationTabs.COMMENTS.path,
  [tabsStores.RSU]: NavigationTabs.RSU.path,
};

export const SearchTypeKeys = {
  ALERT: 0,
  COMMENT: 1,
  KEYWORD: 2,
};

export const SearchTypes = {
  [SearchTypeKeys.ALERT]: 'Alert ID',
  [SearchTypeKeys.COMMENT]: 'Comment ID',
  [SearchTypeKeys.KEYWORD]: 'Keyword',
};

export const SearchTypeErrors = {
  [SearchTypeKeys.ALERT]: 'Only numbers can be entered',
  [SearchTypeKeys.COMMENT]: 'Only numbers can be entered',
  [SearchTypeKeys.KEYWORD]: 'Wrong Keyword',
};

export const TIME_FORMAT = 'hh:mm A';
export const TITLE_DATE_FORMAT = 'MMMM Do YYYY, hh:mm:ss a';
export const DATE_FORMAT = 'hh:mm A MMM DD, YYYY';
export const TODAY_DATE_FORMAT = 'hh:mm A [Today]';

export const ALERTS_FETCH_LIMIT = 100;

// Curation Page
export const MAX_UNREAD_NEWS = 100;
export const DEFERRED_CLEAR_TIMER = 500;
export const NEWS_TO_SHOW = 5;
export const NEWS_FETCH_TIMEOUT = 60000; // 1 min
export const DEFAULT_FROM_TIME = [-2, 'hours'];
export const DEFAULT_TO_TIME = [1, 'days'];
export const DEFAULT_PAST_FROM_TIME = 0;
export const INIT_OPTIONS = {
  categories: [],
  editors: [],
  regions: [],
  services: [],
  sources: [],
  statuses: [],
  types: [],
};

export enum NewsSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export const DEFAULT_GET_NEWS_PARAMS = {
  limit: 50,
  offset: 0,
  order: NewsSortDirection.Asc,
};

export const getDefaultNewsTime = () => ({
  from_time: moment().unix().valueOf(),
  to_time: moment()
    .add(...DEFAULT_TO_TIME)
    .unix()
    .valueOf(),
});

export const KEYS = {
  ESC: 27,
};

export const ALERT_REASONS = {
  auto_approve: 'Auto Approved',
  auto_decline: 'Auto Declined',
};
