import React, { Fragment } from 'react';
import { Button } from 'antd';

import { useStores } from 'src/stores';

import { ManageUsersFormSelectCategory } from '../manage-users-form-select-category';
import { ManageUsersFormSelectSubcategory } from '../manage-users-form-select-subcategory';

export const ManageUsersFormUserViolations = ({
  categoriesCount,
  getFieldDecorator,
  handleAddUserViolation,
  handleRemoveUserViolation,
  handleSelectCategory,
  handleSelectSubcategory,
  selectedCategories,
  selectedSubcategories,
}) => {
  const { manageUsersStore } = useStores();

  return (
    <>
      {categoriesCount.map((category, index) => {
        return (
          <Fragment key={`${category}-${index}`}>
            <ManageUsersFormSelectCategory
              categoryKey={category}
              count={categoriesCount}
              getFieldDecorator={getFieldDecorator}
              handleRemoveUserViolation={handleRemoveUserViolation}
              handleSelect={handleSelectCategory}
              index={index}
              options={manageUsersStore.categories}
              selectedCategory={selectedCategories[index]}
              selectedCategories={selectedCategories}
              required={index === 0}
            />
            <ManageUsersFormSelectSubcategory
              count={categoriesCount}
              getFieldDecorator={getFieldDecorator}
              handleSelect={handleSelectSubcategory}
              index={index}
              selectedCategory={selectedCategories[index]}
              selectedCategories={selectedCategories}
              selectedSubcategories={
                selectedSubcategories[selectedCategories[index]]?.subcategories
              }
              handleAddUserViolation={handleAddUserViolation}
              required={index === 0}
            />
          </Fragment>
        );
      })}
      <Button
        onClick={handleAddUserViolation}
        type="link"
        style={{ padding: 0, marginBottom: '10px' }}
      >
        + Add Violations
      </Button>
    </>
  );
};
