import css from 'classnames';
import React from 'react';
import { TextView } from 'src/components/text-view';
import s from './style.module.css';

const CROP_END = 200;

export const AlertFreeNote = ({
  className = '',
  freeNote,
  cropEnd = CROP_END,
  testID = '',
}) => (
  <div
    className={css(s.freeNoteContainer, 'row-double column-double', className)}
  >
    <div className={s.freeNoteText}>
      <TextView text={freeNote} cropTextSize={cropEnd} testID={testID} />
    </div>
  </div>
);

export default AlertFreeNote;
