import { ValidationRule } from 'antd/lib/form';
import {
  AddressMapControllerMode,
  Field,
  IAddressMapControllerChangePayload,
  IAddressMapControllerProps,
  IChangeProps,
  IGeopointFieldProps,
  IMapFieldProps,
  InputEvent,
  KeyboardEvent,
} from './AddressMapController.interface';


type Range = [number, number];

const isNumberInRange = (num: number, [min, max]: Range) => {
  return min <= num && num <= max;
};

const createRangeValidator = (range: Range): ValidationRule => ({
  validator: (rule, value) => {
    const numericValue = parseFloat(value);

    if (!numericValue || isNumberInRange(numericValue, range)) {
      return Promise.resolve();
    }

    const [min, max] = range;
    return Promise.reject(`Must be between ${min} and ${max}`);
  },
});

const coordsRanges: {
  latitude: Range;
  longitude: Range;
} = {
  latitude: [-90, 90],
  longitude: [-180, 180],
};

export class AddressMapController {
  public _address = {
    address: undefined,
    clearId: undefined,
    latitude: undefined,
    longitude: undefined,
    selectedItem: undefined,
  };

  public _changeListener;

  public _initiator: Field | undefined;

  public _initial = {
    address: undefined,
    latitude: undefined,
    longitude: undefined,
  };

  public _latitude = {
    latitude: undefined,
    latitudeTmp: undefined,
  };

  public _longitude = {
    longitude: undefined,
    longitudeTmp: undefined,
  };

  public _map = {
    latitude: undefined,
    longitude: undefined,
  };

  public _mode = AddressMapControllerMode.Init;
  public _previousMode: AddressMapControllerMode | undefined;

  constructor(props?: IAddressMapControllerProps) {
    if (props) {
      this._processChangeField(Field.Address, props);
      this._processChangeField(Field.Latitude, {
        latitude: props.latitude,
        latitudeTmp: props.latitude,
      });
      this._processChangeField(Field.Longitude, {
        longitude: props.longitude,
        longitudeTmp: props.longitude,
      });
      this._initial.address = props.address;
      this._initial.latitude = props.latitude;
      this._initial.longitude = props.longitude;
    }
    this._setMode(AddressMapControllerMode.Render, undefined);
  }

  public setChangeListener = (
    cb: (IAddressMapControllerChangePayload) => void,
  ) => {
    this._changeListener = cb;
  };

  public removeChangeListener = () => {
    this._changeListener = undefined;
  };

  public _setMode = (
    mode: AddressMapControllerMode,
    initiator: Field | undefined,
  ) => {
    this._previousMode = this._mode;
    this._mode = mode;
    this._initiator = initiator;
  };

  public _resetMode = (initiator: Field | undefined) => {
    if (initiator === this._initiator) {
      this._setMode(AddressMapControllerMode.Render, undefined);
    }
  };

  public _updateStateFieldsValues = (state, changeProps) => {
    Object.keys(changeProps).forEach((prop) => {
      if (!state.hasOwnProperty(prop)) {
        throw new Error(
          `Property "${prop}" is not exist in object "${JSON.stringify(
            state,
          )}"`,
        );
      }

      state[prop] = changeProps[prop];
    });
  };

  public _processChangeField = (field: Field, changeProps: IChangeProps) => {
    switch (field) {
      case Field.Address:
        this._updateStateFieldsValues(this._address, changeProps);

        // Update map location
        if (
          this._map.latitude !== changeProps.latitude &&
          this._map.longitude !== changeProps.longitude
        ) {
          this._map.latitude = changeProps.latitude;
          this._map.longitude = changeProps.longitude;
        }

        const updateCoords: boolean =
          this._mode === AddressMapControllerMode.AddressChange ||
          this._mode === AddressMapControllerMode.MapGeoPointChange ||
          (this._mode === AddressMapControllerMode.Render &&
            this._previousMode !== AddressMapControllerMode.GeoPointChange);

        // Update latitude
        if (updateCoords || changeProps.latitude !== undefined) {
          this._latitude.latitude = changeProps.latitude;
          this._latitude.latitudeTmp = changeProps.latitude;
        }

        // Update longitude
        if (updateCoords || changeProps.longitude !== undefined) {
          this._longitude.longitude = changeProps.longitude;
          this._longitude.longitudeTmp = changeProps.longitude;
        }
        break;

      case Field.Latitude:
        this._updateStateFieldsValues(this._latitude, changeProps);
        if (changeProps.hasOwnProperty('latitude')) {
          this._address.selectedItem = undefined;
          this._address.latitude = changeProps.latitude;
          this._address.longitude = this._longitude.longitude;
        }
        break;

      case Field.Longitude:
        this._updateStateFieldsValues(this._longitude, changeProps);
        if (changeProps.hasOwnProperty('longitude')) {
          this._address.selectedItem = undefined;
          this._address.latitude = this._latitude.latitude;
          this._address.longitude = changeProps.longitude;
        }
        break;

      case Field.Map:
        this._updateStateFieldsValues(this._map, changeProps);
        this._updateStateFieldsValues(this._address, changeProps);
        this._address.selectedItem = undefined;
        this._latitude.latitude = changeProps.latitude;
        this._latitude.latitudeTmp = changeProps.latitude;
        this._longitude.longitude = changeProps.longitude;
        this._longitude.longitudeTmp = changeProps.longitude;
        break;
    }

    if (
      this._address.latitude === undefined ||
      this._address.longitude === undefined
    ) {
      this._address.clearId = Date.now();
      this._address.selectedItem = undefined;
      this._map.latitude = undefined;
      this._map.longitude = undefined;
    }

    if (
      this._mode !== AddressMapControllerMode.Init &&
      this._validateValues()
    ) {
      this._notifyOnChange();
    }
  };

  public _validateValues = () => {
    return (
      this._mode !== AddressMapControllerMode.GeoPointChange ||
      (this._validateLatitude() && this._validateLongitude())
    );
  };

  public _validateLatitude = () => {
    const { latitudeTmp: latitude } = this._latitude;

    return latitude && isNumberInRange(latitude, coordsRanges.latitude);
  };

  public _validateLongitude = () => {
    const { longitudeTmp: longitude } = this._longitude;

    return longitude && isNumberInRange(longitude, coordsRanges.longitude);
  };

  public _notifyOnChange = () => {
    const { address } = this._address;
    const { latitudeTmp: latitude } = this._latitude;
    const { longitudeTmp: longitude } = this._longitude;

    const props: IAddressMapControllerChangePayload = {
      address,
      latitude,
      longitude,
    };

    this._changeListener && this._changeListener(props);
  };

  public _getNormalizedGeoPointValue = (value: string): number => {
    return value !== '' ? Number(value) : undefined;
  };

  // Address location methods

  public onAddressLocationBlur = () => {
    this._resetMode(Field.Address);
  };

  public onAddressLocationFocus = () => {
    this._setMode(AddressMapControllerMode.AddressChange, Field.Address);
  };

  // Latitude methods

  public getRenderLatitudeProps = (): IGeopointFieldProps => {
    return {
      onBlur: this.onLatitudeBlur,
      onChange: this.onLatitudeChange,
      onFocus: this.onLatitudeFocus,
      onKeyUp: this.onLatitudeKeyUp,
      value: this._latitude.latitudeTmp,
    };
  };

  public onLatitudeBlur = () => {
    const { latitude, latitudeTmp } = this._latitude;
    if (latitudeTmp !== latitude) {
      this._processChangeField(Field.Latitude, { latitude: latitudeTmp });
    }
    this._resetMode(Field.Latitude);
  };

  public onLatitudeChange = ({ target: { value } }: InputEvent) => {
    const normalizedValue = this._getNormalizedGeoPointValue(value);
    if (this._latitude.latitudeTmp !== normalizedValue) {
      this._processChangeField(Field.Latitude, {
        latitudeTmp: normalizedValue,
      });
    }
  };

  public onLatitudeFocus = () => {
    this._setMode(AddressMapControllerMode.GeoPointChange, Field.Latitude);
  };

  public onLatitudeKeyUp = (event: KeyboardEvent) => {
    const { latitude, latitudeTmp } = this._latitude;
    if (latitude !== latitudeTmp) {
      let changeProp;

      // Apply value
      if (event.key === 'Enter') {
        changeProp = { latitude: latitudeTmp };

        // Revert value
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        changeProp = { latitudeTmp: latitude };
      }

      if (changeProp) {
        this._processChangeField(Field.Latitude, changeProp);
      }
    }
  };

  public renderLatitude = <T>(
    renderer: (props: IGeopointFieldProps) => T,
  ): T => {
    if (!(renderer instanceof Function)) {
      throw new Error(
        'Expected a function as a first argument for renderLatitude method.',
      );
    }
    const props = this.getRenderLatitudeProps();
    return renderer(props);
  };

  public getLatitudeValidationRules = (): ValidationRule[] => [
    { required: true },
    createRangeValidator(coordsRanges.latitude),
  ];

  // Longitude methods

  public getRenderLongitudeProps = (): IGeopointFieldProps => {
    return {
      onBlur: this.onLongitudeBlur,
      onChange: this.onLongitudeChange,
      onFocus: this.onLongitudeFocus,
      onKeyUp: this.onLongitudeKeyUp,
      value: this._longitude.longitudeTmp,
    };
  };

  public onLongitudeBlur = () => {
    const { longitude, longitudeTmp } = this._longitude;
    if (longitudeTmp !== longitude) {
      this._processChangeField(Field.Longitude, { longitude: longitudeTmp });
    }
    this._resetMode(Field.Longitude);
  };

  public onLongitudeChange = ({ target: { value } }: InputEvent) => {
    const normalizedValue = this._getNormalizedGeoPointValue(value);
    if (this._longitude.longitudeTmp !== normalizedValue) {
      this._processChangeField(Field.Longitude, {
        longitudeTmp: normalizedValue,
      });
    }
  };

  public onLongitudeFocus = () => {
    this._setMode(AddressMapControllerMode.GeoPointChange, Field.Longitude);
  };

  public onLongitudeKeyUp = (event: KeyboardEvent) => {
    const { longitude, longitudeTmp } = this._longitude;
    if (longitude !== longitudeTmp) {
      let changeProp;
      if (event.key === 'Enter') {
        // Apply value
        changeProp = { longitude: longitudeTmp };
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        // Revert value
        changeProp = { longitudeTmp: longitude };
      }

      if (changeProp) {
        this._processChangeField(Field.Longitude, changeProp);
      }
    }
  };

  public renderLongitude = <T>(
    renderer: (props: IGeopointFieldProps) => T,
  ): T => {
    if (!(renderer instanceof Function)) {
      throw new Error(
        'Expected a function as a first argument for renderLongitude method.',
      );
    }
    const props = this.getRenderLongitudeProps();
    return renderer(props);
  };

  public getLongitudeValidationRules = (): ValidationRule[] => [
    { required: true },
    createRangeValidator(coordsRanges.longitude),
  ];

  // Map methods

  public getRenderMapProps = (): IMapFieldProps => {
    const props: IMapFieldProps = {
      onClick: this.onMapGeoPointChange,
    };
    const { latitude, longitude } = this._map;
    if (latitude !== undefined && longitude !== undefined) {
      props.location = { latitude, longitude };
    }

    return props;
  };

  public onMapGeoPointChange = ({ latitude, longitude }) => {
    if (this._latitude !== latitude || this._longitude !== longitude) {
      this._setMode(AddressMapControllerMode.MapGeoPointChange, Field.Map);
      this._processChangeField(Field.Map, { latitude, longitude });
      this._resetMode(Field.Map);
    }
  };

  public renderMap = <T>(renderer: (props: IMapFieldProps) => T): T => {
    if (!(renderer instanceof Function)) {
      throw new Error(
        'Expected a function as a first argument for renderMap method.',
      );
    }

    const props = this.getRenderMapProps();
    return renderer(props);
  };
}
