import { Form, Radio } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import classNames from 'classnames';
import React from 'react';
import {
  IAlertCategories,
  IAlertCategory,
  IAlertSubCategory,
  NHAdminAlertType,
} from 'src/restApi/alertsService';
import { FormModal, FormUtils } from '../FormModal';
import './AlertModerationDialog.less';
import { DecisionType } from './constants';

export interface IModerationPayload {
  reason?: number;
  note?: string;
}

export interface IAlertModerationDialogProps {
  agencyName?: string;
  nhadminAlertType?: number;
  decisionType: DecisionType;
  categories: IAlertCategories;
  isPolice?: boolean;
  isProcessing?: boolean;
  visible: boolean;
  onCancel: () => void;
  onOk: (formValues: object) => void;
}

interface IAlertModerationDialogState {
  validReason?: boolean;
}

export class AlertModerationDialog extends React.PureComponent<
  IAlertModerationDialogProps,
  IAlertModerationDialogState
> {
  public state = {
    validReason: undefined,
  };

  private getModalProps = (): Pick<
    ModalProps,
    'okText' | 'okType' | 'title'
  > | null => {
    const { decisionType } = this.props;

    switch (decisionType) {
      case DecisionType.Approve:
        return {
          okText: 'Approve',
          okType: 'primary',
          title: 'Approve this video',
        };

      case DecisionType.Decline:
        return {
          okText: 'Decline',
          okType: 'danger',
          title: 'Decline this video',
        };

      case DecisionType.SendToRsu:
        return {
          okText: 'Send to RSU',
          okType: 'primary',
          title: 'Send to RSU',
        };

      case DecisionType.EscalateL2Approve:
        return {
          okText: 'Yes, escalate this video again',
          okType: 'primary',
          title: 'Escalate this video',
        };

      case DecisionType.EscalateL2Decline:
        return {
          okText: 'Yes, escalate this video again',
          okType: 'danger',
          title: 'Escalate this video',
        };

      default:
        return null;
    }
  };

  private getFormContent = (
    reasons: IAlertCategory[],
    decisionType: DecisionType,
  ) => {
    const isPublicAssistanceAlert =
      this.props.nhadminAlertType === NHAdminAlertType.PublicAssistance;
    const isAnnouncement = this.props.nhadminAlertType === NHAdminAlertType.RegionalAnnouncement;

    const isOptionDisabled = (category, subcategory) => {
      const isDenial = category.type === 'deny';

      // TODO: Refactor, uses valid_for and except_for fields on categories data
      const isDisabledForPublicAssistance = isDenial
        ? false
        : isPublicAssistanceAlert
        ? category.except_for?.includes('public_assistance') || subcategory.except_for?.includes('public_assistance')
        : category.valid_for?.includes('public_assistance') || subcategory.valid_for?.includes('public_assistance');

      // TODO: Refactor, does not use valid_for and except_for fields since these depend on agencyName and user type
      if (subcategory.title === 'Announcement') {
        return !isAnnouncement;
      }

      return this.props.isProcessing || isDisabledForPublicAssistance;
    };

    const singleCategory = reasons.length === 1;
    const className = classNames({
      category: true,
      single: singleCategory,
    });

    return (
      <div className={className}>
        {reasons.map((category: IAlertCategory) => {
          return singleCategory ? (
            <div className="subcategory" key={`sub_category_${category.title}`}>
              {category.subcategory.map((subcategory: IAlertSubCategory) => (
                <Radio
                  key={`subcategory-${subcategory.id}`}
                  value={subcategory.id}
                  disabled={isOptionDisabled(category, subcategory)}
                >
                  {subcategory.title}
                </Radio>
              ))}
            </div>
          ) : (
            <div className="subcategory" key={`sub_category_${category.title}`}>
              <h3 style={{ marginLeft: -20 }}>{category.title}</h3>
              {category.subcategory.map((subcategory: IAlertSubCategory) => (
                <Radio
                  key={`subcategory-${subcategory.id}`}
                  value={subcategory.id}
                  disabled={isOptionDisabled(category, subcategory)}
                >
                  {subcategory.title}
                </Radio>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  private renderForm = (form: FormUtils) => {
    const { categories, decisionType } = this.props;
    const { getFieldDecorator } = form;
    const denialCategories =
      categories.decline.length === 0
        ? []
        : [
            ...categories.decline.map((category) => ({
              ...category,
              type: 'deny',
            })),
          ];
    const reasons: IAlertCategory[] =
      decisionType === null
        ? []
        : decisionType === DecisionType.Decline ||
          decisionType === DecisionType.EscalateL2Decline
        ? denialCategories
        : categories.approve;

    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('reason')(
            <Radio.Group>
              {this.getFormContent(reasons, decisionType)}
            </Radio.Group>,
          )}
        </Form.Item>
      </Form>
    );
  };

  private handleFormValuesChanged = (formData?: IModerationPayload) => {
    this.setState(() => ({
      validReason: formData !== undefined && formData.reason !== undefined,
    }));
  };

  public render() {
    const { onCancel, onOk, isProcessing, visible } = this.props;
    const { validReason } = this.state;
    const modalProps = this.getModalProps();

    return (
      <FormModal<IModerationPayload>
        cancelButtonProps={{ disabled: isProcessing }}
        closable={false}
        formRenderer={this.renderForm}
        maskClosable={false}
        okButtonProps={{ disabled: !validReason, loading: isProcessing }}
        onCancel={onCancel}
        onFormValuesChanged={this.handleFormValuesChanged}
        onOk={onOk}
        visible={visible}
        width={900}
        wrapClassName="AlertModerationDialog"
        {...modalProps}
      />
    );
  }
}
