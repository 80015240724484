import cx from 'classnames';
import React, { FC } from 'react';
import { BadgeColors, FlagBadge } from '../Badge';

export enum CaseInfoFlagBadgeFlag {
  Crime = 'crime',
}

export interface ICaseInfoFlagBadgeProps {
  className?: string;

  flag: CaseInfoFlagBadgeFlag;
}

const flags = {
  [CaseInfoFlagBadgeFlag.Crime]: {
    color: BadgeColors.Grey,
    text: 'CRIME',
  },
};

export const CaseInfoFlagBadge: FC<ICaseInfoFlagBadgeProps> = ({
  className,
  flag,
}) => {
  const badgeProps = flags[flag];

  return (
    <FlagBadge {...badgeProps} className={cx('CaseInfoFlagBadge', className)} />
  );
};
