import cx from 'classnames';
import React from 'react';
import './CenterContentBlock.less';

interface ICenterContentBlock {
  className?: string;
  children: React.ReactNode;
  withPadding?: boolean;
}

export const CenterContentBlock = ({
  children,
  className,
  withPadding,
}: ICenterContentBlock) => (
  <div className={cx('CenterContentBlock', className)}>
    <div className={cx('CenterContentBlock__content', { withPadding })}>
      {children}
    </div>
  </div>
);
