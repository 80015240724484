import React from 'react';

import { Box } from 'src/components/Box';
import { useStores } from 'src/stores';

export const ManageUsersCategoryColumnData = ({ category }) => {
  const { manageUsersStore } = useStores();

  const categories = category?.map((category) => category.id);

  if (!categories) return <Box />;

  return (
    manageUsersStore.areCategoriesAvailable && (
      <Box>
        {categories.map((category, index) => {
          return (
            <div key={`category-${category}-${index}`}>
              <p>{`${manageUsersStore.categoryEntities[category]?.name}:`}</p>
            </div>
          );
        })}
      </Box>
    )
  );
};
