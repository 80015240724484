import qs from 'query-string';

export class URLService {
  public static getSearchParams = () =>
    new URLSearchParams(window.location.search);

  public static getParsedUrl = (): any => {
    const { search: searchStr } = window.location;
    return qs.parse(searchStr.slice(1, searchStr.length));
  };

  public static setUrl = (params) => {
    const paramsStr = qs.stringify(params);
    window.history.replaceState(
      null,
      null,
      paramsStr ? `?${paramsStr}` : window.location.pathname,
    );
  };

  private static updateURLWithParams = (searchParams: URLSearchParams) => {
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
  };

  private static addParamToURL = (
    searchParams: URLSearchParams,
    paramKey: any,
    paramValue: any,
  ) => {
    searchParams.set(paramKey.toString(), paramValue.toString());
  };

  private static checkIsParamInURL = (
    searchParams: URLSearchParams,
    paramKey: any,
  ) => {
    return searchParams.has(paramKey);
  };

  private static deleteParamFromURL = (
    searchParams: URLSearchParams,
    paramKey: any,
  ) => {
    searchParams.delete(paramKey);
  };

  public static addSearchParams = (query: Object) => {
    const searchParams = URLService.getSearchParams();

    for (const param in query) {
      if (query[param]) {
        URLService.addParamToURL(searchParams, param, query[param]);
      } else if (URLService.checkIsParamInURL(searchParams, param)) {
        URLService.deleteParamFromURL(searchParams, param);
      }
    }

    URLService.updateURLWithParams(searchParams);
  };

  public static getParam = (paramKey: string | null) => {
    const searchParams = URLService.getSearchParams();

    return searchParams.get(paramKey);
  };

  public static deleteSearchParam = (paramKey: string) => {
    const searchParams = URLService.getSearchParams();

    searchParams.delete(paramKey);
    URLService.updateURLWithParams(searchParams);
  };

  public static deleteAllSearchParams = () => {
    window.history.replaceState(null, null, '?');
  };
}
