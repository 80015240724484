import queryString from 'query-string';
import {ExtendedKy} from 'src/api';
import { ILocation } from "src/features/mapLocation/controllers/AddressMapController/AddressMapController.interface";
import { getDistanceGeoPoints } from '../helpers/navigationHelpers';
import { ISearchParams } from '../interfaces';
import {
  newsDistancePrecision,
  relatedNewsLimit,
} from '../pages/NewsCurationPage/features/relatedNewsAlerts/constants';
import { PermissionLevel } from './interfaces';
import { PermissionService } from './permissionService';
import { IResponseItem, IResponseList } from './restApi';

export enum NewsSortDirections {
  Asc = 'asc',
  Desc = 'desc',
}

export interface IFetchNews {
  category?: string[];
  content?: string[];
  from_time?: number;
  keywords?: string;
  limit?: number;
  location?: ILocation;
  onlyEdited?: boolean;
  onlyFollowed?: boolean;
  order?: NewsSortDirections;
  radius?: number;
  region?: string[];
  source?: string[];
  status?: string | string[];
  to_time?: number;
  offset?: number;
  page_token?: string;
}

export interface IQueryStringFetchNews extends Omit<IFetchNews, 'location'> {
  latitude?: number;
  longitude?: number;
}

export enum NewsStatus {
  New = 'new',
  InReview = 'in_review',
  Published = 'published',
  Deleted = 'deleted',
}

export type NewsId = string;

export interface INewNews {
  address: string;
  category: string;
  content: string;
  latitude: number;
  longitude: number;
  news_url?: string;
  service: string;
  status: NewsStatus;
  title: string;
  type: string;
}

export interface INews extends INewNews {
  city?: string | null;
  country?: string | null;
  created_at: string;
  email?: string;
  news_id: NewsId;
  news_url: string | null;
  region?: string | null;
  state?: string;
  status: NewsStatus;
  type: string | null;
  updated_at: string | null;
}

export interface IFetchNewsV2 {
  incidents: INews[];
  page_token: string;
}

export interface IGetNews {
  incident_history: INews[],
  related_articles: INews[],
}

export interface IGetNewsHistory {
  incident_history: INewsHistory[],
  related_articles: INews[],
}

export interface IFetchRelatedNewsV2 {
  incidents: IRelatedNews[];
  page_token: string;
}

export interface INewsHistory {
  time_created: string;
  news_id: string;
  alert_id: string;
  email: string;
  is_edited: boolean;
  status: NewsStatus;
  title: string;
  content: string;
  service: string;
  news_url: string;
  category: string;
  type: string;
  latitude: number;
  longitude: number;
  address: string;
}

interface INewsCommonOptions {
  categories: string[];
  statuses: string[];
  types: string[];
}

export interface INewsOptionsApiResponse extends INewsCommonOptions {
  all_services: string[];
  creatable_services: string[];
  radius: number;
}

interface INewsEditors {
  editors: string[];
}

export interface INewsOptions extends INewsOptionsApiResponse, INewsEditors {}

export interface INewsFilterOptions extends INewsCommonOptions, INewsEditors {
  services: string[];
}

export interface INewsSearchOptions extends INewsFilterOptions {
  keywords: string;
}

export interface IRelatedNewsApi {
  location: ILocation;
  radius?: number;
  status?: string | string[];
  timeFrom: number;
  timeTo: number;
}

export interface IRelatedNews extends INews {
  distance: number;
}

type IFactoryFetchNews = Omit<IFetchNews, "offset"> | Omit<IFetchNews, "page_token">;

export interface INewsService {
  api: ExtendedKy;
  fetchNewsFactory(IFactoryFetchNews): Promise<() => Promise<INews[]>>;
  updateNews(INews): Promise<INews>;
  createNewsAlert(INewNews): Promise<INews>;
  getNewsAlertOptions(): Promise<INewsOptions>;
  getNewsAlert(NewsId): Promise<INews>;
  getNewsAlertHistory(NewsId): Promise<INewsHistory[]>;
  editNewsAlert(NewsId, INews): Promise<INews>;
  setStatus(NewsId, NewsStatus): Promise<INews>;
  deleteNewsAlert(NewsId): Promise<INews>;
  getArticlesByLocation(IRelatedNewsApi): Promise<INews[]>;
  getRelatedArticles(IFetchNews): Promise<IRelatedNews[]>;

}


abstract class BaseNewsService implements INewsService {
  public api: ExtendedKy;

  constructor(api: ExtendedKy) {
    this.api = api;
  }

  protected abstract fetchNews(api: ExtendedKy, {
    location,
    ...params
  }: IFetchNews);

  abstract fetchNewsFactory({
    location,
    ...params
  }: IFactoryFetchNews): Promise<() => Promise<INews[]>>;

  abstract updateNews({ news_id, ...params }): Promise<INews>;

  public async createNewsAlert(bodyParams: INewNews) {
    const response = await this.api
      .post('news', { json: bodyParams })
      .json<IResponseItem<INews>>();

    return response.data;
  }

  public async getNewsAlertOptions() {
    const [apiOptions, editors] = await Promise.all([
      this.getNewsApiAlertOptions(),
      this.getEditors(),
    ]);

    return { ...apiOptions, editors } as INewsOptions;
  }

  private async getEditors() {
    const curationUsers = await PermissionService.getUsers(
      PermissionLevel.Curation,
    );

    return curationUsers.map(({ email }) => email);
  }

  private async getNewsApiAlertOptions() {
    const response = await this.api
      .get('news/options')
      .json<IResponseItem<INewsOptionsApiResponse>>();

    return response.data;
  }

  abstract getNewsAlert(NewsId): Promise<INews>;

  abstract getNewsAlertHistory(id: NewsId): Promise<INewsHistory[]>

  public async editNewsAlert(id: NewsId, bodyParams: INews) {
    const response = await this.api
      .patch(`news/${id}`, { json: bodyParams })
      .json<IResponseItem<INews>>();

    return response.data;
  }

  public async setStatus(id: NewsId, status: NewsStatus) {
    const response = await this.api
      .patch(`news/${id}`, {
        json: { status },
      })
      .json<IResponseItem<INews>>();

    return response.data;
  }

  public async deleteNewsAlert(id: NewsId) {
    return this.setStatus(id, NewsStatus.Deleted);
  }

  public async getArticlesByLocation({
    radius,
    location,
    status,
    timeFrom,
    timeTo,
  }: IRelatedNewsApi) {
    const { latitude, longitude } = location;
    const searchParams: ISearchParams = {
      from_time: String(timeFrom),
      latitude: String(latitude),
      limit: String(relatedNewsLimit),
      longitude: String(longitude),
      to_time: String(timeTo),
    };
    if (radius) {
      searchParams.radius = String(radius);
    }
    if (status) {
      searchParams.statuses = String(status);
    }

    const result = await this.api
      .get('news', searchParams)
      .json<IResponseList<INews>>();

    return result.data.items;
  }

  private addCalculatedDistance = (
    relatedNews: INews[],
    location: ILocation,
  ) => {
    return relatedNews.map((news) => {
      return {
        ...news,
        distance:
          Math.round(
            getDistanceGeoPoints(location, {
              latitude: news.latitude,
              longitude: news.longitude,
            }) * newsDistancePrecision,
          ) / newsDistancePrecision,
      };
    }) as IRelatedNews[];
  };

  public async getRelatedArticles(props: IFetchNews) {
    const { location } = props;

    const [articles]  = await this.fetchNews(this.api, props);
    return this.addCalculatedDistance(articles, location);
  }
}

export class NewsServiceV2 extends BaseNewsService implements INewsService {
  protected async fetchNews(api: ExtendedKy, {
    location,
    ...params
  }: IFetchNews = {}): Promise<[INews[], string]> {
    let queryStringParams: IQueryStringFetchNews = params
    if (location) {
      queryStringParams.longitude = location.longitude;
      queryStringParams.latitude = location.latitude;
    }
    const requestParams = queryString.stringify(queryStringParams);
    const response = await api
      .get(`news?${requestParams}`, { errorMessage: 'Failed to fetch news' })
      .json<IResponseItem<IFetchNewsV2>>();
    return [response.data.incidents, response.data.page_token];
  }

  public async fetchNewsFactory({
    location,
    ...params
  }: IFactoryFetchNews = {}): Promise<() => Promise<INews[]>> {
    let f = this.fetchNews
    let page_token: string | undefined = undefined;
    let thisApi = this.api
    delete params["order"]
    delete params["offset"]
    return async function () {
      let news: INews[]
      [news, page_token] = await f(thisApi, (page_token) ? {page_token} : {location, ...params})
      return news
    }
  }

  public async getNewsAlert(id: NewsId) {
    const response = await this.api.get(`news/${id}`).json<IResponseItem<IGetNews>>();
    return response.data.incident_history?.[0];
  }

  public async getNewsAlertHistory(id: NewsId) {
    const response = await this.api
      .get(`news/${id}`)
      .json<IResponseItem<IGetNewsHistory>>();

    return response.data.incident_history;
  }

  public async updateNews({ news_id, ...params }: INews) {
    const response = await this.api
      .put(`news/${news_id}`, { json: params })
      .json<IResponseItem<INews>>();

    return response.data;
  }
}
