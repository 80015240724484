import { Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Stores } from 'src/stores';
import { BlockLayout } from '../../../components/BlockLayout';
import { CenterContentBlock } from '../components/CenterContentBlock';
import { FormWrapper } from '../components/FormWrapper';
import {
  INewsAlertFormValues,
  NewsAlertForm,
} from '../components/NewsAlertForm';
import { PreviewModal } from '../components/PreviewModal';
import {
  makeCapitalizedDropdownOptions,
  makeSourcesDropdownOptions,
} from '../helpers/newsFilterHelper';

type INewNewsAlertPageProps = RouteComponentProps<void> & Stores;

interface INewNewsAlertPageState {
  showPreviewDialog: boolean;
}

@inject('newsOptionsStoreV2', 'newsStoreV2')
@observer
export class NewNewsAlertPageV2 extends React.Component<
  INewNewsAlertPageProps,
  INewNewsAlertPageState
> {
  public onSubmit;
  public _cachedFooter;
  public initialValues: INewsAlertFormValues = {
    address: '',
    category: undefined,
    content: '',
    latitude: undefined,
    longitude: undefined,
    news_url: '',
    radius: undefined,
    service: undefined,
    status: 'new',
    title: '',
    type: undefined,
  };
  public state = {
    showPreviewDialog: false,
  };
  private formValues: INewsAlertFormValues = {};
  private _deleting = false;
  private _loading = false;
  private _saving = false;

  private goToNewsList = () => {
    const { history } = this.props;

    history.goBack();
  };

  public componentWillUnmount() {
    const { newsStoreV2 } = this.props;

    newsStoreV2.clear();
  }

  private handlePublish = async (formData) => {
    const { newsStoreV2 } = this.props;

    const publishData = {
      ...formData,
      status: 'published',
    };

    const isSaved = await newsStoreV2.createNewsFlow(publishData);

    if (isSaved) {
      this.goToNewsList();

      // TODO: Replace with NotificationService call after notificationsStore refactoring.
      message.success(`'${formData.title}' has been published.`);
    }
  };

  private renderFooterControls = (onSubmit) => {
    const { newsStoreV2 } = this.props;

    this.onSubmit = onSubmit;

    if (
      !this._cachedFooter ||
      this._deleting !== newsStoreV2.deleting ||
      this._loading !== newsStoreV2.loading ||
      this._saving !== newsStoreV2.saving
    ) {
      this._deleting = newsStoreV2.deleting;
      this._loading = newsStoreV2.loading;
      this._saving = newsStoreV2.saving;

      this._cachedFooter = (
        <BlockLayout
          right={
            <div key="footerControls" className="nha-block-align-right">
              <Button
                disabled={newsStoreV2.deleting || newsStoreV2.loading}
                htmlType="submit"
                loading={newsStoreV2.saving}
                onClick={this.onSubmit}
                type="primary"
              >
                Publish
              </Button>
            </div>
          }
        />
      );
    }

    return this._cachedFooter;
  };

  private hidePreviewDialog = () => this.setState({ showPreviewDialog: false });

  private onFormChange = (formValues) => {
    this.formValues = formValues;
  };

  public render() {
    const { newsOptionsStoreV2 } = this.props;
    const {
      categories,
      radius,
      creatable_services,
      types,
    } = newsOptionsStoreV2.options;

    //newsStore.saving;

    return (
      <>
        <CenterContentBlock withPadding>
          <PreviewModal
            formValues={this.formValues}
            show={this.state.showPreviewDialog}
            onSubmit={this.onSubmit}
            hideModal={this.hidePreviewDialog}
          />

          <FormWrapper
            onFormChange={this.onFormChange}
            componentProps={{
              categories: makeCapitalizedDropdownOptions(categories),
              componentTitle: 'Create Alert',
              placeholderLocation: {
                latitude: 34.031705599999995,
                longitude: -118.4717859,
              },
              radius,
              services: makeSourcesDropdownOptions(creatable_services),
              types: makeCapitalizedDropdownOptions(types),
            }}
            formComponent={NewsAlertForm}
            initialValues={this.initialValues}
            renderFooterControls={this.renderFooterControls}
            onCancel={this.goToNewsList}
            onSave={this.handlePublish}
            submitButtonText="Publish"
          />
        </CenterContentBlock>
      </>
    );
  }
}
