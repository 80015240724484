import { Col, Form, Input, Row, Select } from 'antd';
import type { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';
import React from 'react';
import { NewsItemUser } from 'src/componentsNew/NewsItemUser';
import { DATE_FORMAT } from 'src/constants';
import { ILocation } from "src/features/mapLocation/controllers/AddressMapController/AddressMapController.interface";
import { AddressMapController } from "src/features/mapLocation/controllers/AddressMapController";
import { FormItemSpacer } from '../../FormItemSpacer';
import { NewsAlertHistory } from '../../NewsAlertHistory';
import { NewsAlertStatusBadge } from '../../NewsAlertStatusBadge';
import {
  INewsAlertFormProps,
  INewsAlertFormValues,
} from '../containers/NewsAlertForm';
import './NewsAlertFormComponent.less';
import {observer} from "mobx-react";
import {Stores} from "../../../../../stores";

const { Option } = Select;
const { TextArea } = Input;

export interface INewsAlertFormComponentProps extends INewsAlertFormProps, Partial<Stores> {
  addressMapCtrl: AddressMapController;
  form: WrappedFormUtils;
  placeholderLocation?: {
    latitude: number;
    longitude: number;
  };
  location?: ILocation;
  handleSubmit: (event: React.FormEvent<any>) => void;
  radius?: number;
}

@observer
export class NewsAlertFormComponent extends React.Component<
  INewsAlertFormComponentProps
> {
  public _renderOptions = (option) => (
    <Option key={option.value} value={option.value}>
      {option.text}
    </Option>
  );

  public renderLatitude = (
    getFieldDecorator,
    initialValues: INewsAlertFormValues,
    location: ILocation,
  ) => {
    const {
      onLatitudeBlur,
      onLatitudeChange,
      onLatitudeFocus,
      onLatitudeKeyUp,
    } = this.props.addressMapCtrl;

    return getFieldDecorator('latitude', {
      initialValue: (location && location.latitude) || initialValues.latitude,
      rules: this.props.addressMapCtrl.getLatitudeValidationRules(),
    })(
      <Input
        onBlur={onLatitudeBlur}
        onChange={onLatitudeChange}
        onFocus={onLatitudeFocus}
        onKeyUp={onLatitudeKeyUp}
        type="number"
      />,
    );
  };

  public renderLongitude = (
    getFieldDecorator,
    initialValues: INewsAlertFormValues,
    location: ILocation,
  ) => {
    const {
      onLongitudeBlur,
      onLongitudeChange,
      onLongitudeFocus,
      onLongitudeKeyUp,
    } = this.props.addressMapCtrl;
    return getFieldDecorator('longitude', {
      initialValue: (location && location.longitude) || initialValues.longitude,
      rules: this.props.addressMapCtrl.getLongitudeValidationRules(),
    })(
      <Input
        onBlur={onLongitudeBlur}
        onChange={onLongitudeChange}
        onFocus={onLongitudeFocus}
        onKeyUp={onLongitudeKeyUp}
        type="number"
      />,
    );
  };

  public render() {
    const {
      categories,
      componentTitle,
      form: { getFieldDecorator },
      handleSubmit,
      initialValues,
      location,
      services,
      types,
    } = this.props;
    const mainRowProps = { gutter: 12 };
    const formRowProps = { gutter: 12 };
    const date = initialValues.updated_at || initialValues.created_at;

    return (
      <Form
        className="NewsAlertFormComponent"
        hideRequiredMark
        onSubmit={handleSubmit}
      >
        <Row {...mainRowProps}>
          <Col span={22} offset={1}>
            <h1>{componentTitle}</h1>
          </Col>
        </Row>

        <Row {...mainRowProps}>
          <Col span={22} offset={1}>
            <FormItemSpacer>
              <span className="NewsAlertFormComponent__statusLine">
                {getFieldDecorator('status', {
                  initialValue: initialValues.status,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Input type="hidden" />)}

                <NewsAlertStatusBadge
                  className="NewsAlertFormComponent__statusBadge"
                  status={initialValues.status || 'new'}
                />

                <NewsItemUser
                  className="NewsAlertFormComponent__user"
                  email={initialValues.email}
                />

                {date && ` on ${moment(date).format(DATE_FORMAT)}`}
              </span>
            </FormItemSpacer>
          </Col>
        </Row>

        <Row {...mainRowProps}>
          <Col span={10} offset={1}>
            <Row {...formRowProps}>
              <Col span={12}>
                <Form.Item label="Source">
                  {getFieldDecorator('service', {
                    initialValue: initialValues.service,
                    rules: [
                      {
                        message: 'Please select appropriate source',
                        required: true,
                      },
                    ],
                  })(
                    <Select placeholder="Choose a Source">
                      {services.map(this._renderOptions)}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {initialValues.incident_id && (
                  <Form.Item label="Incident ID">
                    {getFieldDecorator('incident_id', {
                      initialValue: initialValues.incident_id,
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(<Input name="incidentId" disabled />)}
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row {...formRowProps}>
              <Col span={24}>
                {/* News title field */}
                <Form.Item label="Title">
                  {getFieldDecorator('title', {
                    initialValue: initialValues.title,
                    rules: [
                      {
                        message: 'Please input news title',
                        required: true,
                      },
                    ],
                  })(
                    <TextArea
                      placeholder={
                        'NW 72nd Ave & NW 13th St - Reports of a natural gas leak at Mirror Lake ' +
                        'Elementary School.'
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row {...formRowProps}>
              <Col span={24}>
                <Form.Item label="Content">
                  {getFieldDecorator('content', {
                    initialValue: initialValues.content,
                    rules: [
                      {
                        message: 'Please input news description',
                        required: true,
                      },
                    ],
                  })(
                    <TextArea
                      rows={4}
                      placeholder={
                        'Unconfirmed sources indicate that police are responding to a suspicious ' +
                        'package near 2nd Pl and Smith St. Expect police.'
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row {...formRowProps}>
              <Col span={12}>
                <Form.Item label="Category">
                  {getFieldDecorator('category', {
                    initialValue: initialValues.category,
                    rules: [
                      {
                        message: 'Please select category',
                        required: true,
                      },
                    ],
                  })(
                    <Select placeholder="Select category">
                      {categories.map(this._renderOptions)}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Type">
                  {getFieldDecorator('type', {
                    initialValue: initialValues.type,
                    rules: [
                      {
                        message: 'Please select type',
                        required: true,
                      },
                    ],
                  })(
                    <Select placeholder="Select type">
                      {types.map(this._renderOptions)}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row {...formRowProps}>
              <Col span={24}>
                <Form.Item label="Article link">
                  {getFieldDecorator('news_url', {
                    initialValue: initialValues.news_url,
                  })(<Input placeholder="Optional" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={11} offset={1}>
            <Row {...formRowProps}>
              <Col span={12}>
                <Form.Item label="Latitude">
                  {this.renderLatitude(
                    getFieldDecorator,
                    initialValues,
                    location,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Longitude">
                  {this.renderLongitude(
                    getFieldDecorator,
                    initialValues,
                    location,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {initialValues.news_id && (
          <Row {...mainRowProps} style={{ paddingTop: 48 }}>
            <Col span={11} offset={1}>
              <NewsAlertHistory newsAlertId={String(initialValues.news_id!)} />
            </Col>

            <Col span={11} offset={1} />
          </Row>
        )}
      </Form>
    );
  }
}
