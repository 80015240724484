import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Flex } from 'src/components/Flex';
import { useStores } from 'src/stores';
import { SearchFilter } from './components/search-filter';
import { SearchPagination } from './components/search-pagination';
import { SearchResultList } from './components/search-result-list';

export const SearchPage = observer(() => {
  const { searchStore } = useStores();

  useEffect(() => {
    // restore search
    if (searchStore.isFiltering) {
      searchStore.fetchAlerts();
    }
  }, [searchStore]);

  return (
    <Flex width="100%" maxWidth="1366px" margin="0 auto" py={3}>
      <Flex width="30%" mr="10px">
        <SearchFilter />
      </Flex>
      <Flex flex={1} flexDirection="column">
        <SearchResultList />
        <SearchPagination />
      </Flex>
    </Flex>
  );
});
