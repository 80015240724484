import { Button, Modal } from 'antd';
import moment from 'moment';
import React from 'react';

const TIME_TO_SHOW_MODERATION_DIALOG_BEFORE_TIMEOUT = 60000;

interface IModerationTimeoutDialogProps {
  onOk: () => Promise<boolean>;
  timeout: string;
}

interface IModerationTimeoutDialogState {
  isProcessing: boolean;
  time: number;
  timeout: moment.Moment | null;
  visible: boolean;
}

export class ModerationTimeoutDialog extends React.PureComponent<
  IModerationTimeoutDialogProps,
  IModerationTimeoutDialogState
> {
  private showDialogTimerId: number | undefined;
  private tickTimerId: number | undefined;

  public state = {
    isProcessing: false,
    time: 0,
    timeout: null,
    visible: false,
  };

  public componentDidMount() {
    this.initShowDialogTimer();
  }

  public componentDidUpdate(prevProps: IModerationTimeoutDialogProps) {
    const { timeout } = this.props;
    if (prevProps.timeout !== timeout) {
      this.initShowDialogTimer();
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.showDialogTimerId);
    clearTimeout(this.tickTimerId);
  }

  private initShowDialogTimer = () => {
    const { timeout: rawTimeout } = this.props;
    const timeout = moment(rawTimeout, moment.ISO_8601, true);

    this.setState({ timeout: timeout.isValid() ? timeout : moment() }, () => {
      const showDialogTimeout = this.getDialogTimeout();

      if (showDialogTimeout > 0) {
        clearTimeout(this.showDialogTimerId);

        this.showDialogTimerId = setTimeout(
          this.setDialogVisible,
          showDialogTimeout,
        );

        this.setState(() => ({
          isProcessing: false,
          visible: false,
        }));
      } else {
        this.setDialogVisible();
      }
    });
  };

  private getSessionTimeout = () => {
    const { timeout } = this.state;
    return +timeout - Date.now();
  };

  private getDialogTimeout = () => {
    return (
      this.getSessionTimeout() - TIME_TO_SHOW_MODERATION_DIALOG_BEFORE_TIMEOUT
    );
  };

  private setDialogVisible = () => {
    this.setState(() => ({
      isProcessing: false,
      time: this.calculateDisplayTime(),
      visible: true,
    }));

    this.tick();
  };

  private calculateDisplayTime = () => {
    return Math.floor(this.getSessionTimeout() / 1000);
  };

  private tick = () => {
    const newTime = this.calculateDisplayTime();

    this.setState({
      time: newTime,
    });

    if (newTime > 0) {
      clearTimeout(this.tickTimerId);
      this.tickTimerId = setTimeout(this.tick, 1000);
    }
  };

  private handleOk = async () => {
    const { onOk } = this.props;

    this.setState(() => ({
      isProcessing: true,
    }));

    const result = await onOk();

    if (result) {
      clearTimeout(this.tickTimerId);
    } else {
      this.setState(() => ({
        isProcessing: false,
      }));
    }
  };

  public render() {
    const { isProcessing, time, visible } = this.state;

    return (
      <Modal
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            loading={isProcessing}
          >
            I need more time
          </Button>,
        ]}
        maskClosable={false}
        title="Are you still here?"
        visible={visible}
        zIndex={1100}
      >
        {time > 0
          ? `Without confirmation your moderation session will expire in ${time} seconds.`
          : 'Without confirmation your moderation session will expire any moment.'}
      </Modal>
    );
  }
}
