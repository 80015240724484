import styled from 'styled-components';

export const VerticalAligner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: auto;
  height: 100%;
  position: relative;
`;
