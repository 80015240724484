import { observer } from 'mobx-react';
import React from 'react';

import { Flex } from 'src/components/Flex';

import { ManageUsersFilter } from './components/manage-users-filter';
import { ManageUsersActions } from './components/manage-users-actions';
import { ManageUsersList } from './components/manage-users-list';
import { ManageUsersBanModal } from './components/manage-users-ban-modal';
import { ManageUsersRemoveBanModal } from './components/manage-users-remove-ban-modal';
import { ManageUsersModal } from './components/manage-users-modal';

export const ManageUsersPage = observer(() => {
  return (
    <>
      <Flex height="100%" margin="0 auto" maxWidth="1366px" width="100%" py={3}>
        <ManageUsersFilter />
        <ManageUsersList />
      </Flex>
      <ManageUsersActions />

      <ManageUsersBanModal />
      <ManageUsersRemoveBanModal />
      <ManageUsersModal />
    </>
  );
});
