import css from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { commentsActions } from 'src/actions';
import { AlertItemComment } from 'src/components/alert-item-comment';
import { AssignButton } from 'src/components/assing-button';
import { Tabs } from 'src/stores';
import {
  isCommentNotModerated,
  timeReceivedForModerationComparator,
} from '../../helpers/commentsHelper';
import { IStoredAlert, IStoredComment } from '../../restApi/interfaces';
import { BadgeLabel } from '../BadgeLabel';
import s from './style.module.css';

@observer
export class AlertItemCommentsThread extends React.Component<IAlertItemCommentsThreadProps> {
  public static defaultProps: Partial<IAlertItemCommentsThreadProps> = {
    canModerateThread: true,
  };

  public state = {
    assignButtonLoading: false,
  };

  public collapsedThreadCommentsCount = 20;

  public handleThreadExpandedState = () => {
    if (this.props.onThreadExpanded) {
      this.props.onThreadExpanded(this.props.alert);
    }
  };

  public renderNotModeratedBadge = (count: number) => {
    return (
      <BadgeLabel
        text={`Not moderated: ${count}`}
        className={s.notModeratedBadge}
        testID="notModeratedCounter"
      />
    );
  };

  public renderDiscussionButton = (
    showCollapseOnly?: boolean,
    commentsCount?: number,
    notModerated?: number,
  ) => {
    const { threadExpanded } = this.props;

    if (showCollapseOnly && !threadExpanded) {
      return null;
    }

    const buttonTitle = threadExpanded
      ? 'Collapse Thread'
      : !showCollapseOnly && `Expand Thread: ${commentsCount}`;

    return (
      <div className={s.discusButtonContainer}>
        <span
          className={s.discusButton}
          onClick={this.handleThreadExpandedState}
          data-test-id="expandThreadDiscussion"
        >
          {buttonTitle}
        </span>
        <div
          className={css(
            s.discussButtonIcon,
            threadExpanded && s.openedDropdown,
          )}
        />
        {notModerated !== undefined &&
          this.renderNotModeratedBadge(notModerated)}
      </div>
    );
  };

  public markAsResolvedAction = (comment: IStoredComment) => {
    const { alert } = this.props;
    commentsActions.markCommentAsResolved(alert.alert_id, comment.comment_id);
  };

  public assignAction = () => {
    this.setState({ assignButtonLoading: true });
    const { alert, tabStore, commentsTabFlag, onAssign } = this.props;

    onAssign(alert.alert_id);

    commentsActions.assignCommentsThread(
      alert.alert_id,
      tabStore,
      commentsTabFlag,
    );
  };

  public render() {
    let comments, expandCount;

    const {
      alert,
      canModerateThread,
      hasRSUModeration,
      isAlertExpanded,
      isEscalate,
      isThreadAssigned,
      isUserOwner,
      onToggleHistory,
      tabStore,
      threadExpanded,
      treatRsuAsNew,
    } = this.props;

    const filteredComments = alert.comments.filter((comment) =>
      isCommentNotModerated(comment, undefined, treatRsuAsNew),
    );

    const notModerated = filteredComments.length;

    const shouldCollapsed =
      alert.comments.length > filteredComments.length ||
      filteredComments.length > this.collapsedThreadCommentsCount;

    if (threadExpanded) {
      comments = alert.comments;
    } else {
      comments = filteredComments.slice(0, this.collapsedThreadCommentsCount);
      comments.sort(timeReceivedForModerationComparator);
      expandCount = alert.comments.length - comments.length;
    }

    return (
      <div
        key="alert-comments-thread-container"
        className={css(
          s.commentsThreadContainer,
          isAlertExpanded && s.commentsThreadContainerTop,
        )}
        data-test-id="commentThreadContainer"
      >
        {shouldCollapsed ? (
          this.renderDiscussionButton(false, expandCount, notModerated)
        ) : (
          <div className={s.discusButtonContainer}>
            {this.renderNotModeratedBadge(notModerated)}
          </div>
        )}
        <div className={s.commentsContainer}>
          {comments.map((comment, i) => {
            return (
              <AlertItemComment
                firstItem={i === 0}
                tabStore={tabStore}
                lastItem={comments.length - 1 === i}
                comment={comment}
                onToggleHistory={onToggleHistory}
                treatRsuAsNew={treatRsuAsNew}
                canModerated={canModerateThread}
                isEscalate={isEscalate}
                hasRSUModeration={hasRSUModeration}
                key={`thread-comment-${comment.comment_id}`}
                markAsResolvedAction={() => this.markAsResolvedAction(comment)}
                isUserOwner={isUserOwner}
                isByOriginalPoster={
                  alert.user_id && alert.user_id === comment.user_id
                }
                shouldExpandAssignedComment={isThreadAssigned}
              />
            );
          })}
          {!isThreadAssigned && canModerateThread && (
            <AssignButton
              onClick={this.assignAction}
              loadingMode={this.state.assignButtonLoading}
            />
          )}
        </div>
        {shouldCollapsed && this.renderDiscussionButton(true)}
      </div>
    );
  }
}

export interface IAlertItemCommentsThreadProps {
  alert: IStoredAlert;
  isAlertExpanded?: boolean;
  canModerateThread?: boolean;
  hasRSUModeration?: boolean;
  tabStore: Tabs;
  isEscalate?: boolean;
  isThreadAssigned?: boolean;
  isUserOwner?: boolean;
  commentsTabFlag: string;
  onAssign: Function;
  onToggleHistory: (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => Promise<void>;
  treatRsuAsNew: boolean;
  onThreadExpanded?: Function;
  threadExpanded?: boolean;
}
