import { action, computed, observable } from 'mobx';
import type { IObservableArray, ObservableMap } from 'mobx/lib/internal';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import {
  indexBy,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  prop,
  reject,
  split,
  trim,
  uniq,
} from 'ramda';
import { AdminService } from 'src/pages/admin/services/adminService';
import {
  SearchMeta,
  SearchParams,
  SearchService,
} from 'src/pages/search/services/searchService';
import { getAlertNumericId } from 'src/pages/search/services/utils';
import { InjectRootStore } from './injectRootStore';
import { RootStore } from './rootStore';

// TODO: split UI store from domain store
// https://mobx.js.org/best/store.html
const alertTypesMap = {
  news: ['trending_news'],
  police: ['police_text_event', 'police_image_event', 'police_video_event'],
  user: ['text_event', 'image_event', 'video_event', 'ring_video_event'],
  "Request For Assistance": ['public_assistance'],
} as const;

// TODO: use filters getter everywhere instead of renaming API fields?
export class SearchStore extends InjectRootStore {
  private readonly searchService: SearchService;

  @observable isLoading = false;
  @observable alerts = new Map<string, any>() as ObservableMap<string, any>;
  @observable startDate: Moment | null = null;
  @observable endDate: Moment | null = null;
  sortBy = ['created_at:desc'];
  pageSize = 20;
  startAfter: Moment | null = null;
  @observable hasNextPage = false;
  totalItems = 0;
  @observable text = '';
  @observable dingIds = '';
  @observable alertIds = '';
  @observable userId = '';
  @observable alertTypes = [] as IObservableArray<string>;
  alertTypesMap = alertTypesMap;
  @observable locationRadius = 10;
  @observable hasSearched = false;
  @observable agencyName = '';
  @observable adminAlertIds = '';
  @observable locationLatitude = 0;
  @observable locationLongitude = 0;

  constructor(rootStore: RootStore, searchService: SearchService) {
    super(rootStore);
    this.searchService = searchService
    this.restoreFiltersFromURL();
  }

  // API requests
  public fetchAlerts = async () => {
    this.clearAlerts();
    this.setHasSearched(false);
    this.setLoading(true);
    this.addFiltersToURL();

    const { data, meta } = await this.searchService.search(this.filters);
    const alerts = data?.items ?? [];

    this.setHasSearched(true);
    this.setLoading(false);
    this.setAlerts(alerts);
    this.setHasNextPage(meta);
    this.setStartAfter(alerts);
  };

  public fetchNextPage = async () => {
    if (!this.hasNextPage) return;
    if (this.alerts.size >= this.totalItems) return;

    this.setLoading(true);

    const { data, meta } = await this.searchService.search(this.filters);
    const alerts = data?.items ?? [];
    this.setLoading(false);
    this.addAlerts(alerts);
    this.setHasNextPage(meta);
    this.setStartAfter(alerts);
  };

  public fetchAlertHistory = async (alertId: string) => {
    const history = await AdminService.fetchAlertHistory(
      getAlertNumericId(alertId),
    );
    this.setHistory(alertId, history);
  };

  private addFiltersToURL = () => {
    const {
      alert_ids,
      admin_ids,
      agency_name,
      location_latitude,
      location_longitude,
      location_radius,
      ...filters
    } = filterNilOrEmpty({
      alert_ids: splitAndRemoveDuplicates(this.alertIds),
      admin_ids: splitAndRemoveDuplicates(this.adminAlertIds),
      date_start: this.endDate?.set('seconds', 0).toISOString(),
      date_end: this.startDate?.set('seconds', 0).toISOString(),
      text: this.text,
      user_id: this.userId,
      ding_ids: this.dingIds,
      alert_types: this.alertTypes,
      agency_name: this.agencyName,
      location_radius: this.locationRadius,
      location_latitude: this.locationLatitude,
      location_longitude: this.locationLongitude,
    });
    let params =
      this.isFilteringByAlertId || this.isFilteringByAdminAlertId
        ? { alert_ids, admin_ids }
        : this.isFilteringByPoliceAlertType
        ? { ...filters, agency_name }
        : filters;
    if (!(this.isFilteringByAlertId || this.isFilteringByAdminAlertId) && this.isFilteringByGeoPoint) {
      params = {
        ...params,
        location_latitude,
        location_longitude,
        location_radius,
      }
    }

    const qs = stringifyParams(params);

    window.history.replaceState(null, null, `?${qs}`);
  };

  private restoreFiltersFromURL = () => {
    const parsed = parseSearch();
    const {
      alert_ids = [],
      admin_ids = [],
      text = '',
      date_start = null,
      date_end = null,
      user_id = '',
      ding_ids = [],
      alert_types = [],
      agency_name = '',
      location_radius = 10,
      location_longitude = '0',
      location_latitude = '0',
    } = filterNilOrEmpty(parsed);

    this.setAlertIds(ensureArrayAndJoin(alert_ids));
    this.setAdminAlertIds(ensureArrayAndJoin(admin_ids));
    this.setText(text);
    this.setDates([
      date_start ? moment(date_start) : null,
      date_end ? moment(date_end) : null,
    ]);
    this.setUserId(user_id);
    this.setDingIds(ensureArrayAndJoin(ding_ids));
    this.setAlertTypes(ensureArray(alert_types));
    this.setAgencyName(agency_name);

    this.setLocationLongitude(parseFloat(location_longitude));
    this.setLocationLatitude(parseFloat(location_latitude));
    this.setRadius(parseNumber(location_radius));
  };

  //#region Computed

  @computed
  public get isFilteringByAlertId() {
    return this.alertIds.length > 0;
  }

  @computed
  public get isFilteringByAdminAlertId() {
    return this.adminAlertIds.length > 0;
  }

  @computed
  public get isFilteringById() {
    return this.isFilteringByAlertId || this.isFilteringByAdminAlertId;
  }

  @computed
  public get isFilteringByGeoPoint() {
    return Boolean(this.locationLatitude) && Boolean(this.locationLongitude) && Boolean(this.locationRadius);
  }

  @computed
  get isFilteringByPoliceAlertType() {
    return this.alertTypes.includes('police' as keyof typeof alertTypesMap);
  }

  @computed
  public get isFiltering() {
    return [
      this.alertIds,
      this.adminAlertIds,
      this.startDate,
      this.endDate,
      this.text,
      this.userId,
      this.dingIds,
      this.alertTypes,
      this.agencyName,
      this.isFilteringByGeoPoint,
    ].some((v) => !isNilOrEmpty(v));
  }

  @computed
  private get filters() {
    const { ids, agency_name, location_radius, location_latitude, location_longitude, ...filters } = filterNilOrEmpty({
      agency_name: this.agencyName,
      date_end: this.endDate,
      date_start: this.startDate,
      ding_ids: splitAndRemoveDuplicates(this.dingIds),
      event_types: this.alertTypes.flatMap((type) => this.alertTypesMap[type]),
      ids: [
        ...splitAlertsAndRemoveDuplicates('user')(this.alertIds),
        ...splitAlertsAndRemoveDuplicates('admin')(this.adminAlertIds),
      ],
      owner_uid: this.userId,
      page_size: this.pageSize,
      sort_by: this.sortBy,
      start_after: this.startAfter,
      text: this.text,
      location_radius: this.locationRadius,
      location_latitude: this.locationLatitude,
      location_longitude: this.locationLongitude,
    });

    let params = this.isFilteringByAlertId || this.isFilteringByAdminAlertId
        ? { ids }
        : this.isFilteringByPoliceAlertType
        ? { ...filters, agency_name }
        : filters;
    if (!(this.isFilteringByAlertId || this.isFilteringByAdminAlertId) && this.isFilteringByGeoPoint) {
      params = {
        ...params,
        location_latitude,
        location_longitude,
        location_radius,
      }
    }

    return params;
  }

  @computed
  public get hasAlerts() {
    return this.alerts.size > 0;
  }

  //#endregion

  //#region Actions

  @action
  public setAgencyName = (name: string) => {
    this.agencyName = name;
  };

  @action
  public setAlertTypes = (alertTypes: string[]) => {
    this.alertTypes.replace(alertTypes);
  };

  @action
  public setText = (value: string) => {
    this.text = value;
  };

  @action
  public setAlertIds = (ids: string) => {
    this.alertIds = ids;
  };

  /*
  TODO: remove this when guiids are implemented
  and there are no longer alerts with ids user:1234
  or admin:1234
  */
  @action
  public setAdminAlertIds = (ids: string) => {
    this.adminAlertIds = ids;
  };
  /*
  End Remove
  */

  @action
  public setUserId = (id: string) => {
    this.userId = id;
  };

  @action
  public setDingIds = (ids: string) => {
    this.dingIds = ids;
  };

  @action
  public setLocationLatitude = (value: number) => {
    this.locationLatitude = value;
  };

  @action
  public setLocationLongitude = (value: number) => {
    this.locationLongitude = value;
  };

  @action
  public setRadius = (value: number) => {
    this.locationRadius = value;
  };

  @action
  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  @action
  private setHasSearched = (value: boolean) => {
    this.hasSearched = value;
  };

  @action
  private setAlerts = (alerts: any[]) => {
    this.alerts.replace(indexById(alerts));
  };

  @action
  private addAlerts = (alerts: any[]) => {
    this.alerts.merge(indexById(alerts));
  };

  @action
  private clearAlerts = () => {
    this.alerts.clear();
    this.startAfter = null;
    this.hasNextPage = false;
  };

  public clearFilters = () => {
    this.setAlertIds('');
    this.setAdminAlertIds('');
    this.setDates([null, null]);
    this.setText('');
    this.setUserId('');
    this.setDingIds('');
    this.setAlertTypes([]);
    this.setAgencyName('');
    this.setRadius(10);
    this.setLocationLongitude(0);
    this.setLocationLatitude(0);
  };

  @action
  private setHasNextPage = ({ total_items }: SearchMeta) => {
    this.totalItems = total_items;

    if (this.alerts.size >= total_items) {
      this.hasNextPage = false;
    } else {
      this.hasNextPage = total_items > this.pageSize;
    }
  };

  @action
  private setStartAfter = (alerts) => {
    if (alerts.length === 0) return;

    const lastItemIndex = alerts.length - 1;

    this.startAfter = alerts[lastItemIndex].created_at;
  };

  @action
  public setDates = ([start, end]: [Moment, Moment]) => {
    this.startDate = start;
    this.endDate = end;
  };

  @action
  private setHistory = (alertId: string, history) => {
    const alert = this.alerts.get(alertId);
    alert.history = history;
  };
  //#endregion
}

const indexById = indexBy<any>(prop('id'));

const isNilOrEmpty = (value: any) => isNil(value) || isEmpty(value);

const filterNilOrEmpty = reject(isNilOrEmpty);

const splitAndRemoveDuplicates = pipe(
  split(','),
  map(trim),
  reject(isEmpty),
  uniq,
);

const splitAlertsAndRemoveDuplicates = (alertType: string) =>
  pipe(
    split(','),
    map((id) => translateAlertIds(alertType, id)),
    map(trim),
    reject(isEmpty),
    uniq,
  );

const translateAlertIds = (alertType: string, id: string) => {
  const trimmedId = id.trim();
  if (!trimmedId) {
    return '';
  }
  const prefix = alertType === 'user' ? 'user' : 'admin';
  return `${prefix}:${trimmedId}`;
};

const ensureArray = (maybeArray) =>
  Array.isArray(maybeArray) ? maybeArray : [maybeArray];

const ensureArrayAndJoin = pipe(ensureArray, join(','));

const parseSearch = () =>
  queryString.parse(window.location.search, {
    arrayFormat: 'comma',
  }) as SearchParams;

const stringifyParams = (object: Parameters<typeof queryString.stringify>[0]) =>
  queryString.stringify(object, {
    arrayFormat: 'comma',
  });

const parseNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }
  return parseInt(value);
};
