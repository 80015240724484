import css from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { commentsActions } from 'src/actions';
import { AlertAdditionalMenu } from 'src/components/alert-additional-menu';
import { ModerateButtons } from 'src/components/moderate-buttons';
import { Tabs, useStores } from 'src/stores';
import { ActionType, ApproveCategoryReason } from '../../constants';
import {
  IComment,
  ModerationStatus,
  PermissionLevel,
} from '../../restApi/interfaces';
import s from './CommentItemModerationTool.module.css';

interface CommentItemModerationToolProps {
  comment: IComment;
  tabStore: Tabs;
  isEscalate?: boolean;
}

export const CommentItemModerationTool = observer<
  FC<CommentItemModerationToolProps>
>(({ comment, tabStore, isEscalate }) => {
  const { uiStore, moderateModalStore, authStore, commentsStore } = useStores();
  const [actionButtonsDisabled, setActionButtonsDisabled] = useState(false);

  const showEscalateMenuButton = !(
    authStore.isUserEscalationLevel(PermissionLevel.L2) && isEscalate
  );

  const escalateL2Action = () => {
    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);
    uiStore.toggleModal(true);

    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div className={s.escalateText}>
            This comment has already been escalated.
            <br />
            Are you sure you want to escalate it again?
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Yes, escalate this comment',
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        confirmAction: () => {
          uiStore.toggleModal(false);
          commentsActions.escalateCommentToL2(comment.comment_id);
        },
        isInterestingCheckbox: false,
        isButtonEnable: true,
        submitButtonColor: 'blue',
        hasBackground: false,
      },
      header: {
        ...uiStore.modal.header,
        text: 'Escalate this comment to L2',
      },
    };
  };

  const sendToRSU = () => {
    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);

    uiStore.toggleModal(true);

    commentsStore.resetActiveModerationTimeout(); // reset timeout since action was taken

    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div>
            You’re about to send this comment to Superusers.
            <br />
            Comment needs to be approved first.
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Approve & Send to Superuser',
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        confirmAction: () => {
          commentsActions.sendCommentToRSU(
            tabStore,
            comment.comment_id,
            isEscalate ? authStore.userEscalationLevel : undefined,
          );
          uiStore.toggleModal(false);
        },
        isInterestingCheckbox: false,
        isButtonEnable: true,
        submitButtonColor: 'blue',
        hasBackground: false,
      },
      header: {
        ...uiStore.modal.header,
        text: 'Send to Superuser',
      },
    };
  };

  const escalateAction = () => {
    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);

    uiStore.toggleModal(true);

    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div>
            Are you sure you want to escalate this comment to <br />
            Senior Operators?
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Yes, escalate this comment',
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        confirmAction: () => {
          uiStore.toggleModal(false);
          commentsActions.escalateCommentToL1(comment.comment_id);
        },
        isInterestingCheckbox: false,
        isButtonEnable: true,
        submitButtonColor: 'blue',
        hasBackground: false,
      },
      header: {
        ...uiStore.modal.header,
        text: `${ActionType[ApproveCategoryReason.Escalate]} this comment`,
      },
    };
  };

  const approveAction = async () => {
    const escalationLevel = isEscalate
      ? authStore.userEscalationLevel
      : undefined;

    setActionButtonsDisabled(true);

    try {
      await commentsActions.submitComment(
        comment.alert_id,
        tabStore,
        escalationLevel,
        comment.comment_id,
        ModerationStatus.Approved,
      );
    } catch (e) {
      console.error(e);
    }

    commentsStore.resetActiveModerationTimeout(); // reset timeout since action was taken
    setActionButtonsDisabled(false);
  };

  const rejectAction = async () => {
    const escalationLevel = isEscalate
      ? authStore.userEscalationLevel
      : undefined;

    setActionButtonsDisabled(true);

    try {
      await commentsActions.submitComment(
        comment.alert_id,
        tabStore,
        escalationLevel,
        comment.comment_id,
        ModerationStatus.Declined,
      );
    } catch (e) {
      console.error(e);
    }

    commentsStore.resetActiveModerationTimeout(); // reset timeout since action was taken
    setActionButtonsDisabled(false);
  };

  const handleEscalationHandler = () => {
    isEscalate ? escalateL2Action() : escalateAction();
    commentsStore.resetActiveModerationTimeout(); // reset timeout since action was taken
  };

  const additionalMenu = [
    {
      title: 'Escalate',
      action: handleEscalationHandler,
      visible: showEscalateMenuButton,
    },
    { title: 'Send to RSU', action: sendToRSU, visible: true },
  ];

  return (
    <div className={css(s.commentContainer)}>
      <ModerateButtons
        className="left-row-double"
        disabled={actionButtonsDisabled}
        approveOnClick={approveAction}
        rejectOnClick={rejectAction}
      />
      <AlertAdditionalMenu menuItems={additionalMenu} />
    </div>
  );
});
