import { api } from 'src/api';
import {
  IComment,
  IModerationHistory,
  IModerationSessionStatus,
  IStoredAlert,
  IUpdateAlertCommentsInterface,
} from './interfaces';
import { IResponseItem, IResponseList } from './restApi';
import { logError } from '../stores/utils/errorLogger';
import { CommentService as CService } from '../services/commentService';

const path = 'comment';

export enum SessionStoppageReason {
  Manual = 'manual',
  Timeout = 'timeout',
  ClosedTab = 'left the tab',
}

export interface ICommentsConfig {
  time_until_missed_sla: number;
}

export interface ICommentTypeStatistics {
  amount: number;
  age_of_oldest: number;
}

export interface ICommentModerationStatistic {
  sessions: number;
  basic_comments: ICommentTypeStatistics;
}

export class CommentService {
  public static async getStatistic() {
    const response = await api
      .get('comment/statistics')
      .json<IResponseItem<ICommentModerationStatistic>>();
    return response.data;
  }

  public static async getAlertComment(commentId: string) {
    const response = await api
      .get(`${path}/${commentId}`)
      .json<IResponseItem<IComment>>();

    CService.convertCommentIdToString(response.data);

    return response.data;
  }

  public static async updateAlertComments(data: IUpdateAlertCommentsInterface) {
    const response = await api
      .patch('comments', { json: data })
      .json<IResponseItem<IComment>>();

    CService.convertCommentIdToString(response.data);

    return response.data;
  }

  public static async sendCommentToRSU(commentId: string) {
    try {
      await api.patch(`${path}/${commentId}/rsu`);
      return true;
    } catch (e) {
      return false;
    }
  }

  public static async escalateComment(comment: IComment) {
    try {
      comment.comment_id = parseInt(comment.comment_id);
      await api.patch(`${path}/${comment.comment_id}/escalate`, {
        json: comment,
      });

      return true;
    } catch (e) {
      return false;
    }
  }

  public static async getComment(commentId: string) {
    const response = await api
      .get(`${path}/${commentId}`)
      .json<IResponseItem<IComment>>();

    CService.convertCommentIdToString(response.data);

    return response.data;
  }

  public static async getCommentHistory(commentId: string) {
    try {
      const response = await api
        .get(`${path}/${commentId}/history`)
        .json<IResponseItem<IModerationHistory>>();

      return response.data ?? [];
    } catch (e) {
      return [];
    }
  }

  public static async setStopModerationOnPageLeave() {
    const payload = {
      active: false,
      stoppage_reason: SessionStoppageReason.ClosedTab,
    };

    fetch(`/api/v1/comment/moderation-session`, {
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      keepalive: true,
      method: 'PUT',
      mode: 'same-origin',
    });
  }

  public static async setModerationSessionStatus(
    status: boolean,
    stoppageReason?: SessionStoppageReason,
  ) {
    const response = await api
      .put(`${path}/moderation-session`, {
        json: {
          active: status,
          stoppage_reason: stoppageReason,
        },
      })
      .json<IResponseItem<IModerationSessionStatus>>();

    return response.data.active;
  }

  public static async getModerationSessionStatus() {
    const response = await api
      .get(`${path}/moderation-session`)
      .json<IResponseItem<IModerationSessionStatus>>();

    return response.data.active;
  }

  public static async assignThreads() {
    try {
      const response = await api
        .patch(`${path}/assign`)
        .json<IResponseList<IStoredAlert>>();

      CService.convertAlertsCommentIdsToString(response.data?.items);

      return response.data.items;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        skipMessage: true,
        title: 'No threads to assign',
      });
      return [];
    }
  }
}
