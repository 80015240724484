import { captureException } from '@sentry/browser';
import { notification } from 'antd';
import ky, { Input, NormalizedOptions, Options, ResponsePromise } from 'ky';
import React from 'react';
import { ResponseStatus } from 'src/restApi/restApi';
import styled from 'styled-components';

interface ExtraOptions {
  /**
   * Time in seconds before the notification is closed. When set to 0 or null, it will never be closed automatically.
   * @see https://3x.ant.design/components/notification/
   */
  errorDuration?: number;
  errorMessage?: string | false;
  sendToSentry?: boolean;
}

type ExtendedNormalizedOptions = NormalizedOptions & ExtraOptions;
type ExtendedOptions = Options & ExtraOptions;

type Ky = typeof ky;

export interface ExtendedKy extends Ky {
  (url: Input, options?: ExtendedOptions): ResponsePromise;
  get(url: Input, options?: ExtendedOptions): ResponsePromise;
  post(url: Input, options?: ExtendedOptions): ResponsePromise;
  put(url: Input, options?: ExtendedOptions): ResponsePromise;
  delete(url: Input, options?: ExtendedOptions): ResponsePromise;
  patch(url: Input, options?: ExtendedOptions): ResponsePromise;
  head(url: Input, options?: ExtendedOptions): ResponsePromise;
}

interface ApiResponse {
  status: ResponseStatus;
  error: ResponseError;
}

interface ResponseError {
  message: string;
}

// this doesn't handle network errors
// we want to automate error reporting for those kinds of errors too

export const api = ky.create({
  prefixUrl: '/api/v1/',
  retry: 0,
  timeout: false,
  hooks: {
    afterResponse: [
      async (
        { url },
        {
          errorMessage = `Failed to fetch ${url}`,
          sendToSentry = true,
          errorDuration = 0,
        }: ExtendedNormalizedOptions,
        response,
      ) => {
        const { status, error }: ApiResponse = await response.json();

        if (!response.ok || status !== ResponseStatus.Ok) {
          if (errorMessage) {
            const message = error?.message || errorMessage;
            showNotification(message, errorDuration);
          }

          if (sendToSentry) {
            captureException(errorMessage);
          }
        }
      },
    ],
  },
}) as ExtendedKy;

export const apiV2 = ky.create({
  prefixUrl: '/api/v2/',
  retry: 0,
  timeout: false,
  hooks: {
    afterResponse: [
      async (
        { url },
        {
          errorMessage = `Failed to fetch ${url}`,
          sendToSentry = true,
          errorDuration = 0,
        }: ExtendedNormalizedOptions,
        response,
      ) => {
        const { status, error }: ApiResponse = await response.json();

        if (!response.ok || status !== ResponseStatus.Ok) {
          if (errorMessage) {
            const message = error?.message || errorMessage;
            showNotification(message, errorDuration);
          }

          if (sendToSentry) {
            captureException(errorMessage);
          }
        }
      },
    ],
  },
}) as ExtendedKy;

const showNotification = (errorMessage: string, duration: number) => {
  notification.error({
    message: 'An error occurred',
    duration,
    description: <ErrorDescription children={errorMessage} />,
  });
};

export const ErrorDescription = styled.p`
  overflow-wrap: break-word;
`;
