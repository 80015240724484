import { Timeline } from 'antd';
import moment from 'moment';
import React from 'react';
import { INewsHistory } from 'src/restApi/newsService';
import { DATE_FORMAT } from '../../constants';
import { BadgeAligner } from '../Badge/components/BadgeAligner';
import { NewsItemUser } from '../NewsItemUser';
import { getNewsHistoryRecordConfig } from './helpers/getNewsHistoryRecordConfig';
import './NewsHistoryItem.less';
import { AdminAlertLink } from 'src/pages/admin/components/AdminAlertLink';

export function NewsHistoryItem(item: INewsHistory & { key?: number }) {
  const config = getNewsHistoryRecordConfig(item);

  if (!config) {
    console.error(
      'NewsHistoryItem configuration is undefined for item: ',
      item,
    );
    return undefined;
  }

  return (
    <Timeline.Item
      className="NewsHistoryItem"
      color={config.color}
      key={item.key}
    >
      <div className="NewsHistoryItem__info">
        {moment(item.time_created).format(DATE_FORMAT)}
        {item.email ? (
          <NewsItemUser
            className="NewsHistoryItem__info__user"
            email={item.email}
          />
        ) : (
          <span className="NewsHistoryItem__info__source">{item.service}</span>
        )}
      </div>

      <BadgeAligner>
        {config.badgeComponents}

        {item.alert_id && (
          <span className="NewsHistoryItem__alertId">
            Alert ID: <AdminAlertLink alertId={item.alert_id} />
          </span>
        )}
      </BadgeAligner>

      <div className="NewsHistoryItem__title">{item.title}</div>

      <div className="NewsHistoryItem__content">{item.content}</div>
    </Timeline.Item>
  );
}
