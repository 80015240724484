import React from 'react';

import css from 'classnames';
import styles from './style.module.css';

class VideoThumbnail extends React.Component<VideoThumbnailProps> {
  public videoContainer: any;
  // eslint-disable-next-line
  // @ts-ignore
  public refs: {
    image_container: HTMLCanvasElement;
  };

  public componentDidMount() {
    this.videoContainer = document.createElement('video');
    this.videoContainer.addEventListener(
      'loadeddata',
      this.getThumbnail,
      false,
    );

    this.videoContainer.src = this.props.src;
    this.videoContainer.currentTime = 5;
  }

  public getThumbnail = () => {
    const canvas = this.refs.image_container;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        this.videoContainer,
        0,
        0,
        this.props.width,
        this.props.height,
      );
      this.videoContainer = null;
    }
  };

  public render() {
    return (
      <div
        ref="container"
        className={css(styles.container)}
        style={{ width: this.props.width, height: this.props.height }}
      >
        <canvas ref="image_container" />
      </div>
    );
  }
}

interface VideoThumbnailProps {
  width: number;
  height: number;
  src: string;
}

export default VideoThumbnail;
