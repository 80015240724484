import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { IAlertHistory } from 'src/restApi/alertsService';
import { AlignedLoader } from '../../components/AlignedLoader';
import { IStoredAlert } from '../../restApi/interfaces';
import { BadgeAligner } from '../Badge/components/BadgeAligner';
import { getModerationBadges } from '../Moderation/ModerationBadges/helpers/getModerationBadges';
import { ModerationHistory } from '../Moderation/ModerationHistory';
import './AlertBadgesAndHistory.less';

export interface IAlertBadgesAndHistoryProps {
  alert: IStoredAlert;
  className?: string;
  history: IAlertHistory[] | null | undefined;
  onHistoryToggle?: (alertId: number, visible: boolean) => void;
}

interface IAlertBadgesAndHistoryState {
  visible: boolean;
}

export class AlertBadgesAndHistory extends React.Component<
  IAlertBadgesAndHistoryProps,
  IAlertBadgesAndHistoryState
> {
  public state = {
    visible: false,
  };

  private handleToggleHistory = (e) => {
    e.preventDefault();

    const { alert, onHistoryToggle } = this.props;
    const { visible } = this.state;

    onHistoryToggle?.(alert.alert_id, !visible);

    this.setState({ visible: !visible });
  };

  private renderHistory = () => {
    const { visible } = this.state;
    const { history } = this.props;

    if (!visible) return null;

    if (!history) {
      return <AlignedLoader className="AlertBadgesAndHistory__loader" />;
    }

    if (!history.length) {
      return (
        <div className="AlertBadgesAndHistory__emptyHistory">
          History is empty
        </div>
      );
    }

    return (
      <ModerationHistory
        className="AlertBadgesAndHistory__history"
        history={history}
        testID="alertHistoryBadges"
      />
    );
  };

  public render() {
    const { alert, className } = this.props;
    const { visible } = this.state;

    return (
      <div className={classNames('AlertBadgesAndHistory', className)}>
        <Button
          className="AlertBadgesAndHistory__button"
          icon={visible ? 'caret-up' : 'caret-down'}
          onClick={this.handleToggleHistory}
          data-test-id="historyButton"
        />

        <span data-test-id="historyContainer">
          {visible && this.renderHistory()}
          {!visible && (
            <BadgeAligner multiLine>{getModerationBadges(alert)}</BadgeAligner>
          )}
        </span>
      </div>
    );
  }
}
