export interface IConfirmPopup {
  visible: boolean;
  confirmText: string;
  confirmAction?: () => void;
  cancelText: string;
  cancelAction?: () => void;
  infoText: JSX.Element | null;
  infoClassName?: string;
}

export interface IPopupSimpleMenu {
  title: String;
  action?: Function;
  devided?: boolean;
  visible?: boolean;
  linked?: boolean;
  linkPath?: string;
}
export interface IPopup {
  visible: boolean;
  text: string;
  type: IPopupType;
}

export type IPopupType = 'warning' | 'error' | 'info';

export interface IUser {
  email: string;
  family_name: string;
  given_name: string;
  hd: string;
  id: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
  scope: string[];
  level: number;
}

export interface IExpandedComments {
  [alertId: string]: boolean;
}

export interface IAdminUser {
  email: string;
  scope: string[];
  isChanged?: boolean;
}

export interface INavigationList {
  title: string;
  path: string;
  showOnTab: boolean;
}

export interface IModerateOptions {
  entityType: number;
  submitType: SubmitAlertType;
  reasons: number[];
  status: number;
  reasonsToRender: Reasons[];
  freeNote: string;
  interesting: boolean;
}

export type SubmitAlertType = string;

export enum MediaViewerSourceType {
  Video = 'video',
  Image = 'image',
  Other = 'other',
}

export interface IMediaViewerSource {
  src: string;
  type: MediaViewerSourceType;
}
interface Node {
  value: string;
  label: string;
  className?: string;
  children?: Node[];
}
export type ICategories = Node;

export interface IServerResponse {
  status: number;
  data: any;
}

export interface IWebsocket {
  data: string;
}

export type PermissionsCheckHandler = (permissions: string[]) => string[];

export interface IPermissions {
  [link: string]: string[] | PermissionsCheckHandler;
}

export interface Reasons {
  title: string;
  subcategory?: { title: string }[];
}

export interface IModal {
  header: {
    text?: string;
  };
  content: {
    element?: JSX.Element | null;
    showSelect?: boolean;
  };
  footer: {
    submitButtons?: boolean;
    cancelAction?: Function;
    cancelText?: string;
    confirmAction?: Function;
    confirmText?: string;
    isButtonEnable?: boolean;
    isInterestingCheckbox?: boolean;
    submitButtonColor?: 'blue' | 'green';
    hasBackground?: boolean;
  };
}

export interface Reasons {
  title: string;
  subcategory?: { title: string }[];
}

export interface AutoapproveConfig {
  alerts: {
    expire_in: number;
  };
  comments: {
    expire_in: number;
  };
}

export interface IPageScrollPos {
  x: number;
  y: number;
}

export interface ISearchParams {
  [key: string]: string;
}

export interface IDropdownOptions {
  text: string;
  value: string;
}
