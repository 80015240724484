import css from 'classnames';
import React from 'react';
import styles from './style.module.css';

class Button extends React.Component<ButtonProps> {
  public static defaultProps: Partial<ButtonProps> = {
    color: 'white',
    additionalClassName: '',
    height: 36,
  };

  public render() {
    return (
      <div
        className={css(
          styles.container,
          styles[this.props.color],
          this.props.active && styles[`${this.props.color}Active`],
          this.props.additionalClassName,
        )}
        style={{ height: this.props.height }}
        onClick={() => this.props.action && this.props.action()}
      >
        {this.props.children}
      </div>
    );
  }
}

interface ButtonProps {
  children?: JSX.Element[] | JSX.Element;
  action?: Function;
  color?: string;
  additionalClassName?: string;
  active?: boolean;
  height?: number;
}

export { Button };
