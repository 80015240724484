import { Button, Form, Input, InputNumber, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import { replace, toUpper } from 'ramda';
import React, { ChangeEvent, FormEvent } from 'react';
import { Flex } from 'src/components/Flex';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import { SearchRangePicker } from '../search-date-picker';
const { Title } = Typography;

export const SearchFilter = observer(() => {
  const { searchStore } = useStores();

  const handleAlertIdsChange = getHandler(searchStore.setAlertIds);
  const handleAdminAlertIdsChange = getHandler(searchStore.setAdminAlertIds);
  const handleKeywordsChange = getHandler(searchStore.setText);
  const handleUserIdChange = getHandler(searchStore.setUserId);
  const handleDingIdChange = getHandler(searchStore.setDingIds);
  const handleAgencyNameChange = getHandler(searchStore.setAgencyName);

  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (searchStore.isLoading) return;

    searchStore.fetchAlerts();
  };

  return (
    <Container flexDirection="column">
      <Form layout="vertical" onSubmit={handleSearchSubmit}>
        <Title level={3} data-test-id="filtersTitle">
          Filters
        </Title>
        <Form.Item label="Alert IDs">
          <Input
            name="alert_ids"
            placeholder="Enter some alert IDs"
            allowClear
            onChange={handleAlertIdsChange}
            value={searchStore.alertIds}
            data-test-id="filterByAlertId"
          />
        </Form.Item>
        <Form.Item label="Admin Alert IDs">
          <Input
            name="admin_ids"
            placeholder="Enter some admin alert IDs"
            allowClear
            onChange={handleAdminAlertIdsChange}
            value={searchStore.adminAlertIds}
            data-test-id="filterByAdminAlertId"
          />
        </Form.Item>
        <Flex flex={1} justifyContent="center">
          OR
        </Flex>
        <Form.Item label="Date / Time">
          <SearchRangePicker disabled={searchStore.isFilteringById} />
        </Form.Item>
        <Form.Item label="Keywords">
          <Input
            name="keywords"
            placeholder="Enter some keywords"
            allowClear
            onChange={handleKeywordsChange}
            value={searchStore.text}
            disabled={searchStore.isFilteringById}
            data-test-id="filterByKeywords"
          />
        </Form.Item>
        <Form.Item label="User ID">
          <Input
            name="userId"
            placeholder="Enter an User ID"
            allowClear
            onChange={handleUserIdChange}
            disabled={searchStore.isFilteringById}
            value={searchStore.userId}
            data-test-id="filterByUserId"
          />
        </Form.Item>
        <Form.Item label="Ding IDs">
          <Input
            name="dingIds"
            placeholder="Enter some Ding IDs"
            allowClear
            onChange={handleDingIdChange}
            disabled={searchStore.isFilteringById}
            value={searchStore.dingIds}
            data-test-id="filterByDingId"
          />
        </Form.Item>
        <Container flexDirection="row">
          <Form.Item label="Latitude">
            <InputNumber
              name="location_latitude"
              onChange={searchStore.setLocationLatitude}
              disabled={searchStore.isFilteringByAlertId}
              value={searchStore.locationLatitude}
              data-test-id="filterLocationLatitude"
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <InputNumber
              name="location_longitude"
              onChange={searchStore.setLocationLongitude}
              disabled={searchStore.isFilteringByAlertId}
              value={searchStore.locationLongitude}
              data-test-id="filterLocationLongitude"
            />
          </Form.Item>
          <Form.Item label="Radius (in Miles)">
            <InputNumber
              name="location_radius"
              onChange={searchStore.setRadius}
              disabled={searchStore.isFilteringByAlertId}
              value={searchStore.locationRadius}
              data-test-id="filterLocationRadius"
            />
          </Form.Item>
        </Container>
        <Form.Item label="Alert type">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select an alert type"
            onChange={searchStore.setAlertTypes}
            disabled={searchStore.isFilteringById}
            value={searchStore.alertTypes.slice()}
            data-test-id="filterByAlertType"
            allowClear
          >
            {Object.keys(searchStore.alertTypesMap).map((type) => (
              <Select.Option key={type} data-test-id={type}>
                {capitalize(type)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Agency name">
          <Input
            name="agencyName"
            placeholder="Enter an agency name"
            allowClear
            onChange={handleAgencyNameChange}
            disabled={!searchStore.isFilteringByPoliceAlertType}
            value={searchStore.agencyName}
            data-test-id="filterByAgencyName"
          />
        </Form.Item>
        <Form.Item>
          <SearchButton
            type="primary"
            htmlType="submit"
            size="large"
            block
            disabled={searchStore.isLoading}
            data-test-id="searchButton"
          >
            Search
          </SearchButton>
          <SearchButton
            type="ghost"
            htmlType="button"
            size="large"
            block
            disabled={searchStore.isLoading}
            onClick={searchStore.clearFilters}
            data-test-id="resetButton"
          >
            Reset
          </SearchButton>
        </Form.Item>
      </Form>
    </Container>
  );
});

const SearchButton = styled(Button)`
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.space[2]}px;
  }
`;

const Container = styled(Flex)`
  top: 60px;
  column-gap: 0.875rem;
`;

const getHandler = (fn: (arg: any) => void) => (
  e: ChangeEvent<HTMLInputElement>,
) => fn(e.target.value);

const capitalize = replace(/^./, toUpper);
