import cx from 'classnames';
import React from 'react';
import { Loader } from '../../../../components/Loader';
import { VerticalAligner } from '../../../../components/VerticalAligner';
import './block-loader.less';

export interface IBlockLoaderProps {
  className?: string;
  styles?: React.CSSProperties;
}

export const BlockLoader = ({ className, ...props }: IBlockLoaderProps) => (
  <VerticalAligner
    {...props}
    className={cx('BlockLoader__verticalAligner', className)}
  >
    <Loader />
  </VerticalAligner>
);
