import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { MediaViewer } from 'src/components/MediaViewer';
import VideoThumbnail from 'src/components/MediaViewer/components/VideoThumbnail';
import css from 'classnames';
import './SearchAlertMultimediaViewer.less';

export const SearchAlertMultimediaViewer = (props) => {
  const { alertId, className, multimediaAssets, size } = props;
  const hasSingleMediaAsset = multimediaAssets?.length === 1;
  const firstAsset = multimediaAssets[0];

  const renderCustomThumbnails = (children) => {
    return children.map((item, i) => {
      if (item.props.source.type === 'image') {
        return (
          <Box
            key={`thumbnail-asset-img-${alertId}-${i}`}
            backgroundImage={`url(${getProcessedMediaUrl(item.props.source.src)})`}
            backgroundPosition={'center'}
            backgroundSize={'cover'}
            height={'48px'}
          >
            <img
              alt={`media-thumbnail-asset-${alertId}-${i}`}
              src={getProcessedMediaUrl(item.props.source.src)}
              height={80}
              style={{ display: 'none' }}
              data-test-id="mediaItem image"
            />
          </Box>
        );
      } else {
        return (
          <Box key={`thumbnail-asset-video-${alertId}-${i}`}>
            <VideoThumbnail
              src={getProcessedMediaUrl(item.props.source.src)}
              width={70}
              height={48}
              data-test-id="mediaItem video"
            />
          </Box>
        );
      }
    });
  };

  return !hasSingleMediaAsset ? (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      className={css(`SearchAlertMultimediaViewer__container`, className)}
      data-test-id="alertMmiContainer"
    >
      <Carousel
        className={css('SearchAlertMultimediaViewer__carousel')}
        renderThumbs={renderCustomThumbnails}
        showArrows={false}
        showIndicators={false}
      >
        {multimediaAssets.map((asset, i) => (
          <MediaViewer
            key={`media-carousel-${alertId}-${i}`}
            source={{ src: asset.url, type: asset.type }}
            size={size}
          />
        ))}
      </Carousel>
    </Flex>
  ) : (
    <MediaViewer
      //className={css('SearchAlertMultimediaViewer__mediaViewer')} TODO: Uncomment this line if you want to keep the aspect ratio
      source={{ src: firstAsset.url, type: firstAsset.type }}
      size={size}
    />
  );
};

const getProcessedMediaUrl = (url: string) => {
  // We only need to transform URLs starting with nps-assets and not other URLs like share service
  if (!url.startsWith("https://nps-assets")) {
    return url;
  }
  let processedUrl = url.replace('/unprocessed_uploads', '');
  return processedUrl.replace(/(\/[^/]+?)(?=(\.[^.]+$))/, (match) =>
    match.endsWith('_x') ? match : `${match}_x`,
  );
};
