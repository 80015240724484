import css from 'classnames';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import React, {
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { useEvent } from 'react-use';
import { Button } from 'src/components/button';
import { ModerationStatus } from 'src/restApi/interfaces';
import { URLService } from 'src/services/urlService';
import { useStores } from 'src/stores';
import { ICategories } from '../../../../interfaces';
import flag from './img/flag.svg';
import './react-tree-view.css';
import s from './style.module.css';
import { TreeCheckbox } from './TreeCheckbox';

interface AdminCategoriesSelectorProps {
  acceptButtonHandler: Function;
  closeElement: () => void;
}

export const AdminCategoriesSelector = observer(
  ({ acceptButtonHandler, closeElement }: AdminCategoriesSelectorProps) => {
    const { adminStore } = useStores();
    const { selectedFilters: defaultFilters } = adminStore;
    const ref = useRef<HTMLDivElement | null>(null);
    const [checkedAlerts, setCheckedAlerts] = useState(defaultFilters.alerts);
    const [checkedComments, setCheckedComments] = useState(
      defaultFilters.comments,
    );
    const [checkedDeleted, setCheckedDeleted] = useState(
      defaultFilters.deleted,
    );
    const [checkedFlagged, setCheckedFlagged] = useState(
      defaultFilters.flagged,
    );
    const [
      checkedIsAlertsWithCaseInfo,
      setCheckedIsAlertsWithCaseInfo,
    ] = useState(defaultFilters.isAlertsWithCaseInfo);
    const [
      checkedIsAlertsWithoutCaseInfo,
      setCheckedIsAlertsWithoutCaseInfo,
    ] = useState(defaultFilters.isAlertsWithoutCaseInfo);
    const [checkedIsLEOAlerts, setCheckedIsLEOAlerts] = useState(
      defaultFilters.isLEOAlerts,
    );
    const [checkedIsLEOComments, setCheckedIsLEOComments] = useState(
      defaultFilters.isLEOComments,
    );
    const [checkedOnRSU, setCheckedOnRSU] = useState(defaultFilters.onRSU);
    const [checkedResolved, setCheckedResolved] = useState(
      defaultFilters.resolved,
    );
    const [expandedAlerts, setExpandedAlerts] = useState([]);
    const [expandedComments, setExpandedComments] = useState([]);

    const nodes = useMemo(
      () => getCheckboxTreeNodes(adminStore.categoryFilters),
      [adminStore.categoryFilters],
    );

    const handleConfirm = () => {
      URLService.addSearchParams({
        alerts: checkedAlerts.join(';'),
        comments: checkedComments.join(';'),
        deleted: checkedDeleted,
        flagged: checkedFlagged,
        is_alerts_with_case_info: checkedIsAlertsWithCaseInfo,
        is_alerts_without_case_info: checkedIsAlertsWithoutCaseInfo,
        is_leo_alerts: checkedIsLEOAlerts,
        is_leo_comments: checkedIsLEOComments,
        on_rsu: checkedOnRSU,
        resolved: checkedResolved,
      });

      transaction(() => {
        adminStore.setFlaggedFilter(checkedFlagged);
        adminStore.setDeletedFilter(checkedDeleted);
        adminStore.setCommentsFilter(checkedComments);
        adminStore.setAlertsFilter(checkedAlerts);
        adminStore.setIsAlertsWithCaseInfoFilter(checkedIsAlertsWithCaseInfo);
        adminStore.setIsAlertsWithoutCaseInfoFilter(
          checkedIsAlertsWithoutCaseInfo,
        );
        adminStore.setIsLEOAlertsFilter(checkedIsLEOAlerts);
        adminStore.setIsLEOCommentsFilter(checkedIsLEOComments);
        adminStore.setOnRSUFilter(checkedOnRSU);
        adminStore.setResolvedFilter(checkedResolved);
      });

      acceptButtonHandler();
    };

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          closeElement();
        }
      },
      [closeElement],
    );

    useEvent('mousedown', handleClickOutside);

    return (
      <div className={s.container} ref={ref}>
        <CheckboxTree
          checked={checkedAlerts}
          expanded={expandedAlerts}
          nodes={nodes.alerts}
          onCheck={setCheckedAlerts}
          onExpand={setExpandedAlerts}
        />
        <CheckboxTree
          checked={checkedComments}
          expanded={expandedComments}
          nodes={nodes.comments}
          onCheck={setCheckedComments}
          onExpand={setExpandedComments}
        />
        {(nodes.alerts.length > 0 || nodes.comments.length > 0) && (
          <span>
            <TreeCheckbox
              checked={checkedFlagged}
              className={s.flaggedContainer}
              onChange={setCheckedFlagged}
              title={
                <>
                  Flagged Content{' '}
                  <img className={s.flag} src={flag} alt="Flagged content" />
                </>
              }
              value="on"
            />
            <TreeCheckbox
              checked={checkedOnRSU}
              className={s.onRsuContainer}
              isDanger
              onChange={setCheckedOnRSU}
              title="On RSU"
              value="on"
            />
            <TreeCheckbox
              checked={checkedResolved}
              className={s.resolvedContainer}
              onChange={setCheckedResolved}
              title="Resolved Alerts"
              value="on"
            />
            <TreeCheckbox
              checked={checkedDeleted}
              className={s.deletedContainer}
              isDanger
              onChange={setCheckedDeleted}
              title="Deleted Comments"
              value="on"
            />
            <TreeCheckbox
              checked={checkedIsLEOAlerts}
              className={s.deletedContainer}
              onChange={setCheckedIsLEOAlerts}
              title="Police Alerts"
              value="on"
            />
            <TreeCheckbox
              checked={checkedIsLEOComments}
              className={s.deletedContainer}
              onChange={setCheckedIsLEOComments}
              title="Police Comments"
              value="on"
            />
            <TreeCheckbox
              checked={checkedIsAlertsWithCaseInfo}
              className={s.deletedContainer}
              onChange={setCheckedIsAlertsWithCaseInfo}
              title="Alerts With Case Info"
              value="on"
            />
            <TreeCheckbox
              checked={checkedIsAlertsWithoutCaseInfo}
              className={s.deletedContainer}
              onChange={setCheckedIsAlertsWithoutCaseInfo}
              title="Alerts Without Case Info"
              value="on"
            />
          </span>
        )}
        <Button
          action={handleConfirm}
          additionalClassName={css(s.submitButton, 'row top-column')}
        >
          <div>Confirm</div>
        </Button>
      </div>
    );
  },
);

const getCheckboxTreeNodes = (categoriesData) => {
  return categoriesData.reduce((res, category) => {
    res[category.id] = [getCategoryMenuLevel(category)];
    return res;
  }, {});
};

const getCategoryMenuLevel = (
  { statusReason, subcategory = [], title, status, id },
  valuePath = '',
  level = 0,
): ICategories => {
  const valueId = status
    ? `${status}${statusReason ? `,${statusReason}` : ''}`
    : id;

  const value = `${valuePath}${valuePath.length ? ',' : ''}${valueId}`;

  let className;

  switch (level) {
    case 0:
      className = 'main-category';
      break;
    case 1:
      className = `category-selector ${
        status === ModerationStatus.Declined ||
        status === ModerationStatus.AutoDeclined
          ? 'declined'
          : ''
      }`;
      break;
    default:
      className = subcategory ? 'has-children' : 'no-children';
  }

  const children = subcategory.map((subcategory) =>
    getCategoryMenuLevel(subcategory, value, level + 1),
  );

  return {
    value,
    className,
    label: title,
    children: children.length > 0 ? children : null, // leaf node https://github.com/jakezatecky/react-checkbox-tree/issues/185
  };
};
