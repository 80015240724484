import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Popup } from 'src/components/popup';
import { browserType } from 'src/helpers';
import { useStores } from 'src/stores';
import { HomeHeader } from './components/header';
import { MainLayout } from './components/layouts';
import { NavigationTabs, UnsupportBrowserText } from './constants';
import './helpers/datadogLogs';
import { AdminPage } from './pages/admin';
import { AlertsEscalation } from './pages/alerts-escalation';
import { AlertsPage } from './pages/AlertsPage';
import { CommentsPage } from './pages/CommentsPage';
import { AllWordsPage } from './pages/config-list/words/containers';
import { ManageUsersPage } from './pages/manage-users';
import { NewsCurationPageV2 } from './pages/NewsCurationPage';
import { PermissionsPage } from './pages/permissions';
import { RSUPage } from './pages/rsu';
import { SearchPage } from './pages/search';
import { Signin } from './pages/signin';
import { PrivateRoute } from './PrivateRoute';
import { PrivateRouter } from './PrivateRouter';

export const App = observer(() => {
  const { authStore, uiStore } = useStores();
  const { popup } = uiStore;

  useEffect(() => {
    authStore.getUser();

    if (!browserType.isChrome) {
      uiStore.showPopup(UnsupportBrowserText, 'warning');
    }
  }, [authStore, uiStore]);

  return (
    <div className="container">
      {popup && popup.visible && (
        <Popup
          text={popup.text}
          onClose={uiStore.hidePopup}
          type={popup.type}
        />
      )}
      <MainLayout initialHeaderControls={<HomeHeader />}>
        <Switch>
          <PrivateRouter>
            <PrivateRoute
              exact
              path={NavigationTabs.ALERTS.path}
              component={AlertsPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.ESCALATED.path}
              component={AlertsEscalation}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.COMMENTS.path}
              component={CommentsPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.RSU.path}
              component={RSUPage}
            />
            <PrivateRoute
              exact={false}
              path={NavigationTabs.CURATION_V2.path}
              component={NewsCurationPageV2}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.CONFIG_LIST_ALL_WORDS.path}
              component={AllWordsPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.ADMIN.path}
              component={AdminPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.PERMISSIONS.path}
              component={PermissionsPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.SEARCH.path}
              component={SearchPage}
            />
            <PrivateRoute
              exact
              path={NavigationTabs.MANAGE_USERS.path}
              component={ManageUsersPage}
            />
          </PrivateRouter>
          <Route exact path={NavigationTabs.SIGNIN.path} component={Signin} />
        </Switch>
      </MainLayout>
    </div>
  );
});
