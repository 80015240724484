import css from 'classnames';
import React from 'react';
import { Button } from 'src/components/button';
import styles from './style.module.css';

export class AdminDownloadReportButton extends React.Component<
  AdminDownloadReportButtonProps
> {
  public render() {
    const title = this.props.active ? 'Download Report' : 'Generate Report';

    return (
      <span className={styles.container}>
        {this.props.active && (
          <Button
            additionalClassName={css(styles.undoButton, 'row-right')}
            action={() => this.props.undoOnClick && this.props.undoOnClick()}
          >
            <span className={'row'}>Undo</span>
          </Button>
        )}
        <Button
          additionalClassName={css(
            styles.csvButton,
            this.props.active && styles.csvActiveButton,
            'row',
          )}
          action={() => this.props.onClick && this.props.onClick()}
        >
          <div
            className={css(
              styles.icon,
              this.props.active && styles.activeIcon,
              'right-row',
            )}
          />
          <span className={'row'}>{title}</span>
        </Button>
      </span>
    );
  }
}

interface AdminDownloadReportButtonProps {
  onClick?: Function;
  active?: boolean;
  undoOnClick?: Function;
}
