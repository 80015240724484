import cx from 'classnames';
import React from 'react';
import './Icon.less';

export enum IconType {
  AlertId = 'alertId',
  Agency = 'agency',
  Case = 'case',
  Clock = 'clock',
  DingId = 'dingId',
  Flag = 'flag',
  Location = 'location',
  Message = 'message',
  Note = 'note',
  Officer = 'officer',
  Telephone = 'telephone',
  User = 'user',
  Copy = 'copy',
  Exclamation = 'exclamation',
  Info = 'info',
}

export interface IconProps {
  className?: string;
  iconType?: IconType;
  inline?: boolean;
  onClick?: () => void;
}

export const Icon = ({
  iconType = IconType.Location,
  inline = true,
  className,
  onClick,
}: IconProps) => (
  <div
    className={cx(
      'Icon',
      `${iconType}Icon`,
      { inline, clickable: Boolean(onClick) },
      className,
    )}
    onClick={onClick}
  />
);
