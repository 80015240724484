import { sortBy } from 'lodash';
import { flow, observable } from 'mobx';
import {INewsOptions, INewsService} from '../restApi/newsService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';
import {RootStore} from "./rootStore";

export class NewsOptionsStore extends InjectRootStore {
  public readonly newsService: INewsService;

  @observable
  public loading = true;

  @observable.ref
  public options: INewsOptions | null = null;

  constructor(rootStore: RootStore, newsService: INewsService) {
    super(rootStore)
    this.newsService = newsService
  }

  public loadOptionsFlow = flow(function* (this: NewsOptionsStore) {
    try {
      this.loading = true;

      const options = yield this.newsService.getNewsAlertOptions();

      options.editors = sortBy(options.editors, (editor) =>
        editor.toLowerCase(),
      );

      this.options = options;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to load news filters values',
      });
    } finally {
      this.loading = false;
    }
  });
}
