import React from 'react';
import { BadgeLabel } from '../../../../components/BadgeLabel';
import './news-alert-status-badge.less';

export interface INewsAlertStatusBadgeProps {
  className?: string;

  status: string;
}

export const NewsAlertStatusBadge = ({
  className = '',
  status,
}: INewsAlertStatusBadgeProps) => {
  return (
    <BadgeLabel
      className={`NewsAlertStatusBadge ${status} ${className}`}
      text={status.toUpperCase()}
    />
  );
};
