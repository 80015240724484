import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import { ulid } from 'ulid';
import { config } from '../config';
import { env, isDevelopment } from './appHelpers';

export enum DDLogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export enum DDLogDestination {
  Http = 'http',
  Console = 'console',
  Silent = 'silent',
}

const ddToken = config.datadog.token;

datadogLogs.init({
  clientToken: ddToken,
  forwardErrorsToLogs: true,
});

const context = {
  clientSessionId: ulid(),
  env,
  hash: config.buildInfo.hash,
  user: null as string | null,
  version: config.buildInfo.version,
};

datadogLogs.addLoggerGlobalContext('service', 'neighbors-admin-client');
datadogLogs.addLoggerGlobalContext('clientContext', context);
datadogLogs.addLoggerGlobalContext('host', window.location.host);

export class DD {
  private static logId = 0;

  private static addDefaultParameters(props: any, message: string) {
    this.logId += 1;

    return {
      content: message,
      logId: this.logId,
      payload: props,
    };
  }

  public static error(message: string, object: Object = {}) {
    return this.log(message, object, DDLogLevel.Error);
  }

  public static log(message: string, object: Object = {}, level?: DDLogLevel) {
    return datadogLogs.logger.log(
      message,
      this.addDefaultParameters(object, message),
      level,
    );
  }

  public static debug(message: string, object: Object = {}) {
    return this.log(message, object, DDLogLevel.Debug);
  }

  public static info(message: string, object: Object = {}) {
    return this.log(message, object, DDLogLevel.Info);
  }

  public static warn(message: string, object: Object = {}) {
    return this.log(message, object, DDLogLevel.Warn);
  }

  public static setUser(email: string | null) {
    context.user = email;
    datadogLogs.addLoggerGlobalContext('clientContext', context);
  }
}

if (isDevelopment) {
  datadogLogs.logger.setHandler(HandlerType.console);
}
