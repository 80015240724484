import React from 'react';

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M11.727 13.208c-2.324 0-4.215-1.906-4.215-4.25V8.25c0-2.344 1.89-4.25 4.215-4.25s4.215 1.906 4.215 4.25v.708c0 2.344-1.89 4.25-4.215 4.25zm5.144 2.112a3.555 3.555 0 0 1 2.584 3.417V21H4v-2.263a3.555 3.555 0 0 1 2.584-3.417 19.456 19.456 0 0 1 10.287 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#a" />
        <g fill="#354052" fillOpacity=".2" mask="url(#b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};
