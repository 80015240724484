import css from 'classnames';
import React from 'react';

import { Box } from 'src/components/Box';
import { AlertItemDataContainer } from 'src/components/alert-item-data-container';
import { MediaViewer } from 'src/components/MediaViewer';
import { SearchAlertMultimediaViewer } from 'src/pages/search/components/search-alert-multimedia-viewer';

import { alertsActions } from 'src/actions';
import { IStoredAlert } from '../../restApi/interfaces';
import { Tabs } from 'src/stores';
import { getMediaContentType } from 'src/helpers';

import styles from './style.module.css';

interface AlertItemProps {
  tabStore: Tabs;
  recordedDate?: boolean;
  sharedDate?: boolean;
  hasTimer?: boolean;
  isEscalate?: boolean;
  alert: IStoredAlert;
  compact?: boolean;
  canModerated?: boolean;
  hasRSUModeration?: boolean;
  hideNoVisualContentText?: boolean;
}

interface AlertItemStates {
  isEditingMode: boolean;
  blockEditing: boolean;
  isModeratedAlert: boolean;
}

class AlertItem extends React.PureComponent<AlertItemProps, AlertItemStates> {
  public state = {
    isEditingMode: false,
    blockEditing: true,
    isModeratedAlert: false,
  };

  public mediaSize = {
    height: 396,
    width: '100%',
  };

  public compactMediaSize = {
    height: 396,
    width: '100%',
  };

  public componentDidMount() {
    alertsActions.fetchAlertHistory(
      this.props.alert.alert_id,
      this.props.tabStore,
    );
  }

  public render() {
    const { alert, tabStore, compact, hasRSUModeration } = this.props;
    const hasMultipleMedia = alert?.media_assets?.length > 0;

    return (
      <div
        className={css(styles.alertItem, {
          [styles.compactAlertContainer]: compact,
        })}
      >
        {hasMultipleMedia ? (
          <Box width={2 / 3} className={css(styles.boxMediaContainer)}>
            <SearchAlertMultimediaViewer
              alertId={alert.alert_id}
              multimediaAssets={alert.media_assets}
              showHistory={true}
              size={compact ? this.compactMediaSize : this.mediaSize}
            />
          </Box>
        ) : (
          <div
            className={css(styles.mediaContainer, {
              [styles.compactMediaContainer]: compact,
            })}
          >
            <MediaViewer
              id={this.props.alert.alert_id}
              size={compact ? this.compactMediaSize : this.mediaSize}
              posterURL={this.props.alert.image_url}
              hideNoVisualContentText={this.props.hideNoVisualContentText}
              source={getMediaContentType(
                this.props.alert.src,
                this.props.alert.image_url,
              )}
            />
          </div>
        )}
        <AlertItemDataContainer
          cancelEditingAction={() =>
            alertsActions.cancelEditAlert(alert.alert_id, tabStore)
          }
          markAsResolved={() =>
            alertsActions.markAlertAsResolved(alert.alert_id)
          }
          hasTimer={this.props.hasTimer}
          isEscalate={this.props.isEscalate}
          alert={alert}
          tabStore={tabStore}
          sharedDate={this.props.sharedDate}
          recordedDate={this.props.recordedDate}
          compact={compact}
          canModerated={this.props.canModerated}
          hasRSUModeration={hasRSUModeration}
        />
        <div className="clear" />
      </div>
    );
  }
}

export { AlertItem };
