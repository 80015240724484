import { Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Stores } from 'src/stores';
import { AlignedLoader } from '../../../components/AlignedLoader';
import { BlockLayout } from '../../../components/BlockLayout';
import { ModerationInfoBlock } from '../../../componentsNew/Moderation/ModerationInfoBlock/ModerationInfoBlock';
import { INews } from '../../../restApi/newsService';
import { CenterContentBlock } from '../components/CenterContentBlock';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { FormWrapper } from '../components/FormWrapper';
import {
  INewsAlertFormValues,
  NewsAlertForm,
} from '../components/NewsAlertForm';
import { PreviewModal } from '../components/PreviewModal';
import {
  makeCapitalizedDropdownOptions,
  makeSourcesDropdownOptions,
} from '../helpers/newsFilterHelper';

type IEditNewsAlertPageProps = RouteComponentProps<any> & Stores;

interface IEditNewsAlertPageState {
  showDeleteConfirmationDialog: boolean;
  showPreviewDialog: boolean;
}

@inject('newsOptionsStoreV2', 'newsStoreV2')
@observer
export class EditNewsAlertPageV2 extends React.Component<
  IEditNewsAlertPageProps,
  IEditNewsAlertPageState
> {
  private static replaceNullValueWithUndefined = (values: INews): INews => {
    return Object.keys(values).reduce((obj, prop) => {
      obj[prop] = values[prop] !== null ? values[prop] : undefined;
      return obj;
    }, {}) as INews;
  };

  public _cachedFooter;
  public onSubmit;
  public initialValues: INewsAlertFormValues;
  public isSavedNews = false;
  public newsId: string;
  public state = {
    showDeleteConfirmationDialog: false,
    showPreviewDialog: false,
  };
  private _deleting = false;
  private _loading = false;
  private _saving = false;

  public formValues: INewsAlertFormValues = {};

  public goToNewsList = () => {
    const { history } = this.props;

    history.goBack();
  };

  public handlePublish = async (values) => {
    const { newsStoreV2 } = this.props;

    const publishValues = {
      ...values,
      status: 'published',
    };

    const isUpdated = ((await newsStoreV2.updateNewsFlow(
      publishValues,
    )) as unknown) as boolean;

    if (isUpdated) {
      this.handleSuccessNewsCreation(publishValues);
    }
  };

  public handleSuccessNewsCreation = (updated) => {
    this.goToNewsList();
    message.success(`'${updated.title}' has been published.`);
  };

  public handleSuccessDeletion = () => {
    this.goToNewsList();
    message.success('News alert has been deleted.');
  };

  private saveInitialNewsData = (newsData) => {
    if (!this.initialValues && newsData) {
      this.initialValues = this.formValues = EditNewsAlertPageV2.replaceNullValueWithUndefined(
        newsData,
      );
    }
  };

  public componentWillMount() {
    const { match, newsStoreV2 } = this.props;

    const newsId = match.params.id;

    newsStoreV2.loadNewsFlow(newsId);
  }

  public componentWillUnmount() {
    const { newsStoreV2 } = this.props;

    newsStoreV2.clear();
  }

  public onDeleteSubmit = async () => {
    const { newsStoreV2 } = this.props;

    const isDeleted = ((await newsStoreV2.deleteNewsFlow()) as unknown) as boolean;

    if (isDeleted) {
      this.handleSuccessDeletion();
    }
  };

  public renderFooterControls = (onSubmit) => {
    const { newsStoreV2 } = this.props;

    this.onSubmit = onSubmit;
    if (
      !this._cachedFooter ||
      this._deleting !== newsStoreV2.deleting ||
      this._loading !== newsStoreV2.loading ||
      this._saving !== newsStoreV2.saving
    ) {
      this._deleting = newsStoreV2.deleting;
      this._loading = newsStoreV2.loading;
      this._saving = newsStoreV2.saving;

      const showDeleteButton =
        this.initialValues && this.initialValues.status !== 'deleted';
      const deleteButtonIsDisabled =
        newsStoreV2.deleting || newsStoreV2.loading || newsStoreV2.saving;
      const postButtonIsDisabled = newsStoreV2.deleting || newsStoreV2.loading;

      this._cachedFooter = (
        <BlockLayout
          left={
            showDeleteButton && (
              <Button
                disabled={deleteButtonIsDisabled}
                onClick={this.showDeleteConfirmationDialog}
                type="danger"
              >
                Delete
              </Button>
            )
          }
          right={
            <div key="footerControls" className="nha-block-align-right">
              <Button
                disabled={postButtonIsDisabled}
                loading={newsStoreV2.saving}
                onClick={onSubmit}
                type="primary"
              >
                Publish
              </Button>
            </div>
          }
        />
      );
    }
    return this._cachedFooter;
  };

  public showDeleteConfirmationDialog = () =>
    this.setState({ showDeleteConfirmationDialog: true });
  public showPreviewDialog = () => this.setState({ showPreviewDialog: true });

  public hideDeleteConfirmationDialog = () =>
    this.setState({ showDeleteConfirmationDialog: false });
  public hidePreviewDialog = () => this.setState({ showPreviewDialog: false });

  public onFormChange = (formValues) => {
    this.formValues = formValues;
  };

  public render() {
    const { newsOptionsStoreV2, newsStoreV2 } = this.props;
    const {
      categories,
      radius,
      creatable_services,
      types,
    } = newsOptionsStoreV2.options;

    // newsStore.saving;

    this.saveInitialNewsData(newsStoreV2.news);

    return !newsStoreV2.loading && this.initialValues ? (
      <CenterContentBlock withPadding>
        <DeleteConfirmationModal
          cancelButtonProps={{ disabled: newsStoreV2.deleting }}
          cancelText="Cancel"
          closable={!newsStoreV2.deleting}
          confirmLoading={newsStoreV2.deleting}
          maskClosable={!newsStoreV2.deleting}
          onCancel={this.hideDeleteConfirmationDialog}
          onOk={this.onDeleteSubmit}
          okText="Delete"
          okType="danger"
          title="Select reason to delete an article"
          visible={this.state.showDeleteConfirmationDialog}
        />

        <PreviewModal
          formValues={this.formValues}
          onSubmit={this.onSubmit}
          show={this.state.showPreviewDialog}
          hideModal={this.hidePreviewDialog}
        />

        <FormWrapper
          onFormChange={this.onFormChange}
          componentProps={{
            categories: makeCapitalizedDropdownOptions(categories),
            componentTitle: 'Edit Alert',
            dummyForMobx: newsStoreV2.loading || newsStoreV2.saving,
            placeholderLocation: {
              latitude: 34.031705599999995,
              longitude: -118.4717859,
            },
            radius,
            services: makeSourcesDropdownOptions(creatable_services),
            types: makeCapitalizedDropdownOptions(types),
          }}
          initialValues={this.initialValues}
          formComponent={NewsAlertForm}
          onCancel={this.goToNewsList}
          onSave={this.handlePublish}
          renderFooterControls={this.renderFooterControls}
          submitButtonText="Publish"
        />
      </CenterContentBlock>
    ) : newsStoreV2.loading ? (
      <AlignedLoader />
    ) : (
      <ModerationInfoBlock title="Failed to load News Alert" />
    );
  }
}
