import classNames from 'classnames';
import React from 'react';
import './ImageView.less';

export interface IImageViewProps {
  className?: string;
  height?: number | string;
  onError?: (error) => void;
  src: string;
  width?: number | string;
  testID?: string;
}

export function ImageView({
  className,
  height,
  onError,
  src,
  width,
  testID,
}: IImageViewProps) {
  const isDefinedStyles = width && height;

  return (
    <img
      alt="Alert media"
      className={classNames('ImageView', className)}
      onError={onError}
      src={src}
      style={isDefinedStyles && { height, width }}
      data-test-id={testID}
    />
  );
}
