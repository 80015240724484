import classNames from 'classnames';
import React from 'react';
import { ImageView } from 'src/components/ImageView';
import { IMediaViewerSource } from 'src/interfaces';
import { DD, DDLogLevel } from '../../helpers/datadogLogs';
import { NoMediaView } from './components/NoMediaView';
import { VideoPreload, VideoView } from './components/VideoView';
import './MediaViewer.less';

interface IMediaViewerState {
  updatedUrlsMap: { [key: string]: string };
}

type ErrorHandler = (error) => void;

export class MediaViewer extends React.Component<
  IMediaViewerProps,
  IMediaViewerState
> {
  public static defaultProps = {
    preview: false,
  };

  public state = {
    updatedUrlsMap: {},
  };

  private createLoadErrorHandler = (
    url: string,
    customType?: string,
  ): ErrorHandler => (error) => {
    const { updatedUrlsMap } = this.state;
    const type = customType || this.props.source.type;

    if (url in updatedUrlsMap) {
      DD.log(
        `Failed to show ${type} "${url}", "${this.getUrl(url)}"`,
        error,
        DDLogLevel.Error,
      );
    } else {
      const modifiedUrl = url?.replace('/unprocessed_uploads', '').replace(/(\.[a-z0-9]+)$/, '_x$1');
      this.setState({
        updatedUrlsMap: { ...updatedUrlsMap, [url]: modifiedUrl },
      });
    }
  };

  private getUrl = (url: string) => {
    return this.state.updatedUrlsMap[url] || url;
  };

  public render() {
    const {
      className,
      hideNoVisualContentText = false,
      posterURL: originalPosterURL,
      preload = VideoPreload.Metadata,
      preview,
      size,
      source: { src: originalSrc, type },
    } = this.props;

    const src = this.getUrl(originalSrc);
    const handleResourceLoadError = this.createLoadErrorHandler(originalSrc);

    const posterURL = this.getUrl(originalPosterURL);
    const handlePosterLoadError = this.createLoadErrorHandler(
      originalPosterURL,
      'poster',
    );

    switch (type) {
      case 'image':
        const width = size?.width === '100%' ? 'auto' : size?.width;
        return (
          <ImageView
            className={classNames('MediaViewer MediaViewer__image', className)}
            height={size && size.height}
            key="image_view"
            onError={handleResourceLoadError}
            src={src}
            width={width}
            testID="mediaItem image"
          />
        );

      case 'video':
        return (
          <VideoView
            className={classNames('MediaViewer MediaViewer__video', className)}
            shouldShowControls={!preview}
            poster={posterURL}
            preload={preload}
            onError={handleResourceLoadError}
            onPosterURLLoadError={handlePosterLoadError}
            src={src}
            height={size && size.height}
            width={size && size.width}
            testID="mediaItem video"
          />
        );

      case 'other':
        return (
          <NoMediaView
            className={classNames('MediaViewer', className)}
            hideNoVisualContentText={hideNoVisualContentText}
            testID="mediaItem text"
          />
        );
    }

    return undefined;
  }
}

export interface IMediaViewerProps {
  /** Optional css class name */
  className?: string;
  /** Image url */
  posterURL?: string;
  // TODO: Remove after migration to the new Alert Card component
  /** Legacy parameter */
  id?: number;
  /** Content source */
  source: IMediaViewerSource;
  /** Flag to show text that no visual content */
  hideNoVisualContentText?: boolean;
  /** Whether preload video. By default VideoPreload.Metadata is used */
  preload?: VideoPreload;
  // TODO: Remove after migration to the new Alert Card component
  /** Legacy parameter. Flag to show preview instead of video player */
  preview?: boolean;
  /** Viewport size for video or image */
  size?: {
    height: number | string;
    width: number | string;
  };
}

export { VideoPreload };
