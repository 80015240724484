import { action, observable } from 'mobx';
import { DD } from '../helpers/datadogLogs';
import { IStoredAlert, IStoredComment } from '../restApi/interfaces';
import { InjectRootStore } from './injectRootStore';

export type Tabs = 'alertsTab' | 'commentsTab' | 'escalateTab' | 'rsuTab';

type IndexableAlertsListStore = {
  [key in Tabs]: IStoredAlert[];
};

export class AlertsListStore
  extends InjectRootStore
  implements IndexableAlertsListStore
{
  @observable public alertsTab: IStoredAlert[] = [];
  @observable public commentsTab: IStoredAlert[] = [];
  @observable public escalateTab: IStoredAlert[] = [];
  @observable public rsuTab: IStoredAlert[] = [];

  public getAlert = (tabName: Tabs) => (alertId: number) => {
    const alerts = this[tabName];

    return alerts.find((alert) => alert.alert_id === alertId);
  };

  public getCurrentAmountOfAlertsInTheStore = (tabName: Tabs) => {
    return this[tabName].length;
  };

  public getAlertsIDsInTheStore = (tabName: Tabs) => {
    const alertIds = new Set<number>();
    const alerts = this[tabName];

    alerts.forEach((alert) => {
      alertIds.add(alert.alert_id);
    });
    return Array.from(alertIds.values());
  };
  public getAlertWithoutComments = (tabName: Tabs) => (alertId: number) => {
    const alerts = this[tabName];

    return alerts.find(
      (alert) => alert.alert_id === alertId && !alert.comments,
    );
  };

  public getAlertWithComments = (tabName: Tabs) => (alertId: number) => {
    const alerts = this[tabName];

    return alerts.find((alert) => alert.alert_id === alertId && alert.comments);
  };

  public getAlertComments = (tabName: Tabs) => (alertId: number) => {
    const alert = this.getAlertWithComments(tabName)(alertId);

    if (!alert?.comments?.length) return [];

    return alert.comments;
  };

  public getAlertComment =
    (tabName: Tabs) => (alertId: number, commentId: string) => {
      const alert = this.getAlertWithComments(tabName)(alertId);

      if (!alert?.comments?.length) return undefined;

      return alert.comments.find((comment) => comment.comment_id === commentId);
    };

  public hasAlertComments = (tabName: Tabs) => (alertId: number) => {
    const alert = this.getAlert(tabName)(alertId);

    return Boolean(alert?.comments?.length);
  };

  public isAlertWithComments = (tabName: Tabs) => (alertId: number) => {
    if (!alertId) return false;

    const alerts = this[tabName];
    const alert = alerts.find((e) => e.alert_id === alertId);

    return alert?.comments !== undefined;
  };

  public isAlertInStore = (tabName: Tabs) => (alertId: number) => {
    if (!alertId) return false;

    const alerts = this[tabName];
    return alerts.find((e) => e.alert_id === alertId) !== undefined;
  };

  public isAlertInStoreWithoutComments =
    (tabName: Tabs) => (alertId: number) => {
      if (!alertId) return false;

      const alerts = this[tabName];
      const alert = alerts.find((e) => e.alert_id === alertId);

      return alert?.comments === undefined;
    };

  public isCommentOnAlert =
    (tabName: Tabs) => (alertId: number, commentId: string) => {
      const alert = this.getAlertWithComments(tabName)(alertId);

      if (!alert?.comments) return false;

      return alert.comments.some((c) => c.comment_id === commentId);
    };

  public isCommentOlderThanCommentsOnAlert =
    (tabName: Tabs) => (alertId: number, comment: IStoredComment) => {
      const alert = this.getAlertWithComments(tabName)(alertId);
      const { time_created } = comment;
      const alertComments = alert.comments.sort(
        (a, b) => a.time_created - b.time_created,
      );

      const oldestComment = alertComments.length ? alertComments[0] : null;

      return oldestComment && oldestComment.time_created
        ? oldestComment.time_created < time_created
        : null;
    };

  public setAlerts = (tabName: Tabs) =>
    action((alerts: IStoredAlert[]) => {
      this[tabName] = alerts;
    });

  public addAlert = (tabName: Tabs) => {
    return action((alert: IStoredAlert) => {
      if (this.isAlertInStore(tabName)(alert.alert_id)) {
        this.logAlertAlreadyPresent(tabName, alert);
        this.updateAlert(tabName)(alert);
        return;
      }
      this[tabName].push(alert);
    });
  };

  private logAlertAlreadyPresent = (tabName: Tabs, alert: IStoredAlert) => {
    DD.debug(
      'Tried to add alert to alerts list store but it was already present.',
      {
        alertId: alert.alert_id,
        entityType: 'alert',
        stack: Error().stack,
        tabName,
      },
    );
  };

  public removeAlert = (tabName: Tabs) => {
    return action((alertId: number) => {
      this[tabName] = this[tabName].filter(
        (alert: IStoredAlert) => alert.alert_id !== alertId,
      );
    });
  };

  public removeAlertWithoutComments = (tabName: Tabs) => {
    return action((alertId: number) => {
      this[tabName] = this[tabName].filter(
        (alert: IStoredAlert) =>
          !(alert.alert_id === alertId && !alert.comments),
      );
    });
  };

  public removeAlertWithComments = (tabName: Tabs) => {
    return action((alertId: number) => {
      this[tabName] = this[tabName].filter(
        (alert: IStoredAlert) =>
          !(alert.alert_id === alertId && alert.comments),
      );
    });
  };

  public removeAllAlertWithComments = (tabName: Tabs) => {
    return action(() => {
      this[tabName] = this[tabName].filter(
        (alert: IStoredAlert) => !(alert.alert_id && alert.comments),
      );
    });
  };

  public addCommentToAlert = (tabName: Tabs) => {
    return action((alertId: number, comment: IStoredComment) => {
      if (this.isCommentOnAlert(tabName)(alertId, comment.comment_id)) {
        this.logCommentAlreadyPresent(tabName, alertId, comment);
        this.updateAlertComment(tabName)(comment);
        return;
      }
      if (this.isCommentOlderThanCommentsOnAlert(tabName)(alertId, comment)) {
        this.logCommentOlderThanOldestOnAlert(tabName, alertId, comment);
      }

      this[tabName] = this[tabName].map((alert) => {
        if (alert.alert_id === alertId) {
          return {
            ...alert,
            comments: alert.comments ? [...alert.comments, comment] : [comment],
          };
        }

        return alert;
      });
    });
  };

  private logCommentAlreadyPresent = (
    tabName: Tabs,
    alertId: number,
    comment: IStoredComment,
  ) => {
    DD.debug(
      'Tried to add comment to alert in alerts list store but it was already present.',
      {
        alertId,
        commentId: comment.comment_id,
        incomingCommentTime: comment.time_created,
        entityType: 'comment',
        stack: Error().stack,
        tabName,
      },
    );
  };

  private logCommentOlderThanOldestOnAlert = (
    tabName: Tabs,
    alertId: number,
    comment: IStoredComment,
  ) => {
    const alert = this.getAlertWithComments(tabName)(alertId);
    const alertComments = alert.comments.sort(
      (a, b) => a.time_created - b.time_created,
    );

    const oldestComment = alertComments.length ? alertComments[0] : null;

    DD.debug(
      'Tried to add comment to alert in alerts list store and it is older than the oldest comment.',
      {
        alertId,
        oldestCommentTime: oldestComment ? oldestComment.time_created : null,
        commentId: comment.comment_id,
        entityType: 'comment',
        stack: Error().stack,
        tabName,
      },
    );
  };

  public updateAlert = (tabName: Tabs) =>
    action((alertToUpdate: IStoredAlert): IStoredAlert => {
      let updatedAlert = {} as IStoredAlert;

      this[tabName] = this[tabName].map((alert) => {
        if (alert.alert_id === alertToUpdate.alert_id) {
          updatedAlert = { ...alert, ...alertToUpdate };
          return updatedAlert;
        }

        return alert;
      });

      return updatedAlert;
    });

  public updateAlertWithComments = (tabName: Tabs) => {
    return action((alertToUpdate: IStoredAlert) => {
      let updatedAlert = {} as IStoredAlert;

      this[tabName] = this[tabName].map((alert) => {
        if (alert.alert_id === alertToUpdate.alert_id && alert.comments) {
          updatedAlert = { ...alert, ...alertToUpdate };
          return updatedAlert;
        }

        return alert;
      });

      return updatedAlert;
    });
  };

  public updateAlertWithoutComments = (tabName: Tabs) => {
    return action((alertToUpdate: IStoredAlert): IStoredAlert => {
      let updatedAlert = {} as IStoredAlert;

      this[tabName] = this[tabName].map((alert) => {
        if (alert.alert_id === alertToUpdate.alert_id && !alert.comments) {
          updatedAlert = { ...alert, ...alertToUpdate };
          return updatedAlert;
        }

        return alert;
      });

      return updatedAlert;
    });
  };

  public updateAlertComment = (tabName: Tabs) => {
    return action(
      this.createCommentUpdateFn(tabName, (comment, newCommentInfo) => ({
        ...comment,
        ...newCommentInfo,
      })),
    );
  };

  public replaceAlertComment = (tabName: Tabs) => {
    return action(
      this.createCommentUpdateFn(tabName, (_, newComment) => newComment),
    );
  };

  private createCommentUpdateFn =
    (
      tabName: Tabs,
      updateComment: (
        oldComment: IStoredComment,
        newComment: IStoredComment,
      ) => IStoredComment,
    ) =>
    (newComment: IStoredComment) => {
      const alert = this.getAlertWithComments(tabName)(newComment.alert_id);
      const comments = (alert && alert.comments) || [];
      const oldCommentIndex = comments.findIndex(
        (comment) => comment.comment_id === newComment.comment_id,
      );

      if (oldCommentIndex !== -1) {
        const oldComment = comments[oldCommentIndex];
        comments[oldCommentIndex] = updateComment(oldComment, newComment);
      }
    };

  public addCommentToAlertWithComments = (tabName: Tabs) => {
    return action((alertId: number, comment: IStoredComment) => {
      if (this.isCommentOnAlert(tabName)(alertId, comment.comment_id)) {
        this.logCommentAlreadyPresent(tabName, alertId, comment);
        this.updateAlertComment(tabName)(comment);
        return;
      }

      this[tabName] = this[tabName].map((alert: IStoredAlert) => {
        if (alert.alert_id === alertId && alert.comments) {
          console.log(
            'comments added to alert with comments: ',
            alertId,
            'comment: ',
            comment,
          );
          return {
            ...alert,
            comments: alert.comments ? [...alert.comments, comment] : [comment],
          };
        }

        return alert;
      });
    });
  };
}
