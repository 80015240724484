import { Button, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { INewsAlertFormValues } from '../NewsAlertForm';
import './preview-modal.less';

interface IReviewModalProps {
  show: boolean;
  formValues: INewsAlertFormValues;
  hideModal: any;
  onSubmit?: any;
}

export class PreviewModal extends React.Component<IReviewModalProps, {}> {
  public onPreviewModalSubmit = (e: React.MouseEvent<HTMLInputElement>) => {
    this.props.hideModal();
    this.props.onSubmit(e);
  };

  public render() {
    const {
      created_at,
      category,
      service,
      type,
      state,
      city,
      address,
      content,
      title,
    } = this.props.formValues;
    const shortInfo = [
      moment(created_at).format('MMM DD YYYY'),
      category,
      type,
      service,
      state,
      city,
      address,
    ];
    return (
      <Modal
        className="NHAModal"
        key={'showPreviewDialog'}
        visible={this.props.show}
        onCancel={this.props.hideModal}
        footer={[
          <Button key="back" onClick={this.props.hideModal} type="default">
            Edit
          </Button>,
          <Button
            key="submit"
            onClick={this.onPreviewModalSubmit}
            type="primary"
          >
            Post
          </Button>,
        ]}
      >
        <div className="NHAModalNewsAlertTitle">{title}</div>
        <div className="NHAModalNewsAlertShortInfo">
          {shortInfo.filter(Boolean).map((infoEl, i) => (
            <span className="NHAModalNewsAlertShortInfoEl" key={i}>
              {infoEl}
            </span>
          ))}
        </div>
        <div className="NHAModalNewsAlertText">{content}</div>
      </Modal>
    );
  }
}
