import { PhraseType } from '../../../restApi/phrasesService';

export const WORD_TYPE_ALL = { value: 'all', text: 'All' };
export const WORD_LANGUAGE_ALL = { value: 'all', text: 'All' };

// Types
export const WORD_LANGUAGES = [
  { value: 'english', text: 'English' },
  { value: 'spanish', text: 'Spanish' },
];
export const WORD_TYPES = [
  { value: PhraseType.ForbiddenComments, text: 'Forbidden - Comments' },
  { value: PhraseType.ForbiddenAlerts, text: 'Forbidden - Alerts' },
  { value: PhraseType.Risky, text: 'Risky' },
  { value: PhraseType.Approved, text: 'Auto Approve' },
  { value: PhraseType.ProfanityComments, text: 'Profanity - Comments' },
  { value: PhraseType.ForceManualComments, text: 'Force Manual - Comments' },
];
export const FILTER_WORD_TYPES = [...WORD_TYPES, WORD_TYPE_ALL];

export interface IFilter {
  phrase: string;
  word_type: string;
  language: string;
}

export const DEFAULT_FILTER_VALUES: IFilter = {
  language: WORD_LANGUAGE_ALL.value,
  phrase: '',
  word_type: WORD_TYPE_ALL.value,
};

export const WORD_LANG_NORMALIZED: { [key: string]: string } = {
  english: 'English',
  spanish: 'Spanish',
};

export const WORD_TYPE_NORMALIZED: { [key: string]: string } = {
  [PhraseType.Approved]: 'Approved',
  [PhraseType.ForbiddenComments]: 'Forbidden - Comments',
  [PhraseType.ForbiddenAlerts]: 'Forbidden - Alerts',
  [PhraseType.ProfanityComments]: 'Profanity - Comments',
  [PhraseType.Risky]: 'Risky',
  [PhraseType.ForceManualComments]: 'Force Manual - Comments',
};

const ALL_WORDS_TITLE = 'All words';
const FORBIDDEN_COMMENTS_TITLE = 'Forbidden - Comments';
const FORBIDDEN_ALERTS_TITLE = 'Forbidden - Alerts';
const RISKY_TITLE = 'Risky';
const APPROVED_TITLE = 'Approved';
const PROFANITY_COMMENTS_TITLE = 'Profanity - Comments';
const FORCE_MANUAL_COMMENTS_TITLE = 'Force Manual - Comments';
export const DEFAULT_TITLE = ALL_WORDS_TITLE;

export const CATEGORY_TITLE_DIC = {
  all: ALL_WORDS_TITLE,
  [PhraseType.Approved]: APPROVED_TITLE,
  [PhraseType.ForbiddenComments]: FORBIDDEN_COMMENTS_TITLE,
  [PhraseType.ForbiddenAlerts]: FORBIDDEN_ALERTS_TITLE,
  [PhraseType.ProfanityComments]: PROFANITY_COMMENTS_TITLE,
  [PhraseType.Risky]: RISKY_TITLE,
  [PhraseType.ForceManualComments]: FORCE_MANUAL_COMMENTS_TITLE,
};

export const WORD_NEW_TIME = 300000; // 5 mins

export const FILTER_KEYS = Object.keys(DEFAULT_FILTER_VALUES);
