import css from 'classnames';
import React from 'react';

import styles from './pseudo-hyper-text.module.css';

export class PseudoHyperText extends React.Component {
  public handleClick = (url) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(url, '_blank');
  };

  public render() {
    let contents;

    if (typeof this.props.children === 'string') {
      const tokens = this.props.children.split(/\s/);

      contents = tokens.map((token, i) => {
        const hasSpace = i !== tokens.length - 1;
        const maybeSpace = hasSpace ? ' ' : '';

        if (token.match(/^https?:\//)) {
          return (
            <span
              key={i}
              className={css(styles.pseudoLink)}
              onClick={this.handleClick(token)}
            >
              {token}
              {maybeSpace}
            </span>
          );
        }
        return token + maybeSpace;
      });
    } else {
      return this.props.children;
    }
    return contents;
  }
}
