import { Button } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import React from 'react';
import { BlockLayout } from 'src/components/BlockLayout';
import { CommonModal } from 'src/components/CommonModal';
import { CollapsibleList } from 'src/componentsNew/CollapsibleList';
import { IPhrase } from 'src/restApi/phrasesService';
import './delete-modal.less';

const wordsListCollapseThreshold = 10;
const renderWord = (word) => (
  <div className="FormModalWordsListItem">
    <div>{word.phrase}</div>
  </div>
);

interface IDeleteModalProps extends ModalProps {
  modalLoading: boolean;
  onSubmit: (event: React.MouseEvent) => void;
  wordsToDelete: IPhrase[];
  title: string;
}

export class DeleteModal extends React.Component<IDeleteModalProps> {
  public state = { listKey: 0 };

  public componentDidUpdate(
    prevProps: Readonly<IDeleteModalProps>,
    prevState: Readonly<{}>,
  ) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({ listKey: this.state.listKey + 1 });
    }
  }

  public render() {
    const {
      modalLoading,
      onSubmit,
      wordsToDelete,
      title,
      ...modalProps
    } = this.props;

    return (
      <CommonModal
        {...modalProps}
        title={title}
        footer={
          <div className="ModalFooter">
            <BlockLayout
              right={
                <div key="footerControls" className="nha-block-align-right">
                  <Button
                    type="default"
                    size="large"
                    onClick={modalProps.onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="danger"
                    size="large"
                    onClick={onSubmit}
                    loading={modalLoading}
                  >
                    {title}
                  </Button>
                </div>
              }
            />
          </div>
        }
      >
        <div className="FormModalWordsList">
          <div className="FormModalWordsListTitle">
            Are you sure you want to delete selected words?
          </div>
          <CollapsibleList<IPhrase>
            key={this.state.listKey}
            collapsedAmount={wordsListCollapseThreshold}
            items={wordsToDelete}
            renderItem={renderWord}
            viewAllText="Show all words"
            collapseText="Collapse words"
          />
        </div>
      </CommonModal>
    );
  }
}
