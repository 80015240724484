import { WebsocketEntityType } from '../constants';
import { AdminHeaderSearchStore } from './adminHeaderSearchStore';
import { AdminStore } from './adminStore';
import { AlertsListStore } from './alertsListStore';
import { AlertsStore } from './alertsStore';
import { CommentsStore } from './commentsStore';
import { AuthStore } from './authStore';
import { ConfigStore } from './configStore';
import { EscalatedNotificationStore } from './escalatedNotificationStore';
import { ManageUsersStore } from './manageUsersStore';
import { ModerateModalStore } from './moderateModalStore';
import { NavigationStore } from './navigationStore';
import { NewsListStore } from './newsListStore';
import { NewsListUIStore } from './newsListUIStore';
import { NewsOptionsStore } from './newsOptionsStore';
import { NewsStore } from './newsStore';
import { NotificationStore } from './notificationsStore';
import { PermissionStore } from './permissionStore';
import { PhrasesStore } from './phrasesStore';
import { SearchStore } from './searchStore';
import { UiStore } from './uiStore';
import {NewsServiceV2} from "../restApi/newsService";
import {api, apiV2} from "../api";
import {SearchService} from "../pages/search/services/searchService";

export class RootStore {
  public readonly adminHeaderSearchStore: AdminHeaderSearchStore;
  public readonly adminStore: AdminStore;
  public readonly alertsListStore: AlertsListStore;
  public readonly alertsStore: AlertsStore;
  public readonly commentsStore: CommentsStore;
  public readonly authStore: AuthStore;
  public readonly configStore: ConfigStore;
  public readonly escalatedAlertsNotificationStore: EscalatedNotificationStore;
  public readonly escalatedCommentsNotificationStore: EscalatedNotificationStore;
  public readonly manageUsersStore: ManageUsersStore;
  public readonly moderateModalStore: ModerateModalStore;
  public readonly navigationStore: NavigationStore;
  public readonly newsListUIStore: NewsListUIStore;
  public readonly newsListStoreV2: NewsListStore;
  public readonly newsListUIStoreV2: NewsListUIStore;
  public readonly newsOptionsStoreV2: NewsOptionsStore;
  public readonly newsStoreV2: NewsStore;
  public readonly notificationsStore: NotificationStore;
  public readonly permissionStore: PermissionStore;
  public readonly phrasesStore: PhrasesStore;
  public readonly searchStore: SearchStore;
  public readonly uiStore: UiStore;

  constructor() {
    const newsServiceV2 = new NewsServiceV2(apiV2);
    const searchService = new SearchService(api)
    // Need these stores first
    this.authStore = new AuthStore(this);
    this.navigationStore = new NavigationStore(this);

    this.adminHeaderSearchStore = new AdminHeaderSearchStore(this);
    this.adminStore = new AdminStore(this);
    this.alertsListStore = new AlertsListStore(this);
    this.alertsStore = new AlertsStore(this);
    this.commentsStore = new CommentsStore(this);
    this.configStore = new ConfigStore(this);
    this.escalatedAlertsNotificationStore = new EscalatedNotificationStore(
      this,
      WebsocketEntityType.Alert,
    );
    this.escalatedCommentsNotificationStore = new EscalatedNotificationStore(
      this,
      WebsocketEntityType.Comment,
    );
    this.manageUsersStore = new ManageUsersStore(this);
    this.moderateModalStore = new ModerateModalStore(this);
    this.newsListUIStore = new NewsListUIStore(this);
    this.newsListStoreV2 = new NewsListStore(this, newsServiceV2);
    this.newsListUIStoreV2 = new NewsListUIStore(this);
    this.newsOptionsStoreV2 = new NewsOptionsStore(this, newsServiceV2);
    this.newsStoreV2 = new NewsStore(this, newsServiceV2);
    this.notificationsStore = new NotificationStore(this);
    this.permissionStore = new PermissionStore(this);
    this.phrasesStore = new PhrasesStore(this);
    this.searchStore = new SearchStore(this, searchService);
    this.uiStore = new UiStore(this);
  }
}

export const rootStore = new RootStore();

export const stores = Object.getOwnPropertyNames(rootStore).reduce(
  (acc, p) => {
    // @ts-ignore
    acc[p] = rootStore[p];
    return acc;
  },
  { rootStore },
);

export interface Stores {
  adminHeaderSearchStore: AdminHeaderSearchStore;
  adminStore: AdminStore;
  alertsListStore: AlertsListStore;
  alertsStore: AlertsStore;
  commentsStore: CommentsStore;
  authStore: AuthStore;
  configStore: ConfigStore;
  escalatedAlertsNotificationStore: EscalatedNotificationStore;
  escalatedCommentsNotificationStore: EscalatedNotificationStore;
  manageUsersStore: ManageUsersStore;
  moderateModalStore: ModerateModalStore;
  navigationStore: NavigationStore;
  newsListUIStore: NewsListUIStore;
  newsListStoreV2: NewsListStore;
  newsListUIStoreV2: NewsListUIStore;
  newsOptionsStoreV2: NewsOptionsStore;
  newsStoreV2: NewsStore;
  notificationsStore: NotificationStore;
  permissionStore: PermissionStore;
  phrasesStore: PhrasesStore;
  searchStore: SearchStore;
  uiStore: UiStore;
  rootStore: RootStore;
}
