import { api } from 'src/api';
import { IResponseItem } from 'src/restApi/restApi';
import { NewsSortDirection } from '../constants';

const path = 'news';

export class NewsService {
  public static fetchNews = async (params: IFetchNews = {}) => {
    const response = await api
      .get(path, { searchParams: params as any })
      .json<IResponseItem<any>>();

    return response.data;
  };

  public static updateNews = async (params: IUpdateNewsInterface) => {
    const { news_id, status } = params;
    const body = { status };

    const response = await api
      .patch(`${path}/${news_id}`, { json: body })
      .json<IResponseItem<any>>();

    return response.data;
  };
}

interface IUpdateNewsInterface {
  news_id?: number | string;
  status: string;
}

interface IFetchNews {
  from_time?: number;
  to_time?: number;
  offset?: number;
  limit?: number;
  order?: NewsSortDirection;
  keywords?: string;
  status?: string[];
  category?: string[];
  content?: string[];
  region?: string[];
  source?: string[];
  onlyEdited?: boolean;
  onlyFollowed?: boolean;
}
