import { difference, uniq } from 'lodash';
import moment from 'moment';
import { api } from 'src/api';
import { processRestError } from 'src/helpers';
import * as alertHelpers from 'src/helpers/alertHelpers';
import * as commentHelper from 'src/helpers/commentsHelper';
import {
  AlertsService,
  IAlert,
  IAlertHistory,
} from 'src/restApi/alertsService';
import { IResponseItem, IResponseList } from 'src/restApi/restApi';
import {
  IAlertStatistic,
  IComment,
  IModerationHistory,
  ModerationStatus,
} from '../../../restApi/interfaces';
import { logError } from '../../../stores/utils/errorLogger';
import {
  ALERTS_TIME_RANGE,
  COMMENTS_TIME_RANGE,
  MINIMUM_RANGE_INTERVAL,
} from '../constants';
import { CommentService } from '../../../services/commentService';

const commentsByRangePath = 'comments_by_range';

interface IFetchEntitiesByRange {
  endDate: moment.Moment;
  startDate: moment.Moment;
}

interface IFetchByRangeBase extends IFetchEntitiesByRange {
  path: string;
  timeRange: number;
}

interface IFetchByRange extends IFetchByRangeBase {
  abortController: AbortController;
}

interface IProcessFetchByRange extends IFetchByRangeBase {
  abortController?: AbortController;
  errorTitle: string;
}

export class AdminService {
  private static thresholdInterval = Math.ceil(
    MINIMUM_RANGE_INTERVAL + MINIMUM_RANGE_INTERVAL / 3,
  );

  // This method send request to the server. Also it adapts time interval if server returns 403 error.
  // This means that there are more than 10000 items found for specified time interval.
  private static async fetchByRangeRequestFlow<T>({
    path,
    startDate,
    endDate,
    timeRange,
    abortController,
  }: IFetchByRange): Promise<T[]> {
    const { signal } = abortController;

    try {
      const response = await api
        .post(path, {
          json: {
            date_end: endDate.toISOString(),
            date_start: startDate.toISOString(),
          },
          signal,
        })
        .json<IResponseList<T>>();

      return response.data.items;
    } catch (error) {
      // @ts-ignore
      const dateDiff = (endDate - startDate) / 60000; // in minutes

      if (
        error.response &&
        error.response.status === 403 &&
        timeRange >= AdminService.thresholdInterval &&
        dateDiff > MINIMUM_RANGE_INTERVAL
      ) {
        const newTimeRange =
          timeRange % 2 === 0 ? timeRange / 2 : MINIMUM_RANGE_INTERVAL;

        return this.fetchByRange<T>({
          abortController,
          endDate,
          path,
          startDate,
          timeRange: newTimeRange,
        });
      }

      throw error;
    }
  }

  // This method requests items for time range. It shows an error in a case of initial call.
  // All subsequent calls will throw an exception to be handled by initial call.
  private static async fetchByRange<T>({
    abortController,
    endDate,
    path,
    startDate,
    timeRange,
  }: IFetchByRange): Promise<T[]> {
    const requests = [];
    let startRangeDate = moment(startDate);

    while (startRangeDate < endDate) {
      let endRangeDate = moment(startRangeDate).add(timeRange, 'minutes');

      if (endRangeDate > endDate) {
        endRangeDate = endDate;
      }

      requests.push(
        this.fetchByRangeRequestFlow({
          abortController,
          endDate: endRangeDate,
          path,
          startDate: startRangeDate,
          timeRange,
        }),
      );

      startRangeDate = endRangeDate;
    }

    const results = await Promise.all(requests);

    const flattenedResults = results.flat();

    return flattenedResults as T[];
  }

  private static async processFetchByRangeRequestsFlow<T>({
    endDate,
    errorTitle,
    startDate,
    ...params
  }: IProcessFetchByRange): Promise<T[]> {
    const controller = new AbortController();
    const adjustedEndDate = moment(endDate);

    try {
      const response = await this.fetchByRange<T>({
        ...params,
        abortController: controller,
        endDate: adjustedEndDate,
        startDate,
      });

      return response;
    } catch (error) {
      controller.abort();

      logError({
        description: error.message,
        exception: error,
        title: errorTitle,
      });

      return [];
    }
  }

  public static async fetchAlertsByRange({
    startDate,
    endDate,
  }: IFetchEntitiesByRange) {
    return AdminService.processFetchByRangeRequestsFlow<IAlert>({
      endDate,
      errorTitle: 'Failed to fetch alerts by date range',
      path: 'alerts_by_range',
      startDate,
      timeRange: ALERTS_TIME_RANGE,
    });
  }

  public static async fetchCommentsByRange({
    startDate,
    endDate,
  }: IFetchEntitiesByRange): Promise<IComment[]> {
    return AdminService.processFetchByRangeRequestsFlow<IComment>({
      endDate,
      errorTitle: 'Failed to fetch comments by date range',
      path: commentsByRangePath,
      startDate,
      timeRange: COMMENTS_TIME_RANGE,
    });
  }

  public static fetchAlertHistory = async (alertId: number | string) => {
    try {
      const response = await api
        .get(`alert/${alertId}/history`)
        .json<IResponseItem<IAlertHistory[]>>();

      return response.data;
    } catch (e) {
      processRestError('AdminService', 'fetchAlertHistory', e);
      return [];
    }
  };

  public static fetchAlertsIdsByKeywords = async (keywords: string) => {
    const response = await api
      .get(encodeURI(`index/alert-ids?keywords=${keywords}`))
      .json<IResponseItem<{ alert_ids: number[] }>>();

    return response.data.alert_ids;
  };

  public static fetchCommentsIdsByKeywords = async (keywords: string) => {
    const response = await api
      .get(encodeURI(`index/comment-ids?keywords=${keywords}`))
      .json<IResponseItem<{ comment_ids: number[] }>>();

    return response.data.comment_ids.map((cId) => cId.toString());
  };

  public static fetchAlertStatistic = async (alertId: number | string) => {
    try {
      const response = await api
        .get(`statistics/${alertId}`)
        .json<IResponseItem<IAlertStatistic>>();

      return response.data;
    } catch (e) {
      processRestError('AdminService', 'fetchAlertHistory', e);
      return undefined;
    }
  };

  public static fetchAlertAndComments = async (id: string | number) => {
    try {
      const response = await api
        .get(`alert_with_comments/${id}`)
        .json<IResponseItem<any>>();

      return response.data;
    } catch (error) {
      processRestError('AdminService', 'fetchAlertAndComments', error);
      return undefined;
    }
  };

  public static getCommentByID = async (commentId: string) => {
    try {
      const response = await api
        .get(`comment/${commentId}`)
        .json<IResponseItem<IComment>>();

      CommentService.convertCommentIdToString(response.data);

      return response.data;
    } catch (error) {
      processRestError('AdminService', 'getCommentByID', error);
      return undefined;
    }
  };

  public static getAlertByID = async (alertId: number | string) => {
    const response = await api
      .get(`alert/${alertId}`)
      .json<IResponseItem<IAlert>>();

    return response.data;
  };

  public static deleteRSUComment = async (commentId: string) => {
    try {
      return await api.delete(`rsu/comment/${commentId}`);
    } catch (error) {
      processRestError('AdminService', 'deleteRSUComment', error);
      return undefined;
    }
  };

  public static deleteComment = async (commentId: string) => {
    try {
      return await api.delete(`comment/${commentId}`);
    } catch (error) {
      processRestError('AdminService', 'deleteComment', error);
      return undefined;
    }
  };

  public static editRSUComment = async (commentId: string, text: string) => {
    try {
      return await api.patch(`rsu/comment/${commentId}`, {
        json: {
          text,
        },
      });
    } catch (error) {
      processRestError('AdminService', 'editRSUComment', error);
      return undefined;
    }
  };

  public static fetchAlertAndCommentsInRange = async (
    from,
    to,
  ): Promise<[IAlert[], IComment[]]> => {
    const [alerts, comments] = await Promise.all([
      AdminService.fetchAlertsByRange({
        startDate: from,
        endDate: to,
      }),
      AdminService.fetchCommentsByRange({
        startDate: from,
        endDate: to,
      }),
    ]);

    const alertsIds = alertHelpers.getAlertsIds(alerts);
    const commentAlertIds = commentHelper.getCommentAlertsIds(comments);
    const missingAlertsIds = uniq(difference(commentAlertIds, alertsIds));
    const missingAlertsResponse = await AdminService.getAlertsByIds(
      missingAlertsIds,
    );

    return [[...missingAlertsResponse, ...alerts], comments];
  };

  public static getAlertsByIds = async (
    alertsIds: number[],
  ): Promise<IAlert[]> => {
    if (alertsIds.length) {
      return await AlertsService.getAlertsByIds(alertsIds);
    }

    return [];
  };

  public static getCommentsByIds = async (commentsIds: string[]) => {
    if (commentsIds.length === 0) return [];

    const response = await api
      .post('comments_by_ids', {
        json: {
          ids: commentsIds,
        },
      })
      .json<IResponseItem<{ comments: IComment[] }>>();

    CommentService.convertCommentIdsToString(response.data?.comments);

    return response.data.comments;
  };

  public static getMissedAlert = async (alertId: number) => {
    try {
      return await AlertsService.getAlert(alertId);
    } catch (error) {
      return {
        alert_id: alertId,
        description: 'No Description',
        title: 'No Title',
      } as IAlert;
    }
  };

  public static createRSUComment = async (alertId: number, text: string) => {
    try {
      const res = await api
        .post('rsu/comments', {
          json: {
            text,
            alert_id: alertId,
          },
        })
        .json<number>();
      return res.toString();
    } catch (error) {
      processRestError('AdminService', 'deleteRSUComment', error);
      return undefined;
    }
  };

  public static getCommentHistory = async (commentId: string) => {
    try {
      const response = await api
        .get(`comment/${commentId}/history`, {
          errorMessage: 'Failed to fetch comment history',
        })
        .json<IResponseItem<IModerationHistory[]>>();

      return response.data ?? [];
    } catch (error) {
      return [];
    }
  };

  public static async remoderateAlert({
    status,
    alert_id,
    categoryId,
    note = '',
  }: {
    alert_id: number;
    categoryId: number;
    note?: string;
    status: ModerationStatus.Approved | ModerationStatus.Declined;
  }) {
    const response = await api
      .put(`alert/${alert_id}/remoderate`, {
        json: { status, category_ids: [categoryId], free_note: note },
        errorMessage: 'An error ocurred while remoderating the alert',
      })
      .json<IResponseItem<IAlert>>();

    return response.data;
  }
}
