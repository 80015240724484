import { DatePicker } from 'antd';
import type {
  RangePickerPresetRange,
  RangePickerProps,
} from 'antd/lib/date-picker/interface';
import type { TimePickerProps } from 'antd/lib/time-picker';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants';
import { getDateOfLastDayMinute } from 'src/pages/admin/helpers';
import { useStores } from 'src/stores';
import styled from 'styled-components';

interface SearchRangePickerProps extends RangePickerProps {}

export const SearchRangePicker = observer<FC<SearchRangePickerProps>>(
  (props) => {
    const { searchStore } = useStores();

    return (
      <StyledRangePicker
        {...props}
        allowClear
        value={[searchStore.startDate, searchStore.endDate]}
        disabledDate={checkDisabledDate}
        format={DATE_FORMAT}
        onChange={searchStore.setDates}
        placeholder={placeholder}
        ranges={ranges}
        showTime={showTime}
        data-test-id="filterByDate"
      />
    );
  },
);

const StyledRangePicker = styled(DatePicker.RangePicker)`
  & > .ant-calendar-picker-input {
    height: 100%;
  }

  & .ant-calendar-range-picker-separator {
    vertical-align: unset;
  }
`;

const checkDisabledDate = (current: Moment) => current > moment().endOf('day');
const placeholder = ['Start Time', 'End Time'] as [string, string];
const sod = moment().startOf('day');
const eod = moment().endOf('day');

const ranges = {
  Today: [sod, eod] as RangePickerPresetRange,
} as const;

const showTime = {
  format: TIME_FORMAT,
  defaultValue: [sod, getDateOfLastDayMinute()],
} as TimePickerProps;
