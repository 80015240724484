import { antd } from './antd';
import { colors } from './colors';
import { space } from './space';

export const theme = {
  antd,
  colors,
  space,
} as const;

export type Theme = typeof theme;
