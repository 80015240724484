import css from 'classnames';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { alertsActions } from 'src/actions';
import { AlertAdditionalMenu } from 'src/components/alert-additional-menu';
import { AlertItemDescription } from 'src/components/alert-item-description';
import { AssignButton } from 'src/components/assing-button';
import { ModerateButtons } from 'src/components/moderate-buttons';
import { Timer } from 'src/components/Timer';
import * as alertHelper from 'src/helpers/alertHelpers';
import { IPopupSimpleMenu } from 'src/interfaces';
import { Stores, Tabs } from 'src/stores';
import { AlertBadgesAndHistory } from '../../componentsNew/AlertBadgesAndHistory';
import {
  ActionType,
  ApprovalContentType,
  ApproveCategoryReason,
  TimeForChangeDecision,
} from '../../constants';
import {
  IStoredAlert,
  ModerationStatus,
  PermissionLevel,
} from '../../restApi/interfaces';
import AlertFreeNote from '../AlertFreeNote';
import s from './style.module.css';

interface AlertItemDataContainerProps extends Stores {
  tabStore: Tabs;
  recordedDate?: boolean;
  sharedDate?: boolean;
  alert: IStoredAlert;
  isEscalate?: boolean;
  hasTimer?: boolean;
  cancelEditingAction?: () => void;
  markAsResolved?: () => void;
  compact?: boolean;
  canModerated?: boolean;
  hasRSUModeration?: boolean;
}

@inject('authStore', 'configStore', 'moderateModalStore', 'uiStore')
@observer
export class AlertItemDataContainer extends React.PureComponent<
  AlertItemDataContainerProps
> {
  public additionalMenu: IPopupSimpleMenu[] = [
    {
      title: 'Escalate',
      action: () => this.escalationHandler(),
      visible: this.showEscalateMenuButton,
    },
    {
      title: 'Send to RSU',
      action: () => this.sendToRsuAction(),
      visible: true,
    },
  ];

  public static defaultProps: Partial<AlertItemDataContainerProps> = {
    hasTimer: true,
    canModerated: true,
  };

  get showEscalateMenuButton(): boolean {
    return !(
      this.props.authStore.isUserEscalationLevel(PermissionLevel.L2) &&
      this.props.isEscalate
    );
  }

  public escalationHandler = () => {
    this.props.isEscalate ? this.escalationL2Action() : this.escalationAction();
  };

  public handleSendToRsu = async () => {
    const { moderateModalStore, configStore, uiStore } = this.props;

    const {
      reasons,
      status,
      freeNote,
      interesting,
    } = moderateModalStore.moderateOptions;
    const [hasSubCategories] = configStore.getApprovalCategoriesWithType(
      ApprovalContentType.Alert,
      reasons[0],
    );

    uiStore.toggleModal(false);
    await alertsActions.sendAlertToRsu(
      this.props.alert.alert_id,
      status,
      hasSubCategories ? reasons.slice(2) : reasons.slice(1),
      freeNote,
      interesting,
      this.props.alert.is_deferred,
      this.props.tabStore,
    );
  };

  public sendToRsuAction = () => {
    const { moderateModalStore, configStore, uiStore } = this.props;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setEntityType(ApprovalContentType.Alert);
    moderateModalStore.setStatus(ModerationStatus.Approved);
    moderateModalStore.setReasonsToRender(
      configStore.getApprovalCategories(
        ApprovalContentType.Alert,
        ApproveCategoryReason.Approve,
      ),
    );
    moderateModalStore.setSelectedReasons([ApproveCategoryReason.Approve]);

    uiStore.modal = {
      ...uiStore.modal,
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Send to RSU',
        confirmAction: () => {
          this.handleSendToRsu();
        },
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        isInterestingCheckbox: true,
        isButtonEnable: false,
      },
      header: {
        ...uiStore.modal.header,
        text: 'Send to RSU',
      },
    };
    uiStore.toggleModal(true);
  };

  public handleSubmit = async () => {
    const { moderateModalStore, configStore, uiStore, alert } = this.props;

    const {
      reasons,
      status,
      freeNote,
      interesting,
    } = moderateModalStore.moderateOptions;

    const [hasSubCategories] = configStore.getApprovalCategoriesWithType(
      ApprovalContentType.Alert,
      reasons[0],
    );

    uiStore.toggleModal(false);
    await alertsActions.submitAlert(
      this.props.alert.alert_id,
      status,
      hasSubCategories ? reasons.slice(2) : reasons.slice(1),
      freeNote,
      interesting,
      alert.is_deferred,
      this.props.tabStore,
    );
  };

  public escalateAlert = async () => {
    const { moderateModalStore, configStore, uiStore } = this.props;

    const alertEscalationLevel = alertHelper.getAlertEscalationLevel(
      this.props.alert,
    );

    uiStore.toggleModal(false);

    if (alertEscalationLevel) {
      const {
        reasons,
        freeNote,
        interesting,
        status,
      } = moderateModalStore.moderateOptions;
      const [hasSubCategories] = configStore.getApprovalCategoriesWithType(
        ApprovalContentType.Alert,
        reasons[0],
      );

      uiStore.toggleModal(false);
      await alertsActions.escalateAlertToL2(
        this.props.alert.alert_id,
        hasSubCategories ? reasons.slice(2) : reasons.slice(1),
        freeNote,
        status,
        interesting,
      );
    } else {
      await alertsActions.escalateAlertToL1(this.props.alert.alert_id);
    }
  };

  public assignAlertHandler = async () =>
    alertsActions.assignAlert(this.props.alert.alert_id, this.props.tabStore);

  public approveAction = () => {
    const { moderateModalStore, configStore, uiStore, alert } = this.props;

    const isEditingMode = alert.uiStates && alert.uiStates.editMode;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setEntityType(ApprovalContentType.Alert);
    moderateModalStore.setStatus(ModerationStatus.Approved);
    moderateModalStore.setReasonsToRender(
      configStore.getApprovalCategories(
        ApprovalContentType.Alert,
        ApproveCategoryReason.Approve,
      ),
    );

    if (
      isEditingMode &&
      alert.status &&
      alert.status === ModerationStatus.Approved
    ) {
      const index = alertHelper.getReasonGroupIndex(
        configStore,
        ApproveCategoryReason.Approve,
        alert.category_ids,
      );

      moderateModalStore.setSelectedReasons([
        ApproveCategoryReason.Approve,
        index,
        ...alert.category_ids,
      ]);
    } else {
      moderateModalStore.setSelectedReasons([ApproveCategoryReason.Approve]);
    }

    uiStore.modal = {
      ...uiStore.modal,
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Confirm',
        confirmAction: () => {
          this.handleSubmit();
        },
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        isInterestingCheckbox: true,
        isButtonEnable: false,
      },
      header: {
        ...uiStore.modal.header,
        text: `${ActionType[ApproveCategoryReason.Approve]} this video`,
      },
    };

    uiStore.toggleModal(true);
  };

  public rejectAction = () => {
    const { moderateModalStore, configStore, uiStore, alert } = this.props;

    const isEditingMode = alert.uiStates && alert.uiStates.editMode;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setEntityType(ApprovalContentType.Alert);
    moderateModalStore.setStatus(ModerationStatus.Declined);
    moderateModalStore.setReasonsToRender(
      configStore.getApprovalCategories(
        ApprovalContentType.Alert,
        ApproveCategoryReason.Deny,
      ),
    );
    moderateModalStore.setSelectedReasons([ApproveCategoryReason.Deny]);

    if (
      isEditingMode &&
      alert.status &&
      alert.status === ModerationStatus.Declined
    ) {
      moderateModalStore.setSelectedReasons([
        ApproveCategoryReason.Deny,
        ...alert.category_ids,
      ]);
    } else {
      moderateModalStore.setSelectedReasons([ApproveCategoryReason.Deny]);
    }

    uiStore.modal = {
      ...uiStore.modal,
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Confirm',
        confirmAction: () => {
          this.handleSubmit();
        },
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        isInterestingCheckbox: true,
        isButtonEnable: false,
      },
      header: {
        ...uiStore.modal.header,
        text: `${ActionType[ApproveCategoryReason.Deny]} this video`,
      },
    };
    uiStore.toggleModal(true);
  };

  public escalationAction = () => {
    const { moderateModalStore, uiStore } = this.props;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);
    uiStore.toggleModal(true);

    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div>
            Are you sure you want to escalate this video to <br />
            Senior Operators?
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        confirmText: 'Yes, escalate this video',
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        confirmAction: () => this.escalateAlert(),
        isInterestingCheckbox: false,
        isButtonEnable: true,
        submitButtonColor: 'blue',
        hasBackground: false,
      },
      header: {
        ...uiStore.modal.header,
        text: `${ActionType[ApproveCategoryReason.Escalate]} this video`,
      },
    };
  };

  public escalationL2Action = () => {
    const { moderateModalStore, configStore, uiStore } = this.props;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);
    uiStore.toggleModal(true);
    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div>
            This video has already been escalated. To escalate it again <br />{' '}
            come up with category that on your opinion matches best.
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        cancelText: 'Decline',
        hasBackground: false,
        submitButtons: true,
        confirmText: 'Approve',
        cancelAction: () => {
          moderateModalStore.setEntityType(ApprovalContentType.Alert);
          moderateModalStore.setStatus(ModerationStatus.Declined);
          moderateModalStore.setReasonsToRender(
            configStore.getApprovalCategories(
              ApprovalContentType.Alert,
              ApproveCategoryReason.Deny,
            ),
          );
          moderateModalStore.setSelectedReasons([ApproveCategoryReason.Deny]);
          uiStore.modal = {
            ...uiStore.modal,
            content: {
              element: null,
              showSelect: true,
            },
            footer: {
              ...uiStore.modal.footer,
              confirmText: 'Yes, escalate this video again',
              submitButtons: false,
              confirmAction: () => this.escalateAlert(),
              cancelText: 'Cancel',
              cancelAction: () => {
                uiStore.toggleModal(false);
                moderateModalStore.cleanModerationOptions();
              },
              isInterestingCheckbox: true,
              isButtonEnable: false,
            },
            header: {
              ...uiStore.modal.header,
              text: `${ActionType[ApproveCategoryReason.Escalate]} this video`,
            },
          };
        },
        confirmAction: () => {
          moderateModalStore.setEntityType(ApprovalContentType.Alert);
          moderateModalStore.setStatus(ModerationStatus.Approved);
          moderateModalStore.setReasonsToRender(
            configStore.getApprovalCategories(
              ApprovalContentType.Alert,
              ApproveCategoryReason.Approve,
            ),
          );
          moderateModalStore.setSelectedReasons([
            ApproveCategoryReason.Approve,
          ]);
          uiStore.modal = {
            ...uiStore.modal,
            content: {
              element: null,
              showSelect: true,
            },
            footer: {
              ...uiStore.modal.footer,
              submitButtons: false,
              confirmText: 'Yes, escalate this video again',
              confirmAction: () => this.escalateAlert(),
              cancelText: 'Cancel',
              cancelAction: () => {
                uiStore.toggleModal(false);
                moderateModalStore.cleanModerationOptions();
              },
              isInterestingCheckbox: true,
              isButtonEnable: false,
            },
            header: {
              ...uiStore.modal.header,
              text: `${ActionType[ApproveCategoryReason.Escalate]} this video`,
            },
          };
        },
        isInterestingCheckbox: false,
      },
      header: {
        ...uiStore.modal.header,
        text: `${ActionType[ApproveCategoryReason.Escalate]} this video`,
      },
    };
  };

  public renderDate = (title: String = '', date: number = moment().unix()) => {
    const createdTime = moment.unix(date);
    const formatedTime = createdTime.format('hh:mm a');
    const formatedDate = createdTime.format('MMM DD, YYYY');

    return (
      <div className={s.dateContainer}>
        {title && <span className={s.dateTitle}>{title}:</span>}
        <span className={s.dateValue}>
          {formatedTime} {formatedDate}
        </span>
      </div>
    );
  };

  public renderModerateTools = () => {
    return (
      <div className={css(s.moderateContainer)}>
        <ModerateButtons
          className="left-row-double"
          approveOnClick={() => this.approveAction()}
          rejectOnClick={() => this.rejectAction()}
        />
        <AlertAdditionalMenu menuItems={this.additionalMenu} />
      </div>
    );
  };

  public renderDesicionTimer = () => {
    const { configStore, alert } = this.props;

    const isEditingMode = alert.uiStates && alert.uiStates.editMode;
    const timerStartTime = isEditingMode
      ? alert.time_updated
      : alert.time_created;
    const durationFromConfig = () =>
      configStore.autoApprove ? configStore.autoApprove.alerts.expire_in : 0;
    const timeDuration = isEditingMode
      ? TimeForChangeDecision
      : durationFromConfig();
    const markersCount = isEditingMode ? 2 : 5;

    return (
      <Timer
        createdTime={moment.unix(timerStartTime).valueOf()}
        showTime
        maxDuration={timeDuration}
        editMode={isEditingMode}
        markersCount={markersCount}
      />
    );
  };

  public renderCancelEditModeButton = () => (
    <div
      className={css(s.closeEditModeButton, 'top-column')}
      onClick={this.props.cancelEditingAction}
    >
      Don't change <div className={s.alertCloseButtonIcon} />
    </div>
  );

  public renderRSUModerationButton = () => (
    <div
      className={css(s.rsuReadButton, 'top-column')}
      onClick={this.props.markAsResolved}
    >
      Mark as resolved <div className={s.readIcon} />
    </div>
  );

  public render() {
    const { alert, compact, canModerated, hasRSUModeration } = this.props;
    const isAlertAssigned = alert.status !== ModerationStatus.New;
    const isEditingMode = alert.uiStates && alert.uiStates.editMode;
    const isAlertOnRSU = alert.on_rsu;
    const hasFreeNotes = 'free_note' in alert && alert.free_note;

    return (
      <div
        className={css(s.alertItemDataContainer, {
          [s.compacteAlertItemDataContainer]: compact,
        })}
      >
        {!isAlertAssigned && canModerated && (
          <AssignButton onClick={this.assignAlertHandler} />
        )}
        <span className={s.contentColumn}>
          <AlertItemDescription
            className="bottom-column"
            alert={this.props.alert}
          />
          {hasFreeNotes && <AlertFreeNote freeNote={alert.free_note} />}

          <AlertBadgesAndHistory
            className={s.badgesBlock}
            alert={alert}
            history={alert.history}
          />

          {canModerated && isAlertAssigned && this.renderModerateTools()}
        </span>
        <span
          className={css(
            s.decisionTimerContainer,
            s.rsuReadContainer,
            'column-double row',
          )}
        >
          {canModerated && isEditingMode && this.renderCancelEditModeButton()}
          {hasRSUModeration && isAlertOnRSU && this.renderRSUModerationButton()}
          {this.props.hasTimer && this.renderDesicionTimer()}
        </span>
      </div>
    );
  }
}
