import React from 'react';
import { BlockLoader } from '../../pages/NewsCurationPage/components/BlockLoader';
import './CollapsibleList.less';

interface ICollapsibleListPropsBase<T> {
  collapsedAmount: number;
  collapseText?: string;
  emptyText?: string;
  items: T[];
  renderItem?: (item: T) => React.ReactNode;
  renderItems?: (items: T[]) => React.ReactNode;
  showLoader?: boolean;
  title?: string;
  viewAllText?: string;
}

interface ICollapsibleListRenderItemProps<T>
  extends ICollapsibleListPropsBase<T> {
  renderItem: (item: T) => React.ReactNode;
}

interface ICollapsibleListRenderItemsProps<T>
  extends ICollapsibleListPropsBase<T> {
  renderItems: (items: T[]) => React.ReactNode;
}

export type ICollapsibleListProps<T> =
  | ICollapsibleListRenderItemProps<T>
  | ICollapsibleListRenderItemsProps<T>;

interface ICollapsibleListState {
  collapsed: boolean;
}

export class CollapsibleList<T> extends React.Component<
  ICollapsibleListProps<T>,
  ICollapsibleListState
> {
  public state = {
    collapsed: true,
  };

  private toggleViewHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    e.preventDefault();
    return false;
  };

  private renderItems(itemsToRender: T[], showCollapseBlock: boolean) {
    const {
      collapseText,
      items,
      renderItem,
      renderItems,
      viewAllText,
    } = this.props;
    const content = renderItems
      ? renderItems(itemsToRender)
      : itemsToRender.map(renderItem);

    return (
      <>
        {content}

        {showCollapseBlock && (
          <div key="collapseBlock" className="CollapsibleList__linkBlock">
            {/* eslint-disable-next-line */}
            <a href="#" onClick={this.toggleViewHandler}>
              {this.state.collapsed
                ? `${viewAllText || 'Expand'} (${items.length})`
                : collapseText || 'Collapse'}
            </a>
          </div>
        )}
      </>
    );
  }

  private renderNoItems() {
    const { emptyText } = this.props;
    return (
      <div className="CollapsibleList__emptyListText">
        {emptyText || 'Nothing to show'}
      </div>
    );
  }

  public render() {
    const { collapsedAmount, items, showLoader, title } = this.props;
    const { collapsed } = this.state;

    const renderCollapsed = collapsed && items.length > collapsedAmount;
    const itemsToRender = renderCollapsed
      ? items.slice(0, collapsedAmount)
      : items;

    let content;
    if (showLoader) {
      content = <BlockLoader />;
    } else {
      content = items.length
        ? this.renderItems(itemsToRender, items.length > collapsedAmount)
        : this.renderNoItems();
    }

    return (
      <div className="CollapsibleList">
        {title && <h2>{title}</h2>}

        {content}
      </div>
    );
  }
}
