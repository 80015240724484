import { IAlert, IAlertHistory } from './alertsService';

// TODO: Move to store interfaces
export interface IAlertUIStates {
  editMode?: boolean;
  blockEditing?: boolean;
  moderated?: boolean;
  expanded?: boolean;
}

export enum LoadingState {
  NotLoaded,
  Loading,
  Loaded,
  Failed,
}

export enum ModerationActionType {
  AlertStatusChanged = 0,
  AlertEscalated = 1,
  CommentStatusChanged = 2,
  AlertSendToRsu = 3,
  CommentEscalated = 4,
  CommentRsu = 5,
  CommentFlagged = 6,
  CommentDeleted = 7,
  AlertMissedSla = 8,
  AlertCreated = 9,
  AlertFlagged = 10,
  CommentMissedSla = 11,
}

export enum ModerationStatus {
  New = 0,
  Approved = 1,
  Assigned = 2,
  Declined = 3,
  AutoApproved = 4,
  AutoDeclined = 5,
}

export interface IAlertStatistic {
  comments?: number;
  views?: number;
}

export enum RobDecision {
  Approve = 'approve',
  Decline = 'decline',
  Manual = 'manual',
}

export enum RobRecommendation {
  Unknown = 'unknown',
}

export interface IRob {
  rob_confidence: string | null;
  rob_decision: RobDecision;
  rob_model_version: string | null;
  rob_recommendation: string | null;
}

export type ICommentHistory = {
  action: {
    alert_id?: number;
    comment_id?: any;
    is_deleted?: boolean;
    deleted_by_admin?: boolean;
    is_deleted_by_user?: boolean;
    missed_sla?: boolean;
  };
  comment_id?: string;
} & ICommonHistory;

export interface ICategory {
  id: number;
  subcategories: number[];
}

export interface IUserData {
  user_id: string;
  user_type: string;
  creation_time: string;
  last_update_time: string;
  status: string;
  history?: object[];
  categories: ICategory[];
  notes: string;
}

export interface IComment extends IRob {
  alert_id: number;
  comment_id: any;
  cop_id?: number;
  email?: string | null;
  flag?: number;
  is_deleted?: boolean | null;
  is_l1?: boolean;
  is_l2?: boolean;
  is_rsu?: boolean | null;
  on_rsu?: boolean;
  neighbor_number: number;
  reason: string[];
  ring_created_at: number;
  status?: ModerationStatus;
  text?: string;
  time_created?: number;
  time_received_for_moderation?: number;
  user_id?: number;
  ring_updated_at?: number;
  missed_sla?: boolean;
  is_deleted_by_user?: boolean;
  deleted_by_admin?: boolean;
  user_data?: IUserData;
}

export interface IPublicAssistanceIncidentDate {
  start: number;
  end: number;
}

export interface IPublicAssistanceContactInformation {
  title: string;
  last_name: string;
  first_name: string;
  email: string;
  phone: string;
}

export interface IPublicAssistanceDetailsData {
  id: string;
  incident_number: string;
  assistance_url: string;
  incident_date: IPublicAssistanceIncidentDate;
  contact_information: IPublicAssistanceContactInformation;
}

export interface IPersonDescriptionData {
  attire: string;
  trait: string;
  additional_details: IPersonDescriptionAdditionalDetailsData;
}

export interface IPersonDescriptionAdditionalDetailsData {
  age: number;
  gender: string;
  race: string;
}

export interface IPersonDescriptionElementData extends IPersonDescriptionData {
  index: number;
}

export interface IVehicleDescriptionData {
  description: string;
  license_plate_info: string;
  other: string;
}

export interface IVehicleDescriptionElementData
  extends IVehicleDescriptionData {
  index: number;
}

export interface IStoredComment extends IComment {
  history?: IModerationHistory[];
  is_flagged?: number;
}

export interface ICommonHistory {
  alert_id: number;
  action: {
    alert_id: number;
    is_flagged?: boolean;
    is_l1?: boolean;
    is_l2?: boolean;
    on_rsu?: boolean;
    reason?: string[];
    status?: ModerationStatus;
  };
  action_type: ModerationActionType;
  email?: string;
  time_created: number;
}

// TODO: Move to store interfaces
// @ts-ignore TS6196
export interface IStoredAlert extends IAlert {
  category?: string;
  comment_time_created?: number;
  comments?: IStoredComment[];
  ding_address?: string;
  flag?: number;
  history?: IAlertHistory[];
  interesting?: boolean;
  is_auto_approvable?: number;
  is_claimed?: number;
  is_comment_visible?: number;
  is_deleted?: boolean;
  deleted_by_admin?: boolean;
  is_user_visible?: number;
  statistic?: IAlertStatistic;
  sqs_backup?: string;
  ttl?: number;
  type?: string;
  uiStates?: IAlertUIStates;
  video_created_at?: number;
}

export type IModerationHistory = IAlertHistory | ICommentHistory;

export interface IUpdateAlertCommentsInterface {
  comment_id?: number;
  alert_id?: number;
  status: number;
  reason?: string[];
  comment_flag?: string;
}

export interface IAutoApproveConfig {
  alerts: {
    expire_in: number;
  };
  comments: {
    expire_in: number;
  };
}

export interface IApprovalCategoriesRaw {
  [index: string]: {
    [index: string]: {
      subcategory: {
        id: number;
        title: string;
      }[];
      title: string;
    }[];
  };
}

export interface ICloudWatchConfig {
  accessKeyId?: string;
  secretAccessKey?: string;
  sessionToken?: string;
  expirationTime?: number;
  logGroupName?: string;
  metricNameSpace?: string;
}

export interface ICloudWatchResponse {
  accessKey?: string;
  secretKey?: string;
  sessionToken?: string;
  expirationTime?: number;
  logGroupName?: string;
  metricNameSpace?: string;
}

export interface IApprovalCategories {
  alert: {
    categories: {
      id: string;
      subcategories: {
        id: number;
        title: string;
      }[];
      title: string;
    }[];
    status: ModerationStatus;
    title: string;
  }[];
}

export enum PermissionLevel {
  Unauthorized = 'unauthorized',
  Default = 'default',
  L1 = 'l1',
  L2 = 'l2',
  Rsu = 'rsu',
  Root = 'root',
  Curation = 'curation',
}

export interface IUserPermissions {
  email: string;
  scope: PermissionLevel[];
}

export interface IUser extends IUserPermissions {
  id: string;
  name: string;
}

export interface IStoredUser {
  email: string;
  scope: PermissionLevel[];
  originalScope?: PermissionLevel[];
  isChanged?: boolean;
  isUpdating?: boolean;
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface IModerationSessionStatus {
  active: boolean;
}

// Type guards
export function isAlertHistory(
  asset: IAlertHistory | ICommentHistory,
): asset is IAlertHistory {
  return (asset as ICommentHistory).comment_id === undefined;
}

export function isCommentHistory(
  asset: IAlertHistory | ICommentHistory,
): asset is ICommentHistory {
  return (asset as ICommentHistory).comment_id !== undefined;
}
