import css from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { LightBox } from 'src/components/light-box';
import { IPopupSimpleMenu } from 'src/interfaces';
import s from './style.module.css';

const linkedItem = (item: IPopupSimpleMenu, itemIndex) => (
  <Link
    className={s.link}
    key={`additional-menu-item-${item.title}-${itemIndex}`}
    to={item.linkPath}
  >
    <div className={css('menu-item', 'linked-menu-item')}>{item.title}</div>
  </Link>
);

const simpleItem = (item: IPopupSimpleMenu, itemIndex) => {
  const testID = `threeDotsItems${item.title.replaceAll(' ', '')}`;
  return (
    <div
      key={`additional-menu-item-${item.title}-${itemIndex}`}
      className={css('menu-item', { 'linked-menu-item': item.action })}
      onClick={() => item.action && item.action()}
      data-test-id={testID}
    >
      {item.title}
    </div>
  );
};

const PopupSimpleMenu = (props: PopupSimpleMenuProps) => (
  <LightBox className={props.className} onClose={props.onClose}>
    {props.menuItems &&
      props.menuItems.map((item, idx) => [
        !item.visible
          ? null
          : item.linked
          ? linkedItem(item, idx)
          : simpleItem(item, idx),
        item.devided && (
          <div
            className={s.devideLine}
            key={`popup-simple-menu-devide-line=${idx}`}
          />
        ),
      ])}
  </LightBox>
);

interface PopupSimpleMenuProps {
  menuItems: IPopupSimpleMenu[];
  className?: string;
  onClose?: Function;
}

export { PopupSimpleMenu };
