import React from 'react';
import { IModerationHistory } from '../../../restApi/interfaces';
import { History, IHistoryProps } from '../../History';
import { ModerationHistoryItem } from '../ModerationHistoryItem';

type IModerationHistoryProps = Omit<
  IHistoryProps<IModerationHistory>,
  'historyItemRender'
>;

export function ModerationHistory({
  history,
  testID,
  ...props
}: IModerationHistoryProps) {
  const sortedHistory = [...history].sort(
    (a, b) => b.time_created - a.time_created,
  );

  return (
    <History<IModerationHistory>
      history={sortedHistory}
      historyItemRender={ModerationHistoryItem}
      testID={testID}
      {...props}
    />
  );
}
