import classNames from 'classnames';
import React from 'react';
import { ICaseInformation } from 'src/restApi/alertsService';
import { AlertPropertyRow, IconType } from '../AlertPropertyRow';
import { ALERT_BAD_WORDS_TYPES, WordsHighlighter } from '../WordsHighlighter';
import './AlertPoliceCaseBlock.less';

interface IAlertPoliceCaseBlock {
  className?: string;
  caseInformation: ICaseInformation;
  renderBoldValue?: boolean;
}

const renderValue = (value: string, renderBoldValue: boolean) => (
  <WordsHighlighter
    className={classNames({ AlertPoliceCaseBlock__boldText: renderBoldValue })}
    type={ALERT_BAD_WORDS_TYPES}
    textToHighlight={value}
  />
);

export function AlertPoliceCaseBlock({
  className,
  caseInformation,
  renderBoldValue = false,
}: IAlertPoliceCaseBlock) {
  const {
    agency_name,
    case_number,
    officer_name,
    phone_number,
  } = caseInformation;
  return (
    <>
      {agency_name && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Agency}
          testID="agencyName"
        >
          Agency Name: {renderValue(agency_name, renderBoldValue)}
        </AlertPropertyRow>
      )}

      {officer_name && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Officer}
          testID="agencyName"
        >
          Officer Name: {renderValue(officer_name, renderBoldValue)}
        </AlertPropertyRow>
      )}

      {case_number && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Case}
          testID="caseNumber"
        >
          Case Number: {renderValue(case_number, renderBoldValue)}
        </AlertPropertyRow>
      )}

      {phone_number && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Telephone}
          testID="casePhoneNumber"
        >
          Telephone: {renderValue(phone_number, renderBoldValue)}
        </AlertPropertyRow>
      )}
    </>
  );
}
