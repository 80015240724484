import css from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { alertsActions, commentsActions } from 'src/actions';
import { AddRSUComment } from 'src/components/add-rsu-comment';
import { AlertItem } from 'src/components/alert-item';
import { AlertItemCommentsThread } from 'src/components/alert-item-comments-thread';
import { AlertItemPreview } from 'src/components/alert-item-preview';
import {
  isAlertAssigned,
  isAlertModerated,
  isAlertThreadAssigned,
  isCurrentUserAssigned,
} from 'src/helpers/alertHelpers';
import { IExpandedComments } from 'src/interfaces';
import { Stores, Tabs } from 'src/stores';
import { IStoredAlert } from '../../restApi/interfaces';
import s from './style.module.css';

export interface IAlertContainerProps
  extends Pick<Stores, 'authStore' | 'moderateModalStore' | 'uiStore'> {
  alert: IStoredAlert;
  canAlertModerated?: boolean;
  canCommentModerated?: boolean;
  canEdited?: boolean;
  childRef?: React.RefObject<HTMLDivElement>;
  commentsTabFlag?: string;
  expandedComments?: IExpandedComments;
  hasRSUModeration?: boolean;
  hideNoVisualContentText?: boolean;
  isEscalated?: boolean;
  minimized?: boolean;
  onAssign?: Function;
  onThreadExpanded?: Function;
  onToggleCommentHistory: (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => Promise<void>;
  removePopupAction: boolean;
  tabStore: Tabs;
  titleReasonShown?: boolean;
  treatRsuAsNew?: boolean;
}

@inject('authStore', 'moderateModalStore', 'uiStore')
@observer
export class AlertContainer extends React.Component<IAlertContainerProps> {
  public static defaultProps: Partial<IAlertContainerProps> = {
    canAlertModerated: true,
    canCommentModerated: true,
    minimized: false,
    removePopupAction: true,
    treatRsuAsNew: false,
  };

  // TODO: refactor removeAlertPopupAction can raise the popup with categories and note
  public sendRSUToAlert = async (
    text: string,
    hasAlertThread: boolean,
    removePopupAction = true,
  ) => {
    const { alert } = this.props;

    if (hasAlertThread && alert.alert_id) {
      return commentsActions.sendRSUCommentToThread(alert.alert_id, text);
    }

    if (alert.alert_id) {
      const commentsActionRes = await commentsActions.sendCommentRSUToAlert(
        alert.alert_id,
        text,
      );

      if (removePopupAction) {
        this.removeAlertPopupAction();
      }

      return commentsActionRes;
    }

    return undefined;
  };

  public removeAlertPopupAction = () => {
    const { moderateModalStore, uiStore } = this.props;

    moderateModalStore.cleanModerationOptions();
    moderateModalStore.setReasonsToRender([]);
    moderateModalStore.setSelectedReasons([]);

    uiStore.toggleModal(true);

    uiStore.modal = {
      ...uiStore.modal,
      content: {
        element: (
          <div>
            Comment is added. Do you want to mark this Alert <br />
            as resolved?
          </div>
        ),
        showSelect: false,
      },
      footer: {
        ...uiStore.modal.footer,
        cancelAction: () => {
          uiStore.toggleModal(false);
          moderateModalStore.cleanModerationOptions();
        },
        cancelText: 'No',
        confirmAction: () => {
          uiStore.toggleModal(false);
          alertsActions.markAlertAsResolved(this.props.alert.alert_id);
        },
        confirmText: 'Yes, mark alert as resolved',
        hasBackground: false,
        isButtonEnable: true,
        isInterestingCheckbox: false,
        submitButtonColor: 'blue',
      },
      header: {
        ...uiStore.modal.header,
        text: 'Mark alert as resolved',
      },
    };
  };

  public onAssign = (alertId: number) => {
    if (this.props.onAssign) {
      this.props.onAssign(alertId);
    }
  };

  public render() {
    const {
      alert,
      isEscalated,
      canEdited,
      onToggleCommentHistory,
      onThreadExpanded,
      titleReasonShown,
      hideNoVisualContentText,
      expandedComments,
      commentsTabFlag,
      canAlertModerated,
      canCommentModerated,
      tabStore,
      hasRSUModeration,
      minimized,
      treatRsuAsNew,
      removePopupAction,
      authStore,
    } = this.props;

    const { userEscalationLevel } = authStore;
    const hasComments = Boolean(
      this.props.alert.comments && this.props.alert.comments.length,
    );
    const isModerated = isAlertModerated(alert);
    const showHeader = hasComments || isModerated;
    const isEditMode = alert.uiStates && alert.uiStates.editMode;
    const hideTimer = isEscalated || !canAlertModerated;
    const blockEditing = alert.uiStates && alert.uiStates.blockEditing;
    const hideAlert = minimized || isModerated;
    const isExpanded = alert.uiStates && alert.uiStates.expanded;
    const showAlert = isExpanded || isEditMode || !hideAlert;

    const isUserOwner = isCurrentUserAssigned(
      alert,
      authStore.user.email,
      isEscalated && userEscalationLevel,
    );

    const isThreadAssigned = isAlertThreadAssigned(
      alert,
      isEscalated && userEscalationLevel,
    );

    const isAlertItemAssigned = isAlertAssigned(alert);
    const isAlertOrThreadAssigned =
      isThreadAssigned || (isAlertItemAssigned && canAlertModerated);

    return (
      <div
        ref={this.props.childRef}
        className={css(
          s.alertContainer,
          isAlertOrThreadAssigned && isUserOwner && s.assignedContainer,
          isEditMode && s.editingContainer,
        )}
        data-test-id="threadContainer"
      >
        {showHeader && !isEditMode && (
          <AlertItemPreview
            hideNoVisualContentText={hideNoVisualContentText}
            editAction={() => alertsActions.editAlert(alert.alert_id, tabStore)}
            canEdited={canEdited && !blockEditing}
            alert={alert}
            isUserOwner={isUserOwner}
            isModerated={canAlertModerated && isModerated}
            isAlertAssigned={isAlertOrThreadAssigned}
            titleReasonShown={titleReasonShown}
            expandAction={() =>
              alertsActions.setAlertWrappedState(
                alert.alert_id,
                !showAlert,
                tabStore,
              )
            }
          />
        )}
        {showAlert && (
          <div className={css(showHeader && s.alertItemContainer)}>
            <AlertItem
              alert={alert}
              tabStore={tabStore}
              isEscalate={isEscalated}
              hasTimer={!hideTimer}
              canModerated={canAlertModerated}
              compact={showHeader && !isEditMode}
              hasRSUModeration={hasRSUModeration}
            />
          </div>
        )}
        {hasComments && (
          <AlertItemCommentsThread
            onAssign={this.onAssign}
            onToggleHistory={onToggleCommentHistory}
            tabStore={tabStore}
            treatRsuAsNew={treatRsuAsNew}
            isAlertExpanded={showAlert}
            canModerateThread={canCommentModerated}
            hasRSUModeration={hasRSUModeration}
            onThreadExpanded={onThreadExpanded}
            threadExpanded={expandedComments[alert.alert_id]}
            alert={alert}
            isEscalate={isEscalated}
            isThreadAssigned={isThreadAssigned}
            isUserOwner={isUserOwner}
            commentsTabFlag={commentsTabFlag}
          />
        )}
        {hasRSUModeration && (
          <AddRSUComment
            key="rsu-add-comment-container"
            createRSUAction={(text) =>
              this.sendRSUToAlert(text, hasComments, removePopupAction)
            }
          />
        )}
      </div>
    );
  }
}
