import css from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Stores } from 'src/stores';
import { SearchTypeErrors, SearchTypes } from '../../../../constants';
import s from './style.module.css';

interface InputContainerProps extends Partial<Stores> {
  onEnter?: Function;
}

@inject('adminHeaderSearchStore')
@observer
export class InputContainer extends React.Component<
  InputContainerProps,
  { focus: boolean }
> {
  get store() {
    return this.props.adminHeaderSearchStore;
  }
  public inputRefName: HTMLInputElement;

  public onChangeHandler = (e) => {
    this.store.changeValue(e.target.value);
  };

  public onFocus = () => {
    this.store.changeInputFocus(true);
  };

  public onBlur = () => {
    this.store.changeInputFocus(false);
  };

  public keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      this.store.checkIfValueValid();
      this.props.onEnter();
    } else {
      this.store.checkIfValueValid(true);
    }
  };

  public render() {
    return (
      <div
        className={css(s.container, {
          [s.focus]: this.store.inputFocus,
          [s.invalid]: !this.store.isValueValid,
        })}
      >
        <input
          ref={(ref) => (this.inputRefName = ref)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={s.input}
          onKeyPress={this.keyPressHandler}
          value={this.store.value}
          onChange={this.onChangeHandler}
          placeholder={SearchTypes[this.store.type]}
        />
        <div className={css(s.hint, { [s.show]: !this.store.isValueValid })}>
          {SearchTypeErrors[this.store.type]}
        </div>
      </div>
    );
  }
}
