import { css as ssCSS, get, SystemStyleObject } from '@styled-system/css';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';
import {
  BaseProps,
  ExtraProps,
  PseudoProps,
  StyledSystemProps,
  SxProps,
  ThemeProps,
  VariantProps,
} from './types';

export type BoxOwnProps<T = HTMLDivElement> = BaseProps<T> &
  ExtraProps &
  PseudoProps &
  StyledSystemProps &
  SxProps &
  ThemeProps &
  VariantProps;

export type BoxProps = ComponentProps<typeof Box>;

const sx = ({ sx, theme }: SxProps & ThemeProps) => ssCSS(sx)(theme);

const variant = ({
  theme,
  variant,
  tx = 'variants',
}: ThemeProps & VariantProps) => {
  const defaultValue = get(theme!, variant as string | string[]);
  return ssCSS(get(theme!, `${tx}.${variant}`, defaultValue))(theme);
};

const css = ({ css }: BaseProps) => css;

const extras = system({
  cursor: true,
  transition: true,
  userSelect: true,
  whiteSpace: true,
  textOverflow: true,
  wordBreak: true,
});

const Box = styled.div<BoxOwnProps>(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  variant,
  sx,
  css,
  compose(
    background,
    border,
    color,
    extras,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    typography,
  ),
  ({
    _active,
    _after,
    _before,
    _checked,
    _disabled,
    _even,
    _expanded,
    _first,
    _focus,
    _focusWithin,
    _grabbed,
    _groupHover,
    _hover,
    _invalid,
    _last,
    _mixed,
    _notFirst,
    _notLast,
    _odd,
    _placeholder,
    _pressed,
    _readOnly,
    _selected,
    _visited,
  }) =>
    ssCSS({
      [active]: _active,
      [after]: _after,
      [before]: _before,
      [checked]: _checked,
      [disabled]: _disabled,
      [even]: _even,
      [expanded]: _expanded,
      [first]: _first,
      [focus]: _focus,
      [focusWithin]: _focusWithin,
      [grabbed]: _grabbed,
      [groupHover]: _groupHover,
      [hover]: _hover,
      [invalid]: _invalid,
      [last]: _last,
      [mixed]: _mixed,
      [notFirst]: _notFirst,
      [notLast]: _notLast,
      [odd]: _odd,
      [placeholder]: _placeholder,
      [pressed]: _pressed,
      [readOnly]: _readOnly,
      [selected]: _selected,
      [visited]: _visited,
    } as SystemStyleObject),
);

Box.displayName = 'Box';

export { Box };

const active = '&:active, &[data-active=true]';
const after = '&:after';
const before = '&:before';
const checked = '&[aria-checked=true]';
const disabled =
  '&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover';
const even = '&:nth-of-type(even)';
const expanded = '&[aria-expanded=true]';
const first = '&:first-of-type';
const focus = '&:focus';
const focusWithin = '&:focus-within';
const grabbed = '&[aria-grabbed=true]';
const groupHover = '[role=group]:hover &';
const hover = '&:hover';
const invalid = '&[aria-invalid=true]';
const last = '&:last-of-type';
const mixed = '&[aria-checked=mixed]';
const notFirst = '&:not(:first-of-type)';
const notLast = '&:not(:last-of-type)';
const odd = '&:nth-of-type(odd)';
const placeholder = '&::placeholder';
const pressed = '&[aria-pressed=true]';
const readOnly = '&[aria-readonly=true], &[readonly]';
const selected = '&[aria-selected=true]';
const visited = '&:visited';
