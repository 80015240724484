import { Button } from 'antd';
import React from 'react';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import styled from 'styled-components';

export const Footer = ({
  selectedWords,
  onWordsAdd,
  onDeselect,
  onDelete,
  moveBtnTxt,
  deleteBtnTxt,
}) => {
  const hasSelectedWords = Boolean(selectedWords);

  return (
    <Flex flex="1" alignItems="center" justifyContent="flex-end">
      <Box>
        {!hasSelectedWords ? (
          <Button onClick={onWordsAdd}>Add words</Button>
        ) : (
          <>
            <StyledButton type="default" onClick={onDeselect}>
              Deselect
            </StyledButton>
            <StyledButton type="default" disabled>
              {moveBtnTxt}
            </StyledButton>
            <StyledButton type="danger" onClick={onDelete}>
              {deleteBtnTxt}
            </StyledButton>
          </>
        )}
      </Box>
    </Flex>
  );
};

const StyledButton = styled(Button)`
  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.space[2]}px;
  }
`;
