import { action, computed, flow, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { AutoapproveConfig } from 'src/interfaces';
import { IAlertCategories } from 'src/restApi/alertsService';
import {
  IApprovalCategoriesRaw,
  ICloudWatchConfig,
} from 'src/restApi/interfaces';
import { ConfigService } from 'src/services/configService';
import { ApprovalContentType } from '../constants';
import { ICommentsConfig } from '../restApi/commentService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';

import { Logger } from 'src/logger';

const REGION = 'us-east-1'; // This should probably come from API

export class ConfigStore extends InjectRootStore {
  @observable public autoApprove: AutoapproveConfig;
  @observable public commentsOptions: ICommentsConfig;
  @observable public categories = {} as IApprovalCategoriesRaw;
  @observable public cloudWatchConfig = {} as ICloudWatchConfig;
  @observable public cloudWatchAccessKeyId: string;
  @observable public cloudWatchSecretKey: string;
  @observable public cloudWatchSessionToken: string;
  @observable public cloudWatchLogGroupName: string =
    'RingNeighborsAdminUILogs';
  @observable public cloudWatchMetricNameSpace: string;
  @observable public cloudWatchExpriationTime: number;
  @observable public clientId: string;
  @observable public isLoggerInstalled: boolean = false;

  public fetchConfigurations = flow(
    function* (this: ConfigStore) {
      const { phrasesStore, manageUsersStore } = this.rootStore;

      yield Promise.all([
        this.fetchCategories(),
        this.fetchCommentsOptions(),
        this.fetchCloudWatchConfig(),
        phrasesStore.loadPhrasesFlow(),
        manageUsersStore.fetchCategories(),
      ]);
    }.bind(this),
  );

  public fetchCategories = flow(
    function* (this: ConfigStore) {
      this.categories = {};

      try {
        const categories = yield ConfigService.fetchCategories();
        this.categories = categories;
      } catch (e) {
        logError({
          description: e.message,
          exception: e,
          title: 'Failed to get alerts moderation categories',
        });
      }
    }.bind(this),
  );

  public fetchCommentsOptions = flow(
    function* (this: ConfigStore) {
      this.commentsOptions = { time_until_missed_sla: 0 };

      try {
        const options = yield ConfigService.fetchCommentsOptions();

        this.commentsOptions = options;
      } catch (e) {
        logError({
          description: e.message,
          exception: e,
          title: 'Failed to get comments options',
        });
      }
    }.bind(this),
  );

  public fetchCloudWatchConfig = async () => {
    if (!this.clientId) {
      const clientId = uuidv4();
      this.setClientId(clientId);
    }
    const result = await ConfigService.fetchCloudWatchConfig(this.clientId);

    this.cloudWatchAccessKeyId = result.accessKey;
    this.cloudWatchSecretKey = result.secretKey;
    this.cloudWatchLogGroupName = result.logGroupName;
    this.cloudWatchSessionToken = result.sessionToken;
    this.cloudWatchMetricNameSpace = result.metricNameSpace;
    this.cloudWatchExpriationTime = result.expirationTime;
  };

  @computed public get alertCategories() {
    const { 0: alertCategories } = this.categories;
    const { 0: approve, 1: decline } = alertCategories;

    return {
      approve,
      decline,
    } as IAlertCategories;
  }

  public getApprovalCategories = (
    contentType: ApprovalContentType,
    categoryType: number,
  ) => {
    const result = this.categories[contentType][categoryType];

    return result.length > 1 ? result : result[0].subcategory;
  };

  public getApprovalCategoriesWithType = (
    contentType: ApprovalContentType,
    categoryType: number,
  ) => {
    const result = this.categories[contentType][categoryType];
    const hasSubCategories = result.length > 1;

    return [
      hasSubCategories,
      hasSubCategories ? result : result[0].subcategory,
    ] as [boolean, any];
  };

  public installLogger = () => {
    // Check is user email is fetched
    if (this.rootStore.authStore.user?.email) {
      const userEmail = this.rootStore.authStore.user.email;

      const logger = new Logger(
        REGION,
        this.cloudWatchLogGroupName || 'RingNeighborsAdminUILogs',
      );

      // Message Formatter
      const messageFormatter = async (e, info = { type: 'unknown' }) => {
        if (!e.message) {
          return null;
        }
        return JSON.stringify({
          message: e.message,
          timestamp: new Date().getTime(),
          clientId: this.clientId,
          userEmail,
          ...info,
        });
      };

      logger.install({ messageFormatter });
      this.setIsLoggerInstalled(true);
    }
  };

  @action
  public setClientId = (clientId: string) => {
    this.clientId = clientId;
  };

  @action
  public setIsLoggerInstalled = (bool: boolean) => {
    this.isLoggerInstalled = bool;
  };

  @action
  public setCloudWatchLogGroupName = (name: string) => {
    this.cloudWatchLogGroupName = name;
  };
}
