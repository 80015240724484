import React from 'react';
import { cropText } from 'src/helpers';
import { COMMENT_BAD_WORDS_TYPES, WordsHighlighter } from '../WordsHighlighter';
import s from './style.module.css';

export class TextView extends React.PureComponent<
  ITextViewProps,
  ITextViewStates
> {
  public static defaultProps: Partial<ITextViewProps> = {
    cropTextSize: 0,
    text: '',
    testID: '',
  };

  constructor(props: ITextViewProps) {
    super(props);
    this.state = { expanded: props.expanded };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  public expandStateHandler = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  public getButtonText = (isExpanded: boolean) =>
    isExpanded ? 'Show Less' : 'More';

  public getCroppedText = (text, cropTextSize) => cropText(text, cropTextSize);

  public renderMoreButton = (isExpanded: boolean) => (
    <>
      {isExpanded && (
        <div
          key="new-line-padding-for-more-button"
          className={s.moreButtonPadding}
        />
      )}
      <span
        key="comment-item-more-button"
        className={s.moreButton}
        onClick={this.expandStateHandler}
      >
        {this.getButtonText(isExpanded)}
      </span>
    </>
  );

  public getHighlightedText = (text: string) => (
    <WordsHighlighter type={COMMENT_BAD_WORDS_TYPES} textToHighlight={text} />
  );

  public getText = (proceededText: string) =>
    this.props.highlighted
      ? this.getHighlightedText(proceededText)
      : proceededText;

  public render() {
    const { expanded } = this.state;
    const { text, cropTextSize, testID } = this.props;
    const shouldTextCropped = text.length > cropTextSize;
    const proceededText = shouldTextCropped
      ? this.getCroppedText(text, cropTextSize)
      : text;

    return (
      <span data-test-id={testID}>
        {!expanded && shouldTextCropped && this.getText(proceededText)}
        {(expanded || !shouldTextCropped) && this.getText(text)}
        {shouldTextCropped && this.renderMoreButton(expanded)}
      </span>
    );
  }
}

interface ITextViewProps {
  text: string;
  cropTextSize: number;
  highlighted?: boolean;
  expanded?: boolean;
  testID?: string;
}

interface ITextViewStates {
  expanded: boolean;
}
