import { message } from 'antd';
import * as Sentry from "@sentry/browser";
import { isDevelopment } from './appHelpers';

type IMessageRetreiver = (error: string | Error) => string | false;

export const processRestError = (
  serviceName: string,
  methodName: string,
  error: 'string' | Error,
  messageGetter?: IMessageRetreiver | false,
) => {
  const errorMessage = new Error(`${serviceName} :: ${methodName} :: ${error}`);

  if (isDevelopment) {
    console.error(errorMessage);
  } else {
    try {
      Sentry.captureException(errorMessage);
    } catch (error) {
      console.error(`Raven Error :: ${error}`);
    }
  }

  let msg;
  // Call message getter if specified to get user error message
  if (messageGetter) {
    msg = messageGetter(error);
  }

  // False in messageGetter variable or msg will skip to show user error
  if (messageGetter !== false && msg !== false) {
    message.error(msg || String(error));
  }
};
