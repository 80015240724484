import { Button, Modal, Typography } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { useStores } from 'src/stores';

import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { UserIcon } from '../../images/user-icon';

const { Text } = Typography;

export const ManageUsersRemoveBanModal = observer(() => {
  const { manageUsersStore } = useStores();

  const handleOkClick = () => {
    manageUsersStore.removeUser();
  };

  const handleCancelClick = () => {
    manageUsersStore.setRemoveBanUserId('');
    manageUsersStore.setIsRemoveBanModalVisible(false);
  };

  return (
    <Modal
      centered={true}
      title={'Remove User?'}
      footer={
        <FooterActions
          disabled={manageUsersStore.removeBanUserId === ''}
          handleOkClick={handleOkClick}
          handleCancelClick={handleCancelClick}
        />
      }
      visible={manageUsersStore.isRemoveBanModalVisible}
    >
      <Flex flexDirection="column">
        <Box py={3}>
          <Text>
            Are you sure you want to remove the user from the management system?
            The user will no longer be highlighted in the moderation tool.
          </Text>
        </Box>
        <Flex alignItems={'center'} pb={6}>
          <UserIcon />
          <Text strong>User ID: {manageUsersStore.removeBanUserId}</Text>
        </Flex>
      </Flex>
    </Modal>
  );
});

const FooterActions = ({ disabled, handleOkClick, handleCancelClick }) => {
  return (
    <Flex justifyContent={'center'}>
      <FooterButton onClick={handleCancelClick} type="default" size="large">
        Cancel
      </FooterButton>
      <FooterButton
        onClick={handleOkClick}
        disabled={disabled}
        type="danger"
        size="large"
      >
        Remove User
      </FooterButton>
    </Flex>
  );
};

const FooterButton = styled(Button)`
  width: 50%;
`;
