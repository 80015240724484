import * as alertHelpers from 'src/helpers/alertHelpers';
import { AlertsService } from 'src/restApi/alertsService';
import { ModerationStatus } from 'src/restApi/interfaces';
import { NotificationService } from 'src/services';
import { rootStore } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import {
  NavigationTabs,
  tabsByStore,
  tabsStores,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';
import { CommentService } from '../restApi/commentService';

const { alertsListStore, authStore, navigationStore } = rootStore;

export const startListeners = () => {
  if (!authStore.hasUserRsuRole) {
    console.warn(
      'Listeners are not started. Current user does not have RSU permissions.',
    );

    return;
  }

  WsConnector.addListener(
    WebsocketMessageType.RemovedFromRsu,
    WebsocketEntityType.Alert,
    markAlertAsRead,
  );

  WsConnector.addListener(
    WebsocketMessageType.RemovedFromRsu,
    WebsocketEntityType.Comment,
    markCommentAsRead,
  );

  WsConnector.addListener(
    WebsocketMessageType.New,
    WebsocketEntityType.Comment,
    incomeNewComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.AutoAssigned,
    WebsocketEntityType.Comment,
    incomeNewComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Comment,
    incomeAssignedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Comment,
    incomeRSUComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Alert,
    incomeRSUAlert,
  );

  WsConnector.addListener(
    WebsocketMessageType.AutoApproved,
    WebsocketEntityType.Comment,
    incomeAutoApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Approved,
    WebsocketEntityType.Comment,
    incomeApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Rejected,
    WebsocketEntityType.Comment,
    incomeRejectedComment,
  );
};

export const fetchAlertsWithComments = async () => {
  const [rsuAlerts, rsuAlertsWithComments] = await Promise.all([
    AlertsService.getAlerts(undefined, true),
    AlertsService.getAlertsWithComments(['on_rsu']),
  ]);

  const alertsWithoutComments = rsuAlerts.filter(
    (alert) =>
      alertHelpers.getAlertsIds(rsuAlertsWithComments).indexOf(alert.alert_id) <
      0,
  );

  return [...alertsWithoutComments, ...rsuAlertsWithComments];
};

export const incomeAutoApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.AutoApproved);
};

export const incomeApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Approved);
};

export const incomeRejectedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Declined);
};

export const incomeRSUAlert = async (data: any) => {
  const isTabActive = checkIsCurrentTabActive();

  if (isTabActive) {
    const alertId = data.alert_id || null;
    const alert = alertId ? await AlertsService.getAlert(alertId) : undefined;

    if (alert) {
      alertsListStore.addAlert(tabsStores.RSU)(alert);
    }
  }

  sendNotificationForTab();
};

export const markAlertAsRead = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();

  if (isTabActive) {
    const alertId = data.alert_id || null;
    alertId &&
      alertsListStore.removeAlertWithoutComments(tabsStores.RSU)(alertId);
  }
};

export const incomeNewComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();

  if (isTabActive) {
    const commentId = data.comment_id || null;
    const alertId = data.alert_id || null;
    const isAlertInStore = alertsListStore.getAlertWithComments(tabsStores.RSU)(
      alertId,
    );

    const isCommentOnAlert = alertsListStore.getAlertComment(tabsStores.RSU)(
      alertId,
      commentId,
    );

    isAlertInStore && !isCommentOnAlert && fetchNewComment(commentId);
    isAlertInStore && isCommentOnAlert && updateComment(commentId);
  }
};

export const incomeAssignedComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert = alertsListStore.getAlertComment(tabsStores.RSU)(
    alertId,
    commentId,
  );

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.RSU)(alertId);

    alert.comments = alert.comments.map((c) =>
      c.comment_id === commentId
        ? {
            ...c,
            status: ModerationStatus.Assigned,
          }
        : c,
    );

    alertsListStore.updateAlertWithComments(tabsStores.RSU)(alert);
  }
};

export const incomeRSUComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();

  if (isTabActive) {
    const commentId = data.comment_id || null;
    const alertId = data.alert_id || null;
    const isAlertInStore = alertsListStore.getAlertWithComments(tabsStores.RSU)(
      alertId,
    );
    const isCommentOnAlert = alertsListStore.getAlertComment(tabsStores.RSU)(
      alertId,
      commentId,
    );

    isAlertInStore && !isCommentOnAlert && fetchNewComment(commentId);
    isAlertInStore && isCommentOnAlert && setCommentOnRSU(alertId, commentId);
    !isAlertInStore && fetchAlertWithComments(alertId);
  }

  sendNotificationForTab();
};

export const setCommentOnRSU = (alertId: number, commentId: string) => {
  const alert = alertsListStore.getAlertWithComments(tabsStores.RSU)(alertId);

  alert.comments = alert.comments.map((c) =>
    c.comment_id === commentId
      ? {
          ...c,
          status: ModerationStatus.Approved,
          on_rsu: true,
        }
      : c,
  );

  alertsListStore.updateAlertWithComments(tabsStores.RSU)(alert);
};

const fetchAlertWithComments = async (alertId: number) => {
  const alert = await AlertsService.getAlertWithComments(alertId);

  alertsListStore.addAlert(tabsStores.RSU)(alert);
};

const fetchNewComment = async (commentId: string) => {
  const comment = await CommentService.getAlertComment(commentId);

  alertsListStore.addCommentToAlertWithComments(tabsStores.RSU)(
    comment.alert_id,
    comment,
  );
};

export const markCommentAsRead = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert = alertsListStore.isCommentOnAlert(tabsStores.RSU)(
    alertId,
    commentId,
  );

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.RSU)(alertId);

    alert.comments = alert.comments.map((c) =>
      c.comment_id === commentId
        ? {
            ...c,
            on_rsu: false,
          }
        : c,
    );

    const hasAlertCommentsOnRSU = alert.comments.some(
      (comment) => comment.on_rsu,
    );

    !hasAlertCommentsOnRSU
      ? alertsListStore.removeAlert(tabsStores.RSU)(alertId)
      : alertsListStore.updateAlertWithComments(tabsStores.RSU)(alert);
  }
};

const updateComment = async (commentId: string) => {
  const comment = await CommentService.getAlertComment(commentId);

  comment && alertsListStore.updateAlertComment(tabsStores.RSU)(comment);
};

const checkIsCurrentTabActive = () =>
  navigationStore.currentTab === NavigationTabs.RSU.path;

const sendNotificationForTab = () => {
  NotificationService.sendNotification(
    'New RSU video',
    "Hey there! You've been notified!",
    tabsByStore[tabsStores.RSU],
  );
};

const incomeUpdatedComment = (data: any, status: number) => {
  const isTabActive = checkIsCurrentTabActive();
  const isCommentOnAlert = alertsListStore.isCommentOnAlert(tabsStores.RSU)(
    data.alert_id,
    data.comment_id,
  );

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlert(tabsStores.RSU)(data.alert_id);

    alert.comments = alert.comments.map((c) =>
      c.comment_id === data.comment_id
        ? {
            ...c,
            status,
            reason: data.reason || [],
          }
        : c,
    );

    alertsListStore.updateAlertWithComments(tabsStores.RSU)(alert);
  }
};

export const clearTabStore = () => {
  alertsListStore.setAlerts(tabsStores.RSU)([]);
};
