import { ProviderError } from '@smithy/property-provider';
import { CredentialProvider } from '@aws-sdk/types';
import { rootStore } from 'src/stores';
import { ConfigService } from 'src/services/configService';

export const fromStore = (): CredentialProvider => {
  return async () => {
    const { accessKey, secretKey, sessionToken, expirationTime, logGroupName } =
      await ConfigService.fetchCloudWatchConfig(rootStore.configStore.clientId);

    rootStore.configStore.setCloudWatchLogGroupName(logGroupName);

    if (accessKey && secretKey && sessionToken) {
      return Promise.resolve({
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
        sessionToken,
        expiration: expirationTime ? new Date(expirationTime) : undefined,
      });
    }

    return Promise.reject(new ProviderError('Unable to find credentials'));
  };
};
