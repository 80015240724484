import { inject, observer } from 'mobx-react';
import React from 'react';
import { IPhrase } from 'src/restApi/phrasesService';
import { URLService } from 'src/services/urlService';
import { AlignedLoader } from 'src/components/AlignedLoader';
import {
  contextCleanupOnUnmount,
  MainLayoutContext,
} from 'src/components/layouts/MainLayout';
import { getDeletedPluralized } from 'src/helpers';
import { LoadingState } from 'src/restApi/interfaces';
import { Stores } from 'src/stores';
import {
  DeleteModal,
  Filters,
  Footer,
  FormModal,
  WordList,
} from '../components';
import {
  CATEGORY_TITLE_DIC,
  DEFAULT_FILTER_VALUES,
  DEFAULT_TITLE,
  IFilter,
  WORD_LANGUAGE_ALL,
  WORD_TYPE_ALL,
} from '../constants';
import './all-words-page.less';

interface IAllWordsPageState {
  createModalVisible: boolean;
  deleteModalVisible: boolean;
  filter: IFilter;
  renderFilter: IFilter;
  lastSelectedWordIdx?: number;
  modalLoading: boolean;
  selectedPhrases: IPhrase[];
}

@inject('phrasesStore')
@observer
export class AllWordsPage extends React.Component<Stores, IAllWordsPageState> {
  public _cachedFooter: React.ReactNode;

  public state = {
    createModalVisible: false,
    deleteModalVisible: false,
    filter: { ...DEFAULT_FILTER_VALUES },
    lastSelectedWordIdx: 0,
    modalLoading: false,
    renderFilter: { ...DEFAULT_FILTER_VALUES },
    selectedPhrases: [],
  };

  public componentDidMount() {
    this.props.phrasesStore.startWordManagementFlow();
  }

  public componentWillUnmount() {
    contextCleanupOnUnmount(this);
    this.props.phrasesStore.stopWordManagementFlow();
    this.resetState();
  }

  private loaderVisible = () =>
    this.props.phrasesStore.phrasesLoadingState === LoadingState.Loading;

  private getPluralized = (amount: number) =>
    getDeletedPluralized('word', amount);

  private showCreateModal = () => this.setState({ createModalVisible: true });
  private hideCreateModal = () => this.setState({ createModalVisible: false });
  private showDeleteModal = () => this.setState({ deleteModalVisible: true });
  private hideDeleteModal = () => this.setState({ deleteModalVisible: false });

  private onFilterSubmit = ({ phrase, word_type, language }) => {
    this.setState(() => ({
      filter: { phrase, word_type, language },
      renderFilter: { phrase, word_type, language },
      selectedPhrases: [],
    }));
  };

  private onFilterChange = (filter) =>
    this.setState((prevState) => ({
      filter: { ...prevState.filter, ...filter },
    }));

  private onSearchReset = () =>
    this.setState({ filter: { ...DEFAULT_FILTER_VALUES } });

  private resetState = () => {
    this.setState({
      modalLoading: false,
      selectedPhrases: [],
    });
  };

  private deselectWords = () =>
    this.setState({
      selectedPhrases: [],
    });

  private getFooter() {
    const { selectedPhrases } = this.state;

    this._cachedFooter = (
      <Footer
        onDelete={this.showDeleteModal}
        onWordsAdd={this.showCreateModal}
        selectedWords={selectedPhrases.length}
        onDeselect={this.deselectWords}
        moveBtnTxt={`Move ${this.getPluralized(selectedPhrases.length)}`}
        deleteBtnTxt={`Delete ${this.getPluralized(selectedPhrases.length)}`}
      />
    );

    return this._cachedFooter;
  }

  private onWordSelect = (word: IPhrase) => (e) => {
    const filteredPhrases = this.getFilteredWords();
    const index: number = filteredPhrases.findIndex(
      (value: IPhrase) => value === word,
    );
    if (index === -1) {
      return;
    }

    if (e.nativeEvent.shiftKey) {
      const start = Math.min(index, this.state.lastSelectedWordIdx);
      const end = Math.max(index, this.state.lastSelectedWordIdx);
      const selectedRange: IPhrase[] = filteredPhrases.filter(
        (item, i) => i >= start && i <= end,
      );
      this.setState((prevState) => ({
        lastSelectedWordIdx: end,
        selectedPhrases: [
          ...new Set([...prevState.selectedPhrases, ...selectedRange]),
        ],
      }));
    } else {
      this.setState((prevState) => ({
        lastSelectedWordIdx: index,
        selectedPhrases: e.target.checked
          ? [...new Set([...prevState.selectedPhrases, word])]
          : prevState.selectedPhrases.filter((phrase) => phrase !== word),
      }));
    }
  };

  private onFetchAction = async (action, closeModal) => {
    this.setState({ modalLoading: true });
    const response = await action();
    this.setState({ modalLoading: false });
    closeModal();
    return response;
  };

  private onAddWordSubmit = async (wordsToAdd, wordType, language) => {
    const { phrasesStore } = this.props;

    await this.onFetchAction(
      () => phrasesStore.addPhrasesFlow(wordsToAdd, wordType, language),
      this.hideCreateModal,
    );
  };

  private onDeleteWordSubmit = async () => {
    const { phrasesStore } = this.props;
    const { selectedPhrases } = this.state;

    const wordsIdsToDelete = selectedPhrases.map((phrase) => phrase.id);

    await this.onFetchAction(
      () => phrasesStore.deletePhrasesFlow(wordsIdsToDelete),
      this.hideDeleteModal,
    );

    this.resetState();
  };

  private getFilteredWords = (): IPhrase[] => {
    const { phrasesStore } = this.props;
    const { renderFilter } = this.state;

    return phrasesStore.phrases
      .filter(
        (phrase) =>
          renderFilter.word_type === WORD_TYPE_ALL.value ||
          phrase.word_type === renderFilter.word_type,
      )
      .filter(
        (phrase) =>
          renderFilter.language === WORD_LANGUAGE_ALL.value ||
          phrase.language === renderFilter.language,
      )
      .filter((phrase) => {
        return (
          renderFilter.phrase === '' ||
          renderFilter.phrase === undefined ||
          phrase.phrase
            .toLowerCase()
            .includes(renderFilter.phrase.toLowerCase())
        );
      });
  };

  public render() {
    if (this.loaderVisible()) return <AlignedLoader />;

    const { selectedPhrases } = this.state;
    const {
      language: parsedLang,
      word_type: parsedType,
    } = URLService.getParsedUrl();
    const title = CATEGORY_TITLE_DIC[parsedType] || DEFAULT_TITLE;

    const words = this.getFilteredWords();

    return (
      <>
        <MainLayoutContext.Consumer>
          {({ setById, types }) => {
            setById(types.footer, this, this.getFooter());
            setById(
              types.sider,
              this,
              <Filters
                onSubmit={this.onFilterSubmit}
                values={this.state.filter}
                onReset={this.onSearchReset}
                onChange={this.onFilterChange}
              />,
            );
          }}
        </MainLayoutContext.Consumer>
        <div className="pageContentWrapper">
          <div className="pageContent">
            <div className="pageTitle">{title}</div>
            <WordList
              words={words}
              selectedWords={selectedPhrases.map((phrase) => phrase.id)}
              parsedLang={parsedLang}
              parsedType={parsedType}
              onWordSelect={this.onWordSelect}
            />
            <FormModal
              visible={this.state.createModalVisible}
              onSubmit={this.onAddWordSubmit}
              modalLoading={this.state.modalLoading}
              onCancel={this.hideCreateModal}
            />
            <DeleteModal
              visible={this.state.deleteModalVisible}
              wordsToDelete={selectedPhrases}
              onSubmit={this.onDeleteWordSubmit}
              modalLoading={this.state.modalLoading}
              title={`Delete ${this.getPluralized(selectedPhrases.length)}`}
              closable={false}
              onCancel={this.hideDeleteModal}
            />
          </div>
        </div>
      </>
    );
  }
}
