import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'antd';
import styled from 'styled-components';

import { ManageUsersForm } from '../manage-users-form';
import { Flex } from 'src/components/Flex';

import { useStores } from 'src/stores';
import { isEmpty } from 'ramda';

export const ManageUsersModalFormComponent = observer(
  ({ form, handleSubmitClick }) => {
    // Declarations
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const { manageUsersStore } = useStores();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState({});
    const [categoriesCount, setCount] = useState([
      manageUsersStore.categoriesIndex,
    ]);

    // Handlers
    const handleAddUserViolation = () => {
      const nextIndex = manageUsersStore.categoriesIndex + 1;

      manageUsersStore.setCategoriesIndex(nextIndex);

      const nextKeys = categoriesCount.concat(manageUsersStore.categoriesIndex);

      setCount(nextKeys);
    };

    const handleRemoveUserViolation = (k) => {
      let keys = categoriesCount;
      if (keys.length === 1) {
        return;
      }
      setCount(keys.filter((key) => key !== k));
    };

    const handleCancelClick = () => {
      manageUsersStore.setIsAddUserModalVisible(false);
      manageUsersStore.clearForm();
      setSelectedCategories([]);
      setSelectedSubcategories({});
      setCount([0]);
      manageUsersStore.setCategoriesIndex(0);
    };

    const isSubmitDisabled = (): boolean => {
      if (!getFieldValue('userId')) return true;

      if (isEmpty(selectedCategories)) return true;

      for (let key of selectedCategories) {
        if (isEmpty(selectedSubcategories[key].subcategories)) {
          return true;
        }
      }
      return false;
    };

    const handleSelectCategory = (index) => (categoryId) => {
      let subcats = { category_id: categoryId, subcategories: [] };
      let updatedCategories = [...selectedCategories];

      updatedCategories[index] = categoryId;
      setFieldsValue({
        [`userViolationsSubcategory${index}`]: [],
      });

      setSelectedCategories(updatedCategories);
      setSelectedSubcategories({
        ...selectedSubcategories,
        [`${categoryId}`]: subcats,
      });
    };

    const handleSelectSubcategory = (index) => (subcategories) => {
      const categoryId = selectedCategories[index];
      const subcategory = { category_id: categoryId, subcategories: [] };

      let updatedSubcategories = {
        [`${categoryId}`]: subcategory,
        ...selectedSubcategories,
      };
      let updatedCategory = updatedSubcategories[categoryId];
      updatedCategory['subcategories'] = subcategories;
      setSelectedSubcategories(updatedSubcategories);
    };

    useEffect(() => {
      if (manageUsersStore.isEditingUser) {
        const editingCategories =
          manageUsersStore.editingUserCategories.slice();

        const initialCount = editingCategories.map((count, index) => {
          return index;
        });

        const initialCategories = editingCategories.map((category, index) => {
          return category.id;
        });

        const initialSubcategories = editingCategories.reduce(
          (accumulator, category, currentIndex, array) => {
            if (category && category['subcategories']) {
              accumulator[`${category.id}`] = {
                category_id: category.id,
                subcategories: category['subcategories'].slice(),
              };
            }

            return accumulator;
          },
          {},
        );

        setCount(initialCount);
        setSelectedCategories(initialCategories);
        setSelectedSubcategories(initialSubcategories);
      }
      return;
    }, [
      manageUsersStore.editingUserCategories,
      manageUsersStore.isAddUserModalVisible,
      manageUsersStore.isEditingUser,
    ]);
    return (
      <Modal
        centered={true}
        closable={true}
        onCancel={handleCancelClick}
        title={manageUsersStore.isEditingUser ? 'Edit user' : 'Add User'}
        visible={manageUsersStore.isAddUserModalVisible}
        footer={
          <FooterActions
            disabled={isSubmitDisabled()}
            handleCancelClick={handleCancelClick}
            handleSubmitClick={handleSubmitClick}
            isBanToggled={manageUsersStore.isManageUsersFormBanSwitchToggled}
          />
        }
      >
        <ManageUsersForm
          categoriesCount={categoriesCount}
          getFieldDecorator={getFieldDecorator}
          handleAddUserViolation={handleAddUserViolation}
          handleRemoveUserViolation={handleRemoveUserViolation}
          handleSelectCategory={handleSelectCategory}
          handleSelectSubcategory={handleSelectSubcategory}
          selectedCategories={selectedCategories}
          selectedSubcategories={selectedSubcategories}
        />
      </Modal>
    );
  },
);

const FooterActions = ({
  disabled,
  handleCancelClick,
  handleSubmitClick,
  isBanToggled,
}) => {
  return (
    <Flex justifyContent="center">
      <FooterButton onClick={handleCancelClick} type="default" size="large">
        Cancel
      </FooterButton>

      <FooterButton
        data-testid="users-modal-footer-actions"
        disabled={disabled}
        onClick={handleSubmitClick}
        type="danger"
        size="large"
      >
        {isBanToggled ? 'Yes, Ban User' : 'Classify Violations'}
      </FooterButton>
    </Flex>
  );
};

const FooterButton = styled(Button)`
  width: 50%;
`;

type Props = {
  children?: any;
  handleSubmitClick: any;
  form: any;
};

export const ManageUsersModalForm = Form.create<Props>({
  name: 'manage-users-modal-form',
})(ManageUsersModalFormComponent);
