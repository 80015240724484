export const pagesCount = (itemsPerPage: number, itemsCount: number): number =>
  Math.ceil(itemsCount / itemsPerPage);

export const getItemsByPage = (
  items: any[],
  pageNumber: number,
  itemsPerPage: number,
): any[] => {
  const startItemIndex = (pageNumber - 1) * itemsPerPage;
  const endItemIndex = itemsPerPage * pageNumber;

  return items.slice(startItemIndex, endItemIndex);
};
