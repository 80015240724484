import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Icon, IconType } from '../Icon';
import './NewsItemUser.less';

export interface INewsItemUserProps {
  /** Additional class name */
  className?: string;
  /** Email to be shown. If it is empty, then nothing will be rendered */
  email: string;
  /** Additional icon class */
  iconClassName?: string;
}

export function NewsItemUser({
  className,
  email,
  iconClassName,
}: INewsItemUserProps): React.ReactElement<HTMLSpanElement | null> {
  if (!email) {
    return null;
  }

  return (
    <Tooltip
      className={classNames('NewsItemUser', className)}
      placement="top"
      title={email}
    >
      <>
        <Icon
          className={classNames('NewsItemUser__userIcon', iconClassName)}
          iconType={IconType.User}
        />
        <span>{email}</span>
      </>
    </Tooltip>
  );
}
