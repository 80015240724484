import { Col, Icon, Row } from 'antd';
import React from 'react';
import './NewNewsBlock.less';

interface INewNewsBlock {
  isLoading: boolean;
  onClick: (any) => void;
  newNewsCount: number;
}

export class NewNewsBlock extends React.PureComponent<INewNewsBlock> {
  public renderUnreadNewsCounter() {
    const { newNewsCount } = this.props;

    if (!newNewsCount) return null;

    return `${newNewsCount} incoming alerts`;
  }

  public render() {
    const { isLoading, onClick } = this.props;

    return (
      <div className="NewNewsBlock">
        <Row>
          <Col span={20}>
            <button onClick={onClick} className="NewNewsBlock__button">
              <div className="NewNewsBlock__text">
                <Icon type="reload" spin={isLoading} /> Show more alerts
              </div>
              <span className="NewNewsBlock__info">
                {this.renderUnreadNewsCounter()}
              </span>
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}
