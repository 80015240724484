export const relatedNewsLimit = 50;
export const collapsedNewsAmount = 5;

export interface IRelatedTime {
  amount: number;
  units: 'hours' | 'days';
}

export const relatedArticleTimeInterval: IRelatedTime = {
  amount: 2,
  units: 'days',
};

export const updatedArticleTimeInterval: IRelatedTime = {
  amount: 2,
  units: 'days',
};

export const newsDistancePrecision = 10;
