import { IAdminUser } from 'src/interfaces';
import { IResponseItem } from 'src/restApi/restApi';
import { api } from '../api/api';

const path = 'admins';

export class PermissionService {
  public static getUsers = async () => {
    const response = await api.get(path).json<IResponseItem<IAdminUser[]>>();
    const admins = response.data;

    return Object.keys(admins || {}).map((e) => ({
      email: e,
      scope: admins[e],
    }));
  };

  public static deleteUser = async (user: IAdminUser) => {
    const response = await api
      .post(path, { json: user })
      .json<IResponseItem<IAdminUser>>();
    return response.data;
  };

  public static updateUser = async (user: IAdminUser) => {
    const response = await api
      .put(path, { json: user })
      .json<IResponseItem<IAdminUser>>();
    return response.data;
  };

  public static addUser = async (user: IAdminUser) => {
    const response = await api
      .post(path, { json: user })
      .json<IResponseItem<IAdminUser>>();
    return response.data;
  };
}
