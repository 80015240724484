import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { AlertModerationCard } from 'src/componentsNew/Alert/components/AlertModerationCard';
import {
  DecisionType,
  IModerationPayload,
} from 'src/componentsNew/AlertModerationDialog';
import {
  ModerationControlBlock,
  ModerationControlBlockMode,
} from 'src/componentsNew/Moderation/ModerationControlBlock';
import { ModerationInfoBlock } from 'src/componentsNew/Moderation/ModerationInfoBlock/ModerationInfoBlock';
import { ModerationLayout } from 'src/componentsNew/Moderation/ModerationLayout';
import { ModerationStatistic } from 'src/componentsNew/Moderation/ModerationStatistic';
import { SessionStoppageReason } from 'src/restApi/alertsService';
import { useStores } from 'src/stores';

export const AlertsPage = observer(() => {
  const { alertsStore, uiStore, configStore } = useStores();

  const mode = alertsStore.moderationStatus
    ? ModerationControlBlockMode.Stop
    : ModerationControlBlockMode.Start;

  const handleDecisionMade = async (
    type: DecisionType,
    payload: IModerationPayload,
  ) => {
    switch (type) {
      case DecisionType.Approve:
        return alertsStore.approveAlertFlow(payload.reason, payload.note);
      case DecisionType.Decline:
        return alertsStore.declineAlertFlow(payload.reason, payload.note);
      case DecisionType.SendToRsu:
        return alertsStore.approveAlertFlow(payload.reason, payload.note, true);
      case DecisionType.EscalateL1:
        return alertsStore.escalateAlertFlow();
      case DecisionType.EscalateL2Approve:
      case DecisionType.EscalateL2Decline:
        return alertsStore.escalateAlertFlow(payload.reason, payload.note);
    }
  };

  const handleStartModeration = async () => {
    return alertsStore.startModerationSessionFlow();
  };

  const handleStopModeration = () => {
    return alertsStore.stopModerationSessionFlow(SessionStoppageReason.Manual);
  };

  const handleHistoryToggle = async (alertId: number, visible: boolean) => {
    if (!visible) return;

    return alertsStore.getHistoryFlow();
  };

  const handleTimeoutDialog = () => {
    return alertsStore.continueModerationFlow();
  };

  useEffect(() => {
    alertsStore.startFlow().then(uiStore.startTimer);

    return () => {
      alertsStore.stopFlow();
    };
  }, [alertsStore, uiStore]);

  return (
    <ModerationLayout
      controls={
        <>
          <ModerationControlBlock
            loading={alertsStore.isModerationSessionProcessing}
            mode={mode}
            onStopModeration={handleStopModeration}
            onStartModeration={handleStartModeration}
          />
          <ModerationStatistic
            loading={alertsStore.isModerationStatisticLoading}
            statistic={alertsStore.moderationStatistic}
          />
        </>
      }
      main={
        // Moderation is not started
        !alertsStore.moderationStatus ? (
          <ModerationInfoBlock
            hint="Press Start to begin your Moderation Time clock and receive alerts for moderation."
            title="Welcome!"
          />
        ) : // No Alerts for moderation
        !alertsStore.alert ? (
          <ModerationInfoBlock
            hint="We will show an alert as soon as it appear."
            title="No alert for moderation"
          />
        ) : (
          // Loaded Alert
          <AlertModerationCard
            alert={alertsStore.alert}
            alertMeta={alertsStore.alertMeta}
            categories={configStore.alertCategories}
            history={alertsStore.history}
            isProcessing={alertsStore.isAlertProcessing}
            onDecisionMade={handleDecisionMade}
            onHistoryToggle={handleHistoryToggle}
            onTimeoutDialogClick={handleTimeoutDialog}
            canMissSLA={alertsStore.canMissSLA}
            timeUntilMissedSLA={alertsStore.maxTimeUntilMissedSLA}
          />
        )
      }
    />
  );
});
