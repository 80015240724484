import React, { Fragment } from 'react';

import { Box } from 'src/components/Box';
import { useStores } from 'src/stores';

export const ManageUsersSubcategoryColumnData = ({ category }) => {
  const { manageUsersStore } = useStores();
  const subcategories = category?.map((category) => category.subcategories);

  if (!manageUsersStore.areCategoriesAvailable) {
    return <Box />;
  }
  if (!subcategories) {
    return <Box />;
  }

  return (
    <Box>
      {subcategories?.map((cat, index) => {
        return (
          <Fragment key={`category-subcategory-${cat}-${index}`}>
            <div>
              {cat?.map((subcategory, idx) => {
                return (
                  <Fragment key={`subcategory-${subcategory}-${idx}`}>
                    <span>
                      {manageUsersStore.subcategoryEntities[subcategory]?.name}
                    </span>
                    <br />
                  </Fragment>
                );
              })}
            </div>
            <br />
          </Fragment>
        );
      })}
    </Box>
  );
};
