import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'src/stores';
import s from './style.module.css';

export const ConfirmPopup = observer(() => {
  const { uiStore } = useStores();

  const { confirmPopup } = uiStore;

  if (!confirmPopup.visible) return null;

  return (
    <div className={s.confirmPopupContainer}>
      <div className={s.popupWindow}>
        <div className={s.infoText}>{confirmPopup.infoText}</div>
        <div className={s.buttonsContainer}>
          <div className={s.confirmButton} onClick={confirmPopup.confirmAction}>
            {confirmPopup.confirmText}
          </div>
          <div className={s.cancelButton} onClick={confirmPopup.cancelAction}>
            {confirmPopup.cancelText}
          </div>
        </div>
      </div>
    </div>
  );
});
