import { Form as AntForm } from 'antd';
import { FormProps } from 'antd/lib/form';
import css from 'classnames';
import { omit } from 'lodash';
import qs from 'query-string';
import React from 'react';
import { URLService } from 'src/services/urlService';
import s from './filter.module.css';

export class Form extends React.Component<IFilterProps> {
  public pathname;

  public componentDidMount() {
    this.pathname = window.location.pathname;
    if (this.props.useBuiltInFilter) {
      this.onSubmit();
    }
  }

  public componentDidUpdate() {
    if (
      this.pathname === window.location.pathname &&
      !window.location.search.length
    ) {
      this.onSubmit();
    }
  }

  public onSubmit = (e?) => {
    let parsedParams;
    if (e) {
      e.preventDefault();
      parsedParams = this.prepareFormValues({
        ...this.getFormParamsFromUrl(),
        ...this.props.form,
      }); // Get params from URL and merge
    } else {
      parsedParams = {
        ...this.prepareFormValues(this.props.form),
        ...this.getFormParamsFromUrl(),
      };
    }
    const newUrlParams = {
      ...omit(URLService.getParsedUrl(), this.props.filterKeys),
      ...parsedParams,
    };

    URLService.setUrl(newUrlParams);
    this.props.onSubmit(parsedParams);
  };

  public prepareFormValues = (form) => {
    return Object.keys(form).reduce((acc, val) => {
      if (form[val] !== undefined && form[val] !== '') {
        return { ...acc, [val]: form[val] };
      }
      return acc;
    }, {});
  };

  private getFormParamsFromUrl() {
    const { search: searchStr } = window.location;
    const parsedUrl = qs.parse(searchStr.slice(1, searchStr.length));

    return Object.keys(parsedUrl).reduce((acc, val) => {
      if (
        this.props.filterKeys.includes(val) &&
        parsedUrl[val] !== undefined &&
        parsedUrl[val] !== ''
      ) {
        return { ...acc, [val]: this.qsTypeParser(parsedUrl[val]) };
      }
      return acc;
    }, {});
  }

  private qsTypeParser = (value): any => {
    if (!Number.isNaN(Number(value))) {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
      }
    }
    return value;
  };

  public render() {
    const {
      children,
      className,
      useBuiltInFilter,
      defaultFormParams,
      filterKeys,
      onChange,
      onSubmit,
      form,
      ...restProps
    } = this.props;
    return (
      <AntForm
        className={css(s.filtersArea, className)}
        onSubmit={this.onSubmit}
        layout="vertical"
        {...restProps}
      >
        {children}
      </AntForm>
    );
  }
}

interface IFilterProps extends FormProps {
  children?: JSX.Element[] | JSX.Element;
  className?: string;
  useBuiltInFilter?: boolean;
  defaultFormParams?: any;
  filterKeys?: string[];
  onChange?: any;
  onSubmit?: any;
  form?: any;
}
