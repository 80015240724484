import moment from 'moment';
import React from 'react';
import { AlertPoliceCaseBlock } from '../../../../components/AlertPoliceCaseBlock';
import { AlertCaseResolution } from '../../../../components/alert-case-resolution';
import { AlertInfoFields } from '../../../../componentsNew/Alert/components/AlertInfoFields';

import { ModerationHistory } from '../../../../componentsNew/Moderation/ModerationHistory';
import { DATE_FORMAT } from '../../../../constants';
import { hasAlertCaseInfo } from '../../../../helpers/alertHelpers';
import { IStoredAlert } from '../../../../restApi/interfaces';
import './AdminHistoryTimeline.less';
import {AlertExtendedDescriptionBlock} from "../../../../components/AlertExtendedDescriptionBlock";

export interface AdminHistoryTimelineProps {
  alert: IStoredAlert;
}

export const AdminHistoryTimeline = ({
  alert = {} as IStoredAlert,
}: AdminHistoryTimelineProps) => {
  const {
    agency_name,
    case_number,
    is_crime,
    officer_name,
    phone_number,
    resolved,
    resolved_message,
  } = alert;

  const isCaseInfo = hasAlertCaseInfo(alert);
  const history = (alert && alert.history) || [];

  return (
    <ModerationHistory
      className="AdminHistoryTimeline"
      history={history}
      intro={
        <>
          {alert.time_created && (
            <div className="AdminHistoryTimeline__date">
              {moment(alert.time_created * 1000).format(DATE_FORMAT)}
            </div>
          )}
          <div>
            {alert.title && (
              <div className="AdminHistoryTimeline__title">{alert.title}</div>
            )}
            {alert.description && <div>{alert.description}</div>}
            <div>
              <AlertExtendedDescriptionBlock {...alert} />
            </div>
            {isCaseInfo && (
              <div className="AdminHistoryTimeline__technical">
                <AlertPoliceCaseBlock
                  caseInformation={{
                    agency_name,
                    case_number,
                    officer_name,
                    phone_number,
                  }}
                  className="AdminHistoryTimeline__infoLine"
                  renderBoldValue
                />
              </div>
            )}
            {isCaseInfo && (
                <div className="AdminHistoryTimeline__technical">
                    <AlertCaseResolution
                      isCrime={is_crime}
                      resolved={resolved}
                      resolvedMessage={resolved_message}
                    />
                </div>
            )}
            <div className="AdminHistoryTimeline__technical">
              <AlertInfoFields alert={alert} />
            </div>
          </div>
        </>
      }
    />
  );
};
