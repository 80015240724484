import React from 'react';
import {
    IPersonDescriptionElementData,
    IStoredAlert,
    IVehicleDescriptionElementData
} from "../../restApi/interfaces";

interface IAlertExtendedDescriptionRow {
    boldText: string;
    unformattedText: string;
}

export function AlertExtendedDescriptionRow({boldText, unformattedText}: IAlertExtendedDescriptionRow) {
    return (
        <>
            <span><b>{boldText}</b> <span>{unformattedText}</span></span>
        </>
    )
}

export function AlertVehicleDescriptionElement(vehicleDescription: IVehicleDescriptionElementData) {
    return (vehicleDescription) ? (
        <>
            <div className='AlertCard__extendedDescription'>
                <AlertExtendedDescriptionRow boldText={`Vehicle ${vehicleDescription.index} Description:`}
                                             unformattedText={""}/>
                {vehicleDescription.description && (
                    <AlertExtendedDescriptionRow boldText={"Car Type:"} unformattedText={vehicleDescription.description}/>
                )}
                {vehicleDescription.license_plate_info && (
                    <AlertExtendedDescriptionRow boldText={"License Plate:"}
                                                 unformattedText={vehicleDescription.license_plate_info}/>
                )}
                {vehicleDescription.other && (
                    <AlertExtendedDescriptionRow boldText={"Other Details:"} unformattedText={vehicleDescription.other}/>
                )}
            </div>
        </>
    ) : (
        <></>
    )
}

export function AlertPersonDescriptionElement(personDescription: IPersonDescriptionElementData) {
    return (personDescription) ? (
        <>
            <div className='AlertCard__extendedDescription'>
                <AlertExtendedDescriptionRow boldText={`Person ${personDescription.index} Description:`}
                                             unformattedText={""}/>
                {personDescription.attire && (
                    <AlertExtendedDescriptionRow boldText={"Clothing:"} unformattedText={personDescription.attire}/>
                )}
                {personDescription.trait && (
                    <AlertExtendedDescriptionRow boldText={"Unique Traits:"} unformattedText={personDescription.trait}/>
                )}
                {personDescription.additional_details?.age && (
                    <AlertExtendedDescriptionRow boldText={"Age:"}
                                                 unformattedText={personDescription.additional_details.age.toString()}/>
                )}
                {personDescription.additional_details?.gender && (
                    <AlertExtendedDescriptionRow boldText={"Sex:"}
                                                 unformattedText={personDescription.additional_details.gender}/>
                )}
                {personDescription.additional_details?.race && (
                    <AlertExtendedDescriptionRow boldText={"Race:"}
                                                 unformattedText={personDescription.additional_details.race}/>
                )}
            </div>
        </>
    ) : (
        <></>
    )
}

export function AlertExtendedDescriptionBlock({
                                                  person_descriptions,
                                                  vehicle_descriptions
                                              }: IStoredAlert) {
    return (
        <>
            {person_descriptions && person_descriptions.length > 0 && person_descriptions.map(
                (personDescription, index) => (
                    <React.Fragment key={index}>
                        {<AlertPersonDescriptionElement index={index+1} {...personDescription}/>}
                    </React.Fragment>)
            )
            }
            {vehicle_descriptions && vehicle_descriptions.length > 0 && vehicle_descriptions.map(
                (vehicleDescription, index) => (
                    <React.Fragment key={index}>
                        {<AlertVehicleDescriptionElement index={index+1} {...vehicleDescription}/>}
                    </React.Fragment>)
            )
            }
        </>
    );
}



