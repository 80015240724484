import { APP_TITLE } from '../constants';
import { rootStore } from '../stores';

const { uiStore } = rootStore;

export class NotificationService {
  private static messageIndex = 0;

  public static requestPermissions = () => {
    Notification.requestPermission();
  };

  public static resetCounter = () => {
    NotificationService.messageIndex = 0;
  };

  public static sendNotification = (
    title: string,
    message: string,
    tabPath: string,
  ) => {
    NotificationService.messageIndex += 1;

    const notificationOptions = { body: message };

    if (document.hidden) {
      document.title = `(${NotificationService.messageIndex}) ${APP_TITLE}`;
      new Notification(title, notificationOptions);
    }

    uiStore.notifyTab(tabPath);
  };

  public static removeNotifications = (tabPath: string) => {
    uiStore.removeTabNotification(tabPath);
  };
}

NotificationService.requestPermissions();

document.addEventListener('visibilitychange', () => {
  NotificationService.resetCounter();
  document.title = APP_TITLE;
});
