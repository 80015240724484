import cx from 'classnames';
import React from 'react';
import s from './style.module.css';

const ModerateButtons = (props: ModerateButtonsProps) => (
  <div className={cx(props.className, { [s.buttonsDisabled]: props.disabled })}>
    <span
      className={s.approveButton}
      onClick={() => props.approveOnClick && props.approveOnClick()}
      data-test-id="approveButton"
    >
      Approve
    </span>
    <span className={s.devider}>/</span>
    <span
      className={s.rejectButton}
      onClick={() => props.rejectOnClick && props.rejectOnClick()}
      data-test-id="declineButton"
    >
      Decline
    </span>
  </div>
);

interface ModerateButtonsProps {
  className?: string;
  approveOnClick?: Function;
  rejectOnClick?: Function;
  disabled?: boolean;
}

export { ModerateButtons };
