import css from 'classnames';
import React from 'react';
import s from './style.module.css';

const AssignButton = (props: AssignButtonProps) => (
  <div className={css(s.assignButtonContainer, props.className)}>
    <div
      className={css(s.button, {
        [s.editButton]: props.editMode,
        [s.assignButton]: !props.editMode,
        [s.loading]: props.loadingMode,
      })}
      onClick={props.onClick}
      data-test-id="assignButton"
    >
      {props.editMode ? 'Edit' : 'Assign to me'}
    </div>
  </div>
);

interface AssignButtonProps {
  editMode?: boolean;
  loadingMode?: boolean;
  className?: string;
  onClick?: () => void;
}

export { AssignButton };
