import React from 'react';
import moment from 'moment';
import {
  AlertPropertyRow,
  IconType,
} from '../../../../components/AlertPropertyRow';
import { DATE_FORMAT } from '../../../../constants';
import { IPublicAssistanceDetailsData } from 'src/restApi/interfaces';

export interface AlertPublicAssistanceFieldsProps {
  agencyName: string;
  assistanceDetails: IPublicAssistanceDetailsData;
  className?: string;
}

window.moment = moment;

export const AlertPublicAssistanceFields = ({
  agencyName, // Agency Name when cop === true # See src/restApi/alertsService.ts
  assistanceDetails: {
    incident_number,
    incident_date: { start, end },
    contact_information: { first_name, last_name, title },
  },
  className,
}: AlertPublicAssistanceFieldsProps) => {
  return (
    <>
      <AlertPropertyRow className={className} iconType={IconType.Agency}>
        {`Case ID: ${incident_number}`}
      </AlertPropertyRow>

      <AlertPropertyRow className={className} iconType={IconType.User}>
        {`Author: ${title} ${first_name} ${last_name}`}
      </AlertPropertyRow>

      <AlertPropertyRow className={className} iconType={IconType.Agency}>
        {`Agency: ${agencyName}`}
      </AlertPropertyRow>

      <AlertPropertyRow className={className} iconType={IconType.Clock}>
        {`Date/Time Range: ${moment(start).format(DATE_FORMAT)} - ${moment(
          end,
        ).format(DATE_FORMAT)}`}
      </AlertPropertyRow>
    </>
  );
};
