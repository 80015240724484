import { notification } from 'antd';
import { action, observable, reaction } from 'mobx';
import { IAlert } from 'src/restApi/alertsService';
import { WebsocketEntityType, WebsocketMessageType } from '../constants';
import { IComment, PermissionLevel } from '../restApi/interfaces';
import { WsConnector } from '../wsConnector';
import { InjectRootStore } from './injectRootStore';
import { RootStore } from './rootStore';

type WebSocketHandler = (data: Partial<IAlert | IComment>) => void;

export class EscalatedNotificationStore extends InjectRootStore {
  private static instanceCounter = 0;

  private readonly _ids = observable.map<string, boolean>();
  private readonly _isEnabled = observable.box(false);
  private readonly _isDialogShown = observable.box(false);
  private readonly entityType: WebsocketEntityType;
  private readonly notificationKey: string;
  private readonly webSocketHandlers = new Map<
    WebsocketMessageType,
    WebSocketHandler
  >();

  constructor(rootStore: RootStore, entityType: WebsocketEntityType) {
    super(rootStore);

    this.entityType = entityType;
    this.notificationKey = `EscalatedNotification-${EscalatedNotificationStore.instanceCounter}`;

    EscalatedNotificationStore.instanceCounter += 1;

    this.webSocketHandlers.set(
      WebsocketMessageType.Approved,
      this.processModeration,
    );

    this.webSocketHandlers.set(
      WebsocketMessageType.Rejected,
      this.processModeration,
    );

    this.webSocketHandlers.set(
      WebsocketMessageType.L1Escalated,
      this.processEscalation.bind(this, PermissionLevel.L1),
    );

    this.webSocketHandlers.set(
      WebsocketMessageType.L2Escalated,
      this.processEscalation.bind(this, PermissionLevel.L2),
    );

    reaction(
      () => this.rootStore.navigationStore.currentTab,
      () => {
        const { isAlertsTabActive, isEscalatedTabActive } =
          this.rootStore.navigationStore;

        if (
          (this.isAlertMode && isAlertsTabActive) ||
          (this.isCommentMode && isEscalatedTabActive)
        ) {
          this.stopFlow();
        } else {
          this.startFlow();
        }
      },
    );
  }

  private get isAlertMode(): boolean {
    return this.entityType === WebsocketEntityType.Alert;
  }

  private get isCommentMode(): boolean {
    return this.entityType === WebsocketEntityType.Comment;
  }

  private get isEnabled(): boolean {
    return this._isEnabled.get();
  }

  private get isDialogShown(): boolean {
    return this._isDialogShown.get();
  }

  @action private addId = (id: string) => {
    if (this.isEnabled) {
      this._ids.set(id, true);
      this.showDialog();
    }
  };

  @action private deleteId = (id: string) => {
    if (this._ids.has(id)) {
      this._ids.delete(id);

      if (!this._ids.size) {
        this.hideDialog();
      }
    }
  };

  @action private clear = () => {
    this._ids.clear();
    this.hideDialog();
  };

  @action
  private setIsEnabled = (isEnabled: boolean) => {
    let wsAction;

    if (this.isEnabled && !isEnabled) {
      this.clear();
      wsAction = WsConnector.removeListener;
    } else if (!this.isEnabled && isEnabled) {
      wsAction = WsConnector.addListener;
    }

    if (wsAction) {
      this.webSocketHandlers.forEach(
        (handler: WebSocketHandler, msgType: WebsocketMessageType) =>
          wsAction(msgType, this.entityType, handler),
      );
    }

    this._isEnabled.set(isEnabled);
  };

  @action private showDialog = () => {
    if (!this.isDialogShown) {
      const config = this.getNotificationConfig() || {};

      notification.warn({
        description: "Hey there! You've been notified!",
        duration: 0,
        message: `New escalated ${this.isAlertMode ? 'alert' : 'comment'}`,
        placement: 'topRight',
        ...config,
        key: this.notificationKey,
        onClose: this.clear,
      });

      this._isDialogShown.set(true);
    }
  };

  @action private hideDialog = () => {
    if (this.isDialogShown) {
      notification.close(this.notificationKey);

      this._isDialogShown.set(false);
    }
  };

  @action private processEscalation = (
    escalationLevel: PermissionLevel,
    message: IAlert | IComment,
  ) => {
    const id = this.getId(message);
    const isL1 = escalationLevel === PermissionLevel.L1;
    const isL2 = escalationLevel === PermissionLevel.L2;

    if (this.rootStore.authStore.isUserEscalationLevel(PermissionLevel.L1)) {
      if (isL1) {
        this.addId(id);
      } else if (isL2) {
        this.deleteId(id);
      }
    } else if (
      this.rootStore.authStore.isUserEscalationLevel(PermissionLevel.L2) &&
      isL2
    ) {
      this.addId(id);
    }
  };

  @action private processModeration = (message: IAlert | IComment) => {
    const id = this.getId(message);
    this.deleteId(id);
  };

  protected getId = (message: IAlert | IComment) => {
    if (this.isCommentMode) {
      return (message as IComment).comment_id;
    }

    return message.alert_id.toString();
  };

  private getNotificationConfig = () => {
    if (this.isCommentMode) {
      return {
        description: 'At least 1 Comment was escalated',
        message: 'A Comment was escalated',
      };
    }

    return {
      description: 'At least 1 Alert was escalated',
      message: 'An Alert was escalated',
    };
  };

  public startFlow = () => {
    if (!this.isEnabled) {
      this.setIsEnabled(true);
    }
  };

  public stopFlow = () => {
    if (this.isEnabled) {
      this.setIsEnabled(false);
    }
  };
}
