import { Checkbox, Table } from 'antd';
import type { ColumnProps, PaginationConfig } from 'antd/lib/table';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { TITLE_DATE_FORMAT } from 'src/constants';
import { sortHandler } from 'src/helpers';
import { IPhrase } from 'src/restApi/phrasesService';
import {
  WORD_LANG_NORMALIZED,
  WORD_NEW_TIME,
  WORD_TYPE_NORMALIZED,
} from '../../constants';

interface IWordListProps {
  selectedWords: string[];
  onWordSelect: any;
  words: IPhrase[];
  parsedType: string;
  parsedLang: string;
  pagination?: PaginationConfig;
}

export class WordList extends React.Component<IWordListProps> {
  public pagination = {
    defaultPageSize: 100,
    showSizeChanger: false,
  };

  private isWordNew = (wordCreatedAt: number) =>
    new Date().valueOf() - new Date(wordCreatedAt * 1000).valueOf() <
    WORD_NEW_TIME;

  private getColumnsByFilter = (parsedType: string, parsedLang: string) => (
    col: ColumnProps<IPhrase>,
  ) => {
    if (col.key === 'word_type' && parsedType !== 'all') return false;

    return !(col.key === 'language' && parsedLang !== 'all');
  };

  private getColumns = (): ColumnProps<IPhrase>[] => {
    const { parsedType, parsedLang } = this.props;

    const columns: ColumnProps<IPhrase>[] = [
      {
        className: 'tableWordCol',
        dataIndex: 'phrase' as 'phrase',
        key: 'phrase',
        render: (phrase: string, word, index) => (
          <div
            className={cx('tableWordsWord', {
              tableWordsNewWordIndicator: this.isWordNew(word.created_at),
            })}
          >
            <Checkbox
              checked={Boolean(this.props.selectedWords.includes(word.id))}
              onChange={this.props.onWordSelect(word, index)}
            >
              {phrase}
            </Checkbox>
          </div>
        ),
        sorter: (a: IPhrase, b: IPhrase) =>
          // undefined, so antd sorts by it self
          sortHandler((c) => c.phrase)(a, b),
        title: 'Word',
      },
      {
        dataIndex: 'word_type',
        key: 'word_type',
        render: (wordType: string) => (
          <div
            className={cx(
              'tableWordsCategory',
              `tableWordsCategory_${wordType}`,
            )}
          >
            {WORD_TYPE_NORMALIZED[wordType]}
          </div>
        ),
        sorter: (a: IPhrase, b: IPhrase) =>
          sortHandler((c) => c.word_type)(a, b),
        title: 'Words type',
      },
      {
        dataIndex: 'language',
        key: 'language',
        render: (language: string) => WORD_LANG_NORMALIZED[language],
        sorter: (a: IPhrase, b: IPhrase) =>
          sortHandler((c) => c.language)(a, b),
        title: 'Language',
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        render: (timestamp: number) => (
          <div title={moment(timestamp * 1000).format(TITLE_DATE_FORMAT)}>
            {moment(timestamp * 1000).format('MMM DD, YYYY')}
          </div>
        ),
        sorter: (a: IPhrase, b: IPhrase) =>
          sortHandler((c) => new Date(c.created_at).valueOf())(a, b),
        title: 'Added date',
      },
    ];

    return columns.filter(this.getColumnsByFilter(parsedType, parsedLang));
  };

  public render() {
    return (
      <Table
        className="tableContent"
        dataSource={this.props.words}
        columns={this.getColumns()}
        pagination={{ ...this.pagination, ...this.props.pagination }}
        rowKey="id"
      />
    );
  }
}
