import { Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { isOdd } from 'src/helpers';
import { PermissionLevel } from 'src/restApi/interfaces';
import { useStores } from 'src/stores';
import { PermissionUserItem } from '../PermissionUserItem';
import './PermissionUserList.less';

enum DialogDesision {
  Cancel,
  Delete,
}

export const PermissionUserList = observer(() => {
  const { permissionStore } = useStores();

  const showConfirmationDialog = async (email: string) => {
    return new Promise((resolve) => {
      Modal.confirm({
        cancelText: 'Cancel',
        centered: true,
        content: `User "${email}" and it's permissions will be removed from the system.`,
        okText: 'Remove',
        okType: 'danger',
        onCancel() {
          resolve(DialogDesision.Cancel);
        },
        onOk() {
          resolve(DialogDesision.Delete);
        },
        title: 'Are you sure you want to remove this user?',
      });
    });
  };

  const handleDeleteUser = async (email: string) => {
    const dialogDecision = await showConfirmationDialog(email);

    if (dialogDecision === DialogDesision.Delete) {
      // TODO: Replace type casting after TS will have possibility to specify final value type for generators
      return (await permissionStore.deleteUserFlow(email)) as boolean;
    }

    return false;
  };

  const handleSaveChanges = async (email: string, scope: PermissionLevel[]) => {
    if (!scope.length) {
      const deleteDecision = await showConfirmationDialog(email);

      if (deleteDecision === DialogDesision.Cancel) {
        return false;
      }
    }

    // TODO: Replace type casting after TS will have possibility to specify final value type for generators
    return (await permissionStore.updateUserFlow(email, scope)) as boolean;
  };

  return (
    <div className="PermissionUsersList">
      {permissionStore.sortedUsers.map((user, idx) => (
        <PermissionUserItem
          email={user.email}
          key={`permission_user_row_${user.email}`}
          isGreyStyle={isOdd(idx)}
          onDelete={handleDeleteUser}
          onUpdate={handleSaveChanges}
          scope={user.scope}
        />
      ))}
    </div>
  );
});
