import { Flex } from 'src/components/Flex';
import styled from 'styled-components';

export const Footer = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin-left: 10px;
  }
`;
