import { action, computed, observable, toJS } from 'mobx';
import { CellMeasurerCache } from 'react-virtualized';
import { NewsId } from '../restApi/newsService';
import { InjectRootStore } from './injectRootStore';
import { RootStore } from './rootStore';

export class NewsListUIStore extends InjectRootStore {
  private width = 0;
  private readonly _scrollTop = observable.box<number | null>(null);
  private readonly _sizeCache: CellMeasurerCache;
  private readonly _newsToDelete = observable.set<NewsId>(new Set());

  constructor(rootStore: RootStore) {
    super(rootStore);

    this._sizeCache = new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
      keyMapper: this.getRowLabel,
    });
  }

  // Computed methods

  @computed public get newsToDelete(): NewsId[] {
    return [...toJS(this._newsToDelete)];
  }

  @computed public get scrollTop() {
    return this._scrollTop.get();
  }

  // Actions

  @action public setScrollTop = (scrollTop: number | null) => {
    this._scrollTop.set(scrollTop);
  };

  @action public setNewsToDelete = (newsIds: NewsId[], mark: boolean) => {
    newsIds.forEach((newsId) =>
      mark ? this._newsToDelete.add(newsId) : this._newsToDelete.delete(newsId),
    );
  };

  @action public hasNewsToDelete = (newsId: NewsId) => {
    return this._newsToDelete.has(newsId);
  };

  @action public resetNewsToDelete = () => {
    this._newsToDelete.clear();
  };

  // Class methods

  public getRowLabel = (rowIndex: number) => {
    return this.rootStore.newsListStoreV2.getNewsIdByIndex(rowIndex) || (this.rootStore.newsListStoreV2.hasNews ? 'loader' : 'finish');
  };

  public clearCache() {
    this._sizeCache.clearAll();
  }

  public getCache() {
    return this._sizeCache;
  }

  public clearCacheIfResized(width: number, resizeCb?: () => void) {
    if (!width || this.width === width) return;

    this.width = width;

    this.clearCache();

    resizeCb && resizeCb();
  }
}
