import css from 'classnames';
import React from 'react';
import s from './style.module.css';

class PaginationController extends React.Component<
  PaginationControllerProps,
  PaginationControllerStates
> {
  public static defaulProps: Partial<PaginationControllerProps> = {
    pagesPerChunk: 5,
    pageCount: 0,
    currentPage: 1,
    active: false,
  };

  public state = {
    pageChunks: this.getPageChunks(this.props.pageCount),
    currentPagesChunk: 0,
  };

  public componentWillReceiveProps(nextProps: PaginationControllerProps) {
    nextProps.pageCount !== this.props.pageCount &&
      this.updatePageCount(nextProps.pageCount);
  }

  public getPageChunks(pageCount: number = 0): number[][] {
    const { pagesPerChunk } = this.props;

    // Fill an array of page numbers splitted into chunks
    const res = [];
    let tmpArr = [];

    for (let i = 0; i < pageCount; i += 1) {
      const index = i + 1;
      tmpArr.push(index);

      if (index % pagesPerChunk === 0) {
        res.push(tmpArr);
        tmpArr = [];
      }
    }

    if (tmpArr.length) {
      res.push(tmpArr);
    }

    return res;
  }

  public updatePageCount = (pageCount: number = 0) =>
    this.setState(
      {
        pageChunks: this.getPageChunks(pageCount),
        currentPagesChunk: 0,
      },
      () => {
        if (this.props.currentPage > this.props.pageCount) {
          this.setCurrentPage(this.props.pageCount || 1);
        }
      },
    );

  public setCurrentPage = (pageNumber: number = 0) =>
    this.props.onChangePage && this.props.onChangePage(pageNumber);

  public getPageList = () =>
    this.state.pageChunks.length
      ? this.state.pageChunks[this.state.currentPagesChunk].map(
          (pageNumber) => {
            return this.getPageButton(pageNumber, () =>
              this.setCurrentPage(pageNumber),
            );
          },
        )
      : [];

  public showNextPagesChunk = () => {
    const { pageChunks, currentPagesChunk } = this.state;
    const newValue =
      currentPagesChunk + 1 < pageChunks.length
        ? currentPagesChunk + 1
        : currentPagesChunk;
    const firstPageOfNewChunk = pageChunks[newValue][0];

    this.setCurrenPagesChunk(newValue, firstPageOfNewChunk);
  };

  public showPreviousPagesChunk = () => {
    const { pageChunks, currentPagesChunk } = this.state;
    const newValue =
      currentPagesChunk > 0 ? currentPagesChunk - 1 : currentPagesChunk;
    const firstPageOfNewChunk = pageChunks[newValue][0];

    this.setCurrenPagesChunk(newValue, firstPageOfNewChunk);
  };

  public setLastPageActive = () => {
    const { pageChunks } = this.state;
    const chunkIndex = pageChunks.length - 1;
    const pageNumber = this.props.pageCount;

    this.setCurrenPagesChunk(chunkIndex, pageNumber);
  };

  public setFirstPageActive = () => {
    const chunkIndex = 0;
    const pageNumber = 1;

    this.setCurrenPagesChunk(chunkIndex, pageNumber);
  };

  public setCurrenPagesChunk = (chunkIndex: number, chunkPageNumber: number) =>
    this.setState({ currentPagesChunk: chunkIndex }, () =>
      this.setCurrentPage(chunkPageNumber),
    );

  public getPageButton = (pageNumber: number, action: Function) => (
    <div
      key={`pagination_link_page_${pageNumber}`}
      className={css(
        s.defaultButton,
        s.pageButton,
        s.defaultButtonText,
        s.defaultButtonSize,
        { [s.activeButton]: pageNumber === this.props.currentPage },
      )}
      onClick={() => action()}
    >
      {pageNumber}
    </div>
  );

  public renderDevider = () => (
    <div
      className={css(
        s.defaultButtonSize,
        s.pageChunksDevider,
        s.defaultButtonText,
      )}
    >
      ...
    </div>
  );

  public render() {
    if (!this.props.active) return null;

    const { pageChunks, currentPagesChunk } = this.state;
    const isFirstChunk = currentPagesChunk === 0;
    const isLastChunk = pageChunks.length === currentPagesChunk + 1;
    const hasHidedChunks = this.props.pageCount > this.props.pagesPerChunk + 1;
    const isPreviousButtonDisabled = !hasHidedChunks || isFirstChunk;
    const isNextButtonDisabled = !hasHidedChunks || isLastChunk;
    const showChunksDevider = hasHidedChunks && !isLastChunk;
    const pageList = this.getPageList();
    const showLastPage = !isLastChunk && this.props.pageCount > 1;
    const showFirstPage = !isFirstChunk && this.props.pageCount > 1;

    return (
      <div className={css(s.container, this.props.wrapperClassName)}>
        <div
          className={css(s.defaultButton, s.defaultButtonSize, 'left-row', {
            [s.disabledButton]: isPreviousButtonDisabled,
          })}
          onClick={() =>
            !isPreviousButtonDisabled && this.showPreviousPagesChunk()
          }
        >
          <div
            className={css(s.icon, s.previousIcon, {
              [s.disabledIcon]: isPreviousButtonDisabled,
            })}
          />
        </div>

        {showFirstPage && this.getPageButton(1, this.setFirstPageActive)}
        {showFirstPage && this.renderDevider()}
        {pageList}
        {showChunksDevider && this.renderDevider()}
        {showLastPage &&
          this.getPageButton(this.props.pageCount, this.setLastPageActive)}
        <div
          className={css(s.defaultButton, s.defaultButtonSize, 'right-row', {
            [s.disabledButton]: isNextButtonDisabled,
          })}
          onClick={() => !isNextButtonDisabled && this.showNextPagesChunk()}
        >
          <div
            className={css(s.icon, s.nextIcon, {
              [s.disabledIcon]: isNextButtonDisabled,
            })}
          />
        </div>
      </div>
    );
  }
}

interface PaginationControllerProps {
  wrapperClassName?: string;
  pagesPerChunk: number;
  currentPage: number;
  pageCount: number;
  active?: boolean;
  onChangePage(pageNumber: number);
}

interface PaginationControllerStates {
  currentPagesChunk: number;
  pageChunks: number[][];
}

export { PaginationController };
