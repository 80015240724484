import css from 'classnames';
import React from 'react';
import { reasonsToText } from 'src/helpers/alertHelpers';
import { ModerationStatus } from '../../restApi/interfaces';
import s from './style.module.css';

export class Label extends React.Component<ILabelProps> {
  public static defaultProps: Partial<ILabelProps> = {
    escalationLevel: 1,
    inline: true,
  };

  public textByType = {
    police: () => 'POLICE',
    onRsu: () => 'RSU',
    isRsu: () => 'RING',
    flagged: () => 'F',
    deleted: () => 'Deleted',
    admin: () => 'Admin',
    curation: () => 'Curation',
    escalated: () => `L${this.props.escalationLevel}`,
    resolved: () => 'RESOLVED',
    crime: () => 'CRIME',
  };

  public styleByType = {
    police: () => s.policeLabel,
    flagged: () => s.flaggedLabel,
    isRsu: () => s.isRsuLabel,
    onRsu: () => s.onRsuLabel,
    deleted: () => s.deletedLabel,
    admin: () => s.adminLabel,
    curation: () => s.curationLabel,
    escalated: () => s[`escalated${this.props.escalationLevel}Label`],
    resolved: () => s.resolvedLabel,
    crime: () => s.crimeLabel,
  };

  public styleByStatus = {
    [ModerationStatus.New]: s.newLabel,
    [ModerationStatus.Approved]: s.approveLabel,
    [ModerationStatus.Declined]: s.declineLabel,
    [ModerationStatus.AutoApproved]: s.autoApprovedLabel,
    [ModerationStatus.AutoDeclined]: s.autoDeclinedLabel,
    [ModerationStatus.Assigned]: s.assignedLabel,
  };

  public getTextByStatus = {
    [ModerationStatus.New]: () => 'New',
    [ModerationStatus.Approved]: () => this.getReasonText() || 'Approved',
    [ModerationStatus.Declined]: () => this.getReasonText() || 'Declined',
    [ModerationStatus.AutoApproved]: () => 'Auto Approved',
    [ModerationStatus.AutoDeclined]: () => 'Auto Declined',
    [ModerationStatus.Assigned]: () => 'Assigned',
  };

  public getReasonText() {
    const { reason } = this.props;
    return reason && reasonsToText(reason);
  }

  public render() {
    const { className, status, type, compacted, inline } = this.props;
    const labelTitleByStatus =
      (status || status === 0) && this.getTextByStatus[status]();
    const labelTitleByType = type ? this.textByType[type]() : '';
    const labelStyleByType = type ? this.styleByType[type]() : '';

    return (
      <div
        className={css(
          s.labelContainer,
          (status || status === 0) && this.styleByStatus[status],
          compacted && s.compacted,
          labelStyleByType,
          className,
          { [s.inline]: inline },
        )}
      >
        {labelTitleByStatus}
        {labelTitleByType}
      </div>
    );
  }
}

export interface ILabelProps {
  inline?: boolean;
  status?: number;
  reason?: string[];
  compacted?: boolean;
  type?:
    | 'police'
    | 'escalated'
    | 'isRsu'
    | 'flagged'
    | 'onRsu'
    | 'deleted'
    | 'admin'
    | 'curation'
    | 'resolved'
    | 'crime';
  escalationLevel?: number;
  className?: string;
}
