import css from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { commentsActions, commentsTabActions } from 'src/actions';
import {
  AlertContainer,
  IAlertContainerProps,
} from 'src/components/alert-container';
import { AlertModerateModal } from 'src/components/alert-moderate-modal';
import { PageWrapper } from 'src/components/page-wrapper';
import { PaginationController } from 'src/components/pagination-controller';
import * as paginationHelper from 'src/helpers/paginationHelper';
import { IExpandedComments } from 'src/interfaces';
import { AlertsListStore, AuthStore, CommentsStore, UiStore } from 'src/stores';
import { toggleCommentHistory } from '../../actions/commentsActions';
import { Loader } from '../../components/Loader';
import {
  CommentsAssignFlags,
  NavigationTabs,
  tabsStores,
} from '../../constants';
import { checkIsCommentEscalated } from '../../helpers/commentsHelper';
import {
  IComment,
  IStoredAlert,
  ModerationStatus,
} from '../../restApi/interfaces';
import styles from './style.module.css';
import { ModerationLayout } from '../../componentsNew/Moderation/ModerationLayout';
import {
  ModerationControlBlock,
  ModerationControlBlockMode,
} from '../../componentsNew/Moderation/ModerationControlBlock';
import { ModerationInfoBlock } from 'src/componentsNew/Moderation/ModerationInfoBlock';
import { SessionStoppageReason } from '../../restApi/commentService';
import { ModerationTimeoutDialog } from '../../componentsNew/Moderation/ModerationTimeoutDialog';
import { auto_assign_enabled } from '../../helpers';
import { ModerationStatisticComment } from '../../componentsNew/Moderation/ModerationStatisticComment';
import { comments_stats_enabled } from '../../helpers';

export interface ICommentsProps {
  uiStore?: UiStore;
  alertsListStore?: AlertsListStore;
  authStore?: AuthStore;
  commentsStore?: CommentsStore;
}

interface ICommentsState {
  currentPage: number;
  scrollToId: number | null;
  expandedComments: IExpandedComments;
  loading: boolean;
}
const threadRefreshInterval = 3000;
@inject('alertsListStore', 'uiStore', 'authStore', 'commentsStore')
@observer
export class CommentsPage extends React.Component<
  ICommentsProps,
  ICommentsState
> {
  private readonly itemsPerPage = 10;
  private readonly tab = tabsStores.COMMENTS;
  private intervalRefreshThreadsID: any;

  public state = {
    currentPage: 1,
    expandedComments: {},
    loading: false,
    scrollToId: null,
  };

  private ref: React.RefObject<HTMLDivElement> | null = null;

  private static getTimeRecievedForModeration = (
    acc: number,
    comment: IComment,
  ): number => {
    if (
      comment.is_l1 ||
      comment.is_l2 ||
      comment.status !== ModerationStatus.Assigned
    ) {
      return acc;
    }

    if (acc === 0) {
      return comment.time_received_for_moderation;
    }

    return comment.time_received_for_moderation < acc
      ? comment.time_received_for_moderation
      : acc;
  };

  private static sortAlertsByTimeRecievedForModeration = (
    a: IStoredAlert,
    b: IStoredAlert,
  ): number => {
    const commentTime1: number = a.comments.reduce(
      CommentsPage.getTimeRecievedForModeration,
      0,
    );
    const commentTime2: number = b.comments.reduce(
      CommentsPage.getTimeRecievedForModeration,
      0,
    );
    return commentTime1 - commentTime2;
  };

  private handleStartModeration = async () => {
    const { uiStore, alertsListStore, commentsStore } = this.props;
    alertsListStore.removeAllAlertWithComments(this.tab);
    this.props.alertsListStore.commentsTab = [];

    const setAlertsInTab = alertsListStore.setAlerts(this.tab);
    try {
      await commentsStore.getModerationSessionStatusFlow();
      if (!commentsStore.getModerationStatus()) {
        await commentsStore.startModerationSessionFlow();
      }
      const alertsWithComments =
        await commentsTabActions.fetchAlertsWithComments();
      alertsWithComments.sort(
        CommentsPage.sortAlertsByTimeRecievedForModeration,
      );
      setAlertsInTab(alertsWithComments);
      uiStore.startTimer();
      if (commentsActions.fetchAlertsLock.isLocked()) {
        commentsActions.fetchAlertsLock.release();
      }
      await this.InitIntervalRefreshThreads();
    } catch (e) {
      console.error(e);

      setAlertsInTab([]);
    }
  };

  private handleStopModeration = async () => {
    const { commentsStore, alertsListStore } = this.props;
    commentsStore.setModerationSessionLoading(true);
    commentsStore.setModerationStatus(false);
    await commentsActions.fetchAlertsLock.acquire();
    this.StopIntervalRefresh();
    alertsListStore.removeAllAlertWithComments(this.tab);
    this.props.alertsListStore.commentsTab = [];
    commentsStore.setModerationSessionLoading(false);
    return commentsStore.stopModerationSessionFlow(
      SessionStoppageReason.Manual,
    );
  };

  private handleTimeoutDialog = () => {
    const { commentsStore } = this.props;
    return commentsStore.continueModerationFlow();
  };

  public async componentDidMount() {
    const { uiStore, alertsListStore, commentsStore } = this.props;
    alertsListStore.removeAllAlertWithComments(this.tab);
    this.props.alertsListStore.commentsTab = [];
    await commentsActions.fetchAlertsLock.acquire();

    const setAlertsInTab = alertsListStore.setAlerts(this.tab);
    uiStore.removeTabNotification(NavigationTabs.COMMENTS.path);
    this.setLoaderVisible(true);
    let haveToGetAlertsWithComments: boolean;
    try {
      await commentsStore.startFlow();
      haveToGetAlertsWithComments = commentsStore.getModerationStatus();

      if (haveToGetAlertsWithComments) {
        await commentsActions.fetchAlertsLock.release();
        const alertsWithComments =
          await commentsTabActions.fetchAlertsWithComments();
        setAlertsInTab(alertsWithComments);
        await this.InitIntervalRefreshThreads();
      }

      this.setLoaderVisible(false);
      uiStore.startTimer();
    } catch (e) {
      console.error(e);
    }

    this.setLoaderVisible(false);
  }

  public async componentWillUnmount() {
    const { uiStore, commentsStore } = this.props;
    this.StopIntervalRefresh();
    commentsActions.fetchAlertsLock.release();

    commentsTabActions.clearTabStore();
    await commentsStore.stopFlow();
    uiStore.removeTabNotification(NavigationTabs.COMMENTS.path);
    uiStore.stopTimer();
  }

  private setLoaderVisible = (visible: boolean) => {
    const { uiStore } = this.props;

    uiStore.setPageLoading(visible);
  };

  private loaderVisible = () => this.props.uiStore.pageLoading;

  private noItemsMessage = () => {
    const { commentsStore } = this.props;
    const mode = commentsStore.moderationStatus
      ? ModerationControlBlockMode.Stop
      : ModerationControlBlockMode.Start;

    return (
      <ModerationLayout
        controls={
          <>
            <ModerationControlBlock
              loading={commentsStore.isModerationSessionProcessing}
              mode={mode}
              onStopModeration={this.handleStopModeration}
              onStartModeration={this.handleStartModeration}
            />
            {comments_stats_enabled && (
              <ModerationStatisticComment
                loading={commentsStore.isModerationStatisticLoading}
                statistic={commentsStore.moderationStatistic}
              />
            )}
          </>
        }
        main={
          !commentsStore.moderationStatus ? (
            <ModerationInfoBlock
              hint="Press Start to begin your Moderation Time clock and receive comments for moderation."
              title="Welcome!"
            />
          ) : (
            <>
              <ModerationInfoBlock
                hint="We will notify you as soon as it appears"
                title="No content for moderation"
              />
              <ModerationTimeoutDialog
                onOk={this.handleTimeoutDialog}
                timeout={commentsStore.timeout}
              />
            </>
          )
        }
      />
    );
  };

  private onCommentAssign = (alertId: number) => {
    const { commentsStore } = this.props;
    commentsStore.resetActiveModerationTimeout(); // reset timeout since action was taken
    this.setState({ scrollToId: alertId });
  };

  private isNewOrAssignedComment = (comment: IComment) => {
    return (
      !comment.is_deleted_by_user &&
      [ModerationStatus.New, ModerationStatus.Assigned].includes(comment.status)
    );
  };

  private isAssignedOnCurrentUser = (comment: IComment) => {
    const { authStore } = this.props;
    return comment.email === authStore.user.email;
  };

  private isAssigned = (comment: IComment) => comment.email !== null;

  private async InitIntervalRefreshThreads() {
    if (!auto_assign_enabled) {
      return;
    }

    this.intervalRefreshThreadsID = setInterval(async () => {
      const { commentsStore } = this.props;
      if (commentsStore.getModerationStatus()) {
        await commentsActions.refreshAssignedThreads();
        return;
      }
    }, threadRefreshInterval);
  }

  private StopIntervalRefresh() {
    if (!auto_assign_enabled) {
      return;
    }
    clearInterval(this.intervalRefreshThreadsID);
  }

  public async componentDidUpdate(
    prevProps: ICommentsProps,
    prevState: ICommentsState,
  ) {
    const { alertsListStore } = this.props;

    let changed = false;
    const { expandedComments } = prevState;

    Object.keys(expandedComments).forEach((alertId) => {
      if (
        -1 ===
        alertsListStore.commentsTab.findIndex(
          (alert) => alert.alert_id === Number(alertId),
        )
      ) {
        changed = true;
        delete expandedComments[alertId];
      }
    });

    if (changed) {
      this.setState({ expandedComments });
    }

    if (this.ref) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.ref = null;
      this.setState({ scrollToId: null });
    }
  }

  private onThreadExpanded = (alert: IStoredAlert, value?: boolean) => {
    this.setState((prevState) => ({
      expandedComments: {
        ...prevState.expandedComments,
        [alert.alert_id]:
          value !== undefined
            ? value
            : !prevState.expandedComments[alert.alert_id],
      },
    }));
  };

  private onToggleCommentHistory = async (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => {
    return toggleCommentHistory(this.tab, alertId, commentId, isShown);
  };

  private renderAlert = (
    alert: IStoredAlert,
    options: Partial<IAlertContainerProps | { key: string | number }>,
  ): React.ReactElement<AlertContainer> => (
    <AlertContainer
      alert={alert}
      onAssign={this.onCommentAssign}
      onThreadExpanded={this.onThreadExpanded}
      expandedComments={this.state.expandedComments}
      hideNoVisualContentText
      canEdited
      minimized
      canAlertModerated={false}
      tabStore={this.tab}
      commentsTabFlag={CommentsAssignFlags.DEFAULT}
      {...options}
    />
  );

  private renderUserAlert = (
    alert: IStoredAlert,
  ): React.ReactElement<AlertContainer> => {
    let ref: React.RefObject<HTMLDivElement> | undefined;

    if (alert.alert_id === this.state.scrollToId) {
      ref = React.createRef();
      this.ref = ref;
    }

    return this.renderAlert(alert, {
      childRef: ref,
      key: alert.alert_id,
      onToggleCommentHistory: this.onToggleCommentHistory,
    });
  };

  private static getCommentTime = (acc: number, val: IComment): number => {
    if (val.status !== 0) {
      return acc;
    }

    if (acc === 0) {
      return val.ring_created_at;
    }

    return val.ring_created_at < acc ? val.ring_created_at : acc;
  };

  private static sortByCommentTime = (
    a: IStoredAlert,
    b: IStoredAlert,
  ): number => {
    const commentTime1: number = a.comments.reduce(
      CommentsPage.getCommentTime,
      0,
    );
    const commentTime2: number = b.comments.reduce(
      CommentsPage.getCommentTime,
      0,
    );
    return commentTime1 - commentTime2;
  };

  public render() {
    const { uiStore } = this.props;
    const rawAlerts = this.props.alertsListStore.commentsTab ?? [];

    const alerts = [] as IStoredAlert[];
    const userAlerts = [] as IStoredAlert[];

    rawAlerts.forEach((alert: IStoredAlert) => {
      const comments = alert.comments ?? [];

      const basicComments = comments.filter(
        (comment) => !checkIsCommentEscalated(comment),
      );

      const newComments = basicComments.filter(
        this.isNewOrAssignedComment,
      ) as IComment[];

      const hasAssignedOnCurrentUser = newComments.some(
        this.isAssignedOnCurrentUser,
      );

      const allAssignedOnSomebody = newComments.every(this.isAssigned);

      if (hasAssignedOnCurrentUser) {
        userAlerts.push(alert);
      } else if (newComments.length && !allAssignedOnSomebody) {
        alerts.push(alert);
      }
    });

    let isAlertsInList = Boolean(userAlerts.length || alerts.length);

    const pages = paginationHelper.pagesCount(this.itemsPerPage, alerts.length);

    const alertByPage = paginationHelper.getItemsByPage(
      alerts.sort(CommentsPage.sortByCommentTime),
      this.state.currentPage,
      this.itemsPerPage,
    ) as IStoredAlert[];

    if (this.loaderVisible()) {
      return (
        <PageWrapper>
          <Loader data-test-id="loader" />
        </PageWrapper>
      );
    }

    if (!isAlertsInList) {
      return this.noItemsMessage();
    }
    if (auto_assign_enabled) {
      isAlertsInList = false;
    }

    const { commentsStore } = this.props;
    const mode = commentsStore.moderationStatus
      ? ModerationControlBlockMode.Stop
      : ModerationControlBlockMode.Start;

    return (
      <ModerationLayout
        controls={
          <>
            <ModerationControlBlock
              loading={commentsStore.isModerationSessionProcessing}
              mode={mode}
              onStopModeration={this.handleStopModeration}
              onStartModeration={this.handleStartModeration}
            />
            {comments_stats_enabled && (
              <ModerationStatisticComment
                loading={commentsStore.isModerationStatisticLoading}
                statistic={commentsStore.moderationStatistic}
              />
            )}
          </>
        }
        main={
          // Moderation has not started
          !commentsStore.moderationStatus ? (
            <ModerationInfoBlock
              hint="Press Start to begin your Moderation Time clock and receive comments for moderation."
              title="Welcome!"
            />
          ) : (
            <PageWrapper>
              {
                <ModerationTimeoutDialog
                  onOk={this.handleTimeoutDialog}
                  timeout={commentsStore.timeout}
                />
              }
              <div className={css(styles.container, 'top-column-double')}>
                <div
                  className={css(styles.alertsItemsContainer)}
                  data-test-id="assignedCommentsContainer"
                >
                  {userAlerts.map(this.renderUserAlert)}
                </div>
                <div
                  className={css(styles.alertsItemsContainer)}
                  data-test-id="unassignedCommentsContainer"
                >
                  {alertByPage.map((alert) =>
                    this.renderAlert(alert, { key: alert.alert_id }),
                  )}
                </div>
                <PaginationController
                  wrapperClassName={css('column-double', styles.pagination)}
                  active={isAlertsInList}
                  pagesPerChunk={5}
                  pageCount={pages}
                  currentPage={this.state.currentPage}
                  onChangePage={(pageNumber) =>
                    this.setState({ currentPage: pageNumber })
                  }
                />
                <AlertModerateModal
                  className={css(styles.alertCommentsModal)}
                  show={uiStore.isModalShow}
                />
              </div>
            </PageWrapper>
          )
        }
      />
    );
  }
}
