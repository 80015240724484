class LeaveModerationConfirmation {
  private readonly leaveHandlers = new Set<Function>();

  private handleBrowserLeaveDialog = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  private handleBrowserLeave = () => {
    if (this.leaveHandlers.size) {
      this.leaveHandlers.forEach((leaveHandler: Function) => leaveHandler());
    }
  };

  public enableConfirmation = (leaveHandler: Function) => {
    this.leaveHandlers.add(leaveHandler);
    window.addEventListener('beforeunload', this.handleBrowserLeaveDialog);
    window.addEventListener('unload', this.handleBrowserLeave);
  };

  public disableConfirmation = (leaveHandler: Function) => {
    window.removeEventListener('beforeunload', this.handleBrowserLeaveDialog);
    window.removeEventListener('unload', this.handleBrowserLeave);
    this.leaveHandlers.delete(leaveHandler);
  };
}

export const leaveModerationConfirmation = new LeaveModerationConfirmation();
