import { Button, Modal } from 'antd';
import React from 'react';
import { Timer } from 'src/components/Timer';
import {
  AlertModerationDialog,
  IAlertModerationDialogProps,
  useAlertModeration,
  UseAlertModerationProps,
} from 'src/componentsNew/AlertModerationDialog';
import { ModerationTimeoutDialog } from 'src/componentsNew/Moderation/ModerationTimeoutDialog';
import { getAlertCreatedAt } from 'src/helpers';
import {
  IAlert,
  IAlertCategories,
  IAlertHistory,
  IAlertMeta,
} from 'src/restApi/alertsService';
import { AlertCard } from '../AlertCard';
import './AlertModerationCard.less';

interface AlertModerationCardProps
  extends Pick<IAlertModerationDialogProps, 'isProcessing'>,
    Pick<UseAlertModerationProps, 'onDecisionMade'> {
  alert: IAlert;
  alertMeta: IAlertMeta;
  history: IAlertHistory[] | null;
  categories: IAlertCategories;
  onHistoryToggle: (alertId: number, visible: boolean) => Promise<void>;
  onTimeoutDialogClick: () => Promise<boolean>;
  canMissSLA: boolean;
  timeUntilMissedSLA?: number;
}

export const AlertModerationCard = ({
  alert,
  alertMeta,
  canMissSLA,
  categories,
  history,
  isProcessing,
  onDecisionMade,
  onHistoryToggle,
  onTimeoutDialogClick,
  timeUntilMissedSLA,
}: AlertModerationCardProps) => {
  const {
    decisionType,
    isAlertModerationModalVisible,
    isEscalateL1ModalVisible,
    isEscalateL2ModalVisible,
    canEscalate,
    handleCancelClick,
    handleApproveClick,
    handleDeclineClick,
    handleEscalateClick,
    handleSendToRSUClick,
    handleDecisionSubmit,
    handleEscalateL1Submit,
    handleEscalateL2ApproveClick,
    handleEscalateL2DeclineClick,
  } = useAlertModeration({ alert, onDecisionMade });

  return (
    <>
      <AlertCard
        alert={alert}
        canEscalate={canEscalate}
        history={history}
        info={
          !canMissSLA || !timeUntilMissedSLA ? undefined : (
            <Timer
              createdTime={getAlertCreatedAt(alert).valueOf()}
              markersCount={5}
              maxDuration={timeUntilMissedSLA}
              showTime
              wrapperClass="AlertModerationCard__timer"
              wrapperTimeClass="AlertModerationCard__timerTime"
            />
          )
        }
        onApproveClick={handleApproveClick}
        onDeclineClick={handleDeclineClick}
        onEscalateClick={handleEscalateClick}
        onHistoryToggle={onHistoryToggle}
        onSendToRsuClick={handleSendToRSUClick}
      />

      <ModerationTimeoutDialog
        onOk={onTimeoutDialogClick}
        timeout={alertMeta.reserved_timeout}
      />

      <AlertModerationDialog
        agencyName={alert.user_name}
        nhadminAlertType={alert.nhadmin_alert_type}
        isPolice={alert.cop}
        decisionType={decisionType}
        categories={categories}
        isProcessing={isProcessing}
        onCancel={handleCancelClick}
        onOk={handleDecisionSubmit}
        visible={isAlertModerationModalVisible}
      />

      <Modal
        cancelButtonProps={{ disabled: isProcessing }}
        closable={false}
        confirmLoading={isProcessing}
        maskClosable={!isProcessing}
        okText="Yes, escalate this video"
        onCancel={handleCancelClick}
        onOk={handleEscalateL1Submit}
        title="Escalate this video"
        visible={isEscalateL1ModalVisible}
      >
        Are you sure you want to escalate this video to Senior Operators?
      </Modal>

      <Modal
        closable={false}
        title="Escalate this video"
        visible={isEscalateL2ModalVisible}
        footer={[
          <Button key="cancel" onClick={handleCancelClick}>
            Cancel
          </Button>,
          <Button
            key="decline"
            onClick={handleEscalateL2DeclineClick}
            type="danger"
          >
            Decline
          </Button>,
          <Button
            key="approve"
            onClick={handleEscalateL2ApproveClick}
            type="primary"
          >
            Approve
          </Button>,
        ]}
      >
        This video has already been escalated. To escalate it again come up with
        category that on your opinion matches best.
      </Modal>
    </>
  );
};
