import css from 'classnames';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { rsuTabActions } from 'src/actions';
import { AlertContainer } from 'src/components/alert-container';
import { AlertModerateModal } from 'src/components/alert-moderate-modal';
import { PageWrapper } from 'src/components/page-wrapper';
import { PaginationController } from 'src/components/pagination-controller';
import { isArrayNotEmpty, sortHandler } from 'src/helpers';
import * as paginationHelper from 'src/helpers/paginationHelper';
import { IExpandedComments } from 'src/interfaces';
import { NotificationService } from 'src/services';
import { Stores } from 'src/stores';
import { toggleCommentHistory } from '../../actions/commentsActions';
import { Loader } from '../../components/Loader';
import { NoContentView } from '../../components/no-content-view';
import { VerticalAligner } from '../../components/VerticalAligner';
import { NavigationTabs, tabsStores } from '../../constants';
import { IStoredAlert } from '../../restApi/interfaces';
import styles from './style.module.css';

interface RSUStates {
  currentPage: number;
  expandedComments: IExpandedComments;
}

@inject('alertsListStore', 'uiStore')
@observer
export class RSUPage extends React.Component<Stores, RSUStates> {
  private itemsPerPage = 3;
  private tab = tabsStores.RSU;

  public state = {
    currentPage: 1,
    expandedComments: {},
  };

  public componentDidUpdate(prevProps, prevState) {
    let changed = false;
    const { expandedComments } = prevState;

    Object.keys(expandedComments).forEach((alertId) => {
      if (
        -1 ===
        this.props.alertsListStore.rsuTab.findIndex(
          (alert) => alert.alert_id === Number(alertId),
        )
      ) {
        changed = true;
        delete expandedComments[alertId];
      }
    });

    if (changed) {
      this.setState({ expandedComments });
    }
  }

  public async componentDidMount() {
    this.setLoaderVisible(true);
    NotificationService.removeNotifications(NavigationTabs.RSU.path);
    try {
      const alerts = await rsuTabActions.fetchAlertsWithComments();
      this.props.alertsListStore.setAlerts(this.tab)(alerts);
    } catch (e) {
      console.error(e);
    }
    this.setLoaderVisible(false);
  }

  public componentWillUnmount() {
    rsuTabActions.clearTabStore();
    NotificationService.removeNotifications(NavigationTabs.RSU.path);
  }

  public setLoaderVisible = (visible: boolean) =>
    this.props.uiStore.setPageLoading(visible);

  public loaderVisible = () => this.props.uiStore.pageLoading;

  public onThreadExpanded = (alert: IStoredAlert, value?: boolean) => {
    this.setState((prevState) => ({
      expandedComments: {
        ...prevState.expandedComments,
        [alert.alert_id]: value || !prevState.expandedComments[alert.alert_id],
      },
    }));
  };

  public onToggleCommentHistory = async (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => {
    return toggleCommentHistory(this.tab, alertId, commentId, isShown);
  };

  public getAlertContainer = (alert: IStoredAlert) => {
    const hasAlertComments = Boolean(alert.comments);
    const alertKey = `alert_${alert.alert_id}${
      hasAlertComments && '_comments'
    }`;

    return (
      <AlertContainer
        alert={alert}
        key={alertKey}
        canAlertModerated={false}
        canCommentModerated={false}
        onToggleCommentHistory={this.onToggleCommentHistory}
        onThreadExpanded={this.onThreadExpanded}
        expandedComments={this.state.expandedComments}
        hideNoVisualContentText={hasAlertComments}
        treatRsuAsNew
        tabStore={this.tab}
        hasRSUModeration
        removePopupAction={false}
        minimized={!alert.on_rsu}
      />
    );
  };

  private getLastChronologicalTime = (alert: IStoredAlert): number => {
    if (alert.comments && alert.comments.length) {
      const commentsOnRSU = alert.comments.filter((comment) => comment.on_rsu);
      if (commentsOnRSU.length) {
        return commentsOnRSU[commentsOnRSU.length - 1].time_created;
      }
    }
    if (alert.history && alert.history.length) {
      return alert.history[alert.history.length - 1].time_created;
    }
    return alert.time_created;
  };

  public render() {
    const alerts = this.props.alertsListStore.rsuTab;
    const isAlertsInList = isArrayNotEmpty(alerts);
    const pages = paginationHelper.pagesCount(
      this.itemsPerPage,
      alerts.length || 0,
    );
    const preparedAlerts = toJS(alerts).sort(
      sortHandler(this.getLastChronologicalTime),
    );
    const alertsByPage = paginationHelper.getItemsByPage(
      preparedAlerts,
      this.state.currentPage,
      this.itemsPerPage,
    );

    if (this.loaderVisible()) {
      return (
        <PageWrapper>
          <Loader />
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        {!isAlertsInList && (
          <VerticalAligner>
            <NoContentView />
          </VerticalAligner>
        )}
        <div className={css(styles.container, 'top-column-double')}>
          <div className={css(styles.alertsItemsContainer)}>
            {isAlertsInList &&
              alertsByPage.map((alert) => this.getAlertContainer(alert))}
          </div>
          <PaginationController
            wrapperClassName={css('column-double', styles.pagination)}
            active={isAlertsInList}
            pagesPerChunk={5}
            pageCount={pages}
            currentPage={this.state.currentPage}
            onChangePage={(pageNumber) =>
              this.setState({ currentPage: pageNumber })
            }
          />
          <AlertModerateModal show={this.props.uiStore.isModalShow} />
        </div>
      </PageWrapper>
    );
  }
}
