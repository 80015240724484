import cx from 'classnames';
import React from 'react';
import { IFlagBadgeProps } from 'src/componentsNew/Badge/components/FlagBadge';
import { BadgeColors, FlagBadge } from '../../../../Badge';

export enum ModerationFlag {
  Deleted = 'deleted',
  DeletedByUser = 'deletedByUser',
  DeletedByAdmin = 'deletedByAdmin',
  Flagged = 'flagged',
  FromRsu = 'fromRsu',
  L1 = 'l1',
  L2 = 'l2',
  MissedSla = 'missedSla',
  Police = 'police',
  Rsu = 'rsu',
  PublicAssistance = 'publicAssistance',
}

export interface IAlertStateBadgeProps {
  className?: string;
  flag: ModerationFlag;
  flagReason?: string;
}

const flags: Record<ModerationFlag, IFlagBadgeProps> = {
  [ModerationFlag.Deleted]: { color: BadgeColors.Red, text: 'DELETED' },
  [ModerationFlag.DeletedByUser]: {
    color: BadgeColors.Red,
    text: 'DELETED BY USER',
  },
  [ModerationFlag.DeletedByAdmin]: {
    color: BadgeColors.Red,
    text: 'DELETED BY ADMIN',
  },
  [ModerationFlag.Flagged]: { color: BadgeColors.Red, text: 'FLAGGED' },
  [ModerationFlag.FromRsu]: { color: BadgeColors.Orange, text: 'ROC' },
  [ModerationFlag.L1]: { color: BadgeColors.Purple, text: 'L1' },
  [ModerationFlag.L2]: { color: BadgeColors.Purple, text: 'L2' },
  [ModerationFlag.MissedSla]: {
    borderOnly: true,
    color: BadgeColors.Red,
    text: 'MISSED SLA',
  },
  [ModerationFlag.Police]: { text: 'PUBLIC SAFETY USER' },
  [ModerationFlag.Rsu]: { color: BadgeColors.Purple, text: 'RSU' },
  [ModerationFlag.PublicAssistance]: {
    color: BadgeColors.Purple,
    text: 'REQUEST FOR ASSISTANCE',
  },
  [ModerationFlag.MissedSla]: {
    color: BadgeColors.Red,
    text: 'MISSED SLA',
    borderOnly: true,
  },
};

export const ModerationFlagBadge = ({
  className,
  flag,
  flagReason,
}: IAlertStateBadgeProps) => {
  if (!flag) return null;

  const targetProps = flags[flag];

  const badgeProps = {
    ...targetProps,
    text:
      flag === ModerationFlag.Flagged && flagReason
        ? `${targetProps.text}: ${flagReason.toUpperCase()}`
        : targetProps.text,
  };

  return (
    <FlagBadge
      {...badgeProps}
      className={cx('ModerationFlagBadge', className)}
    />
  );
};
