import classNames from 'classnames';
import React from 'react';
import './BlockLayout.less';

interface IAlignerProps {
  center?: React.ReactNode;
  centerClassName?: string;
  className?: string;
  left?: React.ReactNode;
  leftClassName?: string;
  right?: React.ReactNode;
  rightClassName?: string;
}

export function BlockLayout({
  center,
  centerClassName,
  className,
  left,
  leftClassName,
  right,
  rightClassName,
}: IAlignerProps) {
  return (
    <div className={classNames('BlockLayout__wrapper', className)}>
      {left !== undefined && (
        <div className={classNames('BlockLayout__left', leftClassName)}>
          {left}
        </div>
      )}
      {center !== undefined && (
        <div className={classNames('BlockLayout__center', centerClassName)}>
          {center}
        </div>
      )}
      {right !== undefined && (
        <div className={classNames('BlockLayout__right', rightClassName)}>
          {right}
        </div>
      )}
    </div>
  );
}
