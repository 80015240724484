import { Button } from 'antd';
import React from 'react';
import './ModerationControlBlock.less';

export enum ModerationControlBlockMode {
  Start = 'start',
  Stop = 'stop',
}

interface ModerationControlBlockProps {
  mode: ModerationControlBlockMode;
  onStopModeration: () => void;
  onStartModeration: () => void;
  loading?: boolean;
}

export const ModerationControlBlock = ({
  loading,
  mode,
  onStopModeration,
  onStartModeration,
}: ModerationControlBlockProps) => {
  const { description, buttonText, buttonType } = map[mode];

  const handleClick =
    mode === ModerationControlBlockMode.Start
      ? onStartModeration
      : onStopModeration;

  const testID = `moderation${mode}`;

  return (
    <div className="ModerationControlBlock">
      <div className="ModerationControlBlock__moderationButtonBlock">
        <Button
          type={buttonType}
          disabled={loading}
          loading={loading}
          onClick={handleClick}
          size="large"
          data-test-id={testID}
        >
          {buttonText}
        </Button>
        <div className="ModerationControlBlock__moderationHint">
          {description}
        </div>
      </div>
    </div>
  );
};

const map = {
  [ModerationControlBlockMode.Start]: {
    description: 'Press Start to begin your Moderation.',
    buttonText: 'Start Moderation',
    buttonType: 'primary',
  },
  [ModerationControlBlockMode.Stop]: {
    description: 'Press Stop to end your Moderation.',
    buttonText: 'Stop Moderation',
    buttonType: 'danger',
  },
} as const;
