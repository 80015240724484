import { Button, Checkbox, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import { difference } from 'lodash';
import React from 'react';
import { validateEmail } from 'src/helpers';
import { PermissionLevel } from 'src/restApi/interfaces';
import { permissionsList } from '../constants';
import './PermissionUserItem.less';

export enum PermissionUserMode {
  Add,
  Update,
}

interface IPermissionUserItemPropsBase {
  email?: string;
  isGreyStyle?: boolean;
  onAdd?: (email: string, scope: PermissionLevel[]) => Promise<boolean>;
  onDelete?: (email: string) => void;
  onUpdate?: (email: string, scope: PermissionLevel[]) => Promise<boolean>;
  scope?: PermissionLevel[];
}

interface IPermissionUserAddItemProps extends IPermissionUserItemPropsBase {
  mode: PermissionUserMode.Add;
  onAdd: (email: string, scope: PermissionLevel[]) => Promise<boolean>;
}

interface IPermissionUserUpdateItemProps extends IPermissionUserItemPropsBase {
  email: string;
  mode: PermissionUserMode.Update;
  onDelete: (email: string) => void;
  onUpdate: (email: string, scope: PermissionLevel[]) => Promise<boolean>;
  scope: PermissionLevel[];
}

export type IPermissionUserItemProps =
  | IPermissionUserAddItemProps
  | IPermissionUserUpdateItemProps;

interface IPermissionUserItemState {
  email?: string;
  isEmailValidated?: boolean;
  isSaving: boolean;
  changedScope: PermissionLevel[] | null;
}

export class PermissionUserItem extends React.PureComponent<
  IPermissionUserItemProps,
  IPermissionUserItemState
> {
  public static defaultProps = {
    mode: PermissionUserMode.Update,
  };

  public static isScopeChanged(scopeOld, scopeNew): boolean {
    return (
      difference(scopeOld, scopeNew).length +
        difference(scopeNew, scopeOld).length >
      0
    );
  }

  public state = {
    changedScope: null,
    email: '',
    isEmailValidated: false,
    isSaving: false,
  };

  constructor(props) {
    super(props);

    this.state.isEmailValidated = props.mode === PermissionUserMode.Update;
  }

  private handleUserPermissionChange = (permission: PermissionLevel) => {
    const { scope } = this.props;
    const { changedScope } = this.state;

    let newScope = changedScope || scope || [];

    // Remove permission
    if (newScope.includes(permission)) {
      newScope = newScope.filter(
        (storedPermission: PermissionLevel) => storedPermission !== permission,
      );

      // Set permission
    } else {
      if (permission === PermissionLevel.L1) {
        newScope = newScope.filter(
          (storedPermission: PermissionLevel) =>
            storedPermission !== PermissionLevel.L2,
        );
      }

      if (permission === PermissionLevel.L2) {
        newScope = newScope.filter(
          (storedPermission: PermissionLevel) =>
            storedPermission !== PermissionLevel.L1,
        );
      }

      newScope = [...newScope, permission];
    }

    const isScopeChanged = PermissionUserItem.isScopeChanged(scope, newScope);

    this.setState({ changedScope: isScopeChanged ? newScope : null });
  };

  public getPermissionCheckBoxes = (): React.ReactNodeArray =>
    permissionsList.map((permission) => {
      const { scope } = this.props;
      const { changedScope, isEmailValidated } = this.state;
      const isChecked = (changedScope || scope || []).includes(permission);

      return (
        <span
          key={`user_permission_${permission}_checkbox`}
          className="PermissionUserItem__checkboxContainer"
        >
          <Checkbox
            checked={isChecked}
            disabled={!isEmailValidated}
            onChange={() => this.handleUserPermissionChange(permission)}
          />
        </span>
      );
    });

  private handleInputOnChange = (e) => {
    const email = e.target.value;

    this.setState({
      email,
      isEmailValidated: validateEmail(email),
    });
  };

  public handleSave = async () => {
    const { mode, onAdd, onUpdate } = this.props;
    const { changedScope } = this.state;
    const saveMethod = mode === PermissionUserMode.Add ? onAdd : onUpdate;
    const email =
      mode === PermissionUserMode.Add ? this.state.email : this.props.email;

    this.setState({ isSaving: true });

    const success = await saveMethod(email, changedScope);

    this.setState({
      changedScope: success ? null : changedScope,
      email: success ? '' : this.state.email,
      isSaving: false,
    });
  };

  public handleDelete = () => {
    const { email, onDelete } = this.props;

    onDelete(email);
  };

  private renderEmailInputField = () => {
    const { email } = this.state;

    return (
      <>
        <Input
          onChange={this.handleInputOnChange}
          placeholder="Enter email address to add user"
          value={email}
        />
      </>
    );
  };

  private renderEmail = () => {
    const { email, mode } = this.props;

    if (mode === PermissionUserMode.Update) {
      return email;
    }

    return this.renderEmailInputField();
  };

  public render() {
    const { isGreyStyle, mode, onDelete } = this.props;
    const { changedScope, isEmailValidated, isSaving } = this.state;
    const headerStyle = mode === PermissionUserMode.Add;

    return (
      <div className="PermissionUserItem">
        <div className="PermissionUserItem__deleteControl">
          {onDelete && (
            <div className="PermissionUserItem__deleteButtonContainer">
              <Tooltip placement="top" title="Remove user">
                <Button
                  type="danger"
                  icon="user-delete"
                  onClick={this.handleDelete}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={classNames('PermissionUserItem__userItem', {
            PermissionUserItem__greyStyle: !headerStyle && isGreyStyle,
            PermissionUserItem__header: headerStyle,
          })}
        >
          <div className="PermissionUserItem__userEmail">
            {this.renderEmail()}
          </div>
          {this.getPermissionCheckBoxes()}
        </div>
        <div className="PermissionUserItem__controls">
          {(mode === PermissionUserMode.Update || isEmailValidated) &&
            changedScope && (
              <Button
                loading={isSaving}
                onClick={this.handleSave}
                type="primary"
              >
                {mode === PermissionUserMode.Add ? 'Add' : 'Apply'}
              </Button>
            )}
        </div>
      </div>
    );
  }
}
