import { message } from 'antd';
import * as Sentry from "@sentry/browser";
import { isProdBuildMode } from 'src/helpers';

export interface ILogErrorOptions {
  title: string;
  description?: string;
  skipConsole?: boolean;
  skipMessage?: boolean;
  skipSentry?: boolean;
  exception?: Error;
}

export const logError = ({
  skipConsole,
  skipMessage,
  skipSentry,
  title,
  description,
  exception,
}: ILogErrorOptions) => {
  if (!skipMessage) {
    message.error(
      skipConsole || (!description && !exception)
        ? title
        : `${title} (Check console for details)`,
    );
  }

  if (!skipConsole) {
    const details = description || (exception && exception.message);

    console.error(`${title}${details ? `: ${details}` : ''}`);

    if (exception) {
      console.error(exception);
    }
  }

  if (isProdBuildMode && !skipSentry && exception !== undefined) {
    Sentry.captureException(exception);
  }
};
