import React from 'react';
import { theme } from 'src/theme';
import { INewsHistory, NewsStatus } from '../../../restApi/newsService';
import { NewsAlertStatusBadge } from '../../NewsAlertStatusBadge';

const { blue65, orange65, red65, green65 } = theme.colors;

export interface INewsHistoryComputedProps {
  color: string;
  badgeComponents: JSX.Element[];
}

const { Deleted, InReview, New, Published } = NewsStatus;

const statusColors = new Map();
statusColors.set(Deleted, red65);
statusColors.set(New, blue65);
statusColors.set(Published, green65);
statusColors.set(InReview, orange65);
statusColors.set('Edited', orange65);

export const getNewsHistoryRecordConfig = ({
  status,
  is_edited,
}: INewsHistory): INewsHistoryComputedProps => {
  if (status === undefined) {
    return undefined;
  }

  let colorStatus: NewsStatus | 'Edited' = status;
  if (is_edited) {
    colorStatus = 'Edited';
  }

  return {
    badgeComponents: [
      <NewsAlertStatusBadge
        edited={is_edited}
        key="badge-status"
        status={status}
      />,
    ],
    color: statusColors.get(colorStatus),
  };
};
