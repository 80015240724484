interface IinjectUrlParamsProps {
  [key: string]: string | number;
}

export const buildUrlWithParams = (
  url?: string,
  params: IinjectUrlParamsProps = {},
) => {
  if (!url) {
    throw new Error('Url should be specified.');
  }

  return url.replace(/\/:[^/]+/g, (match: string) => {
    const foundParam = match.substr(2);
    if (params[foundParam] === undefined) {
      throw new Error(
        `Required for replacement parameter "${foundParam}" is missing in provided parameters for url "${url}".`,
      );
    }
    return `/${String(params[foundParam])}`;
  });
};
