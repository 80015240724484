import css from 'classnames';
import React from 'react';
import { Form } from './Form';
import { FormItem } from './FormItem';
import s from './left-col.module.css';
import { Select } from './Select';
import { Title } from './Title';

export class LeftCol extends React.Component<LeftColProps> {
  public static Title = Title;
  public static Form = Form;
  public static FormItem = FormItem;
  public static Select = Select;

  public render() {
    return <div className={css(s.leftColumn)}>{this.props.children}</div>;
  }
}

interface LeftColProps {
  children?: JSX.Element[] | JSX.Element;
}
