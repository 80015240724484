import { NavigationTabs } from 'src/constants';
import * as React from 'react';
import { ReactNode } from 'react';

interface AdminAlertLinkProps {
  children?: ReactNode;
  alertId: string | number;
  testID?: string;
}

export const AdminAlertLink = ({
  children,
  alertId,
  testID,
}: AdminAlertLinkProps) => (
  <a
    href={`${NavigationTabs.ADMIN.path}?alertId=${alertId}`}
    target="_blank"
    rel="noreferrer noopener"
    data-test-id={testID}
  >
    {children ?? alertId}
  </a>
);
