import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Highlighter from 'react-highlight-words';
import type { Chunk, FindChunks } from 'react-highlight-words';
import { PhraseType } from 'src/restApi/phrasesService';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import type { HighlighterProps } from './types';

export const ALERT_BAD_WORDS_TYPES = [
  PhraseType.Risky,
  PhraseType.ForbiddenAlerts,
];

export const COMMENT_BAD_WORDS_TYPES = [
  PhraseType.Risky,
  PhraseType.ForbiddenComments,
  PhraseType.ProfanityComments,
];

interface WordsHighlighterProps
  extends Omit<HighlighterProps, 'searchWords' | 'type'> {
  type?: PhraseType | PhraseType[];
  searchWords?: string[];
  testID?: string;
}

export const WordsHighlighter = observer<FC<WordsHighlighterProps>>(
  ({
    searchWords,
    autoEscape = true,
    type = PhraseType.Risky,
    testID,
    ...rest
  }) => {
    const { phrasesStore } = useStores();

    const finalSearchWords =
      searchWords ??
      phrasesStore.getPhrasesText(type instanceof Array ? type : [type]);

    const findChunks = findExactChunks(delimiters);

    return (
      <StyledHighlighter
        {...rest}
        findChunks={findChunks}
        autoEscape={autoEscape}
        searchWords={finalSearchWords}
        data-test-id={testID}
      />
    );
  },
);

const StyledHighlighter = styled(Highlighter)`
  & mark {
    padding: initial;
    background-color: rgba(252, 150, 48, 0.2);
    font-weight: bold;
  }
`;

const findExactChunks = (specifiedDelimiters: string[]) => ({
  searchWords,
  textToHighlight,
}: FindChunks) => {
  if (!textToHighlight) return [];

  const chunks = [] as Chunk[];

  const highlightText = String(textToHighlight).toLowerCase();

  // go thru each word for selection
  searchWords.forEach((sw) => {
    if (sw === '') return;

    // find it in the text till nothing found
    // get the last added word, so we can use it in indexOf
    let offset = 0;
    let start = highlightText.indexOf(sw, offset);

    while (start !== -1) {
      const end = start + sw.length;
      const prevSym = highlightText[start - 1];
      const nextSym = highlightText[end];

      // check for symbols and push it to the chunks
      if (
        (start === 0 || specifiedDelimiters.includes(prevSym)) &&
        (end === highlightText.length || specifiedDelimiters.includes(nextSym))
      ) {
        chunks.push({ start, end });
      }

      offset = end + 1;
      start = highlightText.indexOf(sw, offset);
    }
  });

  return chunks;
};

const delimiters = [
  ...'`~!@#$%^&*()_’”|+-=?;:..""<>,€£¥•،٫؟»«\\{}/[]'.split(''),
  '\n',
  '\u0008',
  '\n000C',
  '\n000A',
  '\n000D',
  '\n0009',
  '\n000B',
  '\n0000',
  '\u0020',
  '\u00A0',
  '\u1680',
  '\u180E',
  '\u2000',
  '\u2001',
  '\u2002',
  '\u2003',
  '\u2004',
  '\u2005',
  '\u2006',
  '\u2007',
  '\u2008',
  '\u2009',
  '\u200A',
  '\u200B',
  '\u202F',
  '\u205F',
  '\u3000',
  '\uFEFF',
];
