import { Checkbox, Col, Icon, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { PseudoHyperText } from '../../../../components/HyperText';
import { NewsItemUser } from '../../../../componentsNew/NewsItemUser';
import { formatDateShortenedToday } from '../../../../helpers';
import { INews } from '../../../../restApi/newsService';
import { NewsAlertStatusBadge } from '../NewsAlertStatusBadge';
import s from './news-curation-item.module.css';

export interface INewsCurationItemProps {
  onStatusChange?: Function;
  newsItem: INews;
  onDeletedChange: Function;
  getNewsItemHref: any;
  flaggedDeleted: boolean;
  flaggedFollowed: boolean;
}

// Renders the alert item in a vertical container
export class NewsCurationItem extends React.Component<INewsCurationItemProps> {
  public onNewsCurationDeleted = (checked: boolean) => {
    this.props.onDeletedChange(this.props.newsItem.news_id, checked);
  };

  public render() {
    const { newsItem, getNewsItemHref } = this.props;
    const href = getNewsItemHref(newsItem.news_id);
    const status = newsItem.status ? newsItem.status : 'new'; // fixme server should send a correct response
    const deleteDisabled = newsItem.status === 'deleted';
    const date = formatDateShortenedToday(newsItem.created_at);

    return (
      <div className={s.newsCurationItemWrapper}>
        <Row justify="space-between" align="middle">
          <Col span={20}>
            <Link className={s.newsCurationItem} to={href}>
              <div className={s.newsCurationItem__Header}>
                <NewsAlertStatusBadge status={status} />
                <div className={s.newsCurationItem__Source}>
                  {newsItem.service}
                </div>
                <div className={s.newsCurationItem__Info}>
                  <NewsItemUser
                    className={s.newsCurationItem__user}
                    email={newsItem.email}
                  />
                  {date}
                </div>
              </div>

              <div className={s.newsCurationItem__Body}>
                <div className={s.newsCurationItem__Title}>
                  <PseudoHyperText>{newsItem.title}</PseudoHyperText>
                </div>
                <div className={s.newsCurationItem__Content}>
                  <PseudoHyperText>{newsItem.content}</PseudoHyperText>
                </div>
              </div>
            </Link>
          </Col>
          <Col span={3}>
            <div
              className={`${s.newsCurationItem__Controls} ${s.newsCurationItem__Controls_Left}`}
            >
              <span className={s.newsCurationItem__linkPlaceholder}>
                {newsItem.news_url && (
                  <a
                    className={classNames(s.newsCurationItem__newsLink, {
                      [s.newsCurationItem__brokenLink]: !/^https?:\/\//.test(
                        newsItem.news_url,
                      ),
                    })}
                    href={newsItem.news_url}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tooltip
                      mouseEnterDelay={0.5}
                      placement="top"
                      title={`Open site: ${newsItem.news_url}`}
                    >
                      <Icon
                        className={s.newsCurationItem__newsLinkIcon}
                        type="link"
                      />
                    </Tooltip>
                  </a>
                )}
              </span>
              <Checkbox
                disabled={deleteDisabled}
                onChange={(e) => this.onNewsCurationDeleted(e.target.checked)}
                checked={this.props.flaggedDeleted}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
