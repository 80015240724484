import { Button, Form, Input, Modal, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useStores } from 'src/stores';

import { Flex } from 'src/components/Flex';
import { Box } from 'src/components/Box';
import { UserIcon } from '../../images/user-icon';

const { Text } = Typography;

export const ManageUsersBanModal = observer(() => {
  const { manageUsersStore } = useStores();
  const [step, setStep] = useState(0);

  const handleCancelClick = () => {
    setStep(0);
    manageUsersStore.setIsBanModalVisible(false);
  };
  const handleOkClick = () => setStep(step + 1);

  const handleBanUserId = (e) => {
    manageUsersStore.setBanUserId(e.target.value);
  };

  const handleBanUserSubmit = (e) => {
    e.preventDefault();
    manageUsersStore.banUser();
    setStep(0);
  };

  return (
    <Modal
      centered={true}
      closable={step === 2}
      footer={
        <FooterActions
          step={step}
          handleOkClick={handleOkClick}
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleBanUserSubmit}
          disabled={manageUsersStore.banUserId === ''}
        />
      }
      title={'Which user do you want to ban?'}
      okText={'Continue'}
      visible={manageUsersStore.isBanModalVisible}
    >
      {step === 0 ? (
        <Form.Item label="User ID">
          <Input
            name="user_id"
            placeholder="Enter User ID"
            allowClear
            onChange={handleBanUserId}
            value={manageUsersStore.banUserId}
          />
        </Form.Item>
      ) : (
        <Flex flexDirection="column">
          <Box py={3}>
            <Text>
              Permanently ban this user so they can no longer post, like, or
              comment on any of the Platforms?
            </Text>
          </Box>
          <Flex alignItems={'center'} pb={6}>
            <UserIcon />
            <Text strong>User ID: {manageUsersStore.banUserId}</Text>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
});

const FooterActions = ({
  disabled,
  step,
  handleOkClick,
  handleCancelClick,
  handleSubmitClick,
}) => {
  return (
    <Flex justifyContent={'center'}>
      <FooterButton onClick={handleCancelClick} type="default" size="large">
        Cancel
      </FooterButton>
      {step === 0 ? (
        <FooterButton
          onClick={handleOkClick}
          type="primary"
          size="large"
          disabled={disabled}
        >
          Continue
        </FooterButton>
      ) : (
        <FooterButton onClick={handleSubmitClick} type="danger" size="large">
          Yes, Ban User
        </FooterButton>
      )}
    </Flex>
  );
};

const FooterButton = styled(Button)`
  width: 50%;
`;
