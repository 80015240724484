import React from 'react';
import {
  ICommentModerationStatistic,
  ICommentTypeStatistics,
} from 'src/restApi/commentService';
import '../ModerationStatistic/ModerationStatistic.less';
import { ModerationStatisticBlock } from '../ModerationStatistic/ModerationStatisticBlock';
import { AlignedLoader } from '../../../components/AlignedLoader';

export interface IModerationStatisticProps {
  loading?: boolean;
  statistic: ICommentModerationStatistic | null;
}

export class ModerationStatisticComment extends React.PureComponent<IModerationStatisticProps> {
  private renderCommentTypeStatistic = (
    heading: string,
    statistic: ICommentTypeStatistics,
  ) => {
    const ageOfOldestInMinutes = Math.ceil(statistic.age_of_oldest / 60);

    return (
      <ModerationStatisticBlock
        heading={heading}
        data={[
          ['Unmoderated comments', statistic.amount, 'unmoderatedComments'],
          [
            'The oldest unmoderated comment',
            `${ageOfOldestInMinutes} min`,
            'oldestUnmoderatedComments',
          ],
        ]}
      />
    );
  };
  public render() {
    const { loading, statistic } = this.props;

    if (statistic === null) {
      return loading ? (
        <AlignedLoader className="ModerationStatistic__loader" />
      ) : null;
    }

    return (
      <>
        <ModerationStatisticBlock
          heading="Overall Statistics"
          data={[
            [
              'Active comments moderators',
              statistic.sessions,
              'overallStatistics',
            ],
          ]}
        />
        {this.renderCommentTypeStatistic(
          'Basic Comments Statistics',
          statistic.basic_comments,
        )}
      </>
    );
  }
}
