import { Select as AntDSelect } from 'antd';
import type { SelectProps } from 'antd/lib/select';
import css from 'classnames';
import React from 'react';
import s from './field.module.css';

interface SelectLeftColProps extends SelectProps {
  className?: string;
  options?: {}[];
}

export const Select = ({
  className,
  options,
  ...restProps
}: SelectLeftColProps) => (
  <AntDSelect className={css(s.leftColumnSelect, className)} {...restProps}>
    {renderOptions(options)}
  </AntDSelect>
);

function renderOptions(options = []) {
  return options.map((option, i) => (
    <AntDSelect.Option key={String(i)} value={option.value}>
      {option.text}
    </AntDSelect.Option>
  ));
}
