import React from 'react';

interface IModerationStatisticBlockProps {
  heading: string;
  data: [string, string | number, string][];
}

export class ModerationStatisticBlock extends React.PureComponent<
  IModerationStatisticBlockProps
> {
  public render() {
    return (
      <div className="ModerationStatisticBlock">
        <div
          className="ModerationStatisticBlock__header"
          data-test-id={this.props.heading}
        >
          {this.props.heading}
        </div>
        {this.props.data.map(([description, value, testID]) => (
          <div key={description} className="ModerationStatisticBlock__dataRow">
            <div
              className="ModerationStatisticBlock__dataValue"
              data-test-id={testID}
            >
              {value}
            </div>
            <div className="ModerationStatisticBlock__dataDescription">
              {description}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
