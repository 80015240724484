import { Badge, Menu } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from 'src/stores';
import styled from 'styled-components';

export const Navigation = observer<React.FC>(() => {
  const { navigationStore, uiStore } = useStores();
  const { pathname } = useLocation();
  const { getIndexOfPath } = navigationStore;

  return (
    <StyledMenu mode="horizontal" selectedKeys={[pathname]}>
      {navigationStore.navigationList.map((item, index) => {
        const dot =
          uiStore.notifiedTabs.indexOf(item.path) >= 0 &&
          getIndexOfPath(pathname) !== index;

        return (
          <Menu.Item key={item.path}>
            <Link to={item.path}>
              <StyledBadge dot={dot}>{item.title}</StyledBadge>
            </Link>
          </Menu.Item>
        );
      })}
    </StyledMenu>
  );
});

const StyledMenu = styled(Menu)`
  border-bottom: none;
  flex-grow: 1;
`;

const StyledBadge = styled(Badge)`
  > .ant-badge-dot {
    background-color: ${({ theme }) => theme.colors.blue100};
    right: -5px;
    top: -5px;
  }
`;
