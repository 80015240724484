import React, { ReactNode } from 'react';
import { NavigationTabs } from 'src/constants';

export interface IUserHyperlinkProps {
  userId: number;
  children?: ReactNode;
  testID?: string;
}

export const UserHyperlink = ({
  userId,
  children,
  testID,
}: IUserHyperlinkProps) => (
  <a
    href={`${NavigationTabs.SEARCH.path}?user_id=${userId}`}
    target="_blank"
    rel="noopener noreferrer"
    data-test-id={testID}
  >
    {children ?? userId}
  </a>
);
