import { notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { AddressMapController } from 'src/features/mapLocation/controllers/AddressMapController';
import { NewsAlertFormComponent } from '../components';

export interface IShareMethods {
  onSubmit: Function;
}

export interface INewsAlertFormValues {
  address?: string;
  category?: string;
  city?: string;
  content?: string;
  created_at?: string;
  email?: string;
  incident_id?: string;
  latitude?: number;
  longitude?: number;
  news_id?: string | number;
  news_url?: string;
  radius?: number;
  service?: string;
  status?: string;
  state?: string;
  title?: string;
  type?: string;
  updated_at?: string;
}

export interface IOptions {
  title: string;
  value: string;
}

export interface INewsAlertFormProps extends FormComponentProps {
  categories: IOptions[];
  componentTitle: string;
  form: WrappedFormUtils;
  initialValues: INewsAlertFormValues;
  onSave: Function;
  services: IOptions[];
  shareMethods: (props: IShareMethods) => {};
  types: IOptions[];
}

export interface INewsAlertFormState {
  location?: {
    latitude: number;
    longitude: number;
  };
}

export class NewsAlertForm extends React.Component<
  INewsAlertFormProps,
  INewsAlertFormState
> {
  public addressMapCtrl;
  public state = {
    location: undefined,
  };

  constructor(props) {
    super(props);
    let addressMapsProps;

    const { address, latitude, longitude } = props.initialValues;
    if (
      (address !== undefined && address !== '') ||
      latitude !== undefined ||
      longitude !== undefined
    ) {
      addressMapsProps = { address, latitude, longitude };
    }

    this.addressMapCtrl = new AddressMapController(addressMapsProps);
    this.addressMapCtrl.setChangeListener(this.handleAddressMapChange);
  }

  public componentDidMount() {
    this.props.shareMethods({
      onSubmit: this.handleSubmit,
    });
    const { latitude, longitude } = this.props.initialValues;
    if (latitude !== undefined && longitude !== undefined) {
      this.setLocation(latitude, longitude);
    }
  }

  public handleAddressMapChange = (values) => {
    this.setLocation(values.latitude, values.longitude);
    this.props.form.setFieldsValue(values);
  };

  public componentWillUnmount() {
    this.addressMapCtrl.removeChangeListener();
  }

  public setLocation = (latitude, longitude) => {
    const latitudeNumber = parseFloat(latitude);
    const longitudeNumber = parseFloat(longitude);
    this.setState({
      location: {
        latitude: isNaN(latitudeNumber) ? undefined : latitudeNumber,
        longitude: isNaN(longitude) ? undefined : longitudeNumber,
      },
    });
  };

  public handleSubmit = (e) => {
    e.preventDefault();

    const { initialValues } = this.props;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { latitude, longitude } = values;
        if (latitude === '0' && longitude === '0') {
          // NPS performs this validation if geometry coordinates are not provided
          notification.error({ message: 'Latitude and longitude cannot be 0', duration: 0 });
          return;
        }

        let valuesToSave = values;

        if (initialValues.news_id) {
          valuesToSave = {
            ...values,
            news_id: initialValues.news_id,
          };
        }

        this.props.onSave(valuesToSave);
      }
    });
  };

  public render() {
    return (
      <NewsAlertFormComponent
        {...this.props}
        addressMapCtrl={this.addressMapCtrl}
        handleSubmit={this.handleSubmit}
        location={this.state.location}
      />
    );
  }
}
