import { Button, Dropdown, Icon, Menu } from 'antd';
import type { ClickParam } from 'antd/lib/menu';
import React, { MouseEvent } from 'react';
import './AlertActions.less';

export interface IAlertActionsProps {
  alertId: number;
  canEscalate?: boolean;
  onApproveClick?: (alertId: number) => void;
  onDeclineClick?: (alertId: number) => void;
  onEscalateClick?: (alertId: number) => void;
  onSendToRsuClick?: (alertId: number) => void;
}

enum MenuAction {
  Escalate = 'escalate',
  SendToRsu = 'sendToRsu',
}

export class AlertActions extends React.Component<IAlertActionsProps> {
  public static defaultProps = {
    canEscalate: true,
  };

  private handleMenuClick = (event: ClickParam) => {
    const { alertId, onEscalateClick, onSendToRsuClick } = this.props;

    if (event.key === MenuAction.Escalate) {
      onEscalateClick?.(alertId);
    } else {
      onSendToRsuClick?.(alertId);
    }
  };

  private handleApprove = (e: MouseEvent) => {
    const { alertId, onApproveClick } = this.props;

    e.preventDefault();

    onApproveClick?.(alertId);
  };

  private handleDecline = (e: MouseEvent) => {
    const { alertId, onDeclineClick } = this.props;

    e.preventDefault();

    onDeclineClick?.(alertId);
  };

  private _renderMenu = () => {
    const { canEscalate, onEscalateClick, onSendToRsuClick } = this.props;

    if (!onEscalateClick && !onSendToRsuClick) return null;

    const menu = (
      <Menu className="AlertActions__menu" onClick={this.handleMenuClick}>
        {onEscalateClick && canEscalate && (
          <Menu.Item
            className="AlertActions__linkEscalate"
            key={MenuAction.Escalate}
            data-test-id="alertEscalate"
          >
            Escalate
          </Menu.Item>
        )}
        {onSendToRsuClick && (
          <Menu.Item
            className="AlertActions__linkSendToRsu"
            key={MenuAction.SendToRsu}
            data-test-id="alertSendToRsu"
          >
            Send to RSU
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown className="AlertActions__dropdown" overlay={menu}>
        <Button className="AlertActions__dropdownOpenButton">
          <Icon className="AlertActions__dropdownIcon" type="down" />
        </Button>
      </Dropdown>
    );
  };

  public render() {
    const {
      canEscalate,
      onApproveClick,
      onDeclineClick,
      onEscalateClick,
      onSendToRsuClick,
    } = this.props;

    if (
      !onApproveClick &&
      !onDeclineClick &&
      !(onEscalateClick && canEscalate) &&
      !onSendToRsuClick
    ) {
      return null;
    }

    return (
      <div className="AlertActions">
        {onApproveClick && (
          // eslint-disable-next-line
          <a
            className="AlertActions__linkApprove"
            onClick={this.handleApprove}
            data-test-id="alertApproveLink"
          >
            Approve
          </a>
        )}

        {onApproveClick && onDeclineClick && (
          <span className="AlertActions__delimiter" />
        )}

        {onDeclineClick && (
          // eslint-disable-next-line
          <a
            className="AlertActions__linkDecline"
            onClick={this.handleDecline}
            data-test-id="alertDeclineLink"
          >
            Decline
          </a>
        )}

        {this._renderMenu()}
      </div>
    );
  }
}
