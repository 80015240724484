import { action, observable } from 'mobx';
import { IModerateOptions, Reasons } from 'src/interfaces';
import { InjectRootStore } from './injectRootStore';

export class ModerateModalStore extends InjectRootStore {
  @observable public moderateOptions = {} as IModerateOptions;

  @action public setFreeNote = (note: string) => {
    this.moderateOptions = {
      ...this.moderateOptions,
      freeNote: note,
    };
  };

  @action public setEntityType = (type: number) => {
    this.moderateOptions = {
      ...this.moderateOptions,
      entityType: type,
    };
  };

  @action public setStatus = (status: number) => {
    this.moderateOptions = {
      ...this.moderateOptions,
      status,
    };
  };

  @action public setReasonsToRender = (reasons: Reasons[]) => {
    this.moderateOptions = {
      ...this.moderateOptions,
      reasonsToRender: reasons,
    };
  };

  @action public setSelectedReasons = (reasons: number[]) => {
    this.moderateOptions = {
      ...this.moderateOptions,
      reasons,
    };
  };

  @action public likeVideo = () => {
    this.moderateOptions = {
      ...this.moderateOptions,
      interesting: !this.moderateOptions.interesting,
    };
  };

  @action public cleanModerationOptions = () => {
    this.moderateOptions = {} as IModerateOptions;
  };
}
