import classNames from 'classnames';
import React from 'react';

interface TreeCheckboxProps {
  additional?: React.ReactElement;
  checked: boolean;
  className: string;
  isDanger?: boolean;
  onChange: (
    checked: boolean,
    e?: React.SyntheticEvent<HTMLInputElement>,
  ) => void;
  title: React.ReactNode;
  value: string;
}

export const TreeCheckbox = ({
  additional,
  checked,
  className,
  isDanger = false,
  onChange,
  title,
  value,
}: TreeCheckboxProps) => (
  <div className={`react-checkbox-tree ${className}`}>
    <span className="rct-text">
      <label>
        <input
          checked={checked}
          type="checkbox"
          value={value}
          onChange={(e) => onChange(e.currentTarget.checked, e)}
        />
        <span
          className={classNames('rct-checkbox', {
            'rct-checkbox-orange': isDanger,
          })}
        >
          <span className="rct-icon rct-icon-uncheck" />
        </span>
        <span className="rct-title">{title}</span>
        {additional}
      </label>
    </span>
  </div>
);
