import { message } from 'antd';
import React from 'react';
import { RsuCommentPopup } from 'src/components/rsu-comment-popup-container';
import s from './style.module.css';

export class AddRSUComment extends React.Component<IAddRSUCommentProps> {
  public openRsuPopup = () => {
    const popup: any = this.refs.rsuNewContainer;
    popup.toogleContainer();
  };

  public createRSUCommentHandler = async (text: string) => {
    if (this.props.createRSUAction) {
      await this.props.createRSUAction(text);
      message.success('Message has been added.');
    }
  };

  public render() {
    return (
      <div key="rsu-add-comment-container" className={s.addCommentContainer}>
        <div className={s.buttonContainer} onClick={() => this.openRsuPopup()}>
          Leave a Comment
        </div>

        <RsuCommentPopup
          className={s.addCommentPopup}
          submitAction={this.createRSUCommentHandler}
          ref="rsuNewContainer"
          type={'new'}
        />
      </div>
    );
  }
}

export interface IAddRSUCommentProps {
  createRSUAction?: (text: string) => void;
}
