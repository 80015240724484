import React from 'react';
import { Form, Icon, Select } from 'antd';
import styled from 'styled-components';

import { useStores } from 'src/stores';

import { Flex } from 'src/components/Flex';

const { Item } = Form;
const { Option } = Select;

const CloseIcon = styled(Icon)`
  border-color: #b9bcc3;
  color: #b9bcc3;
`;

export const ManageUsersFormSelectCategory = (props) => {
  const { manageUsersStore } = useStores();
  const {
    categoryKey,
    count,
    getFieldDecorator,
    handleRemoveUserViolation,
    handleSelect,
    index,
    options,
    required,
  } = props;
  const isRemoveViolationDisabled = count.length > 1;
  let initialCategoryValue = '';

  if (manageUsersStore.isManageUsersFormSelectCategoryEditing(index)) {
    initialCategoryValue = manageUsersStore.userCategoriesData[index]?.id;
  }

  return (
    <Item label="Category" style={{ marginBottom: '15px', width: '100%' }}>
      <Flex alignItems="center" justifyContent="space-between">
        {getFieldDecorator(`userViolationsCategory${index}`, {
          rules: [{ required, message: 'Pleases select a Category' }],
          initialValue: initialCategoryValue,
        })(
          <Select
            data-testid="manage-users-form-select-category"
            placeholder="Select Category"
            style={{ width: '90%' }}
            onChange={handleSelect(index)}
          >
            {options.map((categoryId, idx) => {
              return (
                <Option
                  key={`categoryOption${categoryId}${idx}`}
                  value={categoryId}
                >
                  {manageUsersStore.categoryEntities[categoryId].name}
                </Option>
              );
            })}
          </Select>,
        )}
        {isRemoveViolationDisabled && (
          <span
            id={`userViolationsCategory${index}`}
            onClick={() => handleRemoveUserViolation(categoryKey)}
          >
            <CloseIcon type="close-circle" />
          </span>
        )}
      </Flex>
    </Item>
  );
};
