export enum ResponseStatus {
  Ok = 0,
  Failed = 1,
  ValidationError = 2,
  InvalidScope = 3,
}

export interface IResponseItem<D, M = {}, E = {}> {
  // For some reason, if no data is present API will not return this field
  data?: D;
  // or this one
  meta: M;
  status: ResponseStatus;
  error?: E;
}

export interface IResponseList<D, M = {}> {
  // For some reason, if no data is present API will not return this field
  data?: {
    items: D[];
  };
  // or this one
  meta: M;
  status: ResponseStatus;
}
