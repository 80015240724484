import { api } from 'src/api';
import { IUserPermissions, PermissionLevel } from './interfaces';
import { IResponseItem } from './restApi';

const path = 'admins';

export class PermissionService {
  public static async addUser(user: IUserPermissions) {
    const response = await api
      .put(path, { json: user })
      .json<IResponseItem<IUserPermissions>>();

    return response.data;
  }

  public static async deleteUser(user: IUserPermissions) {
    const response = await api
      .delete(path, {
        json: user,
      })
      .json<IResponseItem<boolean>>();

    return response.data;
  }

  public static async getUsers(permission?: PermissionLevel) {
    const queryArgs = permission ? `?scope=${permission}` : '';

    const response = await api
      .get(`${path}${queryArgs}`)
      .json<IResponseItem<IUserPermissions[]>>();

    const { data } = response;

    return Object.keys(data).map((email) => ({
      email,
      scope: data[email],
    })) as IUserPermissions[];
  }

  public static async updateUser(user: IUserPermissions) {
    const response = await api
      .put(path, { json: user })
      .json<IResponseItem<IUserPermissions>>();

    return response.data;
  }
}
