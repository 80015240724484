import { Button, Result } from 'antd';
import React from 'react';
import './CriticalError.less';

function refreshPage() {
  window.location.reload();
}

export function CriticalError() {
  return (
    <Result
      className="CriticalError"
      status="warning"
      title="Oops! Something went wrong"
      extra={
        <div className="CriticalError__detailsBlock">
          <div className="CriticalError__description">
            It seems that some kind of error appeared. Please reload the page or
            go back later. If it won't work contact the support.
          </div>

          <Button key="refresh" onClick={refreshPage}>
            Refresh
          </Button>
        </div>
      }
    />
  );
}
