import React from 'react';

import moment from 'moment';
import { DATE_FORMAT } from 'src/constants';
import { AlertPropertyRow, IconType } from 'src/components/AlertPropertyRow';
import { Icon as IconComponent } from 'src/componentsNew/Icon';
import { AdminAlertLink } from 'src/pages/admin/components/AdminAlertLink';
import { UserHyperlink } from 'src/componentsNew/UserHyperlink';
import { AlertCaseResolution } from 'src/components/alert-case-resolution';
import { getAlertNumericId } from 'src/pages/search/services/utils';
import { BadActor } from '../../../../componentsNew/Alert/components/AlertInfoFields';
import { Tooltip } from "src/componentsNew/Tooltip/Tooltip";
import {AlertExtendedDescriptionBlock} from "../../../../components/AlertExtendedDescriptionBlock";
import {IStoredAlert} from "../../../../restApi/interfaces";

export const SearchAlertHistoryDetails = (props) => {
  const {
    created_at,
    title,
    description,
    address,
    id,
    owner_uid,
    ding_id,
    category,
    flag_reasons,
    share_url,
    case_information,
    user_name,
    masked_address,
    user_data,
  } = props;
  const alertId = getAlertNumericId(id);
  const isNotNpssUser = owner_uid.indexOf('police:') === -1;
  const isBadActor = user_data != null && Object.keys(user_data).length !== 0;

  return (
    <>
      {created_at && (
        <div className="AdminHistoryTimeline__date">
          {moment(created_at).format(DATE_FORMAT)}
        </div>
      )}

      <div>
        {title && (
          <div
            className="AdminHistoryTimeline__title"
            data-test-id="alertExpanded alertTitle"
          >
            {title}
          </div>
        )}
        {description && (
          <div data-test-id="alertExpanded alertDescription">{description}</div>
        )}
        <div>
          <AlertExtendedDescriptionBlock {...props as IStoredAlert} />
        </div>
        {case_information && (
          <>
            <div className="AdminHistoryTimeline__technical">
              {case_information.agency_name && (
                <AlertPropertyRow
                  iconType={IconType.Agency}
                  testID="agencyName"
                >
                  Agency Name: {case_information.agency_name}
                </AlertPropertyRow>
              )}
              {case_information.officer_name && (
                <AlertPropertyRow
                  iconType={IconType.Officer}
                  testID="officerName"
                >
                  Officer Name: {case_information.officer_name}
                </AlertPropertyRow>
              )}
              {case_information.case_number && (
                <AlertPropertyRow iconType={IconType.Case} testID="caseNumber">
                  Case Number: {case_information.case_number}
                </AlertPropertyRow>
              )}
              {case_information.phone_number && (
                <AlertPropertyRow
                  iconType={IconType.Telephone}
                  testID="casePhoneNumber"
                >
                  Telephone: {case_information.phone_number}
                </AlertPropertyRow>
              )}
            </div>
            <div className="AdminHistoryTimeline__technical">
              <AlertCaseResolution
                isCrime={case_information.is_crime}
                resolved={case_information.resolved}
                resolvedMessage={case_information.resolved_message}
              />
            </div>
          </>
        )}

        <div className="AdminHistoryTimeline__technical">
          {address && <AlertPropertyRow>{address}</AlertPropertyRow>}
          {masked_address && (
            <AlertPropertyRow>{masked_address}</AlertPropertyRow>
          )}
          {created_at && (
            <AlertPropertyRow
              iconType={IconType.Clock}
              testID="alertExpanded alertCreatedAt"
            >
              {`Created Time: ${moment(created_at).format(DATE_FORMAT)}`}
            </AlertPropertyRow>
          )}
          {alertId && (
            <AlertPropertyRow
              iconType={IconType.AlertId}
              copyValue={alertId}
              testID="alertExpanded alertId"
            >
              {`Alert ID: `}
              <AdminAlertLink alertId={alertId}>{alertId}</AdminAlertLink>
            </AlertPropertyRow>
          )}
          {owner_uid && isNotNpssUser && (
            <AlertPropertyRow iconType={IconType.User} copyValue={owner_uid}>
              {`User ID: `}
              <UserHyperlink
                userId={owner_uid}
                data-test-id="alertExpanded alertUserId"
              >
                {owner_uid}
              </UserHyperlink>
              {isBadActor && (
                  <Tooltip content={user_data.notes ? user_data.notes : "Empty"}>
                    <BadActor>
                      <IconComponent iconType={IconType.Exclamation} />
                    </BadActor>
                  </Tooltip>
              )}
            </AlertPropertyRow>
          )}
          {ding_id && ding_id !== '0' && (
            <AlertPropertyRow
              iconType={IconType.DingId}
              testID="alertExpanded alertDingId"
            >
              {`Ding ID: ${ding_id}`}
            </AlertPropertyRow>
          )}
          {share_url && (
            <AlertPropertyRow iconType={IconType.Agency} copyValue={share_url}>
              {`Share link: `}
              <a
                href={share_url}
                target="_blank"
                rel="noreferrer noopener"
                data-test-id="alertExpanded alertShareUrl"
              >
                Link
              </a>
            </AlertPropertyRow>
          )}
          {category && (
            <AlertPropertyRow
              iconType={IconType.Agency}
              testID="alertExpanded alertCategory"
            >
              {`User Selected Category: ${category}`}
            </AlertPropertyRow>
          )}
          {user_name && (
            <AlertPropertyRow
              iconType={IconType.Agency}
              testID="alertExpanded agencyName"
            >
              {`Agency: ${user_name}`}
            </AlertPropertyRow>
          )}
          {flag_reasons && flag_reasons.length > 0 && (
            <AlertPropertyRow
              iconType={IconType.Flag}
              valueClassName="AlertInfoFields__FlagReasons"
              testID="alertExpanded alertFlagReasonContainers"
            >
              <span className="AlertInfoFields__FlagReasonsTitle">
                Flag Reasons:{' '}
              </span>
              <div>
                {flag_reasons.map((reason) => (
                  <div
                    className="AlertInfoFields__FlagReasonsRow"
                    key={reason.name}
                    data-test-id="alertExpanded flagReason"
                  >
                    <div data-test-id="alertExpanded flagReasonName">
                      {reason.name}
                    </div>
                    <div data-test-id="alertExpanded flagReasonCount">
                      {reason.count}
                    </div>
                  </div>
                ))}
              </div>
            </AlertPropertyRow>
          )}
        </div>
      </div>
    </>
  );
};
