export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProdBuildMode = process.env.NODE_ENV === 'production';

export const isLocalEnv = window.location.hostname.indexOf('localhost') !== -1;
export const isDevEnv = window.location.hostname.indexOf('dev') !== -1;
export const isQAEnv = window.location.hostname.indexOf('qa') !== -1;

export const isProdEnv =
  isProdBuildMode && !isLocalEnv && !isDevEnv && !isQAEnv;

export const auto_assign_enabled = isDevEnv || isLocalEnv || isQAEnv || isProdEnv

export const comments_stats_enabled = true

export const max_assigned_threads = 2

export const env = isProdEnv
  ? 'prod'
  : isQAEnv
  ? 'qa'
  : isDevEnv
  ? 'dev'
  : 'local';
