import css from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Navigation } from 'src/components/Navigation';
import { SigninButton } from 'src/components/SigninButton';
import { UserBar } from 'src/components/user-bar';
import { useStores } from 'src/stores';
import styles from './style.module.css';

export const HomeHeader = observer(() => {
  const { authStore, uiStore } = useStores();

  return (
    <div
      className={css(styles.fixedContainer, 'top-column', {
        [styles.headerScrolled]: uiStore.pageScrollPos.y > 0,
      })}
    >
      <div className={css(styles.container)}>
        <div className={styles.logo} />
        <div className={styles.logoText}>Neighbors Admin</div>
        {authStore.isLoggedIn ? (
          <>
            <Navigation />
            <UserBar />
          </>
        ) : (
          <>
            <div className={css(styles.emptyBox, 'top-column')} />
            <SigninButton onClick={authStore.getAuthUrl} />
          </>
        )}
      </div>
    </div>
  );
});
