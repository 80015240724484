import React from 'react';
import './AlertUserViolations.less';
import { IUserData } from 'src/restApi/interfaces';
import { useStores } from 'src/stores';

export interface AlertUserViolationsProps {
  userData: IUserData;
  skipHeader?: boolean;
}

export const getNumberOfUserViolations = (categories: any[]) => {
  let count = 0;
  categories?.map((category, index) => {
    if (category.subcategories) {
      count += category.subcategories.length;
    }
    return true;
  });
  return count;
};

export const AlertUserViolations = ({
  userData,
  skipHeader,
}: AlertUserViolationsProps) => {
  const { manageUsersStore } = useStores();

  return (
    <>
      {userData.categories?.length !== 0 &&
        manageUsersStore.areCategoriesAvailable && (
          <>
            {!skipHeader && (
              <div>
                {`Classified User Violations: ${getNumberOfUserViolations(
                  userData.categories,
                )}`}
              </div>
            )}
            {userData.categories.map((category, categoryIndex) => {
              return category.subcategories.map(
                (subcategoryId, subcategoryIndex) => {
                  return (
                    <div
                      className={'UserViolation'}
                      key={`user-violations-${categoryIndex}-${subcategoryIndex}`}
                    >
                      {`${
                        manageUsersStore.categoryEntities[category.id].name
                      }: ${
                        manageUsersStore.subcategoryEntities[subcategoryId].name
                      }`}
                    </div>
                  );
                },
              );
            })}
          </>
        )}
    </>
  );
};
