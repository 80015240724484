import { Radio } from 'antd';
import type { ModalProps } from 'antd/lib/modal';
import React from 'react';
import { CommonModal } from 'src/components/CommonModal';

export class DeleteConfirmationModal extends React.Component<
  IConfirmationModalProps,
  IConfirmationModalState
> {
  public state = {
    value: 1,
    visible: false,
  };

  private onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  public render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <CommonModal {...this.props}>
        <Radio.Group onChange={this.onChange} value={this.state.value}>
          <Radio style={radioStyle} value={1}>
            Repetitive content
          </Radio>
          <Radio disabled style={radioStyle} value={2}>
            Missing information
          </Radio>
          <Radio disabled style={radioStyle} value={3}>
            Irrelevant
          </Radio>
          <Radio disabled style={radioStyle} value={4}>
            Old news story
          </Radio>
        </Radio.Group>
      </CommonModal>
    );
  }
}

export type IConfirmationModalProps = ModalProps;

interface IConfirmationModalState {
  visible: boolean;
  value: number;
}
