import { message } from 'antd';
import { action, computed, flow, observable, toJS } from 'mobx';
import pluralize from 'pluralize';
import { groupBy, map, mergeRight, pipe, pluck, prop } from 'ramda';
import { LoadingState } from 'src/restApi/interfaces';
import {
  IPhrase,
  PhraseLanguage,
  PhrasesService,
  PhraseType,
} from 'src/restApi/phrasesService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';

const managementOnlyPhraseTypes = [PhraseType.Approved];

const defaultPhrases = Object.values(PhraseType).reduce(
  (acc, type) => ({ ...acc, [type]: [] }),
  {},
);

const groupPhrasesByWordType = pipe(
  groupBy<IPhrase>(prop('word_type')),
  map<Record<PhraseType, IPhrase[]>, Record<PhraseType, string[]>>(
    pluck('phrase'),
  ),
  mergeRight(defaultPhrases),
);

export class PhrasesStore extends InjectRootStore {
  // ! Do not use .replace() method here, as it might broke items order in the Map
  // ! Details: https://github.com/mobxjs/mobx/issues/1980

  private readonly _phrases = observable.map<string, IPhrase>([]);

  private readonly _phrasesLoadingState = observable.box<LoadingState>(
    LoadingState.NotLoaded,
  );

  @computed public get phrases() {
    return Object.values(toJS(this._phrases)) as IPhrase[];
  }

  @computed private get _phrasesTextByType() {
    const phrases = groupPhrasesByWordType(this.phrases);

    return phrases;
  }

  public getPhrasesText(types: PhraseType[]) {
    const phrases = types.reduce(
      (acc, type) => [...acc, ...this._phrasesTextByType[type]],
      [] as string[],
    );

    return phrases;
  }

  @computed public get phrasesLoadingState() {
    return this._phrasesLoadingState.get();
  }

  @action public resetStore = () => {
    this._phrases.clear();
    this.resetLoadingState();
  };

  @action private resetLoadingState = () => {
    this._phrasesLoadingState.set(LoadingState.NotLoaded);
  };

  @action private clearPhrases = (types: PhraseType[]) => {
    for (const [phraseId, phrase] of this._phrases.entries()) {
      if (types.includes(phrase.word_type)) {
        this._phrases.delete(phraseId);
      }
    }
  };

  private phrasesToMap = (phrases: IPhrase[]): Map<string, IPhrase> => {
    return new Map(phrases.map((phrase) => [phrase.id, phrase]));
  };

  public loadPhrasesFlow = flow(function* (this: PhrasesStore) {
    this._phrasesLoadingState.set(LoadingState.Loading);

    try {
      const phrases: IPhrase[] = yield PhrasesService.getPhrases(
        [...Object.values(PhraseType)],
        [...Object.values(PhraseLanguage)],
      );
      this._phrases.clear();
      this._phrases.merge(this.phrasesToMap(phrases));
      this._phrasesLoadingState.set(LoadingState.Loaded);
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to load list of phrases',
      });

      this._phrases.clear();
      this._phrasesLoadingState.set(LoadingState.Failed);
    }
  });

  public addPhrasesFlow = flow(function* (
    this: PhrasesStore,
    phrases: string[],
    phrasesType: PhraseType,
    phrasesLang: PhraseLanguage,
  ) {
    try {
      const addedPhrases: IPhrase[] = yield PhrasesService.addPhrases(
        phrases,
        phrasesType,
        phrasesLang,
      );
      const restPhrases: [string, IPhrase][] = [
        ...this._phrases.entries(),
      ].filter(
        (phraseHash: [string, IPhrase]) =>
          !addedPhrases.some((phrase: IPhrase) => phrase.id === phraseHash[0]),
      );

      const newPhrases = new Map([
        ...this.phrasesToMap(addedPhrases.reverse()),
        ...restPhrases,
      ]);

      this._phrases.clear();
      this._phrases.merge(newPhrases);

      if (phrases.length > addedPhrases.length) {
        const missed: number = phrases.length - addedPhrases.length;
        message.warning(
          `${missed} ${pluralize('word', missed)} ${
            missed > 1 ? 'have' : 'has'
          } already been added.`,
        );
      }

      const count: number = addedPhrases.length;
      message.success(
        `${count} new ${pluralize('word', count)} ${
          count > 1 ? 'have' : 'has'
        } been added.`,
      );
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to add phrases',
      });
    }
  });

  public deletePhrasesFlow = flow(function* (
    this: PhrasesStore,
    ids: string[],
  ) {
    try {
      yield PhrasesService.deletePhrases(ids);

      ids.forEach((id) => this._phrases.delete(id));

      const count: number = ids.length;
      message.success(
        `${count} ${pluralize('word', count)} ${
          count > 1 ? 'have' : 'has'
        } been deleted.`,
      );
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to delete phrases',
      });
    }
  });

  public startWordManagementFlow = this.loadPhrasesFlow;

  @action.bound
  public stopWordManagementFlow = () => {
    this.clearPhrases(managementOnlyPhraseTypes);
    this.resetLoadingState();
  };
}
