import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';
import AlertFreeNote from 'src/components/AlertFreeNote';
import { AlertPoliceCaseBlock } from 'src/components/AlertPoliceCaseBlock';
import { BlockLayout } from 'src/components/BlockLayout';
import {
  ALERT_BAD_WORDS_TYPES,
  WordsHighlighter,
} from 'src/components/WordsHighlighter';
import { AlertBadgesAndHistory } from 'src/componentsNew/AlertBadgesAndHistory';
import { IAlertHistory } from 'src/restApi/alertsService';
import { IStoredAlert } from 'src/restApi/interfaces';
import { AlertActions } from '../AlertActions';
import { AlertInfoFields } from '../AlertInfoFields/AlertInfoFields';
import { AlertMedia } from '../AlertMedia';
import './AlertCard.less';
import {AlertExtendedDescriptionBlock} from "../../../../components/AlertExtendedDescriptionBlock";

export interface AlertCardProps {
  alert: IStoredAlert;
  canEscalate?: boolean;
  controls?: React.ReactNode;
  history?: IAlertHistory[] | null;
  info?: React.ReactNode;
  isAssigned?: boolean;
  onApproveClick?: (alertId: number) => void;
  onDeclineClick?: (alertId: number) => void;
  onEscalateClick?: (alertId: number) => void;
  onHistoryToggle: (alertId: number, visible: boolean) => void;
  onSendToRsuClick?: () => void;
}

const mainFirstColumnSizes = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 14,
  xxl: 14,
};

const mainSecondColumnSizes = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 10,
  xxl: 10,
};

const firstColumnSizes = { xs: 18, sm: 18, md: 18, lg: 18, xl: 24, xxl: 18 };
const secondColumnSizes = { xs: 6, sm: 6, md: 6, lg: 6, xl: 24, xxl: 6 };

export const AlertCard = ({
  alert,
  canEscalate,
  controls,
  history,
  onApproveClick,
  onDeclineClick,
  onEscalateClick,
  onHistoryToggle,
  onSendToRsuClick,
  info,
  isAssigned = false,
}: AlertCardProps) => {
  const showCaseBlock = shouldShowCaseBlock(alert);

  return (
    <div
      className={classNames('AlertCard', { AlertCard__assigned: isAssigned })}
    >
      <Row gutter={24}>
        {/* Media preview column */}

        <Col {...mainFirstColumnSizes} className="AlertCard__previewColumn">
          <AlertMedia alert={alert} className="AlertCard__media" />
        </Col>

        {/* Information column */}

        <Col {...mainSecondColumnSizes} className="AlertCard__detailsColumn">
          <div className="AlertCard__detailsColumnContentWrapper">
            {/* Header row */}

            <Row gutter={16} className="AlertCard__head">
              {/* Title */}

              <Col
                {...firstColumnSizes}
                className="AlertCard__title"
                data-test-id="alertTitle"
              >
                <WordsHighlighter
                  textToHighlight={alert.title}
                  type={ALERT_BAD_WORDS_TYPES}
                />
              </Col>

              {/* Timer and additional information */}

              {info && (
                <Col {...secondColumnSizes} className="AlertCard__additional">
                  <div
                    className="AlertCard__additionalContent"
                    data-test-id="alertAdditionalContent"
                  >
                    {info}
                  </div>
                </Col>
              )}
            </Row>

            {/* Alert card info row */}

            <Row gutter={16}>
              <Col {...firstColumnSizes} className="AlertCard__info">
                {/* Description */}

                <WordsHighlighter
                  className="AlertCard__description"
                  textToHighlight={alert.description}
                  type={ALERT_BAD_WORDS_TYPES}
                  testID="alertDescription"
                />

                {/* Extended Description */}

                <div>
                  <AlertExtendedDescriptionBlock {...alert} />
                </div>


                {/* Case Info */}

                {showCaseBlock && (
                  <div className="AlertCard__caseInfo">
                    <AlertPoliceCaseBlock caseInformation={alert} />
                  </div>
                )}

                {/* Alert Info */}

                <div className="AlertCard__alertInfo">
                  <AlertInfoFields
                    className="AlertCard__alertField"
                    alert={alert}
                  />
                </div>
              </Col>
            </Row>

            {/* Controls row */}

            <Row gutter={16} className="AlertCard__actions">
              <Col span={24}>
                <BlockLayout
                  className={'AlertCard__actionsLayout'}
                  left={
                    <AlertActions
                      alertId={alert.alert_id}
                      canEscalate={canEscalate}
                      onApproveClick={onApproveClick}
                      onDeclineClick={onDeclineClick}
                      onEscalateClick={onEscalateClick}
                      onSendToRsuClick={onSendToRsuClick}
                    />
                  }
                  right={controls}
                />
              </Col>
            </Row>

            {/* Free note row */}

            {alert.free_note && (
              <Row gutter={16}>
                <Col {...firstColumnSizes}>
                  <AlertFreeNote
                    className="AlertCard__freeNote"
                    freeNote={alert.free_note}
                    testID="alertFreeNote"
                  />
                </Col>
              </Row>
            )}

            {/* Alert History row */}

            <Row gutter={16}>
              <Col {...firstColumnSizes} className="AlertCard__history">
                <AlertBadgesAndHistory
                  alert={alert}
                  history={history || null}
                  onHistoryToggle={onHistoryToggle}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const shouldShowCaseBlock = ({
  agency_name,
  case_number,
  is_crime,
  officer_name,
  phone_number,
  resolved_message,
  resolved,
}: IStoredAlert) => {
  return (
    Boolean(agency_name) ||
    Boolean(case_number) ||
    Boolean(officer_name) ||
    Boolean(phone_number) ||
    Boolean(resolved_message) ||
    is_crime ||
    resolved
  );
};
