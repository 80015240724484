import { Badge } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IBadgeLabelProp {
  className?: string;
  text: string;
  testID?: string;
}

export const BadgeLabel = ({ className, text, testID }: IBadgeLabelProp) => (
  <StyledBadge count={text} className={className} data-test-id={testID} />
);

const StyledBadge = styled(Badge)`
  border-radius: ${({ theme }) => theme.antd['@border-radius-base']};

  & .ant-badge-count {
    background-color: transparent;
    box-shadow: none;
    font-size: 9px;
    font-stretch: normal;
    letter-spacing: 0.3px;
  }
`;
