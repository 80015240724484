import { InjectRootStore } from './injectRootStore';
import { action, computed, flow, observable, reaction, toJS } from 'mobx';
import {
  CommentService,
  SessionStoppageReason,
} from '../restApi/commentService';
import { logError } from './utils/errorLogger';
import { notification } from 'antd';
import moment from 'moment';
import { RootStore } from './rootStore';
import { leaveModerationConfirmation } from '../features/LeaveModerationConfirmation';
import {
  WebsocketEntityType,
  WebsocketMessageType,
  moderationCommentStatsPollingInterval,
} from '../constants';
import { IComment } from '../restApi/interfaces';
import { WsConnector } from '../wsConnector';
import { ICommentModerationStatistic } from '../restApi/commentService';

type SocketHandler = (data: Partial<IComment>) => void;
const timeoutInSeconds = 240;

export class CommentsStore extends InjectRootStore {
  private readonly _comment = observable.box<IComment | null>(null);
  private readonly _timeout = observable.box<string>(Date.now().toString());
  private readonly _moderationStatus = observable.box<boolean>(false);
  private readonly _moderationSessionLoadingCounter = observable.box<number>(0);
  private readonly _moderationStatistic =
    observable.box<ICommentModerationStatistic | null>(null);
  private readonly _moderationStatisticLoading = observable.box<boolean>(false);
  private moderationStatsUpdateInterval: number | undefined;
  private _initiatedListeners = false;
  private activeModerationTimeout: number | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    reaction(
      () => this.moderationStatus,
      (moderationStatus) => {
        if (moderationStatus) {
          leaveModerationConfirmation.enableConfirmation(this.leavePageFlow);
        } else {
          leaveModerationConfirmation.disableConfirmation(this.leavePageFlow);
        }
        this.getModerationStatisticFlow();
      },
    );
    // Load statistic on every user decision
    reaction(
      () => this.comment,
      () => this.getModerationStatisticFlow(),
    );
  }

  @computed get comment(): IComment | null {
    return toJS(this._comment.get());
  }

  @computed get moderationStatus() {
    return this._moderationStatus.get();
  }

  public getModerationStatus() {
    return this.moderationStatus;
  }

  @computed get isModerationSessionProcessing() {
    return this._moderationSessionLoadingCounter.get() > 0;
  }

  @computed get isModerationStatisticLoading() {
    return this._moderationStatisticLoading.get();
  }

  @action public setModerationStatistic = (
    statistic: ICommentModerationStatistic | null,
  ) => {
    this._moderationStatistic.set(statistic);
  };

  @computed get moderationStatistic(): ICommentModerationStatistic | null {
    return toJS(this._moderationStatistic.get());
  }

  @computed get timeout() {
    return toJS(this._timeout.get());
  }

  @action public setModerationStatus = (moderationStatus: boolean) => {
    this._moderationStatus.set(moderationStatus);
  };

  @action private setModerationStatisticLoading = (loadingState: boolean) => {
    this._moderationStatisticLoading.set(loadingState);
  };

  @action public setModerationSessionLoading = (loadingState: boolean) => {
    const moderationSessionLoadingCount =
      this._moderationSessionLoadingCounter.get();

    const nextValue = loadingState
      ? moderationSessionLoadingCount + 1
      : moderationSessionLoadingCount - 1;

    this._moderationSessionLoadingCounter.set(nextValue >= 0 ? nextValue : 0);
  };

  @action private setTimeout() {
    const timeout = new Date();
    timeout.setSeconds(timeout.getSeconds() + 60 + timeoutInSeconds); // 60 second countdown + 4 min timeout
    this._timeout.set(timeout.toISOString());
  }

  private checkSessionUpdatedByCurrentUser = (data: Partial<IComment>) => {
    return this.rootStore.authStore.user?.email === data.email;
  };

  private processModerationSessionOpenedWsMessage = async (
    data: Partial<IComment>,
  ) => {
    if (this.checkSessionUpdatedByCurrentUser(data)) {
      this.resetActiveModerationTimeout();
      this.setModerationStatus(true);
    }
  };

  private processModerationSessionClosedWsMessage = (
    data: Partial<IComment>,
  ) => {
    if (this.checkSessionUpdatedByCurrentUser(data)) {
      this.setModerationStatus(false);
      this.cleanUpAfterStoppedSession();
    }
  };

  private webSocketHandlers = new Map<WebsocketMessageType, SocketHandler>([
    [
      WebsocketMessageType.AutoAssignSessionClosed,
      this.processModerationSessionClosedWsMessage,
    ],
    [
      WebsocketMessageType.AutoAssignSessionOpened,
      this.processModerationSessionOpenedWsMessage,
    ],
  ]);

  public startListeners = () => {
    if (this._initiatedListeners) return;

    this.webSocketHandlers.forEach((handler, msgType) =>
      WsConnector.addListener(msgType, WebsocketEntityType.Comment, handler),
    );

    this._initiatedListeners = true;
  };

  public stopListeners = () => {
    if (!this._initiatedListeners) return;

    this.webSocketHandlers.forEach(
      (handler: SocketHandler, msgType: WebsocketMessageType) =>
        WsConnector.removeListener(
          msgType,
          WebsocketEntityType.Comment,
          handler,
        ),
    );

    this._initiatedListeners = false;
  };

  public leavePageFlow = flow(
    function* (this: CommentsStore) {
      if (!this.moderationStatus) return;

      try {
        yield CommentService.setStopModerationOnPageLeave();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to stop moderation session',
        });
      }
    }.bind(this),
  );

  private timeoutModerationFlow = flow(
    function* (this: CommentsStore) {
      yield this.stopModerationSessionFlow(SessionStoppageReason.Timeout);

      notification.warning({
        description:
          'Your moderation session was stopped because of inactivity.',
        duration: 0,
        message: 'Moderation was stopped',
      });
    }.bind(this),
  );

  private cleanUpAfterStoppedSession = () => {
    clearTimeout(this.activeModerationTimeout);
    this.activeModerationTimeout = undefined;
  };

  public startModerationSessionFlow = flow(
    function* (this: CommentsStore) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus: boolean =
          yield CommentService.setModerationSessionStatus(true);

        this.resetActiveModerationTimeout();
        this.setModerationStatus(moderationStatus);
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  public stopModerationSessionFlow = flow(
    function* (this: CommentsStore, stoppageReason: SessionStoppageReason) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus: boolean =
          yield CommentService.setModerationSessionStatus(
            false,
            stoppageReason,
          );

        this.setModerationStatus(moderationStatus);
        this.cleanUpAfterStoppedSession();
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  // TODO: may need to add additional functionality once we integrate with backend APIs
  public continueModerationFlow = flow<boolean, any[]>(
    function* (this: CommentsStore) {
      try {
        if (!this.moderationStatus) {
          logError({
            skipMessage: true,
            title: 'No comment to continue moderation',
          });

          yield false;
        }

        this.resetActiveModerationTimeout();
        yield true;
      } catch (error) {
        yield false;
      }
    }.bind(this),
  );

  public resetActiveModerationTimeout = () => {
    if (this.activeModerationTimeout) {
      clearTimeout(this.activeModerationTimeout);
    }

    this.setTimeout(); // resetting timeout
    const ms = moment(this._timeout.get()).diff(moment());

    this.activeModerationTimeout = setTimeout(this.timeoutModerationFlow, ms);
  };

  public getModerationSessionStatusFlow = flow(
    function* (this: CommentsStore) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus =
          yield CommentService.getModerationSessionStatus();

        this.setModerationStatus(moderationStatus);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get comments moderation session status',
        });
        this.setModerationStatus(false);
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  public getModerationStatisticFlow = flow(
    function* (this: CommentsStore) {
      try {
        this.setModerationStatisticLoading(true);

        const moderationStatistic = yield CommentService.getStatistic();

        this.setModerationStatistic(moderationStatistic);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get comments moderation statistic',
        });
      } finally {
        this.setModerationStatisticLoading(false);
      }
    }.bind(this),
  );

  public startFlow = flow(
    function* (this: CommentsStore) {
      this.getModerationStatisticFlow();
      this.startListeners();
      yield this.getModerationSessionStatusFlow();
      this.moderationStatsUpdateInterval = setInterval(
        this.getModerationStatisticFlow,
        moderationCommentStatsPollingInterval,
      );
    }.bind(this),
  );

  @action.bound
  public stopFlow = () => {
    this.stopListeners();
    clearTimeout(this.activeModerationTimeout);
    clearInterval(this.moderationStatsUpdateInterval);
    if (this.moderationStatus) {
      this.stopModerationSessionFlow(SessionStoppageReason.Manual);
    }
  };
}
