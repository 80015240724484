import css from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.css';

class RsuCommentPopup extends React.Component<
  RsuCommentPopupProps,
  RsuCommentPopupStates
> {
  public static defaultProps: Partial<RsuCommentPopupProps> = {
    showRSULabel: true,
  };

  public state = {
    isVisible: false,
    isVisibleConfirmationBlock: true,
    text: '',
  };
  public divElement: null | HTMLDivElement;
  public textArea: null | HTMLTextAreaElement;

  public componentWillUpdate(nexProps, nextState) {
    if (nextState.isVisible && nextState.isVisible !== this.state.isVisible) {
      document.body.addEventListener('click', this.handleClick);
    }

    if (!nextState.isVisible && nextState.isVisible !== this.state.isVisible) {
      document.body.removeEventListener('click', this.handleClick);
    }
  }

  public componentDidMount() {
    this.divElement &&
      (this.divElement.style.height = `${this.divElement.scrollHeight}px`);
  }

  public componentWillReceiveProps() {
    this.divElement &&
      (this.divElement.style.height = `${this.divElement.scrollHeight}px`);
  }

  public handleClick = (e) => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.close();
    }
  };

  public open = () => {
    this.setState({ isVisible: true, text: '' });
  };

  public close = () => {
    this.setState({ isVisible: false });
  };

  public toogleContainer = () => {
    this.state.isVisible ? this.close() : this.open();
  };

  public sendHandler = () => {
    this.props.submitAction && this.props.submitAction(this.state.text);
    this.close();
  };

  public deleteButtonHandler = () => {
    this.props.submitAction && this.props.submitAction();
    this.close();
  };
  public editButtonHandler = () => {
    this.props.submitAction && this.props.submitAction(this.textArea.value);
    this.close();
  };

  public showConfirmationContainer = () => {
    this.setState({ isVisibleConfirmationBlock: true });
  };

  public closeConfirmationContainer = () => {
    this.setState({ isVisibleConfirmationBlock: false });
  };

  public renderNewContent = () => {
    const isSendButtonDisabled = this.state.text.length <= 0;

    return (
      <div className={css(styles.deleteContainer)}>
        <div className={css(styles.textContainer)}>
          <textarea
            autoFocus
            className={css('right-row', styles.content, styles.input)}
            placeholder={'Write a comment...'}
            value={this.state.text}
            onChange={(e) => this.setState({ text: e.target.value })}
          />
          <div
            className={css(
              styles.sendButton,
              {
                [styles.disabledSendButton]: isSendButtonDisabled,
                [styles.activeSendButton]: !isSendButtonDisabled,
              },
              'right-row',
            )}
            onClick={() => !isSendButtonDisabled && this.sendHandler()}
          >
            SEND
          </div>
        </div>
      </div>
    );
  };

  public renderDeleteContent = () => {
    return (
      <div className={styles.deleteContainer}>
        <div className={styles.toolsContainer}>
          {/* <span className={styles.closeButton} onClick={this.close}>Close</span> */}
        </div>
        <div className={css(styles.textContainer, 'bottom-column')}>
          {this.props.showRSULabel && (
            <div className={styles.rsuLabel}>RING</div>
          )}
          <div className={css(styles.content)}>
            <div className={styles.commentDate}>{this.props.commentDate}</div>
            <div className={styles.commentText}>{this.props.commentText}</div>
            {this.state.isVisibleConfirmationBlock && (
              <div
                className={css(styles.confirmationContainer, 'bottom-column')}
              >
                <div
                  className={css(
                    styles.submitButton,
                    styles.deleteConfirmButton,
                    'left-row',
                  )}
                  onClick={this.deleteButtonHandler}
                >
                  Delete
                </div>
                <div className={styles.cancelButton} onClick={this.close}>
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  public renderEditContent = () => {
    const onChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
      e.currentTarget.style.height = 'auto';
      e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
      this.divElement.style.height = 'auto';
      this.divElement.style.height = `${e.currentTarget.scrollHeight}px`;
    };

    return (
      <div className={styles.deleteContainer}>
        <div className={css(styles.textContainer, 'bottom-column')}>
          <div className={styles.rsuLabel}>RING</div>
          <div className={css(styles.content)}>
            <div className={styles.commentDate}>{this.props.commentDate}</div>
            <div className={css(styles.commentText)}>
              <div
                className={styles.invisible}
                ref={(e) => (this.divElement = e)}
              >
                {this.props.commentText}
              </div>
              <textarea
                autoFocus
                onChange={onChange}
                className={styles.textarea}
                ref={(e) => (this.textArea = e)}
              >
                {this.props.commentText}
              </textarea>
            </div>

            {this.state.isVisibleConfirmationBlock && (
              <div
                className={css(styles.confirmationContainer, 'bottom-column')}
              >
                <div
                  className={css(
                    styles.submitButton,
                    styles.editConfirmButton,
                    'left-row',
                  )}
                  onClick={this.editButtonHandler}
                >
                  Edit
                </div>
                <div className={styles.cancelButton} onClick={this.close}>
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  public render() {
    if (!this.state.isVisible) return null;
    const { className } = this.props;
    const isDeleteType = this.props.type === 'delete';
    const isNewType = this.props.type === 'new';
    const isEditType = this.props.type === 'edit';
    return (
      <div className={css(styles.container, className)}>
        <div
          className={css(styles.containerArrow, {
            [styles.editArrow]: isEditType,
          })}
        />
        {isDeleteType && this.renderDeleteContent()}
        {isNewType && this.renderNewContent()}
        {isEditType && this.renderEditContent()}
      </div>
    );
  }
}

interface RsuCommentPopupProps {
  type: 'delete' | 'new' | 'edit';
  commentDate?: string;
  commentText?: string;
  submitAction?: Function;
  className?: string;
  showRSULabel?: boolean;
}

interface RsuCommentPopupStates {
  isVisible: boolean;
  isVisibleConfirmationBlock: boolean;
  text: string;
}

export { RsuCommentPopup };
