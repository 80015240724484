import { Form, Modal } from 'antd';
import type {
  FormComponentProps,
  RcBaseFormProps,
  WrappedFormUtils,
} from 'antd/lib/form/Form';
import type { ModalProps } from 'antd/lib/modal/Modal';
import React from 'react';

export type FormUtils = WrappedFormUtils;

export interface IFormModalProps<T = any> extends ModalProps {
  formRenderer: (form: FormUtils) => React.ReactNode;
  onFormValuesChanged?: (formData: T) => void;
  onOk: (formValues: object) => void;
}

class ModalProvider extends React.PureComponent<
  IFormModalProps & FormComponentProps
> {
  public render(): React.ReactElement<ModalProps> {
    const { form, formRenderer, ...modalProps } = this.props;
    return (
      <Modal {...modalProps}>{modalProps.visible && formRenderer(form)}</Modal>
    );
  }
}

export class FormModal<T = any> extends React.PureComponent<
  IFormModalProps<T>
> {
  private readonly formRef: React.RefObject<Form> = React.createRef<Form>();
  private readonly formComponent: React.ComponentClass<
    IFormModalProps<T> & RcBaseFormProps
  > = Form.create({
    onValuesChange: (
      props: IFormModalProps<T> & FormComponentProps,
      changedValues: Partial<T>,
      allValues: T,
    ) => {
      this.handleFormValuesChanged(allValues);
    },
  })(ModalProvider);

  private handleFormValuesChanged = (formData: T) => {
    const { onFormValuesChanged } = this.props;
    if (onFormValuesChanged !== undefined) {
      onFormValuesChanged(formData);
    }
  };

  private handleOk = () => {
    const { form } = this.formRef.current.props;
    const { onOk } = this.props;

    form.validateFields((errors: null | object, values: object) => {
      if (errors !== null) return;

      onOk(values);
    });
  };

  private handleCancel = (e: React.MouseEvent<any, MouseEvent>) => {
    const { form } = this.formRef.current.props;
    const { onCancel } = this.props;

    form.resetFields();
    this.handleFormValuesChanged(undefined);

    if (onCancel !== undefined) {
      onCancel(e);
    }
  };

  public render() {
    const { onFormValuesChanged, ...modalProps } = this.props;

    return (
      <this.formComponent
        {...modalProps}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        wrappedComponentRef={this.formRef}
      />
    );
  }
}
