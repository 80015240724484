import css from 'classnames';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { CommentItemModerationTool } from 'src/components/comment-item-moderation-tool';
import { Label } from 'src/components/label';
import { TextView } from 'src/components/text-view';
import { Timer } from 'src/components/Timer';
import {
  checkIsCommentEscalated,
  isCommentAssigned,
  isCommentNotModerated,
} from 'src/helpers/commentsHelper';
import { Stores, Tabs } from 'src/stores';
import { BadgeAligner } from '../../componentsNew/Badge/components/BadgeAligner';
import {
  ModerationFlag,
  ModerationFlagBadge,
} from '../../componentsNew/Moderation/ModerationBadges/components/ModerationFlagBadge';
import { getModerationBadges } from '../../componentsNew/Moderation/ModerationBadges/helpers/getModerationBadges';
import { ModerationHistory } from '../../componentsNew/Moderation/ModerationHistory';
import { ToggleIconButton } from '../../componentsNew/ToggleIconButton';
import { UserHyperlink } from '../../componentsNew/UserHyperlink';
import { TITLE_DATE_FORMAT } from '../../constants';
import { BlockLoader } from '../../pages/NewsCurationPage/components/BlockLoader';
import { IStoredComment } from '../../restApi/interfaces';
import s from './style.module.css';
import { BadActor } from '../../componentsNew/Alert/components/AlertInfoFields';
import { Icon, IconType } from '../../componentsNew/Icon';
import styled from 'styled-components';
import {
  AlertUserViolations,
  getNumberOfUserViolations,
} from '../../componentsNew/Alert/components/AlertUserViolations';
import { Tooltip } from 'src/componentsNew/Tooltip/Tooltip';

export interface IAlertItemCommentProps extends Partial<Stores> {
  comment: IStoredComment;
  lastItem?: boolean;
  firstItem?: boolean;
  markAsResolvedAction?: Function;
  hasRSUModeration?: boolean;
  canModerated?: boolean;
  onToggleHistory: (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => Promise<void>;
  tabStore: Tabs;
  isEscalate?: boolean;
  isUserOwner?: boolean;
  treatRsuAsNew: boolean;
  isByOriginalPoster?: boolean;
  shouldExpandAssignedComment?: boolean;
}

interface IAlertItemCommentState {
  loadingHistory: boolean;
  showHistory: boolean;
  showUserViolations: boolean;
}

@inject('authStore', 'configStore')
@observer
export class AlertItemComment extends React.Component<
  IAlertItemCommentProps,
  IAlertItemCommentState
> {
  public textCropEnd = 200;
  public timerSectionCount = 5;
  public state = {
    loadingHistory: false,
    showHistory: false,
    showUserViolations: false,
  };

  private toggleShowUserViolations = () => {
    const showUserViolations = !this.state.showUserViolations;

    this.setState({ showUserViolations: showUserViolations });
  };

  private toggleShowHistory = () => {
    const showHistory = !this.state.showHistory;

    this.setShowHistory(showHistory);
  };

  private setShowHistory = (showHistory) => {
    const { comment, onToggleHistory } = this.props;
    const loadingHistory = showHistory;

    this.setState({ loadingHistory, showHistory }, async () => {
      await onToggleHistory(comment.alert_id, comment.comment_id, showHistory);
      this.setState({ loadingHistory: false });
    });
  };

  public componentDidUpdate = () => {
    const { comment } = this.props;
    const { loadingHistory, showHistory } = this.state;

    if (showHistory && !loadingHistory && !comment.history) {
      this.setShowHistory(true);
    }
  };

  public renderMarkAsReadButton = () => (
    <div
      className={css(s.rsuReadButton, 'top-column')}
      onClick={() =>
        this.props.markAsResolvedAction && this.props.markAsResolvedAction()
      }
    >
      Mark as resolved <div className={s.readIcon} />
    </div>
  );

  public render() {
    const { loadingHistory, showHistory, showUserViolations } = this.state;
    const {
      comment,
      firstItem,
      lastItem,
      hasRSUModeration,
      canModerated,
      tabStore,
      isEscalate,
      isUserOwner,
      isByOriginalPoster,
      shouldExpandAssignedComment,
      authStore,
      configStore,
    } = this.props;

    const commentCreatedDate = comment.time_created
      ? moment.unix(comment.time_created)
      : moment();

    const isNotModerated = isCommentNotModerated(comment);

    const isAssigned = isCommentAssigned(
      comment,
      isEscalate && authStore.userEscalationLevel,
    );

    const isCommentEscalated = checkIsCommentEscalated(comment);

    const showEscalationModerationTool =
      canModerated &&
      isNotModerated &&
      isCommentEscalated &&
      isAssigned &&
      isEscalate;

    const showSimpleModerationTool =
      isNotModerated && canModerated && isAssigned;

    const showModerationTool =
      isUserOwner &&
      (showEscalationModerationTool ||
        (!isEscalate && showSimpleModerationTool));

    const isRSU = comment.is_rsu;
    const onRSU = comment.on_rsu;
    const isFlagged = Boolean(comment.flag);
    const hasCopId = Boolean(comment.cop_id);
    const hasUserId = Boolean(comment.user_id);
    const hasNeighborNumber = Boolean(comment.neighbor_number);
    const isBadActor = Boolean(comment.user_data);

    const showTimer =
      !isFlagged &&
      isNotModerated &&
      canModerated &&
      !isRSU &&
      !comment.missed_sla &&
      !comment.is_flagged;

    const showMissedSlaBadge =
      isAssigned && !showTimer && comment.missed_sla && !comment.is_flagged;

    const hasCommentId = Boolean(comment.comment_id);

    return (
      <div
        className={css(
          s.commentContainer,
          lastItem && s.bottomCommentContainer,
          firstItem && s.topCommentContainer,
        )}
      >
        <div className={s.commentContent} data-test-id="commentContent">
          <div className={s.commentHeader} data-test-id="commentHeader">
            {isRSU && <Label type="isRsu" compacted />}
            {hasCopId && isAssigned && (
              <ModerationFlagBadge flag={ModerationFlag.Police} />
            )}
            {hasUserId && (
              <span
                className={css(s.userIdField, { [s.isOP]: isByOriginalPoster })}
              >
                <UserHyperlink
                  userId={comment.user_id}
                  testID="commentUserId"
                />
                {hasNeighborNumber && (
                  <span data-test-id="neighborNumber">
                    &nbsp;(Neighbor{comment.neighbor_number})
                  </span>
                )}
                {isBadActor && comment.user_data.categories.length > 0 && (
                  <>
                    <Tooltip
                      content={
                        comment.user_data.notes
                          ? comment.user_data.notes
                          : 'Empty'
                      }
                    >
                      <CommentBadActor>
                        <Icon iconType={IconType.Exclamation} />
                      </CommentBadActor>
                    </Tooltip>
                    <span className={s.userViolationsHeader}>
                      Classified User Violations (
                      {getNumberOfUserViolations(comment.user_data.categories)})
                    </span>
                    <ToggleIconButton
                      className={s.toggleUserViolationsButton}
                      isOpened={showUserViolations}
                      onToggle={this.toggleShowUserViolations}
                      testID="userViolationsToggleButton"
                    />
                  </>
                )}
              </span>
            )}
            {hasCommentId && (
              <span className={s.commentIdField} data-test-id="commentId">
                {comment.comment_id}
              </span>
            )}
            <span
              className={s.timeAgoField}
              title={commentCreatedDate.format(TITLE_DATE_FORMAT)}
              data-test-id="commentTimeAgoFromNow"
            >
              {commentCreatedDate.fromNow()}
            </span>
            {isFlagged && <div className={s.flaggedIcon} />}
          </div>
          {showUserViolations && (
            <div className={s.userViolationsContainer}>
              <AlertUserViolations
                userData={comment.user_data}
                skipHeader={true}
              />
            </div>
          )}
          <div className={s.commentTextContainer}>
            <TextView
              expanded={isAssigned && shouldExpandAssignedComment}
              text={comment.text}
              highlighted
              cropTextSize={this.textCropEnd}
              testID="commentText"
            />
          </div>
        </div>
        <div
          className={css(s.moderationToolsContainer, {
            [s.moderationToolsContainerHeight]: !showHistory,
          })}
        >
          {showHistory &&
            (comment.history ? (
              <ModerationHistory
                history={comment.history}
                testID="commentHistoryItem"
              />
            ) : (
              loadingHistory && <BlockLoader />
            ))}
          {!showHistory && !showModerationTool && (
            <div className={s.labelsContainer}>
              <BadgeAligner data-test-id="badgeContainer">
                {getModerationBadges(comment)}
              </BadgeAligner>
            </div>
          )}
          {!showHistory && showModerationTool && (
            <CommentItemModerationTool
              tabStore={tabStore}
              comment={comment}
              isEscalate={isEscalate}
            />
          )}
        </div>
        <div className={s.commentModerationContainer}>
          {hasRSUModeration && onRSU && this.renderMarkAsReadButton()}
        </div>
        <div className={s.timerContainer}>
          {showTimer && (
            <Timer
              showTime
              wrapperClass={s.timer}
              createdTime={moment.unix(comment.time_created).valueOf()}
              maxDuration={
                configStore.commentsOptions
                  ? configStore.commentsOptions.time_until_missed_sla
                  : 0
              }
              markersCount={this.timerSectionCount}
              testID="commentTimer"
            />
          )}
          {showMissedSlaBadge && (
            <ModerationFlagBadge flag={ModerationFlag.MissedSla} />
          )}
          <ToggleIconButton
            className={s.toggleButton}
            isOpened={showHistory}
            onToggle={this.toggleShowHistory}
            testID="historyToggleButton"
          />
        </div>
      </div>
    );
  }
}

export const CommentBadActor = styled(BadActor)`
  .Icon {
    top: 0;
  }
`;
