import React from 'react';
import { WORD_LANGUAGES, WORD_TYPES } from '../../../constants';
import { FormModal } from '../components/FormModal';

interface IFormModalContainerProps {
  modalLoading: boolean;
  onCancel: (event: React.MouseEvent<any>) => void;
  onSubmit: Function;
  visible: boolean;
}

interface IFormModalContainerState {
  approvedWords: object;
  previewMode: boolean;
  language: string;
  words: string;
  wordType: string;
}

const defaultState: IFormModalContainerState = {
  approvedWords: {},
  language: WORD_LANGUAGES[0].value,
  previewMode: false,
  wordType: WORD_TYPES[0].value,
  words: '',
};

export class FormModalContainer extends React.Component<
  IFormModalContainerProps,
  IFormModalContainerState
> {
  public state = defaultState;

  public getApprovedWordsTxt = (formWords) => {
    return Object.keys(formWords)
      .reduce((acc, val) => {
        if (formWords[val] === true) {
          return [...acc, val];
        }
        return acc;
      }, [])
      .join('\n');
  };

  public onLanguageChange = (language) => this.setState({ language });

  public onPreviewModeChange = (previewMode) => {
    if (previewMode === true) {
      return this.setState({
        previewMode,
        approvedWords: this.parseTextWords().reduce(
          (acc, val: string) => ({
            ...acc,
            [val]: true,
          }),
          {},
        ),
      });
    }
    return this.setState({ previewMode });
  };

  public onPreviewWordsToAdd = (id) => (e) => {
    this.setState((prevState) => ({
      approvedWords: {
        ...prevState.approvedWords,
        [id]: e.target.checked,
      },
    }));
  };

  public onSubmit = async () => {
    const { onSubmit } = this.props;
    const { previewMode, approvedWords, wordType, language } = this.state;
    const approvedWordsArr = this.getApprovedWordsTxt(approvedWords);
    const wordsToAdd = previewMode
      ? this.parseTextWords(approvedWordsArr)
      : this.parseTextWords();

    onSubmit(wordsToAdd, wordType, language);
  };

  public onWordsChange = (words) => this.setState({ words });

  public onWordTypeChange = (wordType) => this.setState({ wordType });

  public parseTextWords = (words = this.state.words) => {
    return words
      .split('\n')
      .map((phrase) => phrase.trim())
      .filter((phrase) => phrase !== '');
  };

  public resetState = () => {
    this.setState(defaultState);
  };

  public render() {
    const { modalLoading, onCancel, visible } = this.props;
    const {
      approvedWords,
      previewMode,
      language,
      words,
      wordType,
    } = this.state;
    return (
      <FormModal
        afterClose={this.resetState}
        approvedWords={approvedWords}
        closable={false}
        language={language}
        modalLoading={modalLoading}
        onLanguageChange={this.onLanguageChange}
        onPreviewModeChange={this.onPreviewModeChange}
        onPreviewWordsToAdd={this.onPreviewWordsToAdd}
        onSubmit={this.onSubmit}
        onWordsChange={this.onWordsChange}
        onWordTypeChange={this.onWordTypeChange}
        onCancel={onCancel}
        previewMode={previewMode}
        title={previewMode ? 'Preview' : null}
        visible={visible}
        words={words}
        wordType={wordType}
      />
    );
  }
}
