import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Switch } from 'react-router';
import { AlignedLoader } from 'src/components/AlignedLoader';
import { NavigationTabs } from 'src/constants';
import { PrivateRoute } from 'src/PrivateRoute';
import { useStores } from 'src/stores';
import {NewNewsAlertPageV2} from "./pages/NewNewsAlertPageV2";
import {EditNewsAlertPageV2} from "./pages/EditNewsAlertPageV2";
import {CurationPageV2} from "./pages/CurationPage/CurationPageV2";

export const NewsCurationPageV2 = observer(() => {
  const { newsListStoreV2, newsListUIStoreV2, newsOptionsStoreV2 } = useStores();

  useEffect(() => {
    newsOptionsStoreV2.loadOptionsFlow();

    return () => {
      newsListStoreV2.clear();
      newsListUIStoreV2.clearCache();
    };
  }, []); // eslint-disable-line

  if (newsOptionsStoreV2.loading) return <AlignedLoader />;

  return (
    <Switch>
      <PrivateRoute
        exact
        path={NavigationTabs.CURATION_V2_NEW.path}
        component={NewNewsAlertPageV2}
      />
      <PrivateRoute
        exact
        path={NavigationTabs.CURATION_V2_EDIT.path}
        component={EditNewsAlertPageV2}
      />
      <PrivateRoute
        exact
        path={NavigationTabs.CURATION_V2.path}
        component={CurationPageV2}
      />
    </Switch>
  );
});
