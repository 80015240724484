import queryString from 'query-string';
import { api } from 'src/api';
import { ALERTS_FETCH_LIMIT } from '../constants';
import { ISearchParams } from '../interfaces';
import { logError } from '../stores/utils/errorLogger';
import { IModerationSessionStatus, IPersonDescriptionData, IVehicleDescriptionData } from './interfaces';
import {
  ICommonHistory,
  IPublicAssistanceDetailsData,
  IUserData,
  ModerationStatus,
} from './interfaces';
import { IResponseItem, IResponseList } from './restApi';

export interface ICaseInformation {
  agency_name?: string | null;
  case_number?: string | null;
  is_crime?: boolean | null;
  officer_name?: string | null;
  phone_number?: string | null;
  resolved?: boolean | null;
  resolved_message?: string | null;
}

export enum AlertType {
  Image = 'image_alert',
  Ring = 'ring_alert',
  Text = 'text_alert',
  Video = 'video_alert',
  PublicAssistance = 'public_assistance',
}

export enum NHAdminAlertType {
  User = 0,
  RegionalAnnouncement = 1,
  Police = 2,
  News = 3,
  PublicAssistance = 4
}

export enum SessionStoppageReason {
  Manual = 'manual',
  Timeout = 'timeout',
}

type FlagReason = string;

export interface IAlertFlagReason {
  count: number;
  name: FlagReason;
}

export interface IAlert extends ICaseInformation {
  address?: string | null;
  alert_id: number;
  alert_type: AlertType;
  assistance_details?: IPublicAssistanceDetailsData;
  category_ids?: number[];
  cop?: boolean;
  created_at?: number | null;
  description?: string;
  device_id?: string | null;
  ding_id?: string | null;
  email?: string | null;
  flag_reasons?: IAlertFlagReason[] | null;
  free_note?: string | null;
  image_url?: string | null;
  is_deferred?: boolean;
  is_flagged?: number;
  is_l1?: boolean;
  is_l2?: boolean;
  media_assets?: [] | null;
  missed_sla?: boolean;
  moderation_time_s?: number;
  on_rsu?: boolean;
  reason: string[] | null;
  src?: string | null;
  status?: ModerationStatus;
  time_created: number;
  time_created_day: string;
  time_updated?: number;
  title?: string;
  user_id?: number;
  user_selected_category?: string;
  intent?: string;
  share_url?: string | null;
  is_deleted_by_user?: boolean;
  user_name?: string; // This is the Agency Name when cop == true
  user_data?: IUserData;
  nhadmin_alert_type?: NHAdminAlertType | null;
  person_descriptions: IPersonDescriptionData[];
  vehicle_descriptions: IVehicleDescriptionData[];
}

export interface IAlertMeta {
  reserved_timeout?: string;
}

export type IAlertHistory = {
  action: {
    alert_id: number;
    flag_reason?: string;
    missed_sla?: boolean;
    user_selected_category?: string;
  };
} & ICommonHistory;

export interface IAlertSubCategory {
  id: number;
  title: string;
}

export interface IAlertCategory {
  subcategory: IAlertSubCategory[];
  title: string;
  except_for: string[] | null;
}

export interface IAlertCategories {
  approve: IAlertCategory[];
  decline: IAlertCategory[];
}

export interface IAlertTypeStatistics {
  amount: number;
  age_of_oldest: number;
}

export interface IAlertModerationStatistic {
  sessions: number;
  basic_alerts: IAlertTypeStatistics;
  l1_alerts?: IAlertTypeStatistics;
  l2_alerts?: IAlertTypeStatistics;
}

export interface IAlertsConfig {
  time_until_missed_sla: number;
}

export class AlertsService {
  public static async getStatistic() {
    const response = await api
      .get('alerts/statistics')
      .json<IResponseItem<IAlertModerationStatistic>>();

    return response.data;
  }

  public static async getModerationSessionStatus() {
    const response = await api
      .get('alerts/moderation-session')
      .json<IResponseItem<IModerationSessionStatus>>();

    return response.data.active;
  }

  public static async setModerationSessionStatus(
    status: boolean,
    stoppageReason?: SessionStoppageReason,
  ) {
    const response = await api
      .put('alerts/moderation-session', {
        json: {
          active: status,
          stoppage_reason: stoppageReason,
        },
      })
      .json<IResponseItem<IModerationSessionStatus>>();

    return response.data.active;
  }

  public static async setStopModerationOnPageLeave() {
    const payload = { active: false };

    // TODO: Replace fetch request when chrome issue will be fixed. Details: http://crbug.com/490015
    // const blobPayload = new Blob(
    //   [JSON.stringify(payload)],
    //   { type : 'application/json; charset=UTF-8' },
    // );
    // navigator.sendBeacon(
    //   `${baseUrl}/api/v1/alerts/moderation-session`,
    //   blobPayload,
    // );

    // Cannot make cors request as preflight request for request with keepalive specified is currently not supported (06.2019)
    // Which is required for local development
    fetch(`/api/v1/alerts/moderation-session`, {
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      keepalive: true,
      method: 'PUT',
      mode: 'same-origin',
    });
  }

  public static async assignAlert() {
    const { data, meta } = await api
      .patch('alerts/assign')
      .json<IResponseItem<IAlert | null>>();

    return { alert: data, meta };
  }

  public static async getAlertHistory(id: number) {
    const response = await api
      .get(`alert/${id}/history`)
      .json<IResponseItem<IAlertHistory[]>>();

    return response.data;
  }

  public static async approveAlert(
    id: number,
    categoryId: number,
    note = '',
    sendToRSU = false,
  ) {
    if (sendToRSU) return AlertsService.sendToRsu(id, categoryId, note);

    const response = await api
      .patch(`alerts/${id}`, {
        json: {
          category_ids: [categoryId],
          free_note: note,
          status: ModerationStatus.Approved,
        },
      })
      .json<IResponseItem<IAlert>>();

    return response.data;
  }

  public static async sendToRsu(id: number, categoryId: number, note = '') {
    const response = await api
      .patch(`alerts/${id}/rsu`, {
        json: {
          category_ids: [categoryId],
          free_note: note,
        },
      })
      .json<IResponseItem<IAlert>>();

    return response.data;
  }

  public static async declineAlert(id: number, categoryId: number, note = '') {
    const response = await api
      .patch(`alerts/${id}`, {
        json: {
          category_ids: [categoryId],
          free_note: note,
          status: ModerationStatus.Declined,
        },
      })
      .json<IResponseItem<IAlert>>();

    return response.data;
  }

  public static async escalateAlert(
    id: number,
    categoryId?: number,
    note?: string,
  ) {
    const response = await api
      .patch(`alerts/${id}/escalate`, {
        json: {
          category_ids: categoryId ? [categoryId] : undefined,
          free_note: note,
        },
      })
      .json<IResponseItem<IAlert>>();

    return response.data;
  }

  public static async getConfig() {
    const response = await api
      .get('alerts/options')
      .json<IResponseItem<IAlertsConfig>>();

    return response.data;
  }

  // Old API

  public static getAlertsByIds = async (ids: number[]) => {
    try {
      const response = await api
        .post('alerts_by_ids', {
          json: { ids },
        })
        .json<IResponseItem<{ alerts: IAlert[] }>>();

      return response.data.alerts;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to get alerts',
      });
    }

    return [];
  };

  public static getAlert = async (alertId: number) => {
    try {
      const response = await api
        .get(`alert/${alertId}`)
        .json<IResponseItem<IAlert>>();

      return response.data;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to get an alerts',
      });
    }

    return undefined;
  };

  public static getAlerts = async (
    escalationLevel: string = undefined,
    isRSU = false,
  ) => {
    try {
      const requestParams: ISearchParams = {
        limit: String(ALERTS_FETCH_LIMIT),
      };

      if (escalationLevel) {
        requestParams[escalationLevel] = 'true';
      }

      if (isRSU) {
        requestParams.on_rsu = 'true';
      }

      const query = queryString.stringify(requestParams);

      const response = await api
        .get(`alerts?${query}`)
        .json<IResponseList<IAlert>>();

      return response.data.items;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to get alerts',
      });

      return [];
    }
  };

  public static getAlertsWithComments = async (types: string[] = []) => {
    try {
      const limit = { limit: String(ALERTS_FETCH_LIMIT) };

      const requestParams =
        types.length > 0
          ? types.reduce(
            (acc, curr) => {
              return curr
                ? {
                  ...acc,
                  [curr]: 'true',
                }
                : acc;
            },
            { ...limit },
          )
          : limit;

      const query = queryString.stringify(requestParams);

      const response = await api
        .get(`alerts_with_comments?${query}`)
        .json<IResponseList<IAlert>>();

      return response.data.items;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to get alerts with comments',
      });
    }

    return [];
  };

  public static getAlertWithComments = async (alertId: number) => {
    try {
      if (isNaN(alertId) || !Number(alertId)) {
        const error = new Error(
          'AlertsService.getAlertWithComments:alertId is not defined',
        );
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get an alert',
        });
        return undefined;
      }

      const response = await api
        .get(`alert_with_comments/${alertId}`)
        .json<IResponseItem<IAlert>>();

      return response.data;
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to get an alert with comments',
      });
    }

    return undefined;
  };
}
