import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tabs,
  Upload,
} from 'antd';
import cx from 'classnames';
import React from 'react';
import { BlockLayout } from 'src/components/BlockLayout';
import { CommonModal } from 'src/components/CommonModal';
import { WORD_LANGUAGES, WORD_TYPES } from '../../../constants';
import './form-modal.less';

export interface IFormModalProps {
  afterClose: () => void;
  approvedWords: object;
  closable: boolean;
  language: string;
  modalLoading: boolean;
  onCancel: (event: React.MouseEvent<any>) => void;
  onLanguageChange: (lang: string) => void;
  onPreviewModeChange: (mode: boolean) => void;
  onPreviewWordsToAdd: Function;
  onWordsChange: (words: string) => void;
  onWordTypeChange: (type: string) => void;
  onSubmit: () => void;
  previewMode: boolean;
  title: string;
  visible: boolean;
  words: string;
  wordType: string;
}

export const FormModal = ({
  language,
  modalLoading,
  onPreviewModeChange,
  onLanguageChange,
  onWordsChange,
  onWordTypeChange,
  onSubmit,
  onPreviewWordsToAdd,
  previewMode,
  approvedWords,
  words,
  wordType,
  ...modalProps
}: IFormModalProps) => {
  const wordsLength = words.trim().length;
  return (
    <CommonModal
      {...modalProps}
      width={600}
      destroyOnClose
      className={cx('FormModal', { FormModalPreview: previewMode })}
      footer={
        <div className="ModalFooter">
          <BlockLayout
            left={
              <Button
                htmlType="button"
                type="default"
                size="large"
                onClick={modalProps.onCancel}
              >
                Cancel
              </Button>
            }
            right={
              <div key="footerControls" className="nha-block-align-right">
                <Button
                  disabled={!wordsLength || modalLoading}
                  htmlType="button"
                  onClick={() => {
                    previewMode
                      ? onPreviewModeChange(false)
                      : onPreviewModeChange(true);
                  }}
                  size="large"
                  type="default"
                >
                  {previewMode ? 'Edit' : 'Preview'}
                </Button>
                <Button
                  disabled={!wordsLength}
                  htmlType="button"
                  loading={modalLoading}
                  onClick={onSubmit}
                  size="large"
                  type="primary"
                >
                  Add words
                </Button>
              </div>
            }
          />
        </div>
      }
    >
      {previewMode ? (
        <div className="FormModalWordsList">
          <div className="FormModalWordsListTitle">Words to add</div>
          {Object.keys(approvedWords).map((word, i) => (
            <div className="FormModalWordsListItem" key={i}>
              <Checkbox
                onChange={onPreviewWordsToAdd(word)}
                checked={approvedWords[word]}
              >
                {word}
              </Checkbox>
            </div>
          ))}
        </div>
      ) : (
        <Tabs
          defaultActiveKey="form"
          className="FormModalTabs"
          tabPosition="top"
        >
          <Tabs.TabPane tab="Type words" key="form">
            <Form>
              <Form.Item label="Words to add">
                <Input.TextArea
                  value={words}
                  onChange={(e) => onWordsChange(e.target.value)}
                  autosize={{ minRows: 3, maxRows: 8 }}
                  autoFocus
                />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Add to category">
                    <Select onChange={onWordTypeChange} value={wordType}>
                      {WORD_TYPES.map((wordType, i) => (
                        <Select.Option key={String(i)} value={wordType.value}>
                          {wordType.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Language">
                    <Select onChange={onLanguageChange} value={language}>
                      {WORD_LANGUAGES.map((lang, i) => (
                        <Select.Option key={String(i)} value={lang.value}>
                          {lang.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Upload a file" key="upload" disabled>
            <Upload.Dragger name="files" action="/upload.do">
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </Tabs.TabPane>
        </Tabs>
      )}
    </CommonModal>
  );
};
