import { api } from 'src/api';
import type { IResponseItem, IResponseList } from 'src/restApi/restApi';

export interface ManageUsersMeta {
  limit: number;
  offset: number;
  total_items: number;
  found_items: number;
}

export interface ICategory {
  id: string;
  name: string;
}

export interface IStatus {
  status: number;
}

export interface IUser {
  creation_time: string;
  last_update_time: string;
  status: string;
  user_id: string;
  user_type: string;
}

export interface UpdateUsersParams {
  user_id: string;
  status: string;
  moderator: string;
  note?: string;
}

export interface UpdateUsersMeta {
  message: string;
  error: string;
}

export interface ManageUsersSearchParams {
  status?: string;
  limit?: number;
  offset?: number;
  category_id?: number;
  subcategory_ids?: number[];
  user_id?: string;
}

export class ManageUsersService {
  public static getCategories = () =>
    api.get(`users/categories`).json<IResponseList<ICategory, IStatus>>();

  public static getUser = (id: string) => {
    return api.get(`users/${id}`).json<IResponseItem<IUser>>();

    // NOTE: the error response from Service is { status: 1 }
  };

  public static getUserHistory = (id: string) => {
    return api.get(`users/${id}/history`).json();
  };

  public static getUsers = (params: string) => {
    return api
      .get(`users?${params}`)
      .json<IResponseList<IUser, ManageUsersMeta>>();
  };

  public static updateUser = (payload: UpdateUsersParams) => {
    return api
      .post(`users`, { json: payload })
      .json<IResponseItem<IUser, UpdateUsersMeta>>();
  };

  public static removeUser = (id: string) => {
    return api.delete(`users/${id}`).json<IStatus>();
  };
}
