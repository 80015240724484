import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteProps, Switch } from 'react-router-dom';
import {
  alertsTabActions,
  commentsTabActions,
  escalateTabActions,
  rsuTabActions,
} from 'src/actions';
import { AuthenticateWaiting } from 'src/pages/authorization-waiting';
import { useStores } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import { Loader } from './components/Loader';
import { PageWrapper } from './components/page-wrapper';
import { NavigationTabs } from './constants';

export const PrivateRouter = observer<React.FC<RouteProps>>(
  ({ path, children }) => {
    const [initializing, setInitializing] = useState(false);
    const { authStore, configStore, navigationStore } = useStores();
    const { isLoggedIn } = authStore;

    const setCurrentTab = useCallback(() => {
      const currentTab = Array.isArray(path) ? path[0] : path;

      if (!currentTab) return;

      navigationStore.setCurrentTab(currentTab);
    }, [navigationStore, path]);

    useEffect(() => {
      const start = async () => {
        setInitializing(true);

        await configStore.fetchConfigurations();

        setInitializing(false);

        alertsTabActions.startListeners();
        commentsTabActions.startListeners();
        escalateTabActions.startListeners();

        if (authStore.hasUserRsuRole) {
          rsuTabActions.startListeners();
        }

        WsConnector.runWebsocket();
      };

      const stop = () => {
        WsConnector.stopWebsocket();
      };

      autorun(
        (r) => {
          const { isLoggedIn } = authStore;

          if (isLoggedIn) {
            start();
          } else {
            stop();
          }
        },
        { name: 'Auth' },
      );

      setCurrentTab();

      return () => {
        stop();
      };
    }, [authStore, configStore, setCurrentTab]);

    useEffect(() => {
      setCurrentTab();
    });

    if (!isLoggedIn) return <AuthenticateWaiting />;

    if (initializing) {
      return (
        <PageWrapper>
          <Loader />
        </PageWrapper>
      );
    }

    return (
      <Switch>
        {!isLoggedIn && <Redirect to={NavigationTabs.SIGNIN.path} />}
        {isLoggedIn && path === NavigationTabs.SIGNIN.path && (
          <Redirect to="/" />
        )}
        {isLoggedIn && children}
      </Switch>
    );
  },
);
