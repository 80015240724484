import { ICommentsConfig } from 'src/restApi/commentService';
import { api } from '../api/api';
import {
  IApprovalCategoriesRaw,
  IAutoApproveConfig,
  ICloudWatchResponse,
} from '../restApi/interfaces';
import { IResponseItem } from '../restApi/restApi';

export class ConfigService {
  public static async fetchAutoApproveConfig() {
    const result = await api
      .get('autoapprove/config')
      .json<IResponseItem<IAutoApproveConfig>>();

    return result.data;
  }

  public static async fetchCategories() {
    const result = await api
      .get('categories')
      .json<IResponseItem<IApprovalCategoriesRaw>>();

    return result.data;
  }

  public static async fetchCommentsOptions() {
    const result = await api
      .get('comments/options')
      .json<IResponseItem<ICommentsConfig>>();

    return result.data;
  }

  public static async fetchCloudWatchConfig(clientId: string) {
    const result = await api
      .get(`config/${clientId}/cloudwatch`)
      .json<IResponseItem<ICloudWatchResponse>>();

    return result.data;
  }
}
