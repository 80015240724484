import { Alert } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Stores } from 'src/stores';
import { CriticalError } from './componentsNew/CriticalError';

type PrivateRouteProps = RouteProps &
  Pick<Partial<Stores>, 'authStore' | 'navigationStore'>;

@inject('authStore', 'navigationStore')
@observer
export class PrivateRoute extends React.Component<PrivateRouteProps> {
  public state = {
    error: null,
  };

  public componentDidCatch(error: Error) {
    this.setState({ error });
  }

  private updateCurrentTab = () => {
    const { navigationStore } = this.props;

    navigationStore.setCurrentTab(window.location.pathname);
  };

  public componentDidUpdate(prevProps: Readonly<PrivateRouteProps>) {
    const { path } = this.props;
    const { error } = this.state;

    this.updateCurrentTab();

    if (error && path !== prevProps.path) {
      this.setState({ error: null });
    }
  }

  public componentDidMount() {
    this.updateCurrentTab();
  }

  public checkIsPathPermitted = (path: string) => {
    const { authStore } = this.props;
    const pathPermissions = this.props.navigationStore!.pathsPermissions[path];

    if (!pathPermissions) return false;

    const permissions = Array.isArray(pathPermissions)
      ? pathPermissions
      : pathPermissions(authStore.user.scope);

    return (authStore.user.scope || []).some((role) =>
      permissions.includes(role),
    );
  };

  public renderNoPermissionMessage = () => (
    <Alert
      message="You do not have permissions to access this page. Please contact your system administrator."
      type="error"
    />
  );

  public renderRedirect(props: RouteProps) {
    const { navigationStore } = this.props;
    const redirectNavigation = navigationStore.permittedPaths[0];

    return redirectNavigation ? (
      <Redirect
        to={{
          pathname: redirectNavigation.path,
          state: { from: props.location },
        }}
      />
    ) : (
      this.renderNoPermissionMessage()
    );
  }

  public render() {
    const { error } = this.state;
    const { authStore, component: Component, path, ...rest } = this.props;

    if (error) {
      return <CriticalError key="criticalError" />;
    }

    const pathArr = Array.isArray(path) ? path : [path];
    const routeKey = pathArr[0];
    const isPathPermitted = pathArr.some(this.checkIsPathPermitted);
    const hasAccess = authStore.isLoggedIn && isPathPermitted;

    return (
      <Route
        key={routeKey}
        {...rest}
        render={(props) =>
          hasAccess ? <Component {...props} /> : this.renderRedirect(props)
        }
      />
    );
  }
}
