import css from 'classnames';
import React from 'react';
import { AlertInfoFields } from '../../componentsNew/Alert/components/AlertInfoFields/AlertInfoFields';
import { hasAlertCaseInfo } from '../../helpers/alertHelpers';
import { IStoredAlert } from '../../restApi/interfaces';
import { AlertPoliceCaseBlock } from '../AlertPoliceCaseBlock';
import { ALERT_BAD_WORDS_TYPES, WordsHighlighter } from '../WordsHighlighter';
import s from './style.module.css';

export class AlertItemDescription extends React.PureComponent<
  IAlertItemDescriptionProps
> {
  public renderHighlighter = (text: string = '') => (
    <WordsHighlighter type={ALERT_BAD_WORDS_TYPES} textToHighlight={text} />
  );

  public render() {
    const { alert } = this.props;
    const { description, title } = alert;
    const {
      agency_name,
      case_number,
      is_crime,
      officer_name,
      phone_number,
      resolved,
      resolved_message,
    } = alert;
    const isCaseInfo = hasAlertCaseInfo(alert);

    return (
      <div
        className={css(s.alertItemDescriptionContainer, this.props.className)}
      >
        <div className={css(s.titleText)}>
          {title && (
            <div className={css(s.title)} data-test-id="alertTitle">
              {this.renderHighlighter(title)}
            </div>
          )}
          {description && (
            <div
              className={css(s.description, 'bottom-column')}
              data-test-id="alertDescription"
            >
              {this.renderHighlighter(description)}
            </div>
          )}
        </div>
        {isCaseInfo && (
          <div className={css(s.metaInfoCaseContainer, 'bottom-column')}>
            <AlertPoliceCaseBlock
              caseInformation={{
                agency_name,
                case_number,
                is_crime,
                officer_name,
                phone_number,
                resolved,
                resolved_message,
              }}
            />
          </div>
        )}
        <div className={css(s.metaInfoContainer, 'bottom-column')}>
          <AlertInfoFields alert={alert} />
        </div>
      </div>
    );
  }
}

export interface IAlertItemDescriptionProps {
  alert: IStoredAlert;
  className?: string;
}
