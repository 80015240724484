import { api } from 'src/api';
import { IUser } from 'src/interfaces';
import { computed, flow, observable } from 'mobx';
import { IResponseItem } from 'src/restApi/restApi';
import { getUserEscalationLevel } from 'src/helpers';
import { EscalationLevelProperties } from '../constants';
import { DD } from '../helpers/datadogLogs';
import { PermissionLevel } from '../restApi/interfaces';
import { InjectRootStore } from './injectRootStore';

export class AuthStore extends InjectRootStore {
  @observable.ref public user: IUser | null = null;

  @computed get userEscalationLevel() {
    if (!this.isLoggedIn) return null;

    return getUserEscalationLevel(this.user!);
  }

  @computed get hasUserEscalationLevel() {
    return this.userEscalationLevel !== null;
  }

  @computed get hasUserRsuRole() {
    if (!this.isLoggedIn) return null;

    return this.user!.scope.includes(PermissionLevel.Rsu);
  }

  @computed get isLoggedIn() {
    return this.user !== null;
  }

  public getAuthUrl = async () => {
    try {
      const response = await api
        .get('auth')
        .json<IResponseItem<{ url: string }>>();

      window.location.href = response.data.url;
    } catch (e) {
      console.error(`Error getting auth url ${e}`);
    }
  };

  public getUser = flow(
    function* (this: AuthStore) {
      try {
        const response = yield api
          .get('user')
          .json<IResponseItem<{ user: IUser }>>();

        const {
          data: { user },
        } = response;

        if (!user.email) throw new Error('User does not have an email set');

        this.user = user;

        this.rootStore.configStore.installLogger();

        DD.setUser(user.email);
      } catch (e) {
        console.error('Error getting user', e);
      }
    }.bind(this),
  );

  public logout = flow(
    function* (this: AuthStore) {
      try {
        yield api.delete('user');
      } catch (e) {
        console.error('Error logging out', e);
      } finally {
        this.user = null;
        DD.setUser(null);
      }
    }.bind(this),
  );

  public userEscalationLevelMatched = (level: string) => {
    return this.userEscalationLevel === level;
  };

  public isUserEscalationLevel = (
    level: PermissionLevel.L1 | PermissionLevel.L2,
  ) => {
    return this.userEscalationLevel === EscalationLevelProperties[level];
  };

  public checkUserPermission(permisionToCheck: string) {
    if (!this.user?.scope) return false;

    return this.user.scope.includes(permisionToCheck);
  }
}
