import { notification } from 'antd';
import { action, computed, flow, observable, reaction, toJS } from 'mobx';
import moment from 'moment';
import { leaveModerationConfirmation } from 'src/features/LeaveModerationConfirmation';
import {
  AlertsService,
  IAlert,
  IAlertHistory,
  IAlertMeta,
  IAlertModerationStatistic,
  IAlertsConfig,
  SessionStoppageReason,
} from 'src/restApi/alertsService';
import { LoadingState } from 'src/restApi/interfaces';
import { WsConnector } from 'src/wsConnector';
import {
  alertPollingInterval,
  moderationStatsPollingInterval,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';
import { getAlertTimeUntilMissedSLA } from '../helpers';
import { getAlertEscalationLevel } from '../helpers/alertHelpers';
import { InjectRootStore } from './injectRootStore';
import { RootStore } from './rootStore';
import { logError } from './utils/errorLogger';

type SocketHandler = (data: Partial<IAlert>) => void;

export class AlertsStore extends InjectRootStore {
  private readonly _alert = observable.box<IAlert | null>(null);
  private readonly _alertMeta = observable.box<IAlertMeta>({});
  private readonly _alertLoadingCounter = observable.box<number>(0);
  private readonly _history = observable.box<IAlertHistory[] | null>(null);
  private readonly _historyLoadingState = observable.box<LoadingState>(
    LoadingState.NotLoaded,
  );
  private readonly _moderationStatus = observable.box<boolean>(false);
  private readonly _moderationSessionLoadingCounter = observable.box<number>(0);
  private readonly _moderationStatistic = observable.box<IAlertModerationStatistic | null>(
    null,
  );
  private readonly _moderationStatisticLoading = observable.box<boolean>(false);
  private readonly _config = observable.box<IAlertsConfig | null>(null);
  private timer: number | undefined;
  private _initiatedListeners = false;
  private missSLATimeout: number | undefined;
  private activeModerationTimeout: number | undefined;
  private moderationStatsUpdateInterval: number | undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    reaction(
      () => this.moderationStatus,
      (moderationStatus) => {
        if (moderationStatus) {
          leaveModerationConfirmation.enableConfirmation(this.leavePageFlow);
        } else {
          leaveModerationConfirmation.disableConfirmation(this.leavePageFlow);
        }

        this.getModerationStatisticFlow();
      },
    );

    // Load statistic on every user decision
    reaction(
      () => this.alert,
      () => this.getModerationStatisticFlow(),
    );
  }

  @computed get alert(): IAlert | null {
    return toJS(this._alert.get());
  }

  @computed get alertMeta(): IAlertMeta {
    return toJS(this._alertMeta.get());
  }

  @computed get isAlertProcessing() {
    return this._alertLoadingCounter.get() > 0;
  }

  @computed get history(): IAlertHistory[] | null {
    return toJS(this._history.get());
  }

  @computed get historyLoadingState(): LoadingState {
    return this._historyLoadingState.get();
  }

  @computed get moderationStatus() {
    return this._moderationStatus.get();
  }

  @computed get isModerationSessionProcessing() {
    return this._moderationSessionLoadingCounter.get() > 0;
  }

  @computed get moderationStatistic(): IAlertModerationStatistic | null {
    return toJS(this._moderationStatistic.get());
  }

  @computed get isModerationStatisticLoading() {
    return this._moderationStatisticLoading.get();
  }

  @computed get config(): IAlertsConfig | null {
    return toJS(this._config.get());
  }

  @computed get maxTimeUntilMissedSLA() {
    const config = this._config.get();

    return config?.time_until_missed_sla;
  }

  @computed get canMissSLA() {
    const alert = this._alert.get();

    if (!alert) return false;

    return !alert.missed_sla && !alert.is_flagged;
  }

  @action private setAlert = (
    alert: IAlert | null,
    alertMeta: IAlertMeta = {},
  ) => {
    this._alert.set(alert);
    this._alertMeta.set(alertMeta);

    this.createMissedSLATimeout();
    this.createActiveModerationTimeout();
  };

  private createMissedSLATimeout = () => {
    if (this.missSLATimeout) {
      clearTimeout(this.missSLATimeout);
    }

    const alert = this._alert.get();

    if (alert && this.canMissSLA && this.maxTimeUntilMissedSLA) {
      this.missSLATimeout = setTimeout(
        this.missSLAFlow,
        getAlertTimeUntilMissedSLA(alert, this.maxTimeUntilMissedSLA),
      );
    }
  };

  private createActiveModerationTimeout = () => {
    if (this.activeModerationTimeout) {
      clearTimeout(this.activeModerationTimeout);
    }

    const { reserved_timeout } = this.alertMeta;

    if (!this.alert || !reserved_timeout) return;

    const ms = moment(reserved_timeout).diff(moment());

    this.activeModerationTimeout = setTimeout(this.timeoutModerationFlow, ms);
  };

  @action private setLoadingState = (loadingState: boolean) => {
    const alertLoadingCount = this._alertLoadingCounter.get();
    const nextValue = loadingState
      ? alertLoadingCount + 1
      : alertLoadingCount - 1;
    this._alertLoadingCounter.set(nextValue >= 0 ? nextValue : 0);
  };

  @action private setHistory = (history: IAlertHistory[] | null) => {
    this._history.set(history);
  };

  @action private setHistoryLoadingState = (loadingState: LoadingState) => {
    this._historyLoadingState.set(loadingState);
  };

  @action private setModerationStatus = (moderationStatus: boolean) => {
    this._moderationStatus.set(moderationStatus);
  };

  @action private setModerationSessionLoading = (loadingState: boolean) => {
    const moderationSessionLoadingCount = this._moderationSessionLoadingCounter.get();

    const nextValue = loadingState
      ? moderationSessionLoadingCount + 1
      : moderationSessionLoadingCount - 1;

    this._moderationSessionLoadingCounter.set(nextValue >= 0 ? nextValue : 0);
  };

  @action private setModerationStatistic = (
    statistic: IAlertModerationStatistic | null,
  ) => {
    this._moderationStatistic.set(statistic);
  };

  @action private setModerationStatisticLoading = (loadingState: boolean) => {
    this._moderationStatisticLoading.set(loadingState);
  };

  @action private setTimeout = (handler: TimerHandler) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(handler, alertPollingInterval);
  };

  @action private setConfig = (config: IAlertsConfig | null) => {
    this._config.set(config);
  };

  @action private clearConfig = () => {
    this.setConfig(null);
  };

  @action private clearTimeout = () => {
    clearTimeout(this.timer);
    this.timer = undefined;
  };

  @action private clearAlert = () => {
    clearTimeout(this.missSLATimeout);
    this.missSLATimeout = undefined;

    clearTimeout(this.activeModerationTimeout);
    this.activeModerationTimeout = undefined;

    this.setAlert(null);
    this.setHistory(null);
    this.setHistoryLoadingState(LoadingState.NotLoaded);
  };

  @action public resetStore = () => {
    this.clearAlert();
    this.setModerationStatistic(null);
    this.setModerationStatus(false);
    this.clearTimeout();
    this.clearConfig();
  };

  private checkSessionUpdatedByCurrentUser = (data: Partial<IAlert>) => {
    return this.rootStore.authStore.user?.email === data.email;
  };

  private processModerationSessionOpenedWsMessage = (data: Partial<IAlert>) => {
    if (this.checkSessionUpdatedByCurrentUser(data)) {
      this.setModerationStatus(true);
    }
  };

  private processModerationSessionClosedWsMessage = (data: Partial<IAlert>) => {
    if (this.checkSessionUpdatedByCurrentUser(data)) {
      this.setModerationStatus(false);
      this.cleanUpAfterStoppedSession();
    } else if (this.moderationStatus && this.alert === null) {
      this.requestAlertFlow();
    }
  };

  private processNewAlertWsMessage = (newAlert: Partial<IAlert>) => {
    const newAlertEscalationLevel = getAlertEscalationLevel(newAlert);
    const userEscalationLevel = this.rootStore.authStore.userEscalationLevel;
    const userCanRequestAlert =
      !newAlertEscalationLevel ||
      newAlertEscalationLevel === userEscalationLevel;

    if (this.moderationStatus && this.alert === null && userCanRequestAlert) {
      this.requestAlertFlow();
    }
  };

  private processAlertEditedWsMessage = (data: Partial<IAlert>) => {
    if (this.alert !== null && data.alert_id === this.alert.alert_id) {
      this.requestAlertFlow();
    }
  };

  private processAlertAssignedWsMessage = (data: Partial<IAlert>) => {
    if (
      this.alert === null ||
      (data.email === this.alert.email && data.alert_id !== this.alert.alert_id)
    ) {
      this.requestAlertFlow();
    }
  };

  private processAlertDecisionWsMessage = (data: Partial<IAlert>) => {
    if (this.alert !== null && this.alert.alert_id === data.alert_id) {
      this.clearAlert();
      // no need to do anything else because either alert loads in the current tab or this tab will be notified
      // when the alert is assigned in some other tab
    }
  };

  private webSocketHandlers = new Map<WebsocketMessageType, SocketHandler>([
    [
      WebsocketMessageType.AutoAssignSessionClosed,
      this.processModerationSessionClosedWsMessage,
    ],
    [
      WebsocketMessageType.AutoAssignSessionOpened,
      this.processModerationSessionOpenedWsMessage,
    ],
    [WebsocketMessageType.New, this.processNewAlertWsMessage],
    [WebsocketMessageType.Edited, this.processAlertEditedWsMessage],
    [WebsocketMessageType.Flagged, this.processAlertEditedWsMessage],
    [WebsocketMessageType.Assigned, this.processAlertAssignedWsMessage],
    [WebsocketMessageType.Approved, this.processAlertDecisionWsMessage],
    [WebsocketMessageType.Rejected, this.processAlertDecisionWsMessage],
    [WebsocketMessageType.L1Escalated, this.processAlertDecisionWsMessage],
    [WebsocketMessageType.L2Escalated, this.processAlertDecisionWsMessage],
    [WebsocketMessageType.SendToRsu, this.processAlertDecisionWsMessage],
  ]);

  public startListeners = () => {
    if (this._initiatedListeners) return;

    this.webSocketHandlers.forEach((handler, msgType) =>
      WsConnector.addListener(msgType, WebsocketEntityType.Alert, handler),
    );

    this._initiatedListeners = true;
  };

  public stopListeners = () => {
    if (!this._initiatedListeners) return;

    this.webSocketHandlers.forEach(
      (handler: SocketHandler, msgType: WebsocketMessageType) =>
        WsConnector.removeListener(msgType, WebsocketEntityType.Alert, handler),
    );

    this._initiatedListeners = false;
  };

  public getModerationStatisticFlow = flow(
    function* (this: AlertsStore) {
      try {
        this.setModerationStatisticLoading(true);

        const moderationStatistic = yield AlertsService.getStatistic();

        this.setModerationStatistic(moderationStatistic);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get alerts moderation statistic',
        });
      } finally {
        this.setModerationStatisticLoading(false);
      }
    }.bind(this),
  );

  public getModerationSessionStatusFlow = flow(
    function* (this: AlertsStore) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus = yield AlertsService.getModerationSessionStatus();

        this.setModerationStatus(moderationStatus);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get alerts moderation session status',
        });
        this.setModerationStatus(false);
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  public startModerationSessionFlow = flow(
    function* (this: AlertsStore) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus: boolean = yield AlertsService.setModerationSessionStatus(
          true,
        );

        this.setModerationStatus(moderationStatus);

        if (moderationStatus) {
          yield this.requestAlertFlow();
        }
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to start alerts moderation session',
        });
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  private cleanUpAfterStoppedSession = () => {
    this.clearTimeout();
    this.clearAlert();
    leaveModerationConfirmation.disableConfirmation(this.leavePageFlow);
  };

  public stopModerationSessionFlow = flow(
    function* (this: AlertsStore, stoppageReason: SessionStoppageReason) {
      try {
        this.setModerationSessionLoading(true);

        const moderationStatus: boolean = yield AlertsService.setModerationSessionStatus(
          false,
          stoppageReason,
        );

        this.setModerationStatus(moderationStatus);
        this.cleanUpAfterStoppedSession();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to stop alerts moderation session',
        });
      } finally {
        this.setModerationSessionLoading(false);
      }
    }.bind(this),
  );

  private timeoutModerationFlow = flow(
    function* (this: AlertsStore) {
      yield this.stopModerationSessionFlow(SessionStoppageReason.Timeout);

      notification.warning({
        description:
          'Your moderation session was stopped because of inactivity.',
        duration: 0,
        message: 'Moderation was stopped',
      });
    }.bind(this),
  );

  private requestAlertFlow = flow(
    function* (
      this: AlertsStore,
      checkAssignedAlertForStoppedModeration = false,
    ) {
      try {
        this.setLoadingState(true);

        this.clearTimeout();

        yield this.getAlertsConfigFlow();

        if (!this.moderationStatus && !checkAssignedAlertForStoppedModeration) {
          return logError({
            skipMessage: true,
            title: 'Can not request an alert without moderation session',
          });
        }

        const { alert, meta } = yield AlertsService.assignAlert();

        if (alert !== null) {
          this.setAlert(alert, meta);
        }
      } catch (error) {
        // Ignore conflict error if session was not started (for initial request)
        if (
          !checkAssignedAlertForStoppedModeration ||
          !error.response ||
          error.response.status !== 409
        ) {
          logError({
            description: error.message,
            exception: error,
            title: 'Failed to get alert',
          });
        }
      } finally {
        if (!this.alert) {
          this.setTimeout(this.requestAlertFlow);
        }

        this.setLoadingState(false);
      }
    }.bind(this),
  );

  public missSLAFlow = flow(
    function* (this: AlertsStore) {
      try {
        if (!this.moderationStatus || this.alert === null) {
          logError({
            skipMessage: true,
            title: 'No alert to recalculate missed SLA',
          });
          return false;
        }

        const alert: IAlert | null = yield AlertsService.getAlert(
          this.alert.alert_id,
        ) || null;
        this.setAlert(alert, alert ? this.alertMeta : undefined);

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to set missed SLA time',
        });
        return false;
      }
    }.bind(this),
  );

  public continueModerationFlow = flow<boolean, any[]>(
    function* (this: AlertsStore) {
      try {
        if (!this.moderationStatus || this.alert === null) {
          logError({
            skipMessage: true,
            title: 'No alert to continue moderation',
          });

          return false;
        }

        const { alert, meta } = yield AlertsService.assignAlert();

        this.setAlert(alert, meta);

        if (alert === null) {
          this.setModerationStatus(false);

          logError({
            title: 'Your moderation session is expired!',
          });
        }

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to get alert',
        });

        return false;
      }
    }.bind(this),
  );

  public getHistoryFlow = flow<void, []>(
    function* (this: AlertsStore) {
      try {
        if (this.alert === null) {
          return logError({
            skipMessage: true,
            title: 'No alert to load history for',
          });
        }

        this.setHistory(null);
        this.setHistoryLoadingState(LoadingState.Loading);

        const alertId: number = this.alert.alert_id;
        const history: IAlertHistory[] = yield AlertsService.getAlertHistory(
          alertId,
        );

        this.setHistory(history);
        this.setHistoryLoadingState(LoadingState.Loaded);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to load alert history',
        });
        this.setHistoryLoadingState(LoadingState.Failed);
      }
    }.bind(this),
  );

  public approveAlertFlow = flow<void, [number, string?, boolean?]>(
    function* (
      this: AlertsStore,
      categoryId: number,
      note = '',
      sendToRSU = false,
    ) {
      try {
        if (this.alert === null) {
          return logError({
            skipMessage: true,
            title: 'No alert to approve',
          });
        }
        this.setLoadingState(true);
        const alertId: number = this.alert.alert_id;

        yield AlertsService.approveAlert(alertId, categoryId, note, sendToRSU);

        this.clearAlert();
        yield this.requestAlertFlow();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to approve alert',
        });
      } finally {
        this.setLoadingState(false);
      }
    }.bind(this),
  );

  public declineAlertFlow = flow<void, [number, string]>(
    function* (this: AlertsStore, categoryId: number, note = '') {
      try {
        if (this.alert === null) {
          return logError({
            skipMessage: true,
            title: 'No alert to decline',
          });
        }

        this.setLoadingState(true);

        const alertId = this.alert.alert_id;

        yield AlertsService.declineAlert(alertId, categoryId, note);

        this.clearAlert();
        yield this.requestAlertFlow();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to decline alert',
        });
      } finally {
        this.setLoadingState(false);
      }
    }.bind(this),
  );

  public escalateAlertFlow = flow<void, [number?, string?]>(
    function* (this: AlertsStore, categoryId?: number, note?: string) {
      try {
        if (this.alert === null) {
          return logError({
            skipMessage: true,
            title: 'No alert to escalate',
          });
        }

        this.setLoadingState(true);

        const alertId = this.alert.alert_id;

        yield AlertsService.escalateAlert(alertId, categoryId, note);

        this.clearAlert();
        yield this.requestAlertFlow();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to escalate alert',
        });
      } finally {
        this.setLoadingState(false);
      }
    }.bind(this),
  );

  public leavePageFlow = flow(
    function* (this: AlertsStore) {
      if (!this.moderationStatus) return;

      try {
        yield AlertsService.setStopModerationOnPageLeave();
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to stop moderation session',
        });
      }
    }.bind(this),
  );

  public getAlertsConfigFlow = flow(
    function* (this: AlertsStore) {
      if (this.config !== null) return;

      try {
        this.setLoadingState(true);
        const config: IAlertsConfig = yield AlertsService.getConfig();
        this.setConfig(config);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to load alerts config',
        });
      } finally {
        this.setLoadingState(false);
      }
    }.bind(this),
  );

  public startFlow = flow(
    function* (this: AlertsStore) {
      this.getModerationStatisticFlow();
      this.startListeners();

      yield this.getModerationSessionStatusFlow();

      if (this.moderationStatus && !this.alert) {
        yield this.requestAlertFlow(true);
      }

      this.moderationStatsUpdateInterval = setInterval(
        this.getModerationStatisticFlow,
        moderationStatsPollingInterval,
      );
    }.bind(this),
  );

  @action.bound
  public stopFlow = () => {
    this.stopListeners();
    clearInterval(this.moderationStatsUpdateInterval);
  };
}
