import React from 'react';
import { VerticalAligner } from '../../../components/VerticalAligner';
import './ModerationInfoBlock.less';

interface IModerationInfoBlockProps {
  hint?: string;
  title: string;
}

export class ModerationInfoBlock extends React.Component<
  IModerationInfoBlockProps
> {
  public render() {
    const { hint, title } = this.props;

    return (
      <VerticalAligner className="ModerationInfoBlock">
        <div className="ModerationInfoBlock__title">{title}</div>
        <div className="ModerationInfoBlock__hint">{hint}</div>
      </VerticalAligner>
    );
  }
}
