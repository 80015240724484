import React from 'react';
import { Badge, BadgeColors } from 'src/componentsNew/Badge';
import { NHAdminAlertType } from 'src/restApi/alertsService';
import { ModerationStatus } from 'src/restApi/interfaces';

export const SearchAlertModerationBadges = (props) => {
  const badges = getBadges(props);

  return (
    <>
      {badges.map(({ text, key, color, borderOnly }) => (
        <Badge text={text} key={key} color={color} borderOnly={borderOnly} />
      ))}
    </>
  );
};

const getBadges = ({
  flagged,
  flagReasons,
  hiddenByOwner,
  userType,
  moderation: {
    is_l1_escalated,
    is_l2_escalated,
    missed_sla,
    on_rsu,
    reason,
    status,
    nhadmin_alert_type,
  },
}) => {
  const reasonText =
    reason
      ?.slice(0, 2)
      .map((categories) => categories.trim())
      .filter((category) => category !== '')
      .join(', ') ?? null;

  const statusBadge = statusBadges[status];
  const publicAssistance =
    nhadmin_alert_type === NHAdminAlertType.PublicAssistance;

  return [
    hiddenByOwner && {
      color: 'red',
      key: 'badges-deleted-by-owner',
      text: 'DELETED',
      borderOnly: false,
    },
    (flagged || flagReasons?.length > 0) && {
      text: 'Flagged',
      key: 'badges-flag-reason',
      color: 'red',
      borderOnly: false,
    },
    is_l1_escalated && {
      text: 'L1',
      key: 'badges-flag-is-l1-escalated',
      color: 'purple',
      borderOnly: false,
    },
    is_l2_escalated && {
      text: 'L2',
      key: 'badges-flag-is-l2-escalated',
      color: 'purple',
      borderOnly: false,
    },
    missed_sla && {
      text: 'MISSED SLA',
      key: 'badges-flag-missed-sla',
      color: 'red',
      borderOnly: true,
    },
    on_rsu && {
      text: 'ON RSU',
      key: 'badges-flag-on-rsu',
      color: 'purple',
      borderOnly: false,
    },
    userType === 'police' && {
      text: 'PUBLIC SAFETY USER',
      key: 'badges-flag-police',
      color: 'black',
      borderOnly: true,
    },
    publicAssistance && {
      text: 'REQUEST FOR ASSISTANCE',
      key: 'badges-flag-public-assistance',
      color: 'purple',
      borderOnly: false,
    },
    statusBadge && {
      ...statusBadge,
      text: reasonText ?? statusBadge.text,
      key: 'badges-status-reason',
    },
  ].filter(Boolean);
};

const statusBadges = {
  [ModerationStatus.Approved]: {
    color: BadgeColors.Blue,
    text: 'APPROVED',
    borderOnly: false,
  },
  [ModerationStatus.Assigned]: {
    color: BadgeColors.Purple,
    text: 'ASSIGNED',
    borderOnly: false,
  },
  [ModerationStatus.AutoApproved]: {
    color: BadgeColors.Blue,
    text: 'AUTO APPROVED',
    borderOnly: true,
  },
  [ModerationStatus.AutoDeclined]: {
    color: BadgeColors.Orange,
    text: 'AUTO DECLINED',
    borderOnly: true,
  },
  [ModerationStatus.Declined]: {
    color: BadgeColors.Orange,
    text: 'DECLINED',
    borderOnly: false,
  },
  [ModerationStatus.New]: {
    color: BadgeColors.Blue,
    text: 'NEW',
    borderOnly: true,
  },
} as const;
