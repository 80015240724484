import moment from 'moment';
import React from 'react';
import { IAlert, IAlertFlagReason } from 'src/restApi/alertsService';
import {
  AlertPropertyRow,
  IconType,
} from '../../../../components/AlertPropertyRow';
import {
  ALERT_BAD_WORDS_TYPES,
  WordsHighlighter,
} from '../../../../components/WordsHighlighter';
import { DATE_FORMAT } from '../../../../constants';
import { UserHyperlink } from '../../../UserHyperlink';
import './AlertInfoFields.less';
import { AdminAlertLink } from 'src/pages/admin/components/AdminAlertLink';
import { Icon } from '../../../Icon';
import styled from 'styled-components';
import { AlertUserViolations } from '../AlertUserViolations';
import { AlertPublicAssistanceFields } from '../AlertPublicAssistanceFields';
import { Tooltip } from "src/componentsNew/Tooltip/Tooltip";

const renderValue = (value: string | number) => (
  <WordsHighlighter
    type={ALERT_BAD_WORDS_TYPES}
    textToHighlight={String(value)}
  />
);

export interface AlertInfoFieldsProps {
  alert: IAlert;
  className?: string;
}

function renderFlagReasons(flagReasons: IAlertFlagReason[]) {
  if (!flagReasons) return null;

  return (
    <div>
      {flagReasons.map((reason) => (
        <div className="AlertInfoFields__FlagReasonsRow" key={reason.name}>
          <div>{reason.name}</div>
          <div>{reason.count}</div>
        </div>
      ))}
    </div>
  );
}

export function AlertInfoFields({
  alert: {
    alert_id,
    address,
    assistance_details,
    ding_id,
    flag_reasons,
    time_created,
    user_id,
    user_selected_category,
    share_url,
    cop,
    user_name,
    user_data,
    intent
  },
  className,
}: AlertInfoFieldsProps) {
  return (
    <>
      {address && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Location}
          testID="alertAddress"
        >
          {renderValue(address)}
        </AlertPropertyRow>
      )}

      {time_created && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Clock}
          testID="timeCreated"
        >
          Created Time: {moment(time_created * 1000).format(DATE_FORMAT)}
        </AlertPropertyRow>
      )}

      {alert_id && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.AlertId}
          copyValue={alert_id}
        >
          Alert ID:&nbsp;
          <AdminAlertLink alertId={alert_id} testID="alertId">
            {renderValue(alert_id)}
          </AdminAlertLink>
        </AlertPropertyRow>
      )}

      {user_id && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.User}
          copyValue={user_id}
          valueClassName="AlertInfoFields__User"
        >
          User ID:&nbsp;
          <UserHyperlink userId={user_id} testID="alertUserId">
            {renderValue(user_id)}
          </UserHyperlink>
          {user_data != null && Object.keys(user_data).length !== 0 && (
            <Tooltip content={user_data.notes ? user_data.notes : "Empty"}>
              <BadActor>
                <Icon iconType={IconType.Exclamation}/>
              </BadActor>
            </Tooltip>
          )}
        </AlertPropertyRow>
      )}

      {user_id && user_data != null && user_data.categories.length !== 0 && (
        <AlertPropertyRow className={className} iconType={IconType.Info}>
          <AlertUserViolations userData={user_data} />
        </AlertPropertyRow>
      )}

      {ding_id && ding_id !== '0' && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.DingId}
          testID="alertDingId"
        >
          Ding ID: {renderValue(ding_id)}
        </AlertPropertyRow>
      )}

      {share_url && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Agency}
          copyValue={share_url}
        >
          Share link:{' '}
          <a
            href={share_url}
            target="_blank"
            rel="noreferrer noopener"
            data-test-id="alertShareUrl"
          >
            Link
          </a>
        </AlertPropertyRow>
      )}

      {assistance_details && (
        <AlertPublicAssistanceFields
          assistanceDetails={assistance_details}
          agencyName={user_name}
          className={className}
        />
      )}

      {user_selected_category && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Agency}
          testID="alertUserSelectedCategory"
        >
          User Selected Category: {renderValue(user_selected_category)}
        </AlertPropertyRow>
      )}

      {intent && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Info}
          testID="alertIntent"
        >
          Intent: {renderValue(intent)}
        </AlertPropertyRow>
      )}

      {cop && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Agency}
          testID="alertCopAgencyName"
        >
          Agency: {renderValue(user_name)}
        </AlertPropertyRow>
      )}

      {flag_reasons && flag_reasons.length !== 0 && (
        <AlertPropertyRow
          className={className}
          iconType={IconType.Flag}
          valueClassName="AlertInfoFields__FlagReasons"
          testID="alertFlagReasons"
        >
          <span className="AlertInfoFields__FlagReasonsTitle">
            Flag Reasons:{' '}
          </span>
          {renderFlagReasons(flag_reasons)}
        </AlertPropertyRow>
      )}
    </>
  );
}

export const BadActor = styled.span`
  width: 22px;
  height: 22px;
  margin-left: 2px;
  margin-top: -2px;

  .Icon {
    width: 22px;
    height: 22px;
  }
`;
