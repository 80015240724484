import React from 'react';
import { IComment, IStoredAlert } from '../../../../restApi/interfaces';
import {
  ModerationFlag,
  ModerationFlagBadge,
} from '../components/ModerationFlagBadge';
import { ModerationStatusBadge } from '../components/ModerationStatusBadge';
import { NHAdminAlertType } from 'src/restApi/alertsService';

function getHistoryFlagRecordConfig({
  is_deleted,
  deleted_by_admin,
  is_deleted_by_user,
  is_l1,
  is_l2,
  on_rsu,
  status,
  missed_sla,
  ...restProps
}: IStoredAlert | IComment) {
  const flagged =
    (restProps as IStoredAlert).is_flagged || (restProps as IComment).flag;
  const cop = (restProps as IStoredAlert).cop || (restProps as IComment).cop_id;
  const publicAssistance =
    (restProps as IStoredAlert).nhadmin_alert_type ===
    NHAdminAlertType.PublicAssistance;
  const flags = [
    on_rsu && ModerationFlag.Rsu,
    is_l1 && ModerationFlag.L1,
    is_l2 && ModerationFlag.L2,
    is_deleted &&
      typeof deleted_by_admin === 'undefined' &&
      ModerationFlag.Deleted,
    is_deleted &&
      !deleted_by_admin &&
      is_deleted_by_user &&
      ModerationFlag.DeletedByUser,
    is_deleted &&
      deleted_by_admin &&
      !is_deleted_by_user &&
      ModerationFlag.DeletedByAdmin,
    flagged && ModerationFlag.Flagged,
    cop && ModerationFlag.Police,
    missed_sla && ModerationFlag.MissedSla,
    publicAssistance && ModerationFlag.PublicAssistance,
  ].filter(Boolean);

  return flags.map((flag) => (
    <ModerationFlagBadge flag={flag} key={`badge-flag-${flag}`} />
  ));
}

function getHistoryStatusRecordConfig({
  reason,
  status,
  ...restProps
}: IStoredAlert | IComment) {
  if (!status) return null;

  return (
    <ModerationStatusBadge key="badge-status" reason={reason} status={status} />
  );
}

export function getModerationBadges(props: IStoredAlert | IComment) {
  const flagBadges = getHistoryFlagRecordConfig(props);
  const statusBadge = getHistoryStatusRecordConfig(props);

  return [...flagBadges, statusBadge].filter(Boolean);
}
