import cx from 'classnames';
import React from 'react';
import { NewsStatus } from 'src/restApi/newsService';
import { StatusBadge } from '../Badge';

export interface INewsAlertStatusBadgeProps {
  className?: string;

  edited?: boolean;
  status: NewsStatus;
}

const states = new Map();
states.set(NewsStatus.New, { color: 'blue', text: 'NEW', borderOnly: true });
states.set(NewsStatus.Published, { color: 'green', text: 'PUBLISHED' });
states.set(NewsStatus.InReview, { color: 'orange', text: 'IN REVIEW' });
states.set(NewsStatus.Deleted, { color: 'red', text: 'DELETED' });
states.set('Edited', { color: 'orange', text: 'EDITED' });

export const NewsAlertStatusBadge: React.SFC<INewsAlertStatusBadgeProps> = ({
  className,
  edited = false,
  status,
}) => {
  let chosenStatus: NewsStatus | 'Edited' = status;

  if (edited) {
    chosenStatus = 'Edited';
  }

  if (!states.has(chosenStatus)) {
    throw new Error(
      `NewsAlertStatusBadge do not have status: "${chosenStatus}".`,
    );
  }

  const badgeProps = { ...states.get(chosenStatus) };

  return (
    <StatusBadge
      {...badgeProps}
      className={cx('NewsAlertStatusBadge', className)}
    />
  );
};
