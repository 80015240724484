import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ConfirmPopup } from 'src/components/confirm-popup';
import { PermissionsHeader } from 'src/pages/permissions/PermissionsHeader';
import {
  PermissionUserItem,
  PermissionUserMode,
} from 'src/pages/permissions/PermissionUserItem';
import { PermissionUserList } from 'src/pages/permissions/PermissionUserList';
import { PermissionLevel } from 'src/restApi/interfaces';
import { useStores } from 'src/stores';
import './PermissionsPage.less';

export const PermissionsPage = observer(() => {
  const { permissionStore } = useStores();

  const handleAdd = async (email: string, scope: PermissionLevel[]) => {
    return permissionStore.addUserFlow(email, scope) as Promise<boolean>;
  };

  useEffect(() => {
    permissionStore.fetchAdminUsersFlow();
  }, [permissionStore]);

  return (
    <div className="Permissions">
      <div className="Permissions__container">
        <div className="Permissions__topBlock">
          <div className="Permissions__deleteControl" />
          <PermissionsHeader />
          <div className="Permissions__controls" />
        </div>
        <PermissionUserItem mode={PermissionUserMode.Add} onAdd={handleAdd} />
        <PermissionUserList />
      </div>
      <ConfirmPopup />
    </div>
  );
});
