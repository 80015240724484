import {Button, Icon} from 'antd';
import { ButtonProps } from 'antd/lib/button';
import css from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { border } from 'polished';
import React, { FC } from 'react';
import { useToggle } from 'react-use';
import { AlertPropertyRow, IconType } from 'src/components/AlertPropertyRow';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { MediaViewer } from 'src/components/MediaViewer';
import { UserIcon } from 'src/components/user-icon';
import {
  ALERT_BAD_WORDS_TYPES,
  WordsHighlighter,
} from 'src/components/WordsHighlighter';
import { BadgeAligner } from 'src/componentsNew/Badge';
import { UserHyperlink } from 'src/componentsNew/UserHyperlink';
import { getMediaContentType } from 'src/helpers';
import { useStores } from 'src/stores';
import { NoMediaView } from '../../../../components/MediaViewer/components/NoMediaView';
import { getAlertNumericId } from '../../services/utils';
import { SearchAlertHistory } from '../search-alert-history';
import { SearchAlertModerationBadges } from '../search-alert-moderation-badges';
import { SearchAlertMultimediaViewer } from '../search-alert-multimedia-viewer';

import s from '../../../admin/components/admin-element-container/style.module.css';
import { BadActor } from '../../../../componentsNew/Alert/components/AlertInfoFields';
import { Icon as IconComponent } from 'src/componentsNew/Icon';
import { Tooltip } from "src/componentsNew/Tooltip/Tooltip";
import {AlertExtendedDescriptionBlock} from "../../../../components/AlertExtendedDescriptionBlock";
import {IStoredAlert} from "../../../../restApi/interfaces";

const { Agency, AlertId, DingId, User, Exclamation } = IconType;

interface SearchResultAlertProps {
  alertId: string;
}

export const SearchResultAlert = observer<FC<SearchResultAlertProps>>(
  ({ alertId }) => {
    const { searchStore } = useStores();
    const [showHistory, toggleShowHistory] = useToggle(false);
    const alert = searchStore.alerts.get(alertId);
    const {
      category,
      created_at,
      description,
      ding_id,
      event_type,
      flagged,
      flag_reasons,
      hidden_by_owner,
      id,
      image_url,
      media_assets,
      moderation,
      owner_uid,
      share_url,
      title,
      user_type,
      video_url,
      user_name,
      masked_address,
      is_deleted_by_user,
      user_data
    } = alert;
    const email = moderation?.email ?? null;
    const alert_id = getAlertNumericId(id);
    const hasMultipleMedia = media_assets?.length > 0;
    const noMediaMessage = hasMultipleMedia
      ? 'these items'
      : video_url
      ? 'this video'
      : 'this image';

    const handleHistoryClick = () => {
      toggleShowHistory();

      if (showHistory) return;
      searchStore.fetchAlertHistory(id);
    };

    const isBadActor = user_data != null && Object.keys(user_data).length !== 0;

    return (
      <Flex
        {...border('1px', 'solid', '#ced2e5')}
        bg="#f9fbfc"
        borderRadius="4px"
        mb={3}
        p={3}
        flexDirection="column"
        data-test-id="resultsItem"
      >
        <Flex flexDirection="row">
          <Box
            width={showHistory ? '50%' : '25%'}
            mr={showHistory ? 4 : 3}
            sx={{ '& img': { borderRadius: '4px' } }}
          >
            {is_deleted_by_user ? (
              <NoMediaView
                className={css(s.noMediaContainer)}
                hideNoVisualContentText={false}
                message={`User has requested ${noMediaMessage} to be removed`}
                testID="mediaItem text"
              />
            ) : hasMultipleMedia ? (
              <SearchAlertMultimediaViewer
                alertId={id}
                multimediaAssets={media_assets}
                showHistory={showHistory}
              />
            ) : (
              (video_url || image_url) && (
                <MediaViewer
                  source={getMediaContentType(video_url, image_url)}
                />
              )
            )}
          </Box>

          {showHistory ? (
            <SearchAlertHistory alertId={id} width="50%" />
          ) : (
            <>
              <Flex flexDirection="column" width="25%" mr={3} mt={1}>
                {(title || is_deleted_by_user) && (
                  <Box
                    color="#1b1d1c"
                    fontSize={16}
                    fontWeight="bold"
                    wordBreak="break-word"
                    data-test-id="alertCollapsed alertTitle"
                  >
                    <WordsHighlighter
                      type={ALERT_BAD_WORDS_TYPES}
                      textToHighlight={is_deleted_by_user ? 'Null' : title}
                      testID="isDeleted"
                    />
                  </Box>
                )}

                {created_at && (
                  <Box mb={2} data-test-id="alertCollapsed alertCreatedAt">
                    {moment(created_at).format('hh:mm A MMM D, YYYY')}
                  </Box>
                )}

                {masked_address && (
                  <AlertPropertyRow testID="alertCollapsed alertAddress">
                    {masked_address}
                  </AlertPropertyRow>
                )}

                {alert_id && (
                  <AlertPropertyRow iconType={AlertId} copyValue={alert_id}>
                    Alert ID:{' '}
                    <b data-test-id="alertCollapsed alertId">{alert_id}</b>
                  </AlertPropertyRow>
                )}

                {((ding_id && ding_id !== '0') || is_deleted_by_user) && (
                  <AlertPropertyRow
                    iconType={DingId}
                    testID="alertCollapsed alertDingId"
                  >
                    Ding ID: {is_deleted_by_user ? `Null` : <b>{ding_id}</b>}
                  </AlertPropertyRow>
                )}

                {share_url && (
                  <AlertPropertyRow
                    iconType={IconType.Agency}
                    copyValue={share_url}
                  >
                    Share link:{' '}
                    <a
                      href={share_url}
                      data-test-id="alertCollapsed alertShareLink"
                    >
                      Link
                    </a>
                  </AlertPropertyRow>
                )}

                {((user_type !== 'police' && owner_uid) ||
                  is_deleted_by_user) && (
                  <AlertPropertyRow iconType={User} copyValue={owner_uid}>
                    {`User ID: `}
                    {is_deleted_by_user ? (
                      `Null`
                    ) : (
                      <b>
                        <UserHyperlink
                          userId={owner_uid}
                          testID="alertCollapsed alertUserId"
                        />
                      </b>
                    )}
                    {isBadActor && (
                        <Tooltip content={user_data.notes ? user_data.notes : "Empty"}>
                            <BadActor>
                                <IconComponent iconType={Exclamation} />
                            </BadActor>
                        </Tooltip>
                    )}
                  </AlertPropertyRow>
                )}

                {category && (
                  <AlertPropertyRow
                    iconType={Agency}
                    testID="alertCollapsed alertCategory"
                  >
                    User Selected Category: {category}
                  </AlertPropertyRow>
                )}

                {user_type === 'police' && user_name && (
                  <AlertPropertyRow
                    iconType={Agency}
                    testID="alertCollapsed alertAgencyName"
                  >
                    Agency: {user_name}
                  </AlertPropertyRow>
                )}

                {(description || is_deleted_by_user) && (
                  <Box
                    color="#7f8fa4"
                    wordBreak="break-word"
                    mt={2}
                    fontSize={13}
                  >
                    <WordsHighlighter
                      type={ALERT_BAD_WORDS_TYPES}
                      textToHighlight={
                        is_deleted_by_user ? `Null` : description
                      }
                      testID="alertCollapsed alertDescription"
                    />
                    <div>
                        <AlertExtendedDescriptionBlock {...alert as IStoredAlert}/>
                    </div>
                  </Box>
                )}
              </Flex>

              <Flex alignItems="start" width="15%" mr={3} mt={1}>
                {email && (
                  <>
                    <Box mr="10px">
                      <UserIcon size={20} />
                    </Box>
                    <Box
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      fontWeight="bold"
                      color="#1b1d1c"
                    >
                      {email}
                    </Box>
                  </>
                )}
              </Flex>

              {moderation && (
                <>
                  <Box width="25%">
                    <BadgeAligner multiLine>
                      <SearchAlertModerationBadges
                        flagged={flagged}
                        flagReasons={flag_reasons}
                        hiddenByOwner={hidden_by_owner}
                        moderation={moderation}
                        userType={user_type}
                      />
                    </BadgeAligner>
                  </Box>
                  {moderation.moderation_time_s !== 0 && (
                    <Flex
                      justifyContent="flex-end"
                      flex={1}
                      color="#7f8fa4"
                      mt={1}
                    >
                      <ModerationTime seconds={moderation.moderation_time_s} />
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
        </Flex>
        {[
          'trending_news',
        ].indexOf(event_type) === -1 && (
          <Flex justifyContent="flex-end">
            <ShowHistoryButton
              expanded={showHistory}
              onClick={handleHistoryClick}
            />
          </Flex>
        )}
      </Flex>
    );
  },
);

interface ShowHistoryButtonProps extends ButtonProps {
  expanded?: boolean;
}

const ShowHistoryButton = ({ expanded, ...props }: ShowHistoryButtonProps) => (
  <Box
    as={Button}
    {...props}
    type="link"
    color="#94a5ba"
    data-test-id="alertHistory"
  >
    History{' '}
    <Icon type={expanded ? 'up' : 'down'} style={{ fontSize: '10px' }} />
  </Box>
);

const ModerationTime = ({ seconds }) => {
  if (!seconds) return null;

  const diffedTime =
    seconds <= 60
      ? `${Number.parseFloat(seconds).toFixed()} s`
      : moment.duration(seconds, 'seconds').humanize();

  return (
    <span title={`${seconds} s`} data-test-id="diffedTime">
      {diffedTime}
    </span>
  );
};
