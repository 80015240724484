import { DatePicker, message } from 'antd';
import css from 'classnames';
import { uniq } from 'lodash';
import { transaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { isArrayNotEmpty } from 'src/helpers';
import * as commentHelper from 'src/helpers/commentsHelper';
import { URLService } from 'src/services/urlService';
import { Stores } from 'src/stores';
import {
  AdminContentMessageType,
  DATE_FORMAT,
  FilterDirection,
  SearchTypeKeys,
  TIME_FORMAT,
} from '../../../../constants';
import { getDateOfLastDayMinute } from '../../helpers';
import { AdminService } from '../../services/adminService';
import { AdminDownloadReportButton } from '../admin-download-report-button';
import { AdminSelectorSearch } from '../admin-selector-search';
import styles from './style.module.css';

interface IAdminSearchPanelState {
  initialSelectState: boolean;
}

@inject('adminStore', 'adminHeaderSearchStore')
@observer
export class AdminSearchPanel extends React.Component<
  Partial<Stores>,
  IAdminSearchPanelState
> {
  public state = {
    initialSelectState: true,
  };

  private static checkDisabledDate(current) {
    return current > moment().endOf('day');
  }

  public async componentWillMount() {
    const startDate = URLService.getParam('startDate');
    const endDate = URLService.getParam('endDate');
    const alertId = URLService.getParam('alertId');
    const commentId = URLService.getParam('commentId');
    const word = URLService.getParam('word');

    transaction(async () => {
      if (startDate && endDate && !alertId) {
        const users = URLService.getParam('user');
        if (users) {
          URLService.deleteSearchParam('user');
          message.warning(
            'Reviewed By user list has been cleared for stability reasons. Please select required users.',
          );
        }

        this.props.adminStore.from = moment(startDate);
        this.props.adminStore.to = moment(endDate);
        await this.startSearching();
        await this.applyFilters();
      } else if (commentId) {
        this.props.adminStore.commentId = commentId;
        this.props.adminHeaderSearchStore.type = SearchTypeKeys.COMMENT;
        this.props.adminHeaderSearchStore.value = commentId;
        this.fetchCommentById();
      } else if (alertId) {
        this.props.adminStore.alertId = alertId;
        this.props.adminHeaderSearchStore.type = SearchTypeKeys.ALERT;
        this.props.adminHeaderSearchStore.value = alertId;
        this.getAlertWithCommentsById();
      } else if (!alertId && !commentId && word) {
        this.props.adminStore.words = word;
        this.props.adminHeaderSearchStore.type = SearchTypeKeys.KEYWORD;
        this.props.adminHeaderSearchStore.value = word;
        this.fetchByKeywords();
      }
    });
  }

  private handleClickSearch = () => {
    const { adminStore } = this.props;

    const startDate = adminStore.from;
    const endDate = adminStore.to;

    const normalizedStartDate = startDate.set('seconds', 0);
    const normalizedEndDate = endDate.set('seconds', 0);

    URLService.addSearchParams({
      endDate: normalizedEndDate.toISOString(),
      startDate: normalizedStartDate.toISOString(),
    });
    URLService.deleteSearchParam('commentId');
    URLService.deleteSearchParam('alertId');
    URLService.deleteSearchParam('word');

    transaction(async () => {
      this.props.adminStore.resetDataValues();
      this.props.adminHeaderSearchStore.resetValue();
      this.startSearching();
    });
  };

  public startSearching = async () => {
    this.props.adminStore.isLoaderVisible = true;

    transaction(async () => {
      try {
        let endDate = this.props.adminStore.to;

        // Add 1 minute if end date is the last minute of a day
        if (endDate.isSame(getDateOfLastDayMinute(endDate))) {
          endDate = moment(endDate).add(1, 'm');
        }

        const [
          alerts,
          comments,
        ] = await AdminService.fetchAlertAndCommentsInRange(
          this.props.adminStore.from,
          endDate,
        );

        this.props.adminStore.setAlerts(alerts);
        this.props.adminStore.setComments(comments);
      } catch (e) {
        console.error('AdminPage :: Error', e);

        this.props.adminStore.setAlerts([]);
        this.props.adminStore.setComments([]);
      }

      this.props.adminStore.setContentMessageType(
        AdminContentMessageType.NotFoundByDate,
      );
      this.props.adminStore.isLoaderVisible = false;
    });
  };

  public applyFilters = async () => {
    const user = URLService.getParam('user');
    const alerts = URLService.getParam('alerts');
    const comments = URLService.getParam('comments');
    const sortDirection = URLService.getParam(
      'sortDirection',
    ) as FilterDirection | null;
    const sortValue = URLService.getParam('sortValue');
    const flagged = URLService.getParam('flagged');
    const rsu = URLService.getParam('rsu');
    const onRsu = URLService.getParam('on_rsu');
    const deleted = URLService.getParam('deleted');
    const resolved = URLService.getParam('resolved');
    const isAlertsWithCaseInfo = URLService.getParam(
      'is_alerts_with_case_info',
    );
    const isAlertsWithoutCaseInfo = URLService.getParam(
      'is_alerts_without_case_info',
    );
    const isLeoAlert = URLService.getParam('is_leo_alerts');
    const isLeoComments = URLService.getParam('is_leo_comments');

    transaction(() => {
      const { adminStore } = this.props;

      if (user) adminStore.setSelectedUsersFilter(user.split(','));
      if (alerts) adminStore.setAlertsFilter(alerts.split(';'));
      if (comments) adminStore.setCommentsFilter(comments.split(';'));
      if (flagged) adminStore.setFlaggedFilter(flagged === 'true');
      if (rsu) adminStore.setRingUserFilter(rsu === 'true');
      if (onRsu) adminStore.setOnRSUFilter(onRsu === 'true');
      if (deleted) adminStore.setDeletedFilter(deleted === 'true');
      if (resolved) adminStore.setResolvedFilter(resolved === 'true');
      if (isAlertsWithCaseInfo)
        adminStore.setIsAlertsWithCaseInfoFilter(
          isAlertsWithCaseInfo === 'true',
        );
      if (isAlertsWithoutCaseInfo)
        adminStore.setIsAlertsWithoutCaseInfoFilter(
          isAlertsWithoutCaseInfo === 'true',
        );
      if (isLeoAlert) adminStore.setIsLEOAlertsFilter(isLeoAlert === 'true');
      if (isLeoComments)
        adminStore.setIsLEOCommentsFilter(isLeoComments === 'true');

      adminStore.setDirectionSortFilter(
        sortDirection
          ? sortDirection
          : this.props.adminStore.sortFilters.direction,
      );

      adminStore.setValueSortFilter(
        sortValue ? sortValue : this.props.adminStore.sortFilters.value,
      );
    });
  };

  public getAlertWithCommentsById = async () => {
    this.props.adminStore.isLoaderVisible = true;

    transaction(async () => {
      try {
        const alert = await AdminService.fetchAlertAndComments(
          this.props.adminStore.alertId,
        );
        const alertComments = alert ? alert.comments : [];
        if (alert) {
          delete alert.comments;
        }
        this.props.adminStore.setAlerts(alert ? [alert] : []);
        this.props.adminStore.setComments(alertComments);
      } catch (error) {
        console.error('AdminPage :: getAlertWithCommentsById', error);
      }

      this.props.adminStore.setContentMessageType(
        AdminContentMessageType.NotFoundByAlertId,
      );
      this.props.adminStore.isLoaderVisible = false;
    });
  };

  public fetchCommentById = async () => {
    this.props.adminStore.isLoaderVisible = true;

    transaction(async () => {
      try {
        const id = this.props.adminStore.commentId;
        const comment = await AdminService.getCommentByID(id);
        const alert =
          comment && (await AdminService.getMissedAlert(comment.alert_id));

        this.props.adminStore.setComments(comment ? [comment] : []);
        this.props.adminStore.setAlerts(alert ? [alert] : []);
      } catch (error) {
        console.error('AdminPage :: getAlertWithCommentsById', error);
      }

      this.props.adminStore.setContentMessageType(
        AdminContentMessageType.NotFoundByCommentId,
      );
      this.props.adminStore.isLoaderVisible = false;
    });
  };

  public fetchByKeywords = async () => {
    this.props.adminStore.isLoaderVisible = true;

    const words = this.props.adminHeaderSearchStore.value;
    const commentIds = await AdminService.fetchCommentsIdsByKeywords(words);
    const comments = await AdminService.getCommentsByIds(commentIds);
    const commentsIds = commentHelper.getCommentAlertsIds(
      comments.filter((e) => e),
    );
    let alertIds = await AdminService.fetchAlertsIdsByKeywords(words);

    alertIds = uniq([...alertIds, ...commentsIds]);
    const alerts = await AdminService.getAlertsByIds(alertIds);

    transaction(async () => {
      this.props.adminStore.setComments(comments ? comments : []);
      this.props.adminStore.setAlerts(alerts ? alerts : []);

      this.props.adminStore.setContentMessageType(
        AdminContentMessageType.NotFoundByKeyword,
      );
      this.props.adminStore.isLoaderVisible = false;
    });
  };

  public handleGenerateCSVButton = () => {
    this.props.adminStore.generateCSVModeOn
      ? this.props.adminStore.generateCsv(
          this.props.adminStore.alertsToReport(),
        )
      : this.props.adminStore.toogleCSVMode();
  };

  private handleDatePickerChange = ([startDate, endDate]) => {
    const { adminStore } = this.props;

    transaction(() => {
      adminStore.from = startDate;
      adminStore.to = endDate;
    });
  };

  private handleDatePickerOpenChange = (isOpened) => {
    const { adminStore } = this.props;

    if (isOpened) {
      return;
    }

    if (!adminStore.from || !adminStore.to) {
      adminStore.from = undefined;
      adminStore.to = undefined;
      return;
    }

    this.handleClickSearch();
  };

  private handleDatePickerOkClick = () => {
    this.handleClickSearch();
  };

  public render() {
    const { adminStore } = this.props;
    const alerts = adminStore.alertsToDisplay;
    const isAlertsInList = isArrayNotEmpty(alerts);

    return (
      <div className={css(styles.container, 'column')}>
        <div className={styles.searchByAlertContainer}>
          <AdminSelectorSearch />
        </div>
        {isAlertsInList && (
          <AdminDownloadReportButton
            onClick={this.handleGenerateCSVButton}
            active={adminStore.generateCSVModeOn}
            undoOnClick={adminStore.toogleCSVMode}
          />
        )}
        <div className={styles.datePickerContainer}>
          <DatePicker.RangePicker
            allowClear={false}
            value={[adminStore.from, adminStore.to]}
            disabled={adminStore.isLoaderVisible}
            disabledDate={AdminSearchPanel.checkDisabledDate}
            format={DATE_FORMAT}
            onChange={this.handleDatePickerChange}
            onOk={this.handleDatePickerOkClick}
            onOpenChange={this.handleDatePickerOpenChange}
            placeholder={['Start Time', 'End Time']}
            ranges={{
              Today: [moment().startOf('day'), moment().endOf('day')],
            }}
            showTime={{
              format: TIME_FORMAT,
              defaultValue: [moment().startOf('day'), getDateOfLastDayMinute()],
            }}
          />
        </div>
      </div>
    );
  }
}
