import css from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Loader } from 'src/components/Loader';
import { PaginationController } from 'src/components/pagination-controller';
import {
  AlertModerationDialog,
  DecisionType,
  useAlertModeration,
} from 'src/componentsNew/AlertModerationDialog';
import { IStoredAlert, ModerationStatus } from 'src/restApi/interfaces';
import { useStores } from 'src/stores';
import { AdminContentMessageType } from '../../constants';
import { AdminElementContainer } from './components/admin-element-container';
import { AdminSearchPanel } from './components/admin-search-panel';
import { AdminTableHeader } from './components/admin-table-header';
import styles from './style.module.css';

export const AdminPage = observer<React.FC>(() => {
  const { adminStore, configStore } = useStores();

  const alert = adminStore.currentRemoderationAlert;
  const alerts = adminStore.alertsToDisplay;

  const alertsByPage = adminStore.alertsByPage(alerts);
  const isAlertsInList = alertsByPage.length > 0;
  const pagesCount = adminStore.pagesCount(alerts);

  const {
    decisionType,
    handleCancelClick,
    handleApproveClick,
    handleDeclineClick,
    handleDecisionSubmit,
    isAlertModerationModalVisible,
  } = useAlertModeration({
    alert,
    onDecisionMade: async (type, payload) => {
      const { alert_id } = alert;
      const { reason, note } = payload;

      const status = {
        [DecisionType.Approve]: ModerationStatus.Approved,
        [DecisionType.Decline]: ModerationStatus.Declined,
      }[type];

      await adminStore.remoderateAlert({
        alert_id,
        status,
        categoryId: reason,
        note,
      });
    },
  });

  const handleCancelWithAlert = () => {
    adminStore.currentRemoderationAlert = null;
    handleCancelClick();
  };

  const handleApproveWithAlert = (alert: IStoredAlert) => {
    adminStore.currentRemoderationAlert = alert;
    handleApproveClick();
  };

  const handleDeclineWithAlert = (alert: IStoredAlert) => {
    adminStore.currentRemoderationAlert = alert;
    handleDeclineClick();
  };

  const getMessageByType = (type: AdminContentMessageType) => {
    if (adminStore.rawAlertsCount)
      return 'No results found for selected filters';

    switch (type) {
      case AdminContentMessageType.Initial:
        return (
          <div>
            <span>Choose dates</span>
            <div className={css(styles.datepickerIcon, 'row')} />
            <span>to start</span>
          </div>
        );
      case AdminContentMessageType.NotFoundByDate:
        return 'No results found for chosen dates';
      case AdminContentMessageType.NotFoundByAlertId:
        return `No results found for ${adminStore.alertId}`;
      case AdminContentMessageType.NotFoundByCommentId:
        return `No results found for ${adminStore.commentId}`;
      case AdminContentMessageType.NotFoundByKeyword:
        return `No results found for ${adminStore.words}`;
      default:
        return null;
    }
  };

  useEffect(() => {
    adminStore.setFilterCategories(configStore.categories);

    return () => {
      adminStore.resetStore();
    };
  }, [adminStore, configStore]);

  return (
    <>
      <div className={css(styles.container)}>
        <AdminSearchPanel />
      </div>
      {Boolean(adminStore.rawAlertsCount) && <AdminTableHeader />}
      {!isAlertsInList && (
        <div className={css(styles.noResultsFound)}>
          {getMessageByType(adminStore.contentMessageType)}
        </div>
      )}
      <div className={css(styles.container)}>
        {alertsByPage.map((alert) => (
          <AdminElementContainer
            alert={alert}
            key={alert.alert_id}
            onApproveClick={handleApproveWithAlert}
            onDeclineClick={handleDeclineWithAlert}
          />
        ))}
        {adminStore.isLoaderVisible && <Loader />}
      </div>
      <div className={css(styles.container)}>
        <PaginationController
          wrapperClassName="top-column-double"
          active={isAlertsInList}
          pagesPerChunk={5}
          pageCount={pagesCount}
          currentPage={adminStore.currentPage}
          onChangePage={adminStore.setCurretPage}
        />
      </div>
      <AlertModerationDialog
        agencyName={alert?.user_name}
        nhadminAlertType={alert?.nhadmin_alert_type}
        isPolice={alert?.cop}
        decisionType={decisionType}
        categories={configStore.alertCategories}
        onCancel={handleCancelWithAlert}
        onOk={handleDecisionSubmit}
        visible={isAlertModerationModalVisible}
        isProcessing={adminStore.isRemoderationAlertProcessing}
      />
    </>
  );
});
