import React from 'react';
import { Form, Input } from 'antd';

import { useStores } from 'src/stores';

const { Item } = Form;
const { TextArea } = Input;

export const ManageUsersFormNotes = ({ getFieldDecorator }) => {
  const { manageUsersStore } = useStores();

  return (
    <Item label="Notes" style={{ width: '100%' }}>
      {getFieldDecorator('userNotes', {
        rules: [
          {
            required: false,
            max: 1000,
            message: 'Please use only 1000 characters',
          },
        ],
        initialValue: manageUsersStore.editingUserNotes,
      })(<TextArea placeholder="Add Notes Here" rows={4} />)}
    </Item>
  );
};
