import { action, computed, flow, observable, toJS } from 'mobx';
import {INewNews, INews, INewsService, NewsId} from '../restApi/newsService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';
import {RootStore} from "./rootStore";

export class NewsStore extends InjectRootStore {
  private readonly _deleting = observable.box(false);
  private readonly _loading = observable.box(false);
  private readonly _news = observable.box<INews | null>(null);
  private readonly _saving = observable.box(false);
  public readonly newsService: INewsService;


  constructor(rootStore: RootStore, newsService: INewsService) {
    super(rootStore)
    this.newsService = newsService
  }

  // Getters

  @computed get deleting() {
    return this._deleting.get();
  }

  @computed get loading(): boolean {
    return this._loading.get();
  }

  @computed get news() {
    return toJS(this._news.get());
  }

  @computed get saving(): boolean {
    return this._saving.get();
  }

  // Actions

  @action public clear() {
    this.setDeleting(false);
    this.setLoading(false);
    this.setNews(null);
    this.setSaving(false);
  }

  @action private setDeleting(isDeleting: boolean) {
    this._deleting.set(isDeleting);
  }

  @action private setLoading(isLoading: boolean) {
    this._loading.set(isLoading);
  }

  @action private setNews(news: INews | null) {
    return this._news.set(news);
  }

  @action private setSaving(isUpdating: boolean) {
    this._saving.set(isUpdating);
  }

  // Flows

  public createNewsFlow = flow(
    function* (this: NewsStore, props: INewNews) {
      let success: boolean;

      try {
        this.setSaving(true);

        const news = yield this.newsService.createNewsAlert(props);

        this.setNews(news);

        if (this.rootStore.newsListStoreV2.initiated) {
            this.rootStore.newsListStoreV2.loadNewNewsFlow();
        }

        success = true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to create new news',
        });
        success = false;
      } finally {
        this.setSaving(false);
      }

      return success;
    }.bind(this),
  );

  public loadNewsFlow = flow(
    function* (this: NewsStore, newsId: NewsId) {
      try {
        if (!newsId) {
          logError({ title: 'News ID should be provided' });
          return;
        }

        this.setLoading(true);

        const news = (yield this.newsService.getNewsAlert(newsId)) as INews;

        this.setNews(news);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to load news',
        });
      } finally {
        this.setLoading(false);
      }
    }.bind(this),
  );

  public deleteNewsFlow = flow(
    function* (this: NewsStore) {
      let success = false;

      try {
        if (!this.news) {
          logError({ title: 'To delete news item it should be loaded first' });
          return false;
        }

        this.setDeleting(true);

        const updatedNews = yield this.newsService.deleteNewsAlert(
          this.news.news_id,
        );

        this.setNews(updatedNews);

        this.rootStore.newsListStoreV2.updateItem(updatedNews);

        success = true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to delete news item',
        });

        success = false;
      } finally {
        this.setDeleting(false);
      }

      return success;
    }.bind(this),
  );

  public updateNewsFlow = flow(
    function* (this: NewsStore, props: INews) {
      let success: boolean;

      try {
        this.setSaving(true);

        const updatedNews = yield this.newsService.updateNews(props);

        this.setNews(updatedNews);

        this.rootStore.newsListStoreV2.updateItem(updatedNews);

        success = true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to update news item',
        });

        success = false;
      } finally {
        this.setSaving(false);
      }

      return success;
    }.bind(this),
  );
}
