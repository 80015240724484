import { Col, Row } from 'antd';
import css from 'classnames';
import React from 'react';
import s from './news-curation-item.module.css';

export const NewsCurationWrapper: React.SFC = ({ children }) => (
  <div className={css(s.newsCurationItemWrapper)}>
    <Row justify="space-between" align="middle">
      <Col span={20}>
        <div className={css(s.newsCurationItem__Body_Loader)}>{children}</div>
      </Col>
    </Row>
  </div>
);
