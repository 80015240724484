import { Button, Icon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Flex } from 'src/components/Flex';
import { Loader } from 'src/components/Loader';
import { useStores } from 'src/stores';

export const ManageUsersPagination = observer((props) => {
  const { manageUsersStore } = useStores();

  if (manageUsersStore.isLoading) {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  return (
    manageUsersStore.hasNextPage && (
      <Flex justifyContent="flex-end" {...props}>
        <Button onClick={manageUsersStore.fetchNextPage}>
          Fetch more results
          <Icon type="right" />
        </Button>
      </Flex>
    )
  );
});
