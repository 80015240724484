import { ISearchParams } from 'src/interfaces';
import { IAlert } from 'src/restApi/alertsService';
import { IResponseItem, IResponseList } from 'src/restApi/restApi';
import { api } from '../api/api';
import { ALERTS_FETCH_LIMIT } from '../constants';
import { IComment, IStoredAlert } from '../restApi/interfaces';
import { CommentService } from './commentService';

export class AlertService {
  public static getAlerts = async (escalationLevel: string, isRSU = false) => {
    const requestParams = {
      limit: String(ALERTS_FETCH_LIMIT),
    } as ISearchParams;

    if (escalationLevel) {
      requestParams[escalationLevel] = 'true';
    }

    if (isRSU) {
      requestParams.on_rsu = 'true';
    }

    try {
      const response = await api
        .get('alerts', { searchParams: requestParams })
        .json<IResponseList<IAlert>>();

      return response.data.items;
    } catch (e) {
      return [];
    }
  };

  public static getAlertsByIds = async (alertIds: number[]) => {
    const response = await api
      .get('alerts_by_ids', { json: { ids: alertIds } })
      .json<IResponseList<IAlert>>();

    return response.data.items;
  };

  public static getAlertsWithComments = async (types?: (string | null)[]) => {
    const searchParams = (types &&
      types.length &&
      types.reduce(
        (acc, curr) =>
          curr
            ? {
                ...acc,
                [curr]: 'true',
              }
            : acc,
        { limit: String(ALERTS_FETCH_LIMIT) },
      )) || { limit: String(ALERTS_FETCH_LIMIT) };

    try {
      const response = await api
        .get('alerts_with_comments', {
          searchParams,
          errorMessage: 'Request to get alerts with comments has failed.',
        })
        .json<IResponseList<IStoredAlert>>();

      CommentService.convertAlertsCommentIdsToString(response.data?.items);

      return response.data.items;
    } catch (e) {
      return [];
    }
  };

  public static markAlertAsResolved = async (alertId: number) => {
    if (!alertId) return undefined;

    const response = await api
      .patch(`alerts/${alertId}/read`, {
        errorMessage: 'Request to mark alert as resolved has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static sendRSUCommentToAlert = async (
    alertId: number,
    commentMessage: string,
  ) => {
    const response = await api
      .post('rsu/comments', {
        json: { alert_id: alertId, text: commentMessage },
        errorMessage: 'Failed to send RSU comment for alert',
      })
      .json<IResponseItem<number>>();

    return response.data?.toString();
  };

  public static getAlertComment = async (commentId: string) => {
    if (!commentId) return undefined;

    const response = await api
      .get(`comment/${commentId}`, {
        errorMessage: 'Retrieving alerts data has failed',
      })
      .json<IResponseItem<IComment>>();

    CommentService.convertCommentIdToString(response.data);

    return response.data;
  };

  public static getAlertWithComments = async (alertId: number) => {
    if (isNaN(alertId) || !Number(alertId)) return undefined;

    const response = await api
      .get(`alert_with_comments/${alertId}`, {
        errorMessage: 'Retrieving alerts data has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static markAlertCommentAsResolved = async (commentId: string) => {
    try {
      BigInt(commentId);
    } catch (e) {
      return undefined;
    }

    const response = await api
      .patch(`comment/${commentId}/read`, {
        errorMessage: 'Request to mark alert as resolved has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static getAlert = async (alertId: number) => {
    if (!alertId) return undefined;

    const response = await api
      .get(`alert/${alertId}`, {
        errorMessage: 'Retrieving alert data has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static getAlertHistory = async (alertId: number) => {
    if (!alertId) return undefined;

    const response = await api
      .get(`alert/${alertId}/history`, {
        errorMessage: 'Retrieving alert history has failed',
      })
      .json<IResponseItem<any>>();

    return response.data;
  };

  public static updateAlert = async (alert: IStoredAlert) => {
    if (!alert.alert_id) return undefined;

    const response = await api
      .patch(`alert/${alert.alert_id}`, {
        json: alert,
        errorMessage: 'Alert was not updated',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static updateAlertComments = async (
    data: IUpdateAlertCommentsInterface,
  ) => {
    await api.patch('comments', {
      json: data,
      errorMessage: 'Comments were not updated',
    });
  };

  public static sendToRSU = async (alert: IStoredAlert) => {
    if (!alert.alert_id) return undefined;

    const response = await api
      .patch(`alerts/${alert.alert_id}/rsu`, {
        json: alert,
        errorMessage: 'Send to RSU has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static sendAlertCommentToRSU = async (commentId: string) => {
    if (!commentId) return undefined;

    const response = await api
      .patch(`comment/${commentId}/rsu`, {
        errorMessage: 'Send comment to RSU has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static escalateAlert = async (alert: IStoredAlert) => {
    if (!alert.alert_id) return undefined;

    const response = await api
      .patch(`alerts/${alert.alert_id}/escalate`, {
        json: alert,
        errorMessage: 'Escalate alert has failed',
      })
      .json<IResponseItem<IStoredAlert>>();

    CommentService.convertAlertCommentIdsToString(response.data);

    return response.data;
  };

  public static escalateAlertComment = async (comment: IComment) => {
    if (!comment.comment_id) return undefined;

    const response = await api
      .patch(`comment/${comment.comment_id}/escalate`, {
        json: comment,
        errorMessage: 'Escalate comment has failed',
      })
      .json<IResponseItem<IComment>>();

    CommentService.convertCommentIdToString(response.data);

    return response.data;
  };
}

interface IUpdateAlertCommentsInterface {
  comment_id?: string;
  alert_id?: number;
  status: number;
  reason?: string[];
  comment_flag?: string;
}
