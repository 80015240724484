import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const { Content, Footer, Header, Sider } = Layout;

interface MainLayoutProps {
  children: ReactNode;
  initialHeaderControls?: ReactNode;
  initialSiderControls?: ReactNode;
  initialFooterControls?: ReactNode;
}

interface IMainLayoutState {
  header?: any;
  sider?: any;
  footer?: any;
  onScroll?: any;
}

// TODO: Change to Higher Order Component?
export let MainLayoutContext;

export const contextCleanupOnUnmount = (id) => {
  if (MainLayoutContext && MainLayoutContext.Consumer) {
    MainLayoutContext.Consumer._currentValue.restoreAllById(id);
  }
};

const PARTS = {
  header: 'header',
  sider: 'sider',
  footer: 'footer',
  onScroll: 'onScroll',
};

export class MainLayout extends React.Component<
  MainLayoutProps,
  IMainLayoutState
> {
  public maps = {};
  public contextValue = {};

  constructor(props) {
    super(props);

    const parts = Object.keys(PARTS);

    // Initial state
    this.state = parts.reduce((acc, val) => ({ ...acc, [val]: undefined }), {});

    // Methods to control layout header and footer blocks from child nodes
    this.contextValue = {
      types: PARTS, // available setter names
      setById: this.setById,
      restoreAllById: this.restoreAllById,
      restoreById: this.restoreById,
    };
    MainLayoutContext = React.createContext(this.contextValue);
  }

  private _checkIdOrThrow = (id) => {
    if (!id) {
      throw new Error(
        'Property "id" should be specified. It should be unique among components. ' +
          'Link to class instance (this) can be used as an id parameter.',
      );
    }
  };

  public setById = (property, id, component, cb) => {
    this.maps[property] = this.maps[property] || new Map();
    const sectionMap = this.maps[property];
    this._checkIdOrThrow(id);

    if (!component) return false;

    const savedElem = sectionMap.get(id);

    if (savedElem === component) {
      return true;
    }

    sectionMap.delete(id);
    sectionMap.set(id, component);
    this.setState({ [property]: component }, cb);

    return true;
  };

  public restoreById = (property, id, cb = undefined) => {
    this._checkIdOrThrow(id);
    const sectionMap = this.maps[property];

    if (!sectionMap.has(id)) {
      return false;
    }

    sectionMap.delete(id); // to save the order of elements
    const keys = [...sectionMap.keys()];
    const lastKey = keys[sectionMap.size - 1];
    this.setState({ [property]: sectionMap.get(lastKey) }, cb);

    return true;
  };

  public restoreAllById = (id) => {
    Object.keys(this.maps).map((property) => this.restoreById(property, id));
  };

  public render() {
    const {
      children,
      initialHeaderControls,
      initialSiderControls,
      initialFooterControls,
    } = this.props;

    const {
      header = initialHeaderControls,
      sider = initialSiderControls,
      footer = initialFooterControls,
      onScroll,
    } = this.state;

    return (
      <MainLayoutContext.Provider value={this.contextValue}>
        <Layout onScroll={onScroll}>
          {header && <StyledHeader>{header}</StyledHeader>}
          <Layout>
            {sider && <StyledSider width={515}>{sider}</StyledSider>}
            <Content>{children}</Content>
          </Layout>
          {footer && <StyledFooter>{footer}</StyledFooter>}
        </Layout>
      </MainLayoutContext.Provider>
    );
  }
}

const shared = css`
  padding: 0 12px;
  z-index: 9;
`;

const StyledHeader = styled(Header)`
  ${shared}
`;

const StyledFooter = styled(Footer)`
  ${shared}
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  height: 46px;
`;

const StyledSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.white100};
  max-height: 100%;
  overflow: auto;
`;
