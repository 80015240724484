import React from 'react';
import { AlignedLoader } from 'src/components/AlignedLoader';
import {
  IAlertModerationStatistic,
  IAlertTypeStatistics,
} from 'src/restApi/alertsService';
import './ModerationStatistic.less';
import { ModerationStatisticBlock } from './ModerationStatisticBlock';

export interface IModerationStatisticProps {
  loading?: boolean;
  statistic: IAlertModerationStatistic | null;
}

export class ModerationStatistic extends React.PureComponent<
  IModerationStatisticProps
> {
  private renderAlertTypeStatistic = (
    heading: string,
    statistic: IAlertTypeStatistics,
  ) => {
    const ageOfOldestInMinutes = Math.ceil(statistic.age_of_oldest / 60);

    return (
      <ModerationStatisticBlock
        heading={heading}
        data={[
          ['Unmoderated alerts', statistic.amount, 'unmoderatedAlerts'],
          [
            'The oldest unmoderated alert',
            `${ageOfOldestInMinutes} min`,
            'oldestUnmoderatedAlerts',
          ],
        ]}
      />
    );
  };

  public render() {
    const { loading, statistic } = this.props;

    if (statistic === null) {
      return loading ? (
        <AlignedLoader className="ModerationStatistic__loader" />
      ) : null;
    }

    return (
      <>
        <ModerationStatisticBlock
          heading="Overall Statistics"
          data={[
            [
              'Active alerts moderators',
              statistic.sessions,
              'overallStatistics',
            ],
          ]}
        />
        {this.renderAlertTypeStatistic(
          'Basic Alerts Statistics',
          statistic.basic_alerts,
        )}
        {statistic.l1_alerts &&
          this.renderAlertTypeStatistic(
            'L1 Alerts Statistics',
            statistic.l1_alerts,
          )}
        {statistic.l2_alerts &&
          this.renderAlertTypeStatistic(
            'L2 Alerts Statistics',
            statistic.l2_alerts,
          )}
      </>
    );
  }
}
