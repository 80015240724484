import { Button, Dropdown, Icon, Menu, Table, Typography } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { replace, toUpper } from 'ramda';

import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { FileIcon } from '../../images/file-icon';
import { ManageUsersPagination } from '../manage-users-pagination';
import { ManageUsersCategoryColumnData } from '../manage-users-category-column-data';
import { ManageUsersSubcategoryColumnData } from '../manage-users-subcategory-column-data';
import { useStores } from 'src/stores';

const { Title } = Typography;
const { Column } = Table;

export const ManageUsersList = observer(() => {
  const { manageUsersStore } = useStores();

  const handleMenuClick = (e) => {
    const action = e.key.split('-')[0];
    const userId = e.key.split('-')[1];

    if (action === 'remove') {
      handleRemoveClick(userId);
    }

    if (action === 'edit') {
      handleEditClick(userId);
    }
  };

  const handleEditClick = (id) => manageUsersStore.handleEditUserClick(id);

  const handleRemoveClick = (id) => manageUsersStore.handleRemoveUserClick(id);

  useEffect(() => {
    if (manageUsersStore.isFilteringByUserId) {
      manageUsersStore.findUser();
    } else {
      manageUsersStore.fetchUsers();
    }
  }, [manageUsersStore, manageUsersStore.recentlyBannedUser]);

  return (
    <Flex flex={1} flexDirection="column">
      <Title level={3}>User Management</Title>
      <Box pb={manageUsersStore.hasNextPage ? 2 : 6}>
        <Table
          dataSource={manageUsersStore.userTableData}
          pagination={false}
          loading={manageUsersStore.isLoading}
          locale={{
            emptyText: <Empty />,
          }}
        >
          <Column
            title="Added Date"
            dataIndex="creation_time"
            key="date"
            render={(date) => <DateColumnData date={date} />}
            sorter={compareCreationDate}
          />
          <Column title="User ID" dataIndex="user_id" key="userId" />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status) => <StatusColumnData status={status} />}
            sorter={compareStatus}
          />
          <Column
            title="Violation Category"
            dataIndex="categories"
            key="category"
            render={(category) => (
              <ManageUsersCategoryColumnData category={category} />
            )}
          />
          <Column
            title="Violation Sub-Category"
            dataIndex="categories"
            key="subcategory"
            render={(category) => (
              <ManageUsersSubcategoryColumnData category={category} />
            )}
          />
          <Column
            title="Notes"
            dataIndex="notes"
            key="notes"
            width={200}
            render={(text, record) => (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {text}
              </div>
            )}
          />
          <Column
            title="Last Edited"
            dataIndex="last_update_time"
            key="lastEdited"
            render={(date) => <DateColumnData date={date} />}
            sorter={compareLastEditedDate}
          />
          {manageUsersStore.hasBanPrivilege && (
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <ActionDropdown
                  record={record}
                  handleMenuClick={handleMenuClick}
                />
              )}
            />
          )}
        </Table>
      </Box>
      <ManageUsersPagination pb={6} />
    </Flex>
  );
});

const DateColumnData = ({ date }) => {
  const parsedDate = Date.parse(date);
  const dateFormat = 'L';
  const momentDate = moment(parsedDate).format(dateFormat);

  return <div>{momentDate}</div>;
};

const DropdownMenu = ({ record, handleMenuClick }) => (
  <Menu mode="inline" onClick={(e) => handleMenuClick(e, record.status)}>
    <Menu.Item key={`edit-${record.user_id}`}>Edit User</Menu.Item>
    <Menu.Item key={`remove-${record.user_id}`} style={{ color: 'red' }}>
      Remove User
    </Menu.Item>
  </Menu>
);

const ActionDropdown = ({ record, handleMenuClick }) => {
  return (
    <Dropdown
      overlay={
        <DropdownMenu record={record} handleMenuClick={handleMenuClick} />
      }
    >
      <Button>
        <Icon type="ellipsis" />
      </Button>
    </Dropdown>
  );
};

const Circle = styled(Flex)`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${(props) => (props.status === 'active' ? '#52c41a' : '#F1999A')};
`;

const StatusColumnData = ({ status }) => {
  return (
    <Flex alignItems="center">
      <Circle status={status} />
      &nbsp;&nbsp;{capitalize(status)}
    </Flex>
  );
};

const Empty = () => {
  return (
    <div className="ant-empty ant-empty-normal">
      <div className="ant-empty-image">
        <FileIcon />
      </div>
      <p className="ant-empty-description">
        This user has not been added or removed from the User Management list.
      </p>
    </div>
  );
};

const compareStatus = (a, b) => {
  return a.status.localeCompare(b.status);
};

const compareCreationDate = (a, b) => {
  const firstMoment = moment(a.creation_time);
  const secondMoment = moment(b.creation_time);
  return firstMoment.diff(secondMoment);
};

const compareLastEditedDate = (a, b) => {
  const firstMoment = moment(a.last_update_time);
  const secondMoment = moment(b.last_update_time);
  return firstMoment.diff(secondMoment);
};

const capitalize = replace(/^./, toUpper);
