import React, { ChangeEvent, FormEvent } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { useStores } from 'src/stores';
import { Flex } from 'src/components/Flex';
const { Title } = Typography;
const { Option } = Select;

const getHandler = (fn: (arg: any) => void) => (
  e: ChangeEvent<HTMLInputElement>,
) => fn(e.target.value);

const ManageUsersFilterComponent = observer(({ form }) => {
  const { manageUsersStore } = useStores();
  const { getFieldDecorator, setFields, setFieldsValue } = form;

  const initialCategoryValue =
    manageUsersStore.filterUserCategory === ''
      ? undefined
      : manageUsersStore.filterUserCategory;
  const initialSubcategoryValue = manageUsersStore.filterUserSubcategory;

  const handleClearFilters = () => {
    setFields({
      userFilterCategory: {
        value: undefined,
      },
      userFilterSubcategory: {
        value: undefined,
      },
    });
    manageUsersStore.clearFilters();
  };

  const handleUserIdChange = getHandler(manageUsersStore.setFilterUserId);
  const handlerNotesChange = getHandler(manageUsersStore.setFilterNotes);

  const handleUserStatusChange = (status) =>
    manageUsersStore.setFilterUserStatus(status);

  const handleUserCategoriesChange = (category) => {
    setFieldsValue({
      userFilterSubcategory: undefined,
    });
    manageUsersStore.setFilterUserCategory(category);
  };

  const handleUserSubcategoriesChange = (subcategories) => {
    manageUsersStore.setFilterUserSubcategory(subcategories);
  };

  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (manageUsersStore.isLoading) return;

    if (manageUsersStore.isFilteringByUserId) {
      manageUsersStore.findUser();
    } else {
      manageUsersStore.fetchUsers();
    }
  };

  return (
    <Flex width="30%" mr="10px">
      <Flex flexDirection="column" flex={0.8} top={'60px'} position={'static'}>
        <Form layout="vertical" onSubmit={handleSearchSubmit}>
          <Title level={3}>User Filters</Title>
          <Form.Item label="User ID">
            <Input
              name="user_id"
              placeholder="User ID"
              allowClear
              onChange={handleUserIdChange}
              value={manageUsersStore.filterUserId}
              autoComplete="off"
            />
          </Form.Item>
          <Flex flex={1} justifyContent="center">
            OR
          </Flex>
          <Form.Item label="User Status">
            <Select
              placeholder="Select User Status"
              onChange={handleUserStatusChange}
              disabled={manageUsersStore.isFilteringByUserId}
              defaultValue={'banned'}
              value={manageUsersStore.filterUserStatus}
              allowClear
            >
              <Option value="banned">Banned</Option>
              <Option value="active">Active</Option>
            </Select>
          </Form.Item>
          <Form.Item label="User Violations Category">
            {getFieldDecorator('userFilterCategory', {
              initialValue: initialCategoryValue,
            })(
              <Select
                placeholder="Select Category"
                onChange={handleUserCategoriesChange}
                disabled={manageUsersStore.isFilteringByUserId}
              >
                {manageUsersStore.categories.map((category, index) => (
                  <Option
                    key={`category-${category}-${index}`}
                    value={`${category}`}
                  >
                    {manageUsersStore.categoryEntities[category].name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="User Violations Sub-Category">
            {getFieldDecorator('userFilterSubcategory', {
              initialValue: initialSubcategoryValue,
            })(
              <Select
                mode="multiple"
                placeholder="Select Sub-Category"
                onChange={handleUserSubcategoriesChange}
                disabled={manageUsersStore.isFilteringByUserId}
              >
                {manageUsersStore.categoryEntities[
                  manageUsersStore.filterUserCategory
                ]?.subcategories?.map((subcategory, index) => (
                  <Option
                    key={`category-${manageUsersStore.filterUserCategory}-subcategory-${subcategory}-${index}`}
                    value={`${subcategory}`}
                  >
                    {manageUsersStore.subcategoryEntities[subcategory]?.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="Notes">
            <Input
              name="notes"
              placeholder="Notes"
              allowClear
              disabled={manageUsersStore.isFilteringByUserId}
              onChange={handlerNotesChange}
              value={manageUsersStore.filterNotes}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <SearchButton
              type="primary"
              htmlType="submit"
              size="large"
              block
              disabled={manageUsersStore.isLoading}
            >
              Apply
            </SearchButton>
          </Form.Item>
          <Form.Item>
            <SearchButton
              type="ghost"
              htmlType="button"
              size="large"
              block
              disabled={manageUsersStore.isLoading}
              onClick={handleClearFilters}
            >
              Reset
            </SearchButton>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
});

const SearchButton = styled(Button)`
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.space[2]}px;
  }
`;

export const ManageUsersFilter = Form.create({
  name: 'manage-users-filter-form',
})(ManageUsersFilterComponent);
