import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { isDevelopment } from './helpers';
import sentry from './sentry-browser';
import { stores } from './stores';
import { theme } from './theme';
import { LoggerComponent } from './logger';
import './theme/index.less';

const root = document.getElementById('root');
const render = () => {
  const { App } = require('./app');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider {...stores}>
          <LoggerComponent>
            <App />
          </LoggerComponent>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>,
    root,
  );
};

sentry();

render();

if (isDevelopment && module.hot) {
  module.hot.accept('./app', render);
}
