import { Button } from 'antd';
import React from 'react';
import { LeftCol } from 'src/components/LeftCol';
import { SearchInput } from 'src/components/search-input';
import {
  DEFAULT_FILTER_VALUES,
  FILTER_KEYS,
  FILTER_WORD_TYPES,
  WORD_LANGUAGES,
  WORD_LANGUAGE_ALL,
} from '../../constants';
import './filters.less';

export function Filters({
  values: filterValues,
  onChange: onFilterChange,
  onSubmit: onFilterSubmit,
  onReset: onSearchReset,
}) {
  return (
    <LeftCol>
      <LeftCol.Form
        useBuiltInFilter
        defaultFormParams={DEFAULT_FILTER_VALUES}
        filterKeys={FILTER_KEYS}
        form={filterValues}
        onChange={onFilterChange}
        onSubmit={onFilterSubmit}
      >
        <LeftCol.Title className="LeftColumnAllWordsPageTitle">
          Filter words
        </LeftCol.Title>
        <LeftCol.FormItem
          className="leftColumnFieldWordsPage"
          label="Words type"
        >
          <LeftCol.Select
            size="large"
            value={filterValues.word_type}
            className="filterField"
            optionFilterProp="children"
            options={FILTER_WORD_TYPES}
            onChange={(value) => onFilterChange({ word_type: value })}
          />
        </LeftCol.FormItem>
        <LeftCol.FormItem className="leftColumnFieldWordsPage" label="Language">
          <LeftCol.Select
            size="large"
            disabled
            value={filterValues.language}
            className="filterField"
            optionFilterProp="children"
            options={WORD_LANGUAGES.concat(WORD_LANGUAGE_ALL)}
            onChange={(value) => onFilterChange({ language: value })}
          />
        </LeftCol.FormItem>
        <LeftCol.FormItem
          className="leftColumnFieldWordsPage"
          label="Search words"
        >
          <SearchInput
            size="large"
            onChange={(e) =>
              onFilterChange({ [e.target.name]: e.target.value })
            }
            name="phrase"
            value={filterValues.phrase}
            onReset={onSearchReset}
            className="leftColFilterField"
            placeholder="Type to search"
          />
        </LeftCol.FormItem>
        <Button size="large" type="primary" htmlType="submit">
          Apply
        </Button>
      </LeftCol.Form>
    </LeftCol>
  );
}
