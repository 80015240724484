import css from 'classnames';
import React from 'react';
import { Badge, BadgeVariants } from 'src/componentsNew/Badge/components/Badge';
import {
  ModerationFlag,
  ModerationFlagBadge,
} from 'src/componentsNew/Moderation/ModerationBadges/components/ModerationFlagBadge';
import { PermissionLevel } from 'src/restApi/interfaces';
import { permissionsList } from '../constants';
import './PermissionsHeader.less';

const permissionLabels = {
  [PermissionLevel.L1]: <ModerationFlagBadge flag={ModerationFlag.L1} />,
  [PermissionLevel.L2]: <ModerationFlagBadge flag={ModerationFlag.L2} />,
  [PermissionLevel.Rsu]: <ModerationFlagBadge flag={ModerationFlag.Rsu} />,
  [PermissionLevel.Root]: <Badge color={BadgeVariants.Purple} text="Admin" />,
  [PermissionLevel.Curation]: (
    <Badge color={BadgeVariants.Purple} text="Curation" />
  ),
};

export function PermissionsHeader() {
  return (
    <div className="PermissionsHeader">
      <div className="PermissionsHeader__titleContainer">Permissions</div>
      <div
        className={css(
          'PermissionsHeader__permissionsLabelContainer',
          'bottom-column-double',
        )}
      >
        {permissionsList.map((permission) => (
          <span
            key={`permission_label_${permission}`}
            className="PermissionsHeader__labelContainer"
          >
            {permissionLabels[permission]}
          </span>
        ))}
      </div>
    </div>
  );
}
