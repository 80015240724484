import css from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Checkbox } from 'src/components/checkbox';
import { URLService } from 'src/services/urlService';
import { AdminStore } from 'src/stores/adminStore';
import { FilterDirection, FilterValue } from '../../../../constants';
import { AdminCategoriesSelector } from '../admin-categories-selector';
import { AdminUserSelector } from '../admin-user-selector';
import s from './style.module.css';

@inject('adminStore')
@observer
export class AdminTableHeader extends React.Component<
  IAdminTableHeaderProps,
  IAdminTableHeaderState
> {
  public state = {
    showCategory: false,
    showUser: false,
  };

  public toogleTimeCreated = () => {
    const newValue =
      this.props.adminStore.sortFilters.direction === FilterDirection.Asc
        ? FilterDirection.Desc
        : FilterDirection.Asc;
    this.props.adminStore.sortFilters.direction = newValue;
    this.props.adminStore.sortFilters.value = FilterValue.TimeCreated;
    URLService.addSearchParams({
      sortDirection: newValue,
      sortValue: this.props.adminStore.sortFilters.value,
    });
  };

  public toogleTimePerReview = () => {
    const newValue =
      this.props.adminStore.sortFilters.direction === FilterDirection.Asc
        ? FilterDirection.Desc
        : FilterDirection.Asc;
    this.props.adminStore.sortFilters.direction = newValue;
    this.props.adminStore.sortFilters.value = FilterValue.TimePerReview;
    URLService.addSearchParams({
      sortDirection: newValue,
      sortValue: this.props.adminStore.sortFilters.value,
    });
  };

  public toogleShowCategory = () => {
    this.setState((nextState) => {
      return {
        showUser: false,
        showCategory: !nextState.showCategory,
      };
    });
  };

  public toogleShowUser = () => {
    this.setState((nextState) => {
      return {
        showCategory: false,
        showUser: !nextState.showUser,
      };
    });
  };

  public closeElement = () => {
    this.setState({
      showUser: false,
      showCategory: false,
    });
  };

  public checkboxHandler = (
    isAllAlertsChecked: boolean,
    isCheckboxOptional: boolean,
  ) => {
    if (isAllAlertsChecked || isCheckboxOptional) {
      this.props.adminStore.uncheckAllAlerts();
    } else if (!isAllAlertsChecked) {
      this.props.adminStore.checkAllAlerts();
    }
  };

  public render() {
    const isAllAlertsChecked =
      this.props.adminStore.unchekedAlertIds.length <= 0;
    const isCheckboxOptional =
      this.props.adminStore.alertsToDisplay.length !==
      this.props.adminStore.unchekedAlertIds.length;

    return (
      <div className={s.mainContainer}>
        <div className={css(s.checkboxContainer, 'row')}>
          {this.props.adminStore.generateCSVModeOn && (
            <Checkbox
              checked={isAllAlertsChecked || isCheckboxOptional}
              optional={!isAllAlertsChecked && isCheckboxOptional}
              onChange={() =>
                this.checkboxHandler(isAllAlertsChecked, isCheckboxOptional)
              }
              title={'All'}
              titleClassName={s.checkboxTitle}
            >
              All
            </Checkbox>
          )}
        </div>
        <div className={s.container}>
          <div className={css(s.item, s.first)} />
          <div className={css(s.item, s.second, s.filterSort)}>
            <span className={s.padding} onClick={this.toogleTimeCreated}>
              Time Created
            </span>
            <div
              className={css(s.sortingArrow)}
              onClick={this.toogleTimeCreated}
            />
          </div>
          <div className={css(s.item, s.third)}>
            <span className={s.txt} onClick={this.toogleShowUser}>
              Reviewed By
            </span>
            <div className={css(s.blk, { [s.show]: this.state.showUser })}>
              {this.state.showUser && (
                <AdminUserSelector
                  closeElement={this.closeElement}
                  acceptButtonHandler={this.toogleShowUser}
                />
              )}
            </div>
          </div>
          <div className={css(s.item, s.fourth)}>
            <span className={s.txt} onClick={this.toogleShowCategory}>
              Category
            </span>
            <div className={css(s.blk, { [s.show]: this.state.showCategory })}>
              {this.state.showCategory && (
                <AdminCategoriesSelector
                  closeElement={this.closeElement}
                  acceptButtonHandler={this.toogleShowCategory}
                />
              )}
            </div>
          </div>
          <div className={css(s.item, s.filterSort)}>
            <span
              className={css(s.padding, s.timePerReviewText)}
              onClick={this.toogleTimePerReview}
            >
              Time-Per-Review
            </span>
            <div
              className={css(s.sortingArrow)}
              onClick={this.toogleTimePerReview}
            />
          </div>
        </div>
      </div>
    );
  }
}

interface IAdminTableHeaderState {
  showCategory: boolean;
  showUser: boolean;
}

interface IAdminTableHeaderProps {
  adminStore?: AdminStore;
}
