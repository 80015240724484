import { action, computed, observable } from 'mobx';
import type { IObservableArray } from 'mobx/lib/internal';
import { normalize, schema } from 'normalizr';
import { isEmpty, isNil, join, omit, pipe, reject, uniq } from 'ramda';
import queryString from 'query-string';

import { InjectRootStore } from './injectRootStore';
import {
  ManageUsersService,
  ManageUsersSearchParams,
} from 'src/services/manageUsersService';
import { PermissionLevel } from '../restApi/interfaces';

import { RootStore } from './rootStore';

const subcategories = new schema.Entity('subcategories');

const categories = new schema.Entity('categories', {
  subcategories: [subcategories],
});

export interface UserViolationsParams {
  user_id: string;
  status: string;
  category_ids: any[];
  moderator: string;
}

interface ManageUsersFormValues {
  userId: string;
  banUser?: boolean;
  categories: [];
  subcategories: [];
  userNotes?: string;
}

export class ManageUsersStore extends InjectRootStore {
  @observable categoriesIndex = 0;
  @observable banUserId = '';
  @observable editingUserId = '';
  @observable editingUserStatus = '';
  @observable editingUserCategories = [];
  @observable editingUserNotes = '';
  @observable hasSearched = false;
  @observable isLoading = true;
  @observable userNotes = '';

  @observable removeBanUserId = '';
  @observable pageOffset: 0 | number = 0;
  @observable totalItems: 0 | number = 0;
  @observable foundItems: 0 | number = 0;
  @observable recentlyBannedUser = false;
  @observable users = [];
  @observable filterUserId = '';
  @observable filterUserStatus = '';
  @observable filterUserCategory = '';
  @observable filterUserSubcategory = [] as IObservableArray<string>;
  @observable filterNotes = '';

  @observable areCategoriesAvailable = false;
  @observable isRemoveBanModalVisible = false;
  @observable isBanModalVisible = false;
  @observable isAddUserModalVisible = false;
  @observable isManageUsersFormBanSwitchToggled = false;
  @observable isEditingUser = false;

  categories = [];
  categoryEntities = {};
  subcategories = [];
  subcategoryEntities = {};
  pageSize = 20;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.restoreFiltersFromURL();
  }

  private addFiltersToURL = () => {
    const { user_id, ...filters } = filterNilOrEmpty({
      user_id: this.filterUserId,
      user_status: this.filterUserStatus,
      category_id: this.filterUserCategory,
      subcategory_ids: this.filterUserSubcategory,
      notes: this.filterNotes,
    });

    const params = this.isFilteringByUserId
      ? {
          user_id,
        }
      : filters;

    const qs = stringifyParams(params);

    window.history.replaceState(null, null, `?${qs}`);
  };

  private restoreFiltersFromURL = () => {
    const parsed = parseSearch();
    const {
      user_id,
      user_status = 'banned',
      category_id,
      subcategory_ids = [],
      notes
    } = filterNilOrEmpty(parsed);

    this.setFilterUserId(ensureArrayAndJoin(user_id));
    this.setFilterUserStatus(ensureArrayAndJoin(user_status));
    this.setFilterUserCategory(ensureArrayAndJoin(category_id));
    this.setFilterUserSubcategory(ensureArray(subcategory_ids));
    this.setFilterNotes(ensureArrayAndJoin(notes));
  };

  public banAndClassifyUser = async (values: ManageUsersFormValues) => {
    const { userId, banUser, userNotes, ...rest } = values;
    const params = {
      ...this.userParams,
      user_id: `app:${userId}`,
      status: banUser ? 'banned' : 'active',
      category_ids: [],
      notes: userNotes,
    };

    Object.entries(rest).map(([key, value], index) => {
      const category = {
        id: null,
        subcategories: [],
      };

      if (
        value === undefined ||
        value === null ||
        value === '' ||
        value.length === 0
      ) {
        return false;
      }

      if (key.includes('userViolationsCategory')) {
        category.id = value;
        params.category_ids.push(category);
      }

      if (key.includes('userViolationsSubcategory')) {
        const index = key.split('Subcategory')[1];
        if (params.category_ids[index]?.subcategories) {
          params.category_ids[index].subcategories = value;
        }
      }

      return true;
    });

    const sanitizedParameters = sanitizeParameters(params);

    const response = await ManageUsersService.updateUser(
      sanitizedParameters,
    ).catch((err) => err);

    const { status } = response;

    if (status === 1) return;

    this.setIsBanModalVisible(false);
    this.setIsAddUserModalVisible(false);
    this.setBanUserId('');
    this.setUserNotes('');
    this.setPageOffset(0);
    this.setFoundItems(0);
    this.setTotalItems(0);
    this.setRecentlyBannedUser(true);
    this.setIsLoading(true);
    this.clearForm();
  };

  // API requests
  public banUser = async () => {
    const response = await ManageUsersService.updateUser(this.banUserParams);

    const { status } = response;

    this.rootStore.notificationsStore.addNotification({
      type: 'success',
      message: `User #${this.banUserId} Banned Successfully`,
    });

    if (status === 1) return;

    this.setIsBanModalVisible(false);
    this.setBanUserId('');
    this.setPageOffset(0);
    this.setFoundItems(0);
    this.setTotalItems(0);
    this.setRecentlyBannedUser(true);
  };

  public fetchCategories = async () => {
    const { data, status } = await ManageUsersService.getCategories();

    if (status === 1) return;

    const { entities, result } = normalize(data, [categories]);

    this.setCategories(result.sort());
    this.setCategoryEntities(entities.categories);
    this.setSubcategoryEntities(entities.subcategories);
    this.setAreCategoriesAvailable(true);
  };

  public fetchNextPage = async () => {
    if (!this.hasNextPage) return;
    this.setRecentlyBannedUser(false);
    this.setIsLoading(true);
    const qs = stringifyParams(this.filters);

    const { data, meta } = await ManageUsersService.getUsers(qs);
    const users = data?.items ?? [];
    const pageOffset = meta?.offset ?? 0;
    const foundItems = meta?.found_items ?? 0;
    this.setIsLoading(false);

    this.addUsers(users);
    this.setPageOffset(pageOffset);
    this.setFoundItems(foundItems);
  };

  public fetchUsers = async () => {
    this.clearUsers();
    this.setHasSearched(false);
    this.setRecentlyBannedUser(false);
    this.setIsLoading(true);
    this.addFiltersToURL();
    this.setPageOffset(0);
    this.setFoundItems(0);
    this.setTotalItems(0);

    const qs = stringifyParams(this.filters);

    const { data, meta } = await ManageUsersService.getUsers(qs);
    const users = data?.items ?? [];
    const pageOffset = meta?.offset ?? 0;
    const foundItems = meta?.found_items ?? 0;

    this.setPageOffset(pageOffset);
    this.setFoundItems(foundItems);
    this.setTotalItems(meta?.total_items ?? 0);
    this.setHasSearched(true);
    this.setIsLoading(false);
    this.setUsers(users);
    this.setRecentlyBannedUser(false);
  };

  public findUser = async () => {
    this.clearUsers();
    this.setHasSearched(false);
    this.setIsLoading(true);
    this.setRecentlyBannedUser(false);
    this.addFiltersToURL();

    const response = await ManageUsersService.getUser(this.appUserId).catch(
      (err) => err,
    );

    const user = response?.data ?? null;
    const userData = user ? [user] : null;

    this.setHasSearched(true);
    this.setIsLoading(false);
    this.setUsers(userData);
  };

  public removeUser = async () => {
    const response = await ManageUsersService.removeUser(
      `app:${this.removeBanUserId}`,
    );
    const { status } = response;

    if (status === 1) return;

    this.setIsRemoveBanModalVisible(false);
    this.setRemoveBanUserId('');
    this.setRecentlyBannedUser(true);
    this.setPageOffset(0);
    this.setFoundItems(0);
    this.setTotalItems(0);
  };

  @action
  private addUsers = (users: any[]) => {
    this.users.push(...users);
  };

  @action
  public clearForm = () => {
    this.editingUserId = '';
    this.editingUserCategories = [];
    this.editingUserStatus = '';
    this.editingUserNotes = '';
    this.isEditingUser = false;
    this.isManageUsersFormBanSwitchToggled = false;
  };

  @action
  public handleEditUserClick = (id) => {
    this.setBanUserId(id);
    this.setIsAddUserModalVisible(true);
    this.setIsEditingUser(true);
    this.setEditingUserId(id);

    const { status, categories = [], notes } = this.userData.find(
      (user) => user.user_id === id,
    );

    this.setEditingUserStatus(status);
    this.setEditingCategories(categories);
    this.setEditingUserNotes(notes);
  };

  @action
  public handleRemoveUserClick = (id) => {
    this.setRemoveBanUserId(id);
    this.setIsRemoveBanModalVisible(true);
  };

  @action
  public setCategoriesIndex = (index: number) => {
    this.categoriesIndex = index;
  };

  @action
  private setAreCategoriesAvailable = (bool: boolean) => {
    this.areCategoriesAvailable = bool;
  };

  @action
  public setBanUser = (bool: boolean) => {
    this.isManageUsersFormBanSwitchToggled = bool;
  };

  @action
  public setUserNotes = (notes: string) => {
    this.userNotes = notes;
  };

  @action
  private setCategories = (categories) => {
    this.categories = categories;
  };

  @action
  private setCategoryEntities = (categories) => {
    this.categoryEntities = categories;
  };

  @action
  public setIsAddUserModalVisible = (bool: boolean) => {
    this.isAddUserModalVisible = bool;
  };

  @action
  public toggleManageUsersModalVisibility = () => {
    this.setIsAddUserModalVisible(!this.isAddUserModalVisible);
  };

  @action
  public setIsEditingUser = (bool: boolean) => {
    this.isEditingUser = bool;
  };

  @action
  public setEditingUserId = (id: string) => {
    this.editingUserId = id;
  };

  @action
  public setEditingUserStatus = (status: string) => {
    this.editingUserStatus = status;
  };

  @action
  public setEditingCategories = (categories) => {
    this.editingUserCategories = categories;
  };

  @action
  public setEditingUserNotes = (notes: string) => {
    this.editingUserNotes = notes;
  };

  @action
  private setSubcategoryEntities = (subcategories) => {
    this.subcategoryEntities = subcategories;
  };

  @action
  public setUsers = (users: any[] | null) => {
    if (!users) return;
    this.users = users;
  };

  @action
  public setFilterUserId = (id: string) => {
    this.filterUserId = id;
  };

  @action
  public setFilterUserStatus = (status: string) => {
    this.filterUserStatus = status;
  };

  @action
  public setFilterUserCategory = (category: string) => {
    this.setFilterUserSubcategory([]);
    this.filterUserCategory = category;
  };

  @action
  public setFilterUserSubcategory = (subcategories: string[]) => {
    this.filterUserSubcategory.replace(subcategories);
  };

  @action
  public setFilterNotes = (notes: string) => {
    this.filterNotes = notes;
  };

  @action
  public setHasSearched = (bool: boolean) => {
    this.hasSearched = bool;
  };

  @action
  public setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  };

  @action
  public setIsBanModalVisible = (bool: boolean) => {
    this.isBanModalVisible = bool;
  };

  @action
  public setIsRemoveBanModalVisible = (bool: boolean) => {
    this.isRemoveBanModalVisible = bool;
  };

  @action
  public setBanUserId = (id: string) => {
    this.banUserId = id;
  };

  @action setRecentlyBannedUser = (bool: boolean) => {
    this.recentlyBannedUser = bool;
  };

  @action setRemoveBanUserId = (id: string) => {
    this.removeBanUserId = id;
  };

  @action setPageOffset = (pageOffset: number) => {
    this.pageOffset = pageOffset;
  };

  @action setTotalItems = (totalItems: number) => {
    this.totalItems = totalItems;
  };

  @action setFoundItems = (foundItems: number) => {
    this.foundItems = foundItems;
  };

  @action
  public clearFilters = () => {
    this.setFilterUserId('');
    this.setFilterUserCategory('');
    this.setFilterUserSubcategory([]);
    this.setUserNotes('');
  };

  @action
  public clearUsers = () => {
    this.setUsers([]);
  };

  @computed
  private get appUserId() {
    return this.filterUserId.includes(`app:`)
      ? this.filterUserId
      : `app:${this.filterUserId}`;
  }

  @computed
  private get banUserParams() {
    return {
      user_id: `app:${this.banUserId}`, // note: All banned users will probably be App users rather than NPSS users
      status: 'banned',
      // Moderator Email from AuthStore
      moderator: this.rootStore.authStore.user.email,
      notes: this.userNotes,
    };
  }

  @computed
  private get filters() {
    return filterNilOrEmpty({
      status: this.filterUserStatus,
      limit: this.pageSize,
      offset: this.pageOffset + this.foundItems,
      category_id: this.filterUserCategory,
      subcategory_ids: this.filterUserSubcategory,
      notes: this.filterNotes,
    });
  }

  @computed
  public get hasNextPage() {
    return this.totalItems - (this.pageOffset + this.pageSize) > 0;
  }

  @computed
  public get hasUsers() {
    return this.users.length > 0;
  }

  @computed
  public get isFilteringByUserId() {
    return this.filterUserId.length > 0;
  }

  @computed
  public get IsFilteringByUserStatus() {
    return this.filterUserStatus.length > 0;
  }

  @computed
  private get userParams() {
    const banUserParams = {
      moderator: this.rootStore.authStore.user.email,
    };

    const userViolationsParams = {
      ...banUserParams,
    };

    return userViolationsParams;
  }

  @computed
  public get hasBanPrivilege() {
    const userScope = this.rootStore.authStore.user!.scope;

    return BAN_PRIVILEGE.some((privilege) => userScope.indexOf(privilege) >= 0);
  }

  @computed
  public get userTableData() {
    return this.users.map((u, i) => ({ ...u, key: `user-${i}` }));
  }

  @computed
  public get userData() {
    return this.users.slice();
  }

  @computed
  public get userCategoriesData() {
    return this.editingUserCategories.slice();
  }

  public isManageUsersFormSelectCategoryEditing = (index) => {
    return (
      this.isEditingUser &&
      this.editingUserCategories.length > 0 &&
      this.editingUserCategories[index]?.id
    );
  };

  public isManageUsersFormSelectSubcategoryEditing = (index) => {
    return (
      this.isEditingUser &&
      this.editingUserCategories.length > 0 &&
      this.editingUserCategories[index]?.subcategories
    );
  };
}

const BAN_PRIVILEGE = [
  PermissionLevel.Rsu,
  PermissionLevel.L1,
  PermissionLevel.L2,
  PermissionLevel.Root,
];

const ensureArray = (maybeArray) =>
  Array.isArray(maybeArray) ? maybeArray : [maybeArray];

const ensureArrayAndJoin = pipe(ensureArray, join(','));

const isNilOrEmpty = (value: any) => isNil(value) || isEmpty(value);

const filterNilOrEmpty = reject(isNilOrEmpty);

const parseSearch = () =>
  queryString.parse(window.location.search, {
    arrayFormat: 'comma',
  }) as ManageUsersSearchParams;

const stringifyParams = (object: Parameters<typeof queryString.stringify>[0]) =>
  queryString.stringify(object, {
    arrayFormat: 'comma',
  });

export const sanitizeParameters = (params: UserViolationsParams) => {
  if (!params.category_ids) {
    return params;
  }
  if (params.category_ids.length === 0) {
    return omit(['category_ids'], params);
  }

  const categories = params.category_ids;

  const newCategories = categories.reduce((accumulator, currentValue) => {
    let prevSubcategories = [];

    if (accumulator[currentValue.id]?.subcategories?.length > 0) {
      prevSubcategories = accumulator[currentValue.id]?.subcategories;
    }

    accumulator[currentValue.id] = {
      id: currentValue.id,
      subcategories: uniq([
        ...currentValue.subcategories,
        ...prevSubcategories,
      ]).sort((a, b) => a - b),
    };

    return accumulator;
  }, {});

  params.category_ids = Object.values(newCategories);

  return params;
};
