import css from 'classnames';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import AlertFreeNote from 'src/components/AlertFreeNote';
import { AlertPropertyRow, IconType } from 'src/components/AlertPropertyRow';
import { Checkbox } from 'src/components/checkbox';
import { MediaViewer } from 'src/components/MediaViewer';
import { NoMediaView } from 'src/components/MediaViewer/components/NoMediaView';
import { RsuCommentPopup } from 'src/components/rsu-comment-popup-container';
import { UserIcon } from 'src/components/user-icon';
import {
  ALERT_BAD_WORDS_TYPES,
  WordsHighlighter,
} from 'src/components/WordsHighlighter';
import { AlertActions } from 'src/componentsNew/Alert/components/AlertActions';
import { BadgeAligner } from 'src/componentsNew/Badge/components/BadgeAligner';
import { getModerationBadges } from 'src/componentsNew/Moderation/ModerationBadges/helpers/getModerationBadges';
import { ModerationHistory } from 'src/componentsNew/Moderation/ModerationHistory';
import { ToggleIconButton } from 'src/componentsNew/ToggleIconButton';
import { UserHyperlink } from 'src/componentsNew/UserHyperlink';
import { ALERT_REASONS, TITLE_DATE_FORMAT } from 'src/constants';
import { getMediaContentType, sortHandler } from 'src/helpers';
import { CommentService } from 'src/restApi/commentService';
import {
  IStoredAlert,
  ModerationStatus,
  PermissionLevel,
} from 'src/restApi/interfaces';
import { PhraseType } from 'src/restApi/phrasesService';
import { Stores } from 'src/stores';
import { BlockLoader } from '../../../NewsCurationPage/components/BlockLoader';
import { AdminService } from '../../services/adminService';
import { AdminAlertLink } from '../AdminAlertLink';
import { AdminHistoryTimeline } from '../AdminHistoryTimeline/AdminHistoryTimeline';
import s from './style.module.css';
import { SearchAlertMultimediaViewer } from 'src/pages/search/components/search-alert-multimedia-viewer';
import { BadActor } from '../../../../componentsNew/Alert/components/AlertInfoFields';
import { Icon, Icon as IconComponent } from '../../../../componentsNew/Icon';
import { CommentBadActor } from '../../../../components/alert-item-comment';
import { Tooltip } from 'src/componentsNew/Tooltip/Tooltip';
import { AlertExtendedDescriptionBlock } from '../../../../components/AlertExtendedDescriptionBlock';

const { Agency, AlertId, DingId, User } = IconType;

interface IAdminElementContainerState {
  openedCommentHistory: { [key: string]: boolean };
  showHistory: boolean;
  rsuPopupOptions: {
    isVisible: boolean;
    type: 'edit' | 'delete' | 'new';
  };
}

export interface IAdminElementContainerProps extends Partial<Stores> {
  alert: IStoredAlert;
  onApproveClick: (alert: IStoredAlert) => void;
  onDeclineClick: (alert: IStoredAlert) => void;
}

@inject('adminStore', 'authStore')
@observer
export class AdminElementContainer extends React.Component<
  IAdminElementContainerProps,
  IAdminElementContainerState
> {
  public state = {
    openedCommentHistory: {},
    rsuPopupOptions: {
      isVisible: false,
      type: null,
    },
    showHistory: false,
  };

  public componentWillUnmount() {
    this.setState({
      showHistory: false,
    });
  }

  public componentWillReceiveProps(nextProps) {
    if (!nextProps.alert.history) {
      this.setState({
        showHistory: false,
      });
    }
  }

  public calculateDiff = (start: number, end: number) => {
    const createTime = moment(start * 1000);
    const updateTime = moment(end * 1000);
    return moment(updateTime.diff(createTime)).format('mm:ss');
  };

  public openRsuPopup = (ref: string, type: 'edit' | 'delete' | 'new') => {
    const popup: any = this.refs[ref];
    this.setState({
      rsuPopupOptions: {
        type,
        isVisible: true,
      },
    });
    popup.toogleContainer();
  };

  public handleHistoryClick = (e: React.SyntheticEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState(
      ({ showHistory }) => ({
        showHistory: !showHistory,
      }),
      this.fetchHistoryAndStatistic,
    );
  };

  public fetchHistoryAndStatistic = async () => {
    const { showHistory } = this.state;
    const { alert, adminStore } = this.props;
    const { alert_id } = alert;

    if (!showHistory) return;

    const [statistic, history] = await Promise.all([
      AdminService.fetchAlertStatistic(alert_id),
      AdminService.fetchAlertHistory(alert_id),
    ]);

    if (history) {
      adminStore.updateAlert(alert_id, { history });
    }

    if (statistic) {
      adminStore.updateAlert(alert_id, { statistic });
    }
  };

  public handlerPopup = (commentId: string, text: string) => {
    return this.state.rsuPopupOptions.type === 'delete'
      ? this.deleteRSUCommentHandler(commentId)
      : this.editRSUCommentHandler(commentId, text);
  };

  public deleteRSUCommentHandler = async (commentId: string) => {
    await AdminService.deleteRSUComment(commentId);
    this.props.adminStore.deleteComment(commentId);
  };

  public deleteCommentHandler = async (commentId: string) => {
    await AdminService.deleteComment(commentId);
    const updatedComment = await CommentService.getComment(commentId);
    this.props.adminStore.updateComment(updatedComment);
  };

  public editRSUCommentHandler = async (commentId: string, text: string) => {
    await AdminService.editRSUComment(commentId, text);
    this.props.adminStore.updateCommentText(commentId, text);
  };

  public createRSUCommentHandler = async (text: string) => {
    const newCommentId = await AdminService.createRSUComment(
      this.props.alert.alert_id,
      text,
    );
    await this.updateAlertWithComment(newCommentId);
  };

  public updateAlertWithComment = async (newCommentId: string) => {
    const comment = await CommentService.getComment(newCommentId);
    this.props.adminStore.addNewComment(comment);
  };

  public checkboxHandler = (isAlertChecked: boolean) => {
    isAlertChecked
      ? this.props.adminStore.uncheckAlert(this.props.alert.alert_id)
      : this.props.adminStore.checkAlert(this.props.alert.alert_id);
  };

  public renderRsuEditTool = (comment, commentFullDate) => {
    return (
      <div className={css(s.popupConainer)}>
        <span
          className={css(s.rsuCommentLink)}
          onClick={() =>
            this.openRsuPopup(`rsu_container_${comment.comment_id}`, 'delete')
          }
        >
          Delete
        </span>{' '}
        <span
          className={css(s.rsuCommentLink)}
          onClick={() =>
            this.openRsuPopup(`rsu_container_${comment.comment_id}`, 'edit')
          }
        >
          Edit
        </span>
        <RsuCommentPopup
          commentDate={commentFullDate}
          submitAction={(text) => this.handlerPopup(comment.comment_id, text)}
          ref={`rsu_container_${comment.comment_id}`}
          commentText={comment.text}
          type={this.state.rsuPopupOptions.type}
        />
      </div>
    );
  };

  public renderCommentDeleteTool = (comment, commentFullDate) => {
    return (
      <div className={css(s.popupConainer)}>
        <span
          className={css(s.rsuCommentLink)}
          onClick={() =>
            this.openRsuPopup(`rsu_container_${comment.comment_id}`, 'delete')
          }
        >
          Delete
        </span>
        <RsuCommentPopup
          showRSULabel={false}
          commentDate={commentFullDate}
          submitAction={() => this.deleteCommentHandler(comment.comment_id)}
          ref={`rsu_container_${comment.comment_id}`}
          commentText={comment.text}
          type={this.state.rsuPopupOptions.type}
        />
      </div>
    );
  };

  public getModerationTime = (seconds) => {
    if (!seconds) return null;

    const SHOW_SECONDS_TIME = 60;

    const diffedTime =
      seconds <= SHOW_SECONDS_TIME
        ? `${Number.parseFloat(seconds).toFixed()} s`
        : moment.duration(seconds, 'seconds').humanize();

    return <span title={`${seconds} s`}>{diffedTime}</span>;
  };

  public reasonToText = (reason) =>
    (Array.isArray(reason) ? reason : [reason]).join(', ').split('_').join(' ');

  public getAlertReasons = (alert) => {
    if (alert.reason && alert.reason.slice().length) {
      return alert.reason
        .map((reason) => ALERT_REASONS[reason] || reason.split('_').join(' '))
        .join(', ');
    }

    if (alert.status) {
      this.reasonToText(alert.status);
    }
  };

  public loadCommentHistory = async (commentId: string) => {
    const history = await AdminService.getCommentHistory(commentId);
    this.props.adminStore.setAlertCommentHistory(commentId, history);
  };

  public toggleCommentHistory = (commentId: string) => (isOpened) => {
    const openedCommentHistory = {
      ...this.state.openedCommentHistory,
      [commentId]: isOpened,
    };

    if (isOpened) {
      this.loadCommentHistory(commentId);
    } else {
      this.props.adminStore.deleteCommentHistory(commentId);
    }

    this.setState({ openedCommentHistory });
  };

  public render() {
    const { showHistory, openedCommentHistory } = this.state;
    const { alert, onApproveClick, onDeclineClick, authStore, adminStore } =
      this.props;

    const isUserRSU = authStore.checkUserPermission(PermissionLevel.Rsu);

    const isAlertChecked = !adminStore.unchekedAlertIds.includes(
      alert.alert_id,
    );

    const hasFreeNotes = Boolean(alert.free_note);
    const hasMultipleMedia = alert?.media_assets?.length > 0;

    const imageDeletedMessage = `User has requested ${
      hasMultipleMedia ? `these items` : alert.src ? 'this video' : 'this image'
    } to be removed`;

    const ownerPostIsBadActor =
      alert.user_data != null && Object.keys(alert.user_data).length !== 0;

    return (
      <>
        <div className={s.mainContainer}>
          {this.props.adminStore.generateCSVModeOn && (
            <div className={css(s.checkboxContainer, 'row')}>
              <Checkbox
                className={s.check}
                checked={isAlertChecked}
                onChange={() => this.checkboxHandler(isAlertChecked)}
              />
            </div>
          )}
          <div className={css(s.container, s.collapsed)}>
            <div className={css(s.header)}>
              <div
                className={css(s.headerImg, s.big, {
                  [s.extraBig]: showHistory,
                  [s.empty]: !alert.src && !alert.image_url,
                })}
              >
                {alert.is_deleted_by_user ? (
                  <NoMediaView
                    className={css(s.noMediaContainer)}
                    hideNoVisualContentText={false}
                    message={imageDeletedMessage}
                  />
                ) : hasMultipleMedia ? (
                  <SearchAlertMultimediaViewer
                    multimediaAssets={alert.media_assets}
                    showHistory={showHistory}
                    alertId={alert.alert_id}
                    className={css(s.searchAlertMultimediaViewer, {
                      [s.fullheight]: showHistory,
                    })}
                  />
                ) : (
                  (alert.src || alert.image_url) && (
                    <MediaViewer
                      source={getMediaContentType(alert.src, alert.image_url)}
                    />
                  )
                )}
              </div>
              <div
                className={css(s.alertContent, s.expanded, {
                  [s.hide]: showHistory,
                })}
              >
                <div className={css(s.alertTitle, s.expanded)}>
                  {(alert.title || alert.is_deleted_by_user) && (
                    <WordsHighlighter
                      type={ALERT_BAD_WORDS_TYPES}
                      className={css(s.titleArrow, s.expanded)}
                      textToHighlight={
                        alert.is_deleted_by_user ? 'Null' : alert.title
                      }
                    />
                  )}
                </div>
                <div className={css(s.alertDate)}>
                  {alert.time_created &&
                    moment
                      .unix(alert.time_created)
                      .format('hh:mm A MMM D, YYYY')}
                </div>
                {alert.address && (
                  <AlertPropertyRow>{alert.address}</AlertPropertyRow>
                )}
                {alert.alert_id && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={AlertId}
                    copyValue={alert.alert_id}
                  >
                    Alert ID:{' '}
                    <b>
                      <AdminAlertLink alertId={alert.alert_id} />
                    </b>
                  </AlertPropertyRow>
                )}
                {((alert.ding_id && alert.ding_id !== '0') ||
                  alert.is_deleted_by_user) && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={DingId}
                  >
                    Ding ID:{' '}
                    {alert.is_deleted_by_user ? 'Null' : <b>{alert.ding_id}</b>}
                  </AlertPropertyRow>
                )}
                {alert.share_url && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={IconType.Agency}
                    copyValue={alert.share_url}
                  >
                    Share link:{' '}
                    <a
                      href={alert.share_url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Link
                    </a>
                  </AlertPropertyRow>
                )}
                {(alert.user_id || alert.is_deleted_by_user) && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={User}
                    copyValue={alert.user_id}
                  >
                    User ID:{' '}
                    {alert.is_deleted_by_user ? (
                      'Null'
                    ) : (
                      <b>
                        <UserHyperlink userId={alert.user_id} />
                      </b>
                    )}
                    {ownerPostIsBadActor && (
                      <Tooltip
                        content={
                          alert.user_data.notes
                            ? alert.user_data.notes
                            : 'Empty'
                        }
                      >
                        <BadActor>
                          <IconComponent iconType={IconType.Exclamation} />
                        </BadActor>
                      </Tooltip>
                    )}
                  </AlertPropertyRow>
                )}
                {alert.user_selected_category && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={Agency}
                  >
                    User Selected Category: {alert.user_selected_category}
                  </AlertPropertyRow>
                )}
                {alert.cop && (
                  <AlertPropertyRow
                    className={s.alertItemRow}
                    iconType={Agency}
                  >
                    Agency: {alert.user_name}
                  </AlertPropertyRow>
                )}
                <div className={css(s.alertDescription)}>
                  {(alert.description || alert.is_deleted_by_user) && (
                    <WordsHighlighter
                      type={ALERT_BAD_WORDS_TYPES}
                      textToHighlight={
                        alert.is_deleted_by_user ? 'Null' : alert.description
                      }
                    />
                  )}
                </div>
                <div className={css(s.alertDescription)}>
                  <AlertExtendedDescriptionBlock {...alert} />
                </div>
                {!alert.is_deleted && (
                  <AlertActions
                    alertId={alert.alert_id}
                    onApproveClick={() => onApproveClick(alert)}
                    onDeclineClick={() => onDeclineClick(alert)}
                  />
                )}
                {!alert.comments && isUserRSU && (
                  <div
                    className={css('column', s.popupConainer)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span
                      className={css(s.rsuCommentLink)}
                      onClick={() =>
                        this.openRsuPopup(
                          `rsu_new_container_alert_${alert.alert_id}`,
                          'new',
                        )
                      }
                    >
                      Leave a comment
                    </span>
                    <RsuCommentPopup
                      ref={`rsu_new_container_alert_${alert.alert_id}`}
                      submitAction={this.createRSUCommentHandler}
                      type={this.state.rsuPopupOptions.type}
                    />
                  </div>
                )}
              </div>
              <div className={css(s.contentUser, { [s.hide]: showHistory })}>
                {alert.email && [
                  <div className={css(s.avatar)} key={'user-email-icon'}>
                    <UserIcon userEmail={alert.email} size={20} />
                  </div>,
                  <div className={css(s.name)} key={'user-email-text'}>
                    {alert.email}
                  </div>,
                ]}
              </div>
              <div className={css(s.contentReason, { [s.hide]: showHistory })}>
                <BadgeAligner multiLine>
                  {getModerationBadges(alert)}
                </BadgeAligner>
              </div>
              <div className={css(s.contentTime, { [s.hide]: showHistory })}>
                {this.getModerationTime(alert.moderation_time_s)}
              </div>
              <div
                onClick={this.handleHistoryClick}
                className={css(s.showHistory, { [s.expanded]: showHistory })}
              >
                History
              </div>
              {showHistory && (
                <div className={s.history}>
                  <AdminHistoryTimeline alert={alert} />
                  {hasFreeNotes && (
                    <AlertFreeNote
                      className={s.freeNote}
                      freeNote={alert.free_note}
                    />
                  )}
                </div>
              )}
            </div>
            {alert.comments && (
              <div
                className={css(s.content, {
                  [s.empty]: !alert.comments.length && !isUserRSU,
                })}
              >
                {isUserRSU && (
                  <div className={css(s.popupConainer, s.contentElement)}>
                    <span
                      className={s.rsuCommentLink}
                      onClick={() =>
                        this.openRsuPopup(
                          `rsu_new_container_${alert.alert_id}`,
                          'new',
                        )
                      }
                    >
                      Leave a Comment
                    </span>
                    <RsuCommentPopup
                      ref={`rsu_new_container_${alert.alert_id}`}
                      submitAction={this.createRSUCommentHandler}
                      type={'new'}
                    />
                  </div>
                )}
                {alert.comments
                  .sort(sortHandler((comment) => comment.time_created))
                  .map((c) => {
                    const isCommentApproved = [
                      ModerationStatus.Approved,
                      ModerationStatus.AutoApproved,
                    ].includes(c.status);

                    const isCommentDeleted = c.is_deleted;
                    const hasCommentEmail = Boolean(c.email && c.email.length);
                    const hasUserId = Boolean(c.user_id);
                    const hasNeighborNumber = Boolean(c.neighbor_number);
                    const commentDate = moment.unix(c.time_created);
                    const commentFullDate = commentDate.format(
                      'hh:mm A MMM D, YYYY',
                    );
                    const isRSU = c.hasOwnProperty('is_rsu') && c.is_rsu;
                    const isCommentAutoDeclined =
                      c.status === ModerationStatus.AutoDeclined;
                    const isCommentByOriginalPoster =
                      alert.user_id && alert.user_id === c.user_id;
                    const highlightingType: PhraseType[] = [
                      PhraseType.ProfanityComments,
                      isCommentAutoDeclined
                        ? PhraseType.ForbiddenComments
                        : PhraseType.Risky,
                    ];
                    const showCommentHistory =
                      openedCommentHistory[c.comment_id];

                    const isOwnerCommentBadActor =
                      c.user_data != null &&
                      Object.keys(c.user_data).length !== 0;

                    return (
                      <div
                        className={s.contentElement}
                        key={`comment_${c.comment_id}_alert_${this.props.alert.alert_id}`}
                      >
                        <div className={s.contentHeader}>
                          {isRSU && (
                            <div className={css(s.rsuLabel, 'left-row')}>
                              RING
                            </div>
                          )}
                          {(hasUserId || c.is_deleted_by_user) && (
                            <span
                              className={css(s.userId, {
                                [s.isOP]: isCommentByOriginalPoster,
                              })}
                            >
                              {c.is_deleted_by_user ? (
                                'Null'
                              ) : (
                                <UserHyperlink userId={c.user_id} />
                              )}

                              {hasNeighborNumber && (
                                <span>&nbsp;(Neighbor{c.neighbor_number})</span>
                              )}
                              {isOwnerCommentBadActor && (
                                <Tooltip
                                  content={
                                    c.user_data.notes
                                      ? c.user_data.notes
                                      : 'Empty'
                                  }
                                >
                                  <CommentBadActor>
                                    <Icon iconType={IconType.Exclamation} />
                                  </CommentBadActor>
                                </Tooltip>
                              )}
                            </span>
                          )}
                          <span className={s.commentId}>{c.comment_id}</span>
                          <span
                            className={s.time}
                            title={commentDate.format(TITLE_DATE_FORMAT)}
                          >
                            {commentDate.fromNow()}
                          </span>
                        </div>
                        <div className={s.contentBody}>
                          <div className={s.contentText}>
                            <div>
                              {c.text && (
                                <WordsHighlighter
                                  type={highlightingType}
                                  textToHighlight={c.text}
                                />
                              )}
                            </div>
                          </div>
                          <div className={s.contentUser}>
                            <div className={s.avatar}>
                              {hasCommentEmail && (
                                <UserIcon userEmail={c.email} size={20} />
                              )}
                            </div>
                            <div className={s.name}>{c.email || ''}</div>
                          </div>
                          {!showCommentHistory && (
                            <div className={s.contentReason} key="reason">
                              <BadgeAligner multiLine>
                                {getModerationBadges(c)}
                              </BadgeAligner>
                            </div>
                          )}
                          {showCommentHistory && (
                            <div
                              className={s.contentReason}
                              key="commentHistory"
                            >
                              {c.history ? (
                                <ModerationHistory history={c.history} />
                              ) : (
                                <BlockLoader />
                              )}
                            </div>
                          )}
                          <div className={css(s.contentTime)}>
                            {c.ring_created_at &&
                              c.ring_updated_at &&
                              this.calculateDiff(
                                c.ring_created_at,
                                c.ring_updated_at,
                              )}
                          </div>
                          <ToggleIconButton
                            isOpened={showCommentHistory}
                            onToggle={this.toggleCommentHistory(c.comment_id)}
                          />
                        </div>
                        {isRSU &&
                          isUserRSU &&
                          this.renderRsuEditTool(c, commentFullDate)}

                        {!isCommentDeleted &&
                          isCommentApproved &&
                          this.renderCommentDeleteTool(c, commentFullDate)}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
