import React, { useState, useCallback } from 'react';
import { useStores } from 'src/stores';
import { ManageUsersModalForm } from '../manage-users-modal-form';

export const ManageUsersModal = () => {
  const [formRef, setFormRef] = useState(null);
  const { manageUsersStore } = useStores();

  const handleSubmitClick = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        console.error(`Error: `, err);
        return;
      }
      manageUsersStore.banAndClassifyUser(values);
      formRef.resetFields();
    });
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  return (
    <ManageUsersModalForm
      ref={saveFormRef}
      handleSubmitClick={handleSubmitClick}
    />
  );
};
