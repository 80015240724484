import React from 'react';
import { VerticalAligner } from '../../components/VerticalAligner';
import styles from './style.module.css';

const Signin = () => (
  <VerticalAligner>
    <span className={styles.titleText}>Start fight crime right now</span>
    <span className={styles.descriptionText}>
      Sign in to watch videos and look for suspects
    </span>
  </VerticalAligner>
);

export { Signin };
