import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';

export const LoggerComponent = observer((props) => {
  const { configStore } = useStores();

  useEffect(() => {
    if (!configStore.isLoggerInstalled) {
      configStore.installLogger();
    }
  }, [configStore]);

  return props.children;
});
