import { Spin } from 'antd';
import css from 'classnames';
import { transaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import React from 'react';
import {
  contextCleanupOnUnmount,
  MainLayoutContext,
} from 'src/components/layouts/MainLayout';
import { Stores } from 'src/stores';
import { VerticalAligner } from '../../../../components/VerticalAligner';
import { NavigationTabs } from '../../../../constants';
import { buildUrlWithParams } from '../../../../helpers';
import { INewsSearchOptions, NewsId } from '../../../../restApi/newsService';
import { URLService } from '../../../../services/urlService';
import { NewNewsBlock } from '../../components/NewNewsBlock';
import { NewsFilters } from '../../components/NewsFilters';
import { NewsListV2 } from '../../components/NewsList';
import { NEWS_FILTER_PROPS, NEWS_INIT_OPTIONS } from '../../constants';
import s from './curation-page.module.css';

interface ICurationPageState {
  renderedFilters: boolean;
}

interface ICurationPageProps extends Stores {
  history?: History;
}

@inject('newsListStoreV2', 'newsListUIStoreV2', 'newsOptionsStoreV2', 'uiStore')
@observer
export class CurationPageV2 extends React.Component<
  ICurationPageProps,
  ICurationPageState
> {
  private static getFilterParamsFromUrl(): Partial<INewsSearchOptions> {
    const { search } = window.location;
    const urlParams = queryString.parse(search.slice(1));

    return Object.keys(urlParams).reduce((acc, prop) => {
      // Filter only news props
      if (!NEWS_FILTER_PROPS.includes(prop)) return acc;

      const value = urlParams[prop];
      const array = Array.isArray(value) ? value : [value];

      const filledValues = array.filter(
        (value) => value !== undefined && value !== '',
      );

      if (filledValues.length) {
        if (prop !== 'keywords') {
          acc[prop] = filledValues;
        } else {
          acc[prop] = filledValues[0];
        }
      }

      return acc;
    }, {});
  }

  private static setFilterParamsIntoUrl(options: INewsSearchOptions) {
    const filledOptions = Object.keys(options).reduce((acc, prop) => {
      const value = options[prop];
      const isValArr = Array.isArray(value);

      if (
        (isValArr && value.length) ||
        (!isValArr && value !== '' && value !== undefined)
      ) {
        acc[prop] = options[prop];
      }

      return acc;
    }, {});

    URLService.setUrl(filledOptions);
  }

  public state = {
    renderedFilters: false,
  };

  public componentWillMount() {
    const { newsListStoreV2 } = this.props;

    if (!newsListStoreV2.news.length) {
      const filterParams = CurationPageV2.getFilterParamsFromUrl();

      // Set filters
      if (Object.keys(filterParams).length) {
        newsListStoreV2.setSearchOptions({
          ...NEWS_INIT_OPTIONS,
          ...filterParams,
        });
      }

      newsListStoreV2.loadNewsFlow();
    }
  }

  public componentWillUnmount() {
    contextCleanupOnUnmount(this);
  }

  public onFilterSubmit = async (options: INewsSearchOptions) => {
    const { newsListStoreV2, newsListUIStoreV2 } = this.props;

    newsListStoreV2.setSearchOptions(options);
    newsListUIStoreV2.resetNewsToDelete();

    CurationPageV2.setFilterParamsIntoUrl(options);

    await newsListStoreV2.loadNewsFlow();
  };

  public getNewsItemHref = (id) => {
    return buildUrlWithParams(NavigationTabs.CURATION_V2_EDIT.path, { id });
  };

  public renderFilters = () => {
    const { newsListStoreV2, newsOptionsStoreV2 } = this.props;

    return (
      <NewsFilters
        filterState={newsListStoreV2.searchOptions}
        loading={!newsListStoreV2.news.length && newsListStoreV2.loadingNews}
        options={newsOptionsStoreV2.options}
        onFilterSubmit={this.onFilterSubmit}
      />
    );
  };

  private handleShowNewMessages = () => {
    const { newsListStoreV2, newsListUIStoreV2 } = this.props;

    if (newsListStoreV2.newNewsCount) {
      transaction(() => {
        newsListStoreV2.showNewNews();
        newsListUIStoreV2.setScrollTop(0);
      });
    } else {
      newsListStoreV2.loadNewNewsFlow();
    }
  };

  private handleLoadMoreNews = async () => {
    const { newsListStoreV2 } = this.props;

    if (newsListStoreV2.hasNews) {
      await newsListStoreV2.loadNewsFlow();
    }
  };

  private handleDeleteNews = (ids: NewsId[]) => {
    const { newsListStoreV2 } = this.props;

    return newsListStoreV2.deleteNewsFlow(ids);
  };

  private onRenderFilters = () => {
    if (!this.state.renderedFilters) {
      this.setState({
        renderedFilters: true,
      });
    }
  };

  public render() {
    const { newsListStoreV2 } = this.props;
    const { renderedFilters } = this.state;

    const showLoader = !renderedFilters;

    return (
      <div className={css(s.main__container)}>
        <MainLayoutContext.Consumer>
          {({ setById, types }) =>
            setById(
              types.sider,
              this,
              this.renderFilters(),
              this.onRenderFilters,
            )
          }
        </MainLayoutContext.Consumer>

        {showLoader && (
          <VerticalAligner>
            <Spin />
          </VerticalAligner>
        )}

        {!showLoader && (
          <>
            <NewNewsBlock
              newNewsCount={newsListStoreV2.newNewsCount}
              isLoading={newsListStoreV2.loadingNewNews}
              onClick={this.handleShowNewMessages}
            />

            <NewsListV2
              hasMoreNews={newsListStoreV2.hasNews}
              getNewsItemHref={this.getNewsItemHref}
              isDeleting={newsListStoreV2.deletingNews}
              isLoading={newsListStoreV2.loadingNews}
              news={newsListStoreV2.news}
              onDeleteNews={this.handleDeleteNews}
              onLoadMoreNews={this.handleLoadMoreNews}
              scrollTop={newsListStoreV2.scrollTop}
            />
          </>
        )}
      </div>
    );
  }
}
