import React from 'react';
import {
  IModerationHistory,
  isAlertHistory,
  isCommentHistory,
  ModerationActionType,
} from 'src/restApi/interfaces';
import { theme } from 'src/theme';
import {
  ModerationFlag,
  ModerationFlagBadge,
} from '../../ModerationBadges/components/ModerationFlagBadge';
import {
  ModerationStatus,
  ModerationStatusBadge,
} from '../../ModerationBadges/components/ModerationStatusBadge';

const { blue65, orange65, purple100, purple65, red100 } = theme.colors;

interface IHistoryPartsComputedProps {
  color: string;
  badgeComponent?: JSX.Element;
}

export interface IHistoryComputedProps {
  color: string;
  badgeComponents?: JSX.Element[];
}

const statusColors = new Map();
statusColors.set(ModerationStatus.New, blue65);
statusColors.set(ModerationStatus.Approved, blue65);
statusColors.set(ModerationStatus.Assigned, purple65);
statusColors.set(ModerationStatus.Declined, orange65);
statusColors.set(ModerationStatus.AutoApproved, blue65);
statusColors.set(ModerationStatus.AutoDeclined, orange65);

function getHistoryFlagRecordConfig(
  assetHistory: IModerationHistory,
): IHistoryPartsComputedProps {
  const {
    action: { on_rsu, is_l1, is_l2 },
    action_type,
  } = assetHistory;

  let flag: ModerationFlag;
  let flagReason;
  let color = purple100;

  // Check common props

  // Pick color class
  // Use unary & to check action_type against TS enum
  if (
    action_type === ModerationActionType.CommentFlagged ||
    action_type === ModerationActionType.CommentDeleted ||
    action_type === ModerationActionType.AlertMissedSla ||
    action_type === ModerationActionType.CommentMissedSla ||
    action_type === ModerationActionType.AlertFlagged
  ) {
    color = red100;
  }

  // Check common props

  // Select flag
  if (on_rsu) {
    flag = ModerationFlag.Rsu;
  } else if (is_l1) {
    flag = ModerationFlag.L1;
  } else if (is_l2) {
    flag = ModerationFlag.L2;

    // Check alert props
  } else if (isAlertHistory(assetHistory)) {
    if (assetHistory.action.missed_sla) {
      flag = ModerationFlag.MissedSla;
    } else if (assetHistory.action.is_flagged) {
      flag = ModerationFlag.Flagged;
      flagReason = assetHistory.action.flag_reason;
    }

    // Check comment props
  } else if (isCommentHistory(assetHistory)) {
    if (assetHistory.action.missed_sla) {
      flag = ModerationFlag.MissedSla;
    } else if (
      assetHistory.action.is_deleted &&
      typeof assetHistory.action?.deleted_by_admin === 'undefined'
    ) {
      flag = ModerationFlag.Deleted;
    } else if (
      assetHistory.action.is_deleted &&
      assetHistory.action.is_deleted_by_user
    ) {
      flag = ModerationFlag.DeletedByUser;
    } else if (
      assetHistory.action.is_deleted &&
      assetHistory.action.deleted_by_admin
    ) {
      flag = ModerationFlag.DeletedByAdmin;
    } else if (assetHistory.action.is_flagged) {
      flag = ModerationFlag.Flagged;
    }
  }

  if (flag) {
    return {
      badgeComponent: (
        <ModerationFlagBadge
          flag={flag}
          flagReason={flagReason}
          key={'badge-flag'}
        />
      ),
      color,
    };
  }

  return undefined;
}

function getHistoryStatusRecordConfig(
  assetHistory: IModerationHistory,
): IHistoryComputedProps {
  const {
    action_type,
    action: { reason, status },
  } = assetHistory;
  const isAlertSentToRsuAction =
    action_type === ModerationActionType.AlertSendToRsu;
  const badgeStatus: ModerationStatus = isAlertSentToRsuAction
    ? ModerationStatus.Approved
    : status;

  if (badgeStatus === undefined && !reason) return undefined;

  return {
    badgeComponents: [
      <ModerationStatusBadge reason={reason} status={badgeStatus} />,
      // Show user selected category if specified for an alert
      isAlertHistory(assetHistory) &&
        assetHistory.action.user_selected_category && (
          <ModerationStatusBadge
            preselectedReason
            reason={[assetHistory.action.user_selected_category]}
            status={badgeStatus}
          />
        ),
    ]
      .filter(Boolean)
      .map((element, key) => React.cloneElement(element, { key })),
    color: statusColors.get(status),
  };
}

export function getHistoryRecordConfig(
  assetHistory: IModerationHistory,
): IHistoryComputedProps {
  const flagConfig = getHistoryFlagRecordConfig(assetHistory);
  const statusConfig = getHistoryStatusRecordConfig(assetHistory);

  if (flagConfig || statusConfig) {
    return {
      badgeComponents: [].concat(
        flagConfig ? flagConfig.badgeComponent : [],
        statusConfig ? statusConfig.badgeComponents : [],
      ),
      color: flagConfig ? flagConfig.color : statusConfig.color,
    };
  }

  return null;
}
