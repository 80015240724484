import { sortBy } from 'lodash';
import { IDropdownOptions } from '../../../interfaces';
import { NEWS_SERVICES_DICT, NEWS_STATUSES_DICT } from '../constants';

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const makeDropdownOptions = (arr: string[]): IDropdownOptions[] =>
  arr.map(
    (el: string): IDropdownOptions => ({
      text: el,
      value: el,
    }),
  );

export const makeCapitalizedDropdownOptions = (
  arr: string[],
): IDropdownOptions[] =>
  arr.map(
    (el: string): IDropdownOptions => ({
      text: capitalizeFirstLetter(el),
      value: el,
    }),
  );

export const makeSourcesDropdownOptions = (
  sources: string[],
): IDropdownOptions[] =>
  sources.map(
    (source: string): IDropdownOptions => ({
      text: NEWS_SERVICES_DICT[source],
      value: source,
    }),
  );

export const makeStatusesDropdownOptions = (
  statuses: string[],
): IDropdownOptions[] =>
  statuses.map(
    (status: string): IDropdownOptions => ({
      text: NEWS_STATUSES_DICT[status],
      value: status,
    }),
  );

export const makeEditorsDropdownOptions = (
  editors: string[],
): IDropdownOptions[] =>
  editors.map((editor: string) => ({ text: editor, value: editor }));

export const prepareEditorEmails = (editors) => {
  const sortedEditors = sortBy(editors, (editor) =>
    String(editor.email).toLowerCase(),
  );
  return sortedEditors.map((editor) => ({
    text: editor.email,
    value: editor.email,
  }));
};
