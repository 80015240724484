import css from 'classnames';
import React from 'react';
import { PopupSimpleMenu } from 'src/components/popup-simple-menu';
import { IPopupSimpleMenu } from 'src/interfaces';
import s from './style.module.css';

class AlertAdditionalMenu extends React.Component<
  AlertAdditionalMenuProps,
  AlertAdditionalMenuStates
> {
  public state = {
    showMenu: false,
  };

  public openMenu = () => this.setState({ showMenu: true });

  public closeMenu = () => this.setState({ showMenu: false });

  public render() {
    const { showMenu } = this.state;

    return (
      <div className={s.additionalMenuContainer} data-test-id="threeDotsMenu">
        <div
          className={css(s.menuButton, { [s.activeMenuButton]: showMenu })}
          onClick={this.openMenu}
        />
        {showMenu && (
          <PopupSimpleMenu
            onClose={this.closeMenu}
            className={s.menuContainer}
            menuItems={this.props.menuItems}
          />
        )}
      </div>
    );
  }
}

interface AlertAdditionalMenuProps {
  menuItems: IPopupSimpleMenu[];
  className?: string;
  onClick?: Function;
}

interface AlertAdditionalMenuStates {
  showMenu: Boolean;
}

export { AlertAdditionalMenu };
