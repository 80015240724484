import { message } from 'antd';
import {
  action,
  computed,
  flow,
  IObservableArray,
  observable,
  set,
  toJS,
} from 'mobx';
import { sortHandler } from '../helpers';
import { IStoredUser, PermissionLevel } from '../restApi/interfaces';
import { PermissionService } from '../restApi/permissionService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';

export class PermissionStore extends InjectRootStore {
  public static userSorter = sortHandler((user) => user.email);

  @observable private readonly _users: IObservableArray<
    IStoredUser
  > = observable.array([]);

  // Computed methods

  @computed public get sortedUsers(): IStoredUser[] {
    return this.users.sort(PermissionStore.userSorter);
  }

  @computed public get users() {
    return toJS(this._users);
  }

  // Actions

  @action private addUser = (user: IStoredUser) => {
    this._users.push(user);
  };

  @action private deleteUser(email: string) {
    this._users.replace(this._users.filter((user) => user.email !== email));
  }

  @action private getUser = (email: string) => {
    return this._users.find((user) => user.email === email);
  };

  @action public resetStore = () => {
    this.setUsers([]);
  };

  @action private setUsers = (users: IStoredUser[]) => {
    this._users.replace(users);
  };

  @action private updateUser = (userToUpdate: IStoredUser) => {
    for (const user of this._users) {
      if (user.email === userToUpdate.email) {
        set(user, userToUpdate);
      }
    }
  };

  // Flows

  public addUserFlow = flow(
    function* addUserFlow(
      this: PermissionStore,
      email: string,
      scope: PermissionLevel[],
    ) {
      try {
        const users = yield PermissionService.getUsers();
        const isUserExist = users.find(
          (userFromList) => userFromList.email === email,
        );

        if (isUserExist) {
          message.warning(`User "${email}" has already been added`);
          return false;
        }

        const user = yield PermissionService.addUser({
          email,
          scope,
        });

        this.addUser(user);

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to add user',
        });

        return false;
      }
    }.bind(this),
  );

  public deleteUserFlow = flow(
    function* deleteUserFlow(this: PermissionStore, email: string) {
      try {
        const user = this.getUser(email);

        if (!user) {
          message.error(`User "${email}" was not found`);
          return false;
        }

        yield PermissionService.deleteUser(user);

        this.deleteUser(email);

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to delete user',
        });

        return false;
      }
    }.bind(this),
  );

  public fetchAdminUsersFlow = flow(
    function* fetchAdminUsersFlow(this: PermissionStore) {
      try {
        const users = yield PermissionService.getUsers();

        this.setUsers(users);

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to fetch users',
        });

        return false;
      }
    }.bind(this),
  );

  public updateUserFlow = flow(
    function* updateUserFlow(
      this: PermissionStore,
      email: string,
      scope: PermissionLevel,
    ) {
      let user;

      try {
        user = this.getUser(email);

        if (!user) {
          message.error(`User "${email}" was not found`);
          return false;
        }

        if (!scope.length) {
          yield this.deleteUserFlow(email);
          return true;
        }

        const updatedUser = yield PermissionService.updateUser({
          ...user,
          scope,
        });

        this.updateUser(updatedUser);

        return true;
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to update user',
        });

        return false;
      }
    }.bind(this),
  );
}
