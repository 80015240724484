import css from 'classnames';
import React from 'react';
import s from './title.module.css';

export const Title = ({ className, children }: TitleProps) => (
  <div className={css(s.leftColumnTitle, className)}>{children}</div>
);

interface TitleProps {
  children?: JSX.Element[] | JSX.Element | string;
  className?: string;
}
