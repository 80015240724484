import { Button } from 'antd';
import React from 'react';
import { Flex } from 'src/components/Flex';

import { useStores } from 'src/stores';

const isManageUserViolationsEnabled = true;

export const ManageUsersActions = () => {
  const { manageUsersStore } = useStores();

  const handleAddUserClick = () => {
    manageUsersStore.toggleManageUsersModalVisibility();
  };

  const handleBanUserClick = () => {
    manageUsersStore.setIsBanModalVisible(!manageUsersStore.isBanModalVisible);
  };

  return (
    manageUsersStore.hasBanPrivilege && (
      <Flex
        backgroundColor={'white'}
        bottom={0}
        boxShadow={'0 -2px 4px 0 rgba(0, 0, 0, 0.15)'}
        width="100%"
        py={3}
        position={'fixed'}
        zIndex={100}
      >
        <Flex
          width="100%"
          justifyContent="flex-end"
          maxWidth={'1366px'}
          margin={'0 auto'}
        >
          {isManageUserViolationsEnabled ? (
            <Button type="default" onClick={handleAddUserClick}>
              Add User
            </Button>
          ) : (
            <Button type="default" onClick={handleBanUserClick}>
              Ban User
            </Button>
          )}
        </Flex>
      </Flex>
    )
  );
};
