const { colors } = require('./colors');

const {
  blue100,
  greenDark,
  grey100,
  grey20,
  grey35,
  grey65,
  grey3,
  orange100,
  red100,
  blue65,
  white100,
  greyOverlay,
} = colors;

const fontFamily = `'Muli', sans-serif`;

const base = {
  '@primary-color': blue100,
  '@info-color': blue100,
  '@success-color': greenDark,
  '@processing-color': blue100,
  '@error-color': red100,
  '@highlight-color': red100,
  '@warning-color': orange100,
  '@normal-color': grey65,
};

const antd = {
  ...base,
  '@font-family-no-number': fontFamily,
  '@font-family': fontFamily,
  '@code-family': fontFamily,
  '@border-radius-base': '4px',
  '@heading-color': grey100,
  '@text-color': grey100,
  '@text-color-secondary': grey65,
  '@border-color-split': grey20,
  '@heading-color-dark': white100,
  '@text-color-dark': grey100,
  '@text-color-secondary-dark': 'fade(#fff, 65%)',
  '@font-size-base': '13px',
  '@border-width-base': '2px',
  // Badge
  '@badge-font-weight': 600,
  '@badge-height': '24px',
  //Button
  '@btn-default-color': grey65,
  '@btn-font-weight': 600,
  '@btn-primary-color': white100,
  '@btn-primary-bg': base['@primary-color'],
  '@btn-primary-border': base['@primary-color'],
  //Forms
  '@label-color': grey65,
  '@form-item-trailing-colon': false,
  // Input
  '@input-placeholder-color': grey35,
  // Layout
  '@layout-body-background': grey3,
  '@layout-header-background': white100,
  '@layout-footer-background': white100,
  '@layout-header-height': '46px',
  '@layout-header-padding': '0 12px',
  '@layout-footer-padding': '0 12px',
  // Link
  '@link-color': blue65,
  '@link-active-color': blue65,
  '@link-hover-color': blue100,
  '@link-decoration': 'none',
  //Modal
  '@modal-mask-bg': greyOverlay,
};

module.exports = { antd };
