import { Timeline } from 'antd';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constants';
import { IModerationHistory } from '../../../restApi/interfaces';
import { BadgeAligner } from '../../Badge/components/BadgeAligner';
import { Icon, IconType } from '../../Icon';
import { getHistoryRecordConfig } from './helpers/getHistoryRecordConfig';
import './ModerationHistoryItem.less';

export type IAlertCommentHistoryItemProps = IModerationHistory & {
  key?: number;
};

export function ModerationHistoryItem(item: IAlertCommentHistoryItemProps) {
  const config = getHistoryRecordConfig(item);

  if (!config) {
    console.error(
      'ModerationHistoryItem configuration is undefined for item: ',
      item,
    );

    return undefined;
  }

  return (
    <Timeline.Item
      className="ModerationHistoryItem"
      color={config.color}
      key={item.key}
    >
      <span className="ModerationHistoryItem__date">
        {moment(item.time_created * 1000).format(DATE_FORMAT)}
      </span>

      {item.email && (
        <span className="ModerationHistoryItem__user">
          <Icon
            iconType={IconType.User}
            className="ModerationHistoryItem__userIcon"
          />
          {item.email}
        </span>
      )}

      {config.badgeComponents && (
        <BadgeAligner>{config.badgeComponents}</BadgeAligner>
      )}
    </Timeline.Item>
  );
}
