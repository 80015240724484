import css from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';

import { AssignButton } from 'src/components/assing-button';
import { Box } from 'src/components/Box';

import { Label } from 'src/components/label';
import { Timer } from 'src/components/Timer';
import { UserIcon } from 'src/components/user-icon';
import { getMediaContentType } from 'src/helpers';
import {
  getAssignedUserEmail,
  getTopLevelApprovalCategory,
  hasAlertReasons,
  isAlertEscalated,
} from 'src/helpers/alertHelpers';
import { useStores } from 'src/stores';
import { BadgeAligner } from '../../componentsNew/Badge/components/BadgeAligner';
import { getModerationBadges } from '../../componentsNew/Moderation/ModerationBadges/helpers/getModerationBadges';
import {
  TimeForChangeDecision,
  TopLevelAlertApprovalCategory,
} from '../../constants';
import { IStoredAlert } from '../../restApi/interfaces';
import { MediaViewer } from '../MediaViewer';
import './style.less';

const rootClassName = 'AlertItemPreview';

const previewMediaSize = {
  height: 34,
  width: 60,
};

const classNameByTopLevelCategory = {
  [TopLevelAlertApprovalCategory.Crime]: 'crime',
  [TopLevelAlertApprovalCategory.SuspiciousActivity]: 'suspiciousActivity',
  [TopLevelAlertApprovalCategory.Safety]: 'safety',
  [TopLevelAlertApprovalCategory.Solicitor]: 'solicitor',
  [TopLevelAlertApprovalCategory.LostPets]: 'lostPets',
  [TopLevelAlertApprovalCategory.NeighborlyMoment]: 'neighborlyMoment',
};

export interface IAlertItemPreviewProps {
  className?: string;
  alert: IStoredAlert;
  expandAction?: Function;
  isAlertAssigned?: boolean;
  expanded?: boolean;
  moderated?: boolean;
  canEdited?: boolean;
  editAction?: () => void;
  isModerated?: boolean;
  isUserOwner?: boolean;
  titleReasonShown: boolean;
  hideNoVisualContentText: boolean;
}

export const AlertItemPreview = observer<FC<IAlertItemPreviewProps>>(
  ({
    alert,
    canEdited,
    editAction,
    expandAction,
    hideNoVisualContentText,
    isAlertAssigned,
    isModerated,
    isUserOwner,
    titleReasonShown = true,
  }) => {
    const { authStore, configStore } = useStores();

    const assignedUserEmail = getAssignedUserEmail(alert, authStore.user.email);
    const hasMoreThanOneAsset = alert?.media_assets?.length > 1;

    const renderEditingTools = () => (
      <>
        <Timer
          key={'alert-item-preview-timer-container'}
          wrapperClass={css('left-row', `${rootClassName}__timerContainer`)}
          createdTime={moment.unix(alert.time_updated).valueOf()}
          showTime
          editMode
          maxDuration={TimeForChangeDecision}
          markersCount={2}
        />
        <AssignButton
          onClick={editAction}
          editMode
          key={'alert-item-preview-edit-container'}
        />
      </>
    );

    const renderModeratedContent = () => {
      const hasReason = hasAlertReasons(alert);
      const isAlertDeferred = alert.is_deferred;
      const isEscalated = isAlertEscalated(alert);

      return (
        <>
          <span
            className={`${rootClassName}__alertItemDecisionContainer`}
            key={'alert-item-preview-description'}
          >
            {isEscalated && !isAlertDeferred && (
              <Label type="escalated" escalationLevel={alert.is_l1 ? 1 : 2} />
            )}
            {hasReason && (
              <Label status={alert.status} reason={alert.reason as string[]} />
            )}
          </span>

          {canEdited && renderEditingTools()}
        </>
      );
    };

    const getCategorySpecificClassName = (alert: IStoredAlert) => {
      const topLevelCategory = getTopLevelApprovalCategory(configStore, alert);
      const className =
        classNameByTopLevelCategory[topLevelCategory] || 'noCategory';

      return `${rootClassName}__${className}`;
    };

    const getRootClassName = (alert: IStoredAlert, isModerated: boolean) => {
      const modeClassName = `${rootClassName}__${
        isModerated ? 'moderated' : 'default'
      }`;

      const categorySpecificClassName = isModerated
        ? null
        : getCategorySpecificClassName(alert);
      return css(rootClassName, modeClassName, categorySpecificClassName);
    };

    return (
      <div
        onClick={() => !isModerated && expandAction?.()}
        className={getRootClassName(alert, isModerated)}
        data-test-id="alertContainer"
      >
        {hasMoreThanOneAsset ? (
          <MultimediaPreview
            alert_id={alert.alert_id}
            image_url={alert.image_url}
            hideNoVisualContentText={hideNoVisualContentText}
            src={alert.src}
            media_assets={alert.media_assets}
          />
        ) : (
          <div className={`${rootClassName}__mediaPreviewContainer`}>
            <MediaViewer
              id={alert.alert_id}
              size={previewMediaSize}
              posterURL={alert.image_url}
              preview
              source={getMediaContentType(alert.src, alert.image_url)}
              hideNoVisualContentText={hideNoVisualContentText}
            />
          </div>
        )}
        <div
          className={css(
            `${rootClassName}__previewContentContainer`,
            'left-row',
          )}
        >
          <span
            className={`${rootClassName}__moderatedTitle`}
            key="alert-item-title"
          >
            {alert.title}
          </span>
          {isModerated && renderModeratedContent()}
        </div>
        {isAlertAssigned && !isUserOwner && (
          <UserIcon
            className={`${rootClassName}__userIcon`}
            src={assignedUserEmail}
            size={34}
          />
        )}
        {titleReasonShown && (
          <BadgeAligner className={`${rootClassName}__alertReasonLabel`}>
            {getModerationBadges(alert)}
          </BadgeAligner>
        )}
      </div>
    );
  },
);

const MultimediaPreview = ({
  alert_id,
  hideNoVisualContentText,
  src,
  image_url,
  media_assets,
}) => {
  const firstAsset = media_assets[0];
  return (
    <StackedPreview>
      <li data-test-id="alertCollapsed">
        <Box
          border={`1px solid white`}
          borderRadius={'4px'}
          backgroundColor={'black'}
          height={'34px'}
          width={'60px'}
        />
        <ul>
          <li>
            <Box
              borderRadius={'4px'}
              height={'34px'}
              width={'60px'}
              overflow={'hidden'}
            >
              <MediaViewer
                id={alert_id}
                size={previewMediaSize}
                preview
                source={{ src: firstAsset.url, type: firstAsset.type }}
                hideNoVisualContentText={hideNoVisualContentText}
              />
            </Box>
          </li>
        </ul>
      </li>
    </StackedPreview>
  );
};

const StackedPreview = styled.ul`
  list-style: none;
  ul {
    list-style: none;
    position: absolute;
    top: 15px;
    left: 6px;
  }
`;
