export interface IGeoPoint {
  latitude: number;
  longitude: number;
}

const toRadians = (value: number) => {
  return (value * Math.PI) / 180;
};

export const getDistanceGeoPoints = (
  point1: IGeoPoint,
  point2: IGeoPoint,
): number => {
  // This function implements the ‘haversine’ formula to calculate the great-circle distance between two points.

  const lat1 = point1.latitude;
  const lon1 = point1.longitude;
  const lat2 = point2.latitude;
  const lon2 = point2.longitude;

  const R = 6371e3; // metres
  const f1 = toRadians(lat1);
  const f2 = toRadians(lat2);
  const df = toRadians(lat2 - lat1);
  const dl = toRadians(lon2 - lon1);

  const a =
    Math.sin(df / 2) * Math.sin(df / 2) +
    Math.cos(f1) * Math.cos(f2) * Math.sin(dl / 2) * Math.sin(dl / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInMeters = R * c;

  // Distance in miles
  return distanceInMeters * 0.00062137;
};
