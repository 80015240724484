import { ModerationStatus } from 'src/restApi/interfaces';
import {AlertService} from "src/services/alertService";

import { rootStore, Tabs } from 'src/stores';
import {
  REMOVE_ALERT_RSU_DELAY,
  removingAlertDelay,
  tabsStores,
} from '../constants';
import { IStoredAlert } from '../restApi/interfaces';

const { alertsListStore } = rootStore;

export const assignAlert = async (alertId: number, tabStore: Tabs) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const alert = {
    alert_id: alertId,
    status: ModerationStatus.Assigned,
  } as IStoredAlert;

  const updatedAlert = await AlertService.updateAlert(alert);

  updateAlertInStore(updatedAlert, tabStore);
};

export const fetchAlertHistory = async (alertId: number, tabStore: Tabs) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const { getAlert } = alertsListStore;

  const alert = getAlert(tabStore)(alertId);

  if (!alert) return;

  const alertHistory = await AlertService.getAlertHistory(alertId);

  const alertWitStory = { ...alert, history: alertHistory };
  updateAlertInStore(alertWitStory, tabStore);
};

export const submitAlert = async (
  alertId: number,
  status: number,
  reason: number[],
  freeNote: string,
  interesting: boolean,
  isDeffered: boolean,
  tabStore: Tabs,
) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (status === undefined) { throw new TypeError("status must be provided") }
  if (reason === undefined) { throw new TypeError("reason must be provided") }
  if (freeNote === undefined) { throw new TypeError("freeNote must be provided") }
  if (interesting === undefined) { throw new TypeError("interesting must be provided") }
  if (isDeffered === undefined) { throw new TypeError("isDeffered must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const alert = {
    status,
    interesting,
    alert_id: alertId,
    category_ids: reason,
    free_note: freeNote,
  } as IStoredAlert;
  const updatedAlert = await AlertService.updateAlert(alert);

  (updatedAlert as IStoredAlert).uiStates = {
    editMode: false,
    blockEditing: isDeffered,
  };

  updateAlertInStore(updatedAlert, tabStore);
  isDeffered && removeAlertWithDelay(alertId, tabStore);
};

export const escalateAlertToL1 = async (alertId: number) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }

  const alert = { alert_id: alertId } as IStoredAlert;
  const updatedAlert = await AlertService.escalateAlert(alert);

  (updatedAlert as IStoredAlert).uiStates = {
    blockEditing: true,
    moderated: true,
  };

  updateAlertInStore(updatedAlert, tabsStores.ALERTS);
  removeAlertWithDelay(updatedAlert.alert_id, tabsStores.ALERTS);
};

export const escalateAlertToL2 = async (
  alertId: number,
  reason: number[],
  freeNote: string,
  status: number,
  interesting: boolean,
) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (reason === undefined) { throw new TypeError("reason must be provided") }
  if (freeNote === undefined) { throw new TypeError("freeNote must be provided") }
  if (status === undefined) { throw new TypeError("status must be provided") }
  if (interesting === undefined) { throw new TypeError("interesting must be provided") }
  const alert = {
    status,
    interesting,
    alert_id: alertId,
    category_ids: reason,
    free_note: freeNote,
  } as IStoredAlert;
  const updatedAlert = await AlertService.escalateAlert(alert);

  (updatedAlert as IStoredAlert).uiStates = {
    moderated: true,
    blockEditing: true,
  };

  updateAlertInStore(updatedAlert, tabsStores.ESCALATED);
  removeAlertWithDelay(updatedAlert.alert_id, tabsStores.ESCALATED);
};

export const sendAlertToRsu = async (
  alertId: number,
  status: number,
  reason: number[],
  freeNote: string,
  interesting: boolean,
  isDeffered: boolean,
  tabStore: Tabs,
) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (status === undefined) { throw new TypeError("status must be provided") }
  if (reason === undefined) { throw new TypeError("reason must be provided") }
  if (freeNote === undefined) { throw new TypeError("freeNote must be provided") }
  if (interesting === undefined) { throw new TypeError("interesting must be provided") }
  if (isDeffered === undefined) { throw new TypeError("isDeffered must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const alert = {
    status,
    interesting,
    alert_id: alertId,
    category_ids: reason,
    free_note: freeNote,
  } as IStoredAlert;

  const updatedAlert = await AlertService.sendToRSU(alert);

  (updatedAlert as IStoredAlert).uiStates = {
    editMode: false,
    blockEditing: isDeffered,
  };

  updateAlertInStore(updatedAlert, tabStore);
  removeAlertWithDelay(updatedAlert.alert_id, tabStore, REMOVE_ALERT_RSU_DELAY);
};

export const markAlertAsResolved = async (alertId: number) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }

  const updatedAlert = await AlertService.markAlertAsResolved(alertId);

  updatedAlert &&
    alertsListStore.removeAlertWithoutComments(tabsStores.RSU)(alertId);
};

export const setAlertWrappedState = (
  alertId: number,
  state: boolean,
  tabStore: Tabs,
) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (state === undefined) { throw new TypeError("state must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const { getAlertWithComments, updateAlertWithComments } = alertsListStore;
  const alert = getAlertWithComments(tabStore)(alertId);

  if (!alert) return;

  alert.uiStates = {
    ...alert.uiStates,
    expanded: state,
  };

  updateAlertWithComments(tabStore)(alert);
};

export const editAlert = (alertId: number, tabStore: Tabs) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const { getAlert } = alertsListStore;
  const alert = getAlert(tabStore)(alertId);

  if (!alert) return;

  alert.uiStates = {
    ...(alert.uiStates || {}),
    editMode: true,
  };

  updateAlertInStore(alert, tabStore);
};

export const cancelEditAlert = (alertId: number, tabStore: Tabs) => {
  if (alertId === undefined) { throw new TypeError("alertId must be provided") }
  if (tabStore === undefined) { throw new TypeError("tabStore must be provided") }

  const { getAlert } = alertsListStore;
  const alert = getAlert(tabStore)(alertId);

  if (!alert) return;

  alert.uiStates = {
    ...alert.uiStates,
    editMode: false,
  };

  updateAlertInStore(alert, tabStore);
};

const removeAlertWithDelay = (
  alertId: number,
  tabStore: Tabs,
  alertRemovementDelay = removingAlertDelay,
) => {
  setTimeout(() => {
    alertsListStore.removeAlert(tabStore)(alertId);
  }, alertRemovementDelay);
};

const updateAlertInStore = (alert: IStoredAlert, tabStore: Tabs) => {
  alertsListStore.updateAlert(tabStore)(alert);
};
