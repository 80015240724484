import css from 'classnames';
import React from 'react';
import { IPopupType } from 'src/interfaces';
import styles from './style.module.css';

class Popup extends React.Component<PopupProps, PopupStates> {
  public contentContainer: HTMLElement;
  public height = '0px';
  public state = {
    showContent: false,
  };

  public componentDidMount() {
    setTimeout(() => this.setState({ showContent: true }), 250);
  }

  public hidePopup = () => {
    this.setState({ showContent: false }, () =>
      setTimeout(() => this.props.onClose(), 230),
    );
  };

  public render() {
    return (
      <div
        className={css(styles.container, styles[this.props.type])}
        style={{
          height: this.state.showContent
            ? this.contentContainer.clientHeight
            : '0px',
        }}
      >
        <span
          className={styles.contentContainer}
          ref={(contentContainer) => (this.contentContainer = contentContainer)}
        >
          <div className={css(styles.messageText, 'row')} key="popup-text">
            {this.props.text || ''}
          </div>
          <div
            key="popup-close-button"
            className={css(styles.closeIcon, 'column left-row')}
            onClick={() => this.hidePopup()}
          />
        </span>
      </div>
    );
  }
}

interface PopupProps {
  text: string;
  onClose: Function;
  type: IPopupType;
}

interface PopupStates {
  showContent: boolean;
}

export { Popup };
