import React from 'react';

export enum VideoPreload {
  None = 'none',
  Metadata = 'metadata',
  Auto = 'auto',
}

export interface IVideoViewProps {
  className: string;
  shouldShowControls: boolean;
  poster?: string;
  preload?: VideoPreload;
  onError?: (error) => void;
  onPosterURLLoadError?: (error) => void;
  src: string;
  width: number | string;
  height: number | string;
  testID?: string;
}

export class VideoView extends React.Component<IVideoViewProps> {
  public videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

  public componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.videoRef.current.load();
    }
  }

  private modifyErrorHandler(handler) {
    if (!handler) {
      return handler;
    }

    return (event) => {
      event.stopPropagation();
      handler(event);
    };
  }

  public render() {
    const { onPosterURLLoadError, onError, width, height } = this.props;
    const isDefinedStyles = width && height;

    return (
      <video
        ref={this.videoRef}
        className={this.props.className}
        controls={this.props.shouldShowControls}
        poster={this.props.poster}
        preload={this.props.preload}
        onError={this.modifyErrorHandler(onPosterURLLoadError)}
        style={isDefinedStyles && { height, width }}
        data-test-id={this.props.testID}
      >
        <source
          src={this.props.src}
          type="video/mp4"
          onError={this.modifyErrorHandler(onError)}
        />
      </video>
    );
  }
}
