import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import { AlignedLoader } from 'src/components/AlignedLoader';
import styled from 'styled-components';
import './ModerationLayout.less';

interface ModerationLayoutProps {
  controls: ReactNode;
  isLoading?: boolean;
  main: ReactNode;
}

export const ModerationLayout = ({
  controls,
  isLoading,
  main,
}: ModerationLayoutProps) =>
    <ContainerRow type="flex">
      {isLoading ? (
        <AlignedLoader tip="Initiating..." />
      ) : (
        <>
          <MainColumn>{main}</MainColumn>
          <SideMenuCol>{controls}</SideMenuCol>
        </>
      )}
    </ContainerRow>;

const MainColumn = styled(Col)`
  flex: 1;
`;

const ContainerRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey3};
  flex: 1;
  padding: ${({ theme }) => theme.space[3]}px 5%;
`;

const SideMenuCol = styled(Col)`
  background-color: ${({ theme }) => theme.colors.white100};
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colors.grey10};
  margin-left: ${({ theme }) => theme.space[3]}px;
  text-align: center;
  width: 235px;

  > div {
    padding-bottom: ${({ theme }) => theme.space[3]}px;
    padding-top: ${({ theme }) => theme.space[3]}px;
  }

  > div + div {
    padding-top: 0;
  }
`;
