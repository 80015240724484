import type { Moment } from 'moment';
import {ExtendedKy} from 'src/api';
import type { IAlert } from 'src/restApi/alertsService';
import type { IResponseList } from 'src/restApi/restApi';

// https://w.amazon.com/bin/view/Ring/Neighbors/Admin/NPS-Integration/Search
export interface SearchParams {
  ids?: string[];
  text?: string;
  date_start?: Moment | Date | string;
  date_end?: Moment | Date | string;
  categories?: string[];
  subcategories?: string[];
  owner_uid?: string;
  ding_ids?: string[];
  event_types?: string[];
  location_address?: string;
  location_latitude?: number;
  location_longitude?: number;
  location_radius?: number;
  page_size?: number;
  sort_by?: string[];
  start_after?: Moment | Date | string;
  with_comments?: boolean;
}

export interface SearchMeta {
  total_items: number;
}

export interface SearchAlert extends IAlert {
  id: string
}

export class SearchService {
  public api: ExtendedKy;

  constructor(api: ExtendedKy) {
    this.api = api;
  }

  public search = (params: SearchParams) => {
    return this.api
      .post('search', { json: params })
      .json<IResponseList<SearchAlert, SearchMeta>>();
  };
}
