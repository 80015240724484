import classNames from 'classnames';
import React from 'react';
import { BadgeAligner } from '../../componentsNew/Badge/components/BadgeAligner';
import {
  CaseInfoFlagBadge,
  CaseInfoFlagBadgeFlag,
} from '../../componentsNew/CaseInfoFlagBadge';
import {
  CaseInfoStatusBadge,
  CaseInfoStatusBadgeStatus,
} from '../../componentsNew/CaseInfoStatusBadge';
import { AlertPropertyRow, IconType } from '../AlertPropertyRow';
import { ALERT_BAD_WORDS_TYPES, WordsHighlighter } from '../WordsHighlighter';

interface IAlertCaseResolution {
  className?: string;
  isCrime: boolean;
  resolved: boolean;
  resolvedMessage: string;
  renderBoldValue?: boolean;
}

const renderValue = (value: string, renderBoldValue: boolean) => (
  <WordsHighlighter
    className={classNames({ AlertCaseResolution__boldText: renderBoldValue })}
    type={ALERT_BAD_WORDS_TYPES}
    textToHighlight={value}
  />
);

export const AlertCaseResolution = ({
  className,
  isCrime,
  resolved,
  resolvedMessage,
  renderBoldValue,
}: IAlertCaseResolution) => {
  return (
    <>
      {resolvedMessage && (
        <AlertPropertyRow className={className} iconType={IconType.Message}>
          Resolved Message: {renderValue(resolvedMessage, renderBoldValue)}
        </AlertPropertyRow>
      )}

      {(resolved || isCrime) && (
        <BadgeAligner className="AlertPoliceCaseBlock__resolveLabel">
          {resolved && (
            <CaseInfoStatusBadge status={CaseInfoStatusBadgeStatus.Resolved} />
          )}
          {isCrime && <CaseInfoFlagBadge flag={CaseInfoFlagBadgeFlag.Crime} />}
        </BadgeAligner>
      )}
    </>
  );
};
