import { Button, Form, Select } from 'antd';
import css from 'classnames';
import React from 'react';
import { LeftCol } from '../../../../components/LeftCol';
import { SearchInput } from '../../../../components/search-input';
import { IDropdownOptions } from '../../../../interfaces';
import {
  INewsOptions,
  INewsSearchOptions,
} from '../../../../restApi/newsService';
import {
  makeCapitalizedDropdownOptions,
  makeEditorsDropdownOptions,
  makeSourcesDropdownOptions,
  makeStatusesDropdownOptions,
} from '../../helpers/newsFilterHelper';
import s from './NewsFilters.module.css';

export interface IFilterProps {
  filterState: INewsSearchOptions;
  loading: boolean;
  onFilterSubmit: Function;
  options: Partial<INewsOptions>;
}

export class NewsFilters extends React.Component<
  IFilterProps,
  Partial<INewsSearchOptions>
> {
  public state = {} as INewsSearchOptions;

  constructor(props: IFilterProps) {
    super(props);

    this.state = props.filterState;
  }

  private onFilterChange = (filter: Partial<INewsSearchOptions>) => {
    this.setState((state) => ({
      ...state,
      ...filter,
    }));
  };

  private renderOptions(options: IDropdownOptions[] = []) {
    return options.map((option: IDropdownOptions) => (
      <Select.Option key={option.value} value={option.value}>
        {option.text}
      </Select.Option>
    ));
  }

  private onSearchInputReset = () => this.onFilterChange({ keywords: '' });

  private handleFilterSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();

    const { onFilterSubmit } = this.props;

    onFilterSubmit(this.state);
  };

  public render() {
    const { loading, options = {} } = this.props;
    const state = {
      ...this.state,
    };
    const { keywords, statuses, categories, types, services, editors } = state;

    return (
      <LeftCol>
        <Form className={css(s.filtersArea)} layout="vertical">
          <div className={css(s.leftColumnTitle)}>Filter alerts</div>

          <Form.Item className={css(s.filterFieldRow)}>
            <SearchInput
              autoComplete="off"
              className={css(s.filterField)}
              name="keywords"
              onChange={(e) =>
                this.onFilterChange({ keywords: e.target.value })
              }
              onReset={this.onSearchInputReset}
              placeholder="Type to search"
              size="large"
              value={keywords}
            />
          </Form.Item>

          <Form.Item className={css(s.filterFieldRow)} label="Status">
            <Select
              className={css(s.filterField)}
              mode="multiple"
              onChange={(value) => this.onFilterChange({ statuses: value })}
              optionFilterProp="children"
              placeholder="Select a status"
              showSearch
              size="large"
              value={statuses}
            >
              {this.renderOptions(
                makeStatusesDropdownOptions(options.statuses),
              )}
            </Select>
          </Form.Item>

          <Form.Item className={css(s.filterFieldRow)} label="Category">
            <Select
              className={css(s.filterField)}
              mode="multiple"
              onChange={(value) => this.onFilterChange({ categories: value })}
              optionFilterProp="children"
              placeholder="Select a category"
              showSearch
              size="large"
              value={categories}
            >
              {this.renderOptions(
                makeCapitalizedDropdownOptions(options.categories),
              )}
            </Select>
          </Form.Item>

          <Form.Item className={css(s.filterFieldRow)} label="Type">
            <Select
              className={css(s.filterField)}
              mode="multiple"
              optionFilterProp="children"
              onChange={(value) => this.onFilterChange({ types: value })}
              placeholder="Select a types"
              showSearch
              size="large"
              value={types}
            >
              {this.renderOptions(
                makeCapitalizedDropdownOptions(options.types),
              )}
            </Select>
          </Form.Item>

          <Form.Item className={css(s.filterFieldRow)} label="Source">
            <Select
              className={css(s.filterField)}
              mode="multiple"
              optionFilterProp="children"
              onChange={(value) => this.onFilterChange({ services: value })}
              placeholder="Select a source"
              showSearch
              size="large"
              value={services}
            >
              {this.renderOptions(
                makeSourcesDropdownOptions(options.all_services),
              )}
            </Select>
          </Form.Item>

          <Form.Item className={css(s.filterFieldRow)} label="Editors">
            <Select
              className={css(s.filterField)}
              mode="multiple"
              optionFilterProp="children"
              onChange={(value) => this.onFilterChange({ editors: value })}
              placeholder="Select an editor"
              showSearch
              size="large"
              value={editors}
            >
              {this.renderOptions(makeEditorsDropdownOptions(options.editors))}
            </Select>
          </Form.Item>

          <Button
            htmlType="button"
            loading={loading}
            onClick={this.handleFilterSubmit}
            size="large"
            type="primary"
          >
            Search
          </Button>
        </Form>
      </LeftCol>
    );
  }
}
