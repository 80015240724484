import { Spin } from 'antd';
import type { SpinProps } from 'antd/lib/spin';
import React from 'react';
import { VerticalAligner } from '../VerticalAligner';

export interface AlignedLoaderProps extends SpinProps {
  className?: string;
}

export const AlignedLoader = ({ className, ...rest }: AlignedLoaderProps) => (
  <VerticalAligner className={className}>
    <Spin {...rest} />
  </VerticalAligner>
);
