import cx from 'classnames';
import React from 'react';
import { ModerationStatus } from 'src/restApi/interfaces';
import { BadgeColors, StatusBadge } from '../../../../Badge';

export { ModerationStatus };

export interface IAlertStateBadgeProps {
  className?: string;
  preselectedReason?: boolean;
  reason?: string[] | null;
  status: ModerationStatus | undefined;
}

const states = {
  [ModerationStatus.Approved]: {
    color: BadgeColors.Blue,
    text: 'APPROVED',
  },
  [ModerationStatus.Assigned]: {
    color: BadgeColors.Purple,
    text: 'ASSIGNED',
  },
  [ModerationStatus.AutoApproved]: {
    color: BadgeColors.Blue,
    text: 'AUTO APPROVED',
    borderOnly: true,
  },
  [ModerationStatus.AutoDeclined]: {
    color: BadgeColors.Orange,
    text: 'AUTO DECLINED',
    borderOnly: true,
  },
  [ModerationStatus.Declined]: {
    color: BadgeColors.Orange,
    text: 'DECLINED',
  },
  [ModerationStatus.New]: {
    color: BadgeColors.Blue,
    text: 'NEW',
    borderOnly: true,
  },
} as const;

export const ModerationStatusBadge = ({
  className,
  ...props
}: IAlertStateBadgeProps) => {
  const badgeProps = getBadgeProps(props);

  return (
    <StatusBadge
      {...badgeProps}
      className={cx('ModerationStatusBadge', className)}
    />
  );
};

const getBadgeProps = ({
  status,
  reason,
  preselectedReason,
}: IAlertStateBadgeProps) => {
  const baseProps = states[status] ?? { color: BadgeColors.Grey, text: '' };

  if (
    reason &&
    reason.length > 0 &&
    (status === ModerationStatus.Approved ||
      status === ModerationStatus.Declined ||
      (status === ModerationStatus.New && preselectedReason) ||
      status === undefined)
  ) {
    const text = reason
      .slice(0, 2)
      .map((reasonCategories) => reasonCategories.trim())
      .filter((reasonCategory) => reasonCategory !== '')
      .join(', ');

    return {
      ...baseProps,
      text: text ?? baseProps.text,
    };
  }

  return baseProps;
};
