import moment from 'moment';
import pluralize from 'pluralize';
import {
  IMediaViewerSource,
  IUser,
  MediaViewerSourceType,
} from 'src/interfaces';
import { IAlert } from 'src/restApi/alertsService';
import { PermissionLevel, SortOrder } from 'src/restApi/interfaces';
import { config } from '../config';
import {
  EscalationLevelProperties,
  ModerationStatusDictionary,
} from '../constants';

export const isOdd = (num: number) => num % 2 !== 0;

export const sleep = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const validateEmail = (email: string = '') => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const location = () => {
  const { url, port } = config.backend;

  if (url) {
    return /^https?:\/\//.test(url) ? url : `http://${url}`;
  }

  return port
    ? document.location.origin.replace(/:[\d]+/, `:${port}`)
    : document.location.origin;
};

export const cropText = (text: string, max: number) => {
  if (text && text.length > max) {
    return `${text.substring(0, max)}...`;
  }
  return text || '';
};

export const browserType = {
  isChrome: 'chrome' in window && navigator.vendor === 'Google Inc.',
};

export const wssLocation = () => {
  const domain = location();
  const isSecure = /^https:\/\//.test(domain);
  const wsProtocol = isSecure ? 'wss:' : 'ws:';
  return domain.replace(/^https?:/, wsProtocol);
};

export const copyObject = (object: any): any =>
  JSON.parse(JSON.stringify(object));

export const checkObjectTypes = (object: any, types: object) => {
  const requiredFields = Object.keys(types);

  for (const field of requiredFields) {
    if (!object.hasOwnProperty(field)) {
      throw new Error(`Field ${field} missing`);
    } else if (typeof object[field] !== types[field]) {
      throw new Error(
        `Field ${field} cannot be a type ${typeof object[
          field
        ]}. It must be a ${types[field]}`,
      );
    }
  }
};

export const copyValueToClipboard = (data: string | number) => {
  const textArea = document.createElement('textarea');

  textArea.value = data.toString();
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const getMediaContentType = (
  src: string,
  imageUrl: string,
): IMediaViewerSource => {
  if (src && src.length) {
    return {
      src,
      type: MediaViewerSourceType.Video,
    };
  }

  if (imageUrl && imageUrl.length) {
    return {
      src: imageUrl,
      type: MediaViewerSourceType.Image,
    };
  }

  return {
    src: '',
    type: MediaViewerSourceType.Other,
  };
};

export const getPreviousDay = (): Date => {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  return date;
};

export const getMessageTypeString = (type: number | string) => {
  return (type
    ? ModerationStatusDictionary[type]
    : ModerationStatusDictionary['0']
  ).toLowerCase();
};

export const isArrayNotEmpty = (arr: any[]) => arr.length > 0;

export const getUserEscalationLevel = (user: IUser) => {
  if (user.scope.includes(PermissionLevel.L1)) {
    return EscalationLevelProperties[PermissionLevel.L1];
  }

  if (user.scope.includes(PermissionLevel.L2)) {
    return EscalationLevelProperties[PermissionLevel.L2];
  }

  return null;
};

export const sortHandler = (
  resolver = (x: any) => x,
  order: SortOrder = SortOrder.Asc,
  caseInsensitive: boolean = true,
) => (a: any, b: any): -1 | 0 | 1 => {
  const isAscOrder = order === SortOrder.Asc;
  let val1 = resolver(a);
  let val2 = resolver(b);

  if (caseInsensitive && typeof val1 === 'string' && typeof val2 === 'string') {
    val1 = val1.toLocaleLowerCase();
    val2 = val2.toLocaleLowerCase();
  }

  if (val1 > val2) {
    return isAscOrder ? 1 : -1;
  }

  if (val1 < val2) {
    return isAscOrder ? -1 : 1;
  }

  return 0;
};

export function getTextValueObj(el: string) {
  return {
    text: el,
    value: el.toLowerCase(),
  };
}

export function getDeletedPluralized(single: string, amount?: number) {
  return `${amount} ${pluralize(single, amount)}`;
}

export const replaceQuotesBySingleQuotes = (text: string) =>
  text && text.replace(/"/g, "'");

export const getParamsByContentTypes = (types: string[]) =>
  types.map((t) => t.concat('=true')).join('&&');

export const removeInlineSymbols = (text: string) =>
  text && text.replace(/\r\n|\r|\n/g, '');

export const getAlertCreatedAt = (alert: IAlert): moment.Moment =>
  moment.unix(alert.time_created);

export const getAlertTimeUntilMissedSLA = (
  alert: IAlert,
  maxTimeUntilMissedSLA: number,
): number => {
  const slaMissTime = getAlertCreatedAt(alert).add(
    maxTimeUntilMissedSLA,
    'seconds',
  );

  return slaMissTime.diff(moment());
};
