import css from 'classnames';
import React from 'react';
import styles from './style.module.css';

class AlertNoteInput extends React.Component<AlertNoteInputProps> {
  public handleOnChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value);
  };

  public render() {
    return (
      <div className={css(styles.container, this.props.classNames)}>
        <input
          className={css(styles.input)}
          type="text"
          onChange={this.handleOnChange}
          placeholder="Leave a free note here …"
          value={this.props.value}
        />
      </div>
    );
  }
}

interface AlertNoteInputProps {
  value: string;
  onChange?: Function;
  classNames?: string;
}

export { AlertNoteInput };
