import { Form } from 'antd';
import React from 'react';
import './FormItemSpacer.less';

export interface FormItemSpacerProps {
  children: React.ReactNode;
  className?: string;
}

export const FormItemSpacer = ({ children, ...props }: FormItemSpacerProps) => {
  return (
    <Form.Item {...props} className={`FormItemSpacer ${props.className || ''}`}>
      {children}
    </Form.Item>
  );
};
