import { Icon, Input } from 'antd';
import type { InputProps } from 'antd/lib/input/Input';
import css from 'classnames';
import React from 'react';
import { KEYS } from '../../constants';
import s from './style.module.css';

export interface ISearchInputProps extends InputProps {
  className?: string;
  onReset?: any;
}

export const SearchInput = ({
  className,
  onReset,
  ...inputProps
}: ISearchInputProps) => {
  return (
    <div className={css(s.searchInput, className)}>
      <Input
        {...inputProps}
        onKeyUp={(e) => e.keyCode === KEYS.ESC && onReset()}
        suffix={
          inputProps.value ? (
            <Icon
              className={css(s.resetIcon)}
              type="close-circle"
              onClick={onReset}
            />
          ) : (
            <span />
          )
        }
      />
    </div>
  );
};
