import * as commentHelper from 'src/helpers/commentsHelper';
import { ModerationStatus, PermissionLevel } from 'src/restApi/interfaces';
import { AlertService, NotificationService } from 'src/services';
import { rootStore } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import {
  NavigationTabs,
  tabsByStore,
  tabsStores,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';
import { IComment, IStoredAlert } from '../restApi/interfaces';

const { alertsListStore, authStore, navigationStore } = rootStore;

export const startListeners = () => {
  WsConnector.addListener(
    WebsocketMessageType.New,
    WebsocketEntityType.Alert,
    updateStoredAlertWithComments,
  );

  WsConnector.addListener(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Alert,
    updateStoredAlertWithComments,
  );

  WsConnector.addListener(
    WebsocketMessageType.Approved,
    WebsocketEntityType.Alert,
    updateStoredAlertWithComments,
  );

  WsConnector.addListener(
    WebsocketMessageType.Rejected,
    WebsocketEntityType.Alert,
    updateStoredAlertWithComments,
  );

  WsConnector.addListener(
    WebsocketMessageType.New,
    WebsocketEntityType.Comment,
    incomeNewComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Comment,
    incomeAssignedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Comment,
    incomeRSUComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.AutoApproved,
    WebsocketEntityType.Comment,
    incomeAutoApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Approved,
    WebsocketEntityType.Comment,
    incomeApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Rejected,
    WebsocketEntityType.Comment,
    incomeRejectedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.L1Escalated,
    WebsocketEntityType.Comment,
    incomeEscalatedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.L2Escalated,
    WebsocketEntityType.Comment,
    incomeEscalatedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Edited,
    WebsocketEntityType.Comment,
    incomeEditedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Deleted,
    WebsocketEntityType.Comment,
    incomeDeletedComment,
  );
};

export const fetchAlertsWithComments = () => {
  const escalationLevel = authStore.userEscalationLevel;
  return AlertService.getAlertsWithComments([escalationLevel]);
};

export const incomeRejectedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Declined);
};
export const incomeApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Approved);
};

export const incomeAutoApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.AutoApproved);
};

export const incomeRSUComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert = alertsListStore.isCommentOnAlert(
    tabsStores.ESCALATED,
  )(alertId, commentId);

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.ESCALATED)(
      data.alert_id,
    );

    if (!alert) return;

    alert.comments = updateCommentFields(data.comment_id, alert.comments, {
      is_l1: false,
      is_l2: false,
      on_rsu: true,
      status: ModerationStatus.Approved,
    });

    const isThreadModerated = checkIsThreadModerated(
      alert,
      authStore.userEscalationLevel,
    );

    isThreadModerated
      ? alertsListStore.removeAlertWithComments(tabsStores.ESCALATED)(
          alert.alert_id,
        )
      : alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(alert);
  }
};

export const incomeEscalatedComment = async (comment: Partial<IComment>) => {
  sendNotificationForReceivedEscalatedComment(comment);

  if (!checkIsCurrentTabActive()) return;

  const commentEscalationLevel =
    commentHelper.getCommentEscalationLevel(comment);

  if (!commentEscalationLevel) return;

  const isEscalationLevelMatched = authStore.userEscalationLevelMatched(
    commentEscalationLevel,
  );

  const { comment_id, alert_id } = comment;

  const isAlertWithComments =
    alert_id &&
    alertsListStore.isAlertWithComments(tabsStores.ESCALATED)(alert_id);

  const isCommentOnAlert =
    isAlertWithComments &&
    alertsListStore.isCommentOnAlert(tabsStores.ESCALATED)(
      alert_id,
      comment_id,
    );

  if (isCommentOnAlert) {
    await updateComment(comment_id);

    const alert = alertsListStore.getAlert(tabsStores.ESCALATED)(alert_id);

    if (checkIsThreadModerated(alert, authStore.userEscalationLevel)) {
      alertsListStore.removeAlertWithComments(tabsStores.ESCALATED)(
        alert.alert_id,
      );
    }
  } else if (isEscalationLevelMatched) {
    isAlertWithComments
      ? fetchComment(comment_id)
      : fetchAlertWithComments(alert_id);
  }
};

const updateComment = async (commentId: string) => {
  const comment = await AlertService.getAlertComment(commentId);

  alertsListStore.updateAlertComment(tabsStores.ESCALATED)(comment);
};

export const incomeNewComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isAlertWithComments =
    alertId &&
    alertsListStore.isAlertWithComments(tabsStores.ESCALATED)(alertId);

  if (isTabActive && isAlertWithComments) {
    fetchComment(commentId);
  }
};

export const incomeAssignedComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const userEmail = data.email || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert =
    alertId &&
    commentId &&
    alertsListStore.isCommentOnAlert(tabsStores.ESCALATED)(alertId, commentId);

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.ESCALATED)(
      data.alert_id,
    );

    alert.comments =
      alert.comments &&
      updateCommentFields(data.comment_id, alert.comments, {
        email: userEmail,
        status: ModerationStatus.Assigned,
      });

    alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(alert);
  }
};

const incomeUpdatedComment = (data: any, status: number) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert =
    alertId &&
    commentId &&
    alertsListStore.isCommentOnAlert(tabsStores.ESCALATED)(alertId, commentId);

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.ESCALATED)(
      data.alert_id,
    );

    alert.comments =
      alert &&
      alert.comments &&
      updateCommentFields(data.comment_id, alert.comments, {
        is_l1: false,
        is_l2: false,
        reason: data.reason || [],
        status,
      });

    const isThreadModerated = checkIsThreadModerated(
      alert,
      authStore.userEscalationLevel,
    );

    if (alert && !isThreadModerated) {
      alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(alert);
    } else {
      alertsListStore.removeAlertWithComments(tabsStores.ESCALATED)(
        alert.alert_id,
      );
    }
  }
};

export const incomeDeletedComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const isCommentOnAlert =
    alertId &&
    commentId &&
    alertsListStore.isCommentOnAlert(tabsStores.ESCALATED)(alertId, commentId);

  if (isTabActive && isCommentOnAlert) {
    const alert = alertsListStore.getAlertWithComments(tabsStores.ESCALATED)(
      data.alert_id,
    );

    alert.comments =
      alert &&
      alert.comments &&
      updateCommentFields(data.comment_id, alert.comments, {
        is_l1: false,
        is_l2: false,
        reason: data.reason || [],
      });

    const isThreadModerated = checkIsThreadModerated(
      alert,
      authStore.userEscalationLevel,
    );

    if (alert && !isThreadModerated) {
      alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(alert);
    } else {
      alertsListStore.removeAlertWithComments(tabsStores.ESCALATED)(
        alert.alert_id,
      );
    }
  }
};

export const incomeEditedComment = (data: any) => {
  if (checkIsCurrentTabActive()) {
    updateComment(data.comment_id);
  }
};

export const updateStoredAlertWithComments = async (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const alertId = data.alert_id || null;

  if (!isTabActive || !alertId) {
    return;
  }

  const isAlertInStore = alertsListStore.isAlertWithComments(
    tabsStores.ESCALATED,
  )(alertId);

  if (isAlertInStore) {
    const alert = await AlertService.getAlert(alertId);

    alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(alert);
  }
};

export const fetchComment = async (commentId: string) => {
  const comment = await AlertService.getAlertComment(commentId);

  alertsListStore.addCommentToAlertWithComments(tabsStores.ESCALATED)(
    comment.alert_id,
    comment,
  );
};

export const fetchAlertWithComments = async (alertId: number) => {
  const alert = await AlertService.getAlertWithComments(alertId);

  alertsListStore.addAlert(tabsStores.ESCALATED)(alert);
};

const sendNotificationForReceivedEscalatedComment = (
  data: Partial<IComment>,
) => {
  if (!data || checkIsCurrentTabActive()) {
    return;
  }

  const escalationLevel = data.is_l1
    ? PermissionLevel.L1
    : data.is_l2
    ? PermissionLevel.L1
    : undefined;

  if (escalationLevel && authStore.isUserEscalationLevel(escalationLevel)) {
    NotificationService.sendNotification(
      'New escalated comment',
      "Hey there! You've been notified!",
      tabsByStore[tabsStores.ESCALATED],
    );
  }
};

const checkIsCurrentTabActive = () =>
  navigationStore.currentTab === NavigationTabs.ESCALATED.path;

const updateCommentFields = (
  commentId: string,
  comments: IComment[] = [],
  fields: {},
): IComment[] => {
  return comments.map((comment) => {
    return comment.comment_id === commentId
      ? {
          ...comment,
          ...fields,
        }
      : comment;
  });
};

const checkIsThreadModerated = (
  alert: IStoredAlert,
  escalationLevel: string = undefined,
) => {
  const alertComments = alert.comments || [];
  const commentsByEscalationLevel = alertComments.filter(
    (c) => escalationLevel in c && c[escalationLevel],
  );
  const notModeratedComments = commentsByEscalationLevel.some(
    (c) =>
      !c.is_deleted_by_user &&
      (c.status === ModerationStatus.New ||
        c.status === ModerationStatus.Assigned),
  );

  return !notModeratedComments;
};

export const clearTabStore = () => {
  alertsListStore.setAlerts(tabsStores.ESCALATED)([]);
};
