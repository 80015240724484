import { action, observable } from 'mobx';
import { SearchTypeKeys } from '../constants';
import { InjectRootStore } from './injectRootStore';

type TypeOfValidator = 'number' | 'any';

export class AdminHeaderSearchStore extends InjectRootStore {
  @observable public isValueValid = true;
  @observable public value = '';
  @observable public inputFocus = false;
  @observable public type = SearchTypeKeys.ALERT;
  @observable public typeSelectorStateOpen = false;
  @observable public valideBy: TypeOfValidator = 'any';
  @action public changeValue = (value) => (this.value = value);

  public isStateNotEmpty = (state) => {
    return state !== null && state !== undefined;
  };

  @action public checkIfValueValid(state?: boolean) {
    if (this.isStateNotEmpty(state)) {
      this.isValueValid = state;
    } else {
      this.isValueValid = this.checkIsValueValidByType();
    }
  }

  public checkIsValueValidByType = () => {
    const value = this.value.trim();

    if (this.type === SearchTypeKeys.KEYWORD) {
      return Boolean(value);
    }

    return value !== '' && !isNaN(Number(value));
  };

  @action public toggleTypeSelector = (state?: boolean) => {
    this.typeSelectorStateOpen = this.isStateNotEmpty(state)
      ? state
      : !this.typeSelectorStateOpen;
  };

  @action public changeInputFocus = (state) => {
    this.inputFocus = this.isStateNotEmpty(state) ? state : !this.inputFocus;
  };

  @action public changeType = (type) => {
    this.type = type;
  };

  @action public resetValue = () => {
    this.value = '';
  };
}
