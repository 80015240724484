import * as commentsHelper from 'src/helpers/commentsHelper';
import { IStoredComment, ModerationStatus } from 'src/restApi/interfaces';
import { AlertService } from 'src/services';
import { rootStore, Tabs } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import {
  tabsStores,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';
import { IComment, IStoredAlert } from '../restApi/interfaces';
import { CommentService } from '../services/commentService';
import { max_assigned_threads } from '../helpers';
import { E_ALREADY_LOCKED, Mutex, tryAcquire } from 'async-mutex';
import { commentsActions, commentsTabActions } from './index';

const { alertsListStore } = rootStore;

let commentMapByAlertID = new Map<number, Map<string, boolean>>();
const lockCommentByIDByAlertID = new Mutex();
export const fetchAlertsLock = new Mutex();

export const refreshAssignedThreads = async () => {
  try {
    await tryAcquire(fetchAlertsLock).runExclusive(async () => {
      const alertsWithComments =
        await commentsTabActions.fetchAlertsWithComments();

      await commentsActions.SyncComments(
        tabsStores.COMMENTS,
        alertsWithComments,
      );
    });
  } catch (e) {
    if (e === E_ALREADY_LOCKED) {
      return;
    }
  }
};

export const SyncComments = async (
  tabStore: Tabs,
  assignedAlertsWithComments: IStoredAlert[],
) => {
  const releaseCommentByIDByAlertID = await lockCommentByIDByAlertID.acquire();
  // iterates over the commentByIDByAlertID map to delete old moderated threads

  commentMapByAlertID.forEach((_, alertID) => {
    const shouldDeleteAlertID = !assignedAlertsWithComments.some(
      (alertWithComment) => alertWithComment.alert_id === alertID,
    );

    if (shouldDeleteAlertID) {
      commentMapByAlertID.delete(alertID);
      alertsListStore.removeAlert(tabStore)(alertID);
    }
  });

  // iterates over the assigned threads with comment
  assignedAlertsWithComments.forEach((alertWithComment, _) => {
    let commentMap = commentMapByAlertID.get(alertWithComment.alert_id);

    // if the thread does not exist in the map, it will be added
    if (!commentMap) {
      commentMap = createCommentMap(alertWithComment);
      // add the alert to the UI
      if (
        alertsListStore.getCurrentAmountOfAlertsInTheStore(tabStore) <
        max_assigned_threads
      ) {
        commentMapByAlertID.set(alertWithComment.alert_id, commentMap);
        alertsListStore.addAlert(tabStore)(alertWithComment);
      }
    } else {
      handleExistingThread(tabStore, commentMap, alertWithComment);
    }
  });

  releaseCommentByIDByAlertID();
};

const handleExistingThread = (
  tabStore: Tabs,
  commentMap: Map<string, boolean>,
  alertWithComment: IStoredAlert,
) => {
  // In case the moderator moderates a complete thread but new comments appear,
  // the thread should be added back to the commentListStore
  const isNewANewCommentInADeletedThread = !alertsListStore.isAlertInStore(
    tabStore,
  )(alertWithComment.alert_id);

  if (isNewANewCommentInADeletedThread) {
    alertsListStore.addAlert(tabStore)({
      ...alertWithComment,
      ...{ comments: [] },
    });
  }
  // if the alert is in the UI
  // we should check if there are new comments
  alertWithComment.comments.forEach((comment) => {
    const commentStatus = commentMap.get(comment.comment_id);

    // if the comment is not in the UI
    // we should add it
    if (commentStatus !== true) {
      commentMap.set(comment.comment_id, false);
      commentMapByAlertID.set(alertWithComment.alert_id, commentMap);
      // add the comment in the UI

      alertsListStore.addCommentToAlertWithComments(tabStore)(
        alertWithComment.alert_id,
        comment,
      );
    }
  });
};

const createCommentMap = (alertWithComment: IStoredAlert) => {
  const commentMap = new Map<string, boolean>();

  alertWithComment.comments.forEach((comment) => {
    commentMap.set(comment.comment_id, false);
  });

  return commentMap;
};

export const markCommentAsModerated = async (
  alert_id: number,
  comment_id: string,
) => {
  const release = await lockCommentByIDByAlertID.acquire();
  let lockCommentByID = commentMapByAlertID.get(alert_id);
  if (lockCommentByID === undefined) {
    lockCommentByID = new Map<string, boolean>();
  }
  lockCommentByID.set(comment_id, true);
  commentMapByAlertID.set(alert_id, lockCommentByID);
  release();
  return;
};
export const markCommentAsUnmoderated = async (
  alert_id: number,
  comment_id: string,
) => {
  const release = await lockCommentByIDByAlertID.acquire();
  const lockCommentByID = commentMapByAlertID.get(alert_id);
  if (lockCommentByID === undefined) {
    release();
    return;
  }
  lockCommentByID.delete(comment_id);
  commentMapByAlertID.set(alert_id, lockCommentByID);
  release();
  return;
};

export const assignCommentsThread = async (
  alertId: number,
  tabStore: Tabs,
  flag?: string,
) => {
  WsConnector.ignoreMessage(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Comment,
    { alert_id: alertId },
  );
  const updateThreadData = {
    flag,
    alert_id: alertId,
    status: ModerationStatus.Assigned,
  };

  await AlertService.updateAlertComments(updateThreadData);

  const updatedAlert = await AlertService.getAlertWithComments(alertId);

  alertsListStore.updateAlertWithComments(tabStore)(updatedAlert);

  WsConnector.passMessage(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Comment,
  );
};

export const submitComment = async (
  alertId: number,
  tabStore: Tabs,
  escalationLevel: 'is_l1' | 'is_l2',
  commentId: string,
  status: number,
  reason?: string,
) => {
  const wsMessageType =
    status === ModerationStatus.Declined
      ? WebsocketMessageType.Rejected
      : status;
  WsConnector.ignoreMessage(wsMessageType, WebsocketEntityType.Comment, {
    comment_id: commentId,
  });
  const commentToUpdate = {
    status,
    comment_id: commentId,
    reason: reason ? [reason] : [],
  };

  await markCommentAsModerated(alertId, commentId);
  const updatedComment = {
    status,
    alert_id: alertId,
    comment_id: commentId,
    reason: reason ? [reason] : [],
  } as IComment;

  if (escalationLevel) {
    // @ts-ignore
    updatedComment[escalationLevel] = false;
  }
  try {
    const alertWithComments = alertsListStore.getAlertWithComments(tabStore)(
      updatedComment.alert_id,
    );
    if (alertWithComments) {
      // @ts-ignore
      alertWithComments.comments = alertWithComments.comments.map((comment) =>
        comment.comment_id === commentId
          ? { ...comment, ...updatedComment }
          : comment,
      );

      const isThreadModerated = checkIsAlertThreadModerated(
        alertWithComments,
        // @ts-ignore
        escalationLevel,
      );
      const releaseCommentByIDByAlertID =
        await lockCommentByIDByAlertID.acquire();
      if (isThreadModerated) {
        alertsListStore.removeAlert(tabStore)(alertWithComments.alert_id);
      } else {
        alertsListStore.updateAlertWithComments(tabStore)(alertWithComments);
      }
      releaseCommentByIDByAlertID();
    }
    await AlertService.updateAlertComments(commentToUpdate);
  } catch (e) {
    await markCommentAsUnmoderated(alertId, commentId);
  } finally {
    if (!escalationLevel) {
      refreshAssignedThreads();
    }
  }

  WsConnector.passMessage(wsMessageType, WebsocketEntityType.Comment);
};

export const escalateCommentToL1 = async (commentId: string) => {
  WsConnector.ignoreMessage(
    WebsocketMessageType.L1Escalated,
    WebsocketEntityType.Comment,
    { comment_id: commentId },
  );

  const comment = { comment_id: commentId } as IComment;

  await AlertService.escalateAlertComment(comment);

  const updatedComment = await AlertService.getAlertComment(commentId);

  updatedComment.status = ModerationStatus.New;

  const alertWithComments = alertsListStore.getAlertWithComments(
    tabsStores.COMMENTS,
  )(updatedComment.alert_id);

  if (alertWithComments) {
    // @ts-ignore
    alertWithComments.comments = alertWithComments.comments.map((comment) =>
      comment.comment_id === commentId
        ? { ...comment, ...updatedComment }
        : comment,
    );

    await markCommentAsModerated(alertWithComments.alert_id, commentId);
    const isThreadModerated = checkIsAlertThreadModerated(alertWithComments);
    const releaseCommentByIDByAlertID =
      await lockCommentByIDByAlertID.acquire();
    isThreadModerated
      ? alertsListStore.removeAlert(tabsStores.COMMENTS)(
          alertWithComments.alert_id,
        )
      : alertsListStore.updateAlertWithComments(tabsStores.COMMENTS)(
          alertWithComments,
        );
    releaseCommentByIDByAlertID();
  }
  WsConnector.passMessage(
    WebsocketMessageType.L1Escalated,
    WebsocketEntityType.Comment,
  );
  refreshAssignedThreads();
};

export const escalateCommentToL2 = async (commentId: string) => {
  WsConnector.ignoreMessage(
    WebsocketMessageType.L2Escalated,
    WebsocketEntityType.Comment,
    { comment_id: commentId },
  );

  const comment = { comment_id: commentId } as IComment;

  await AlertService.escalateAlertComment(comment);

  const updatedComment = await AlertService.getAlertComment(commentId);

  const alertWithComments =
    updatedComment &&
    alertsListStore.getAlertWithComments(tabsStores.ESCALATED)(
      updatedComment.alert_id,
    );

  if (alertWithComments && updatedComment) {
    updatedComment.status = ModerationStatus.New;
    // @ts-ignore
    alertWithComments.comments = alertWithComments.comments.map((comment) =>
      comment.comment_id === commentId
        ? { ...comment, ...updatedComment }
        : comment,
    );

    const isThreadModerated = checkIsAlertThreadModerated(
      alertWithComments,
      'is_l1',
    );

    isThreadModerated
      ? alertsListStore.removeAlert(tabsStores.ESCALATED)(
          alertWithComments.alert_id,
        )
      : alertsListStore.updateAlertWithComments(tabsStores.ESCALATED)(
          alertWithComments,
        );
  }

  WsConnector.passMessage(
    WebsocketMessageType.L2Escalated,
    WebsocketEntityType.Comment,
  );
};

export const sendCommentToRSU = async (
  tabStore: Tabs,
  commentId: string,
  escalationLevel?: 'is_l1' | 'is_l2',
) => {
  WsConnector.ignoreMessage(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Comment,
    { comment_id: commentId },
  );

  await AlertService.sendAlertCommentToRSU(commentId);

  const updatedComment = await AlertService.getAlertComment(commentId);

  const alertWithComments = alertsListStore.getAlertWithComments(tabStore)(
    updatedComment.alert_id,
  );

  if (alertWithComments) {
    // @ts-ignore
    alertWithComments.comments = alertWithComments.comments.map((comment) =>
      comment.comment_id === commentId
        ? { ...comment, ...updatedComment }
        : comment,
    );

    const isThreadModerated = checkIsAlertThreadModerated(
      alertWithComments,
      // @ts-ignore
      escalationLevel,
    );

    isThreadModerated
      ? alertsListStore.removeAlert(tabStore)(alertWithComments.alert_id)
      : alertsListStore.updateAlertWithComments(tabStore)(alertWithComments);
  }

  WsConnector.passMessage(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Comment,
  );
};

export const sendRSUCommentToThread = (alertId: number, text: string) => {
  return AlertService.sendRSUCommentToAlert(alertId, text);
};

export const sendCommentRSUToAlert = (alertId: number, text: string) => {
  return AlertService.sendRSUCommentToAlert(alertId, text);
};

export const markCommentAsResolved = async (
  alertId: number,
  commentId: string,
) => {
  if (!isNaN(alertId) && Number(alertId) > 0) {
    WsConnector.ignoreMessage(
      WebsocketMessageType.RemovedFromRsu,
      WebsocketEntityType.Comment,
      { comment_id: commentId },
    );

    await AlertService.markAlertCommentAsResolved(commentId);

    const tabStore = tabsStores.RSU;
    const alertWithComments = await AlertService.getAlertWithComments(alertId);

    // @ts-ignore
    const hasAlertCommentsOnRSU = alertWithComments.comments.some(
      (comment) => comment.on_rsu,
    );

    !hasAlertCommentsOnRSU
      ? alertsListStore.removeAlert(tabStore)(alertId)
      : alertsListStore.updateAlert(tabStore)(alertWithComments);

    WsConnector.passMessage(
      WebsocketMessageType.RemovedFromRsu,
      WebsocketEntityType.Comment,
    );
  }
};

const checkIsAlertThreadModerated = (
  alert: IStoredAlert,
  escalationLevel?: string,
) => {
  const alertComments = alert.comments || [];
  let hasThreadNotModeratedComments;

  if (!alertComments.length) {
    return null;
  }

  if (!escalationLevel) {
    const filteredComments = alertComments.filter(
      (comment) => !comment.is_l1 && !comment.is_l2,
    );
    hasThreadNotModeratedComments = filteredComments.some((comment) =>
      commentsHelper.isCommentNotModerated(comment, escalationLevel),
    );
  } else {
    hasThreadNotModeratedComments = alertComments.some((comment) =>
      commentsHelper.isCommentNotModerated(comment, escalationLevel),
    );
  }

  return !hasThreadNotModeratedComments;
};

export const fetchCommentHistory = async (
  alertId: number,
  commentId: string,
  tabStore: Tabs,
) => {
  const { getAlertComment, updateAlertComment, replaceAlertComment } =
    alertsListStore;

  const comment = getAlertComment(tabStore)(alertId, commentId);

  if (!comment) return;

  const { history: oldHistory, ...restComment } = comment;

  replaceAlertComment(tabStore)(restComment);

  const commentHistory = await CommentService.getCommentHistory(commentId);

  const updatedComment = { ...restComment, history: commentHistory };

  updateAlertComment(tabStore)(updatedComment);
};

export const removeCommentHistory = (
  alertId: number,
  commentId: string,
  tabStore: Tabs,
) => {
  const { getAlertComment, updateAlertComment } = alertsListStore;
  const comment = getAlertComment(tabStore)(alertId, commentId);

  if (comment) {
    const clonedComment: IStoredComment = { ...comment };
    delete clonedComment.history;
    updateAlertComment(tabStore)(clonedComment);
  }
};

export const toggleCommentHistory = (
  tabStore: Tabs,
  alertId: number,
  commentId: string,
  isShown: boolean,
) => {
  if (isShown) {
    return fetchCommentHistory(alertId, commentId, tabStore);
  }

  return removeCommentHistory(alertId, commentId, tabStore);
};
