import {
  IComment,
  IStoredAlert,
  ModerationStatus,
} from 'src/restApi/interfaces';
import { escalationLevelPropertiesArray } from '../constants';

export const isAlertInList = (list: IStoredAlert[], alertId: number) => {
  return list.findIndex((alert) => alert.alert_id === alertId) >= 0;
};

export const checkIsCommentEscalated = (data) => {
  return escalationLevelPropertiesArray.some((level) => data[level] === true);
};

export const getCommentEscalationLevel = (comment: Partial<IComment>) => {
  return escalationLevelPropertiesArray.find(
    (level) => comment[level] === true,
  );
};

export const checkIsCommentRSU = (data: any) =>
  data.is_rsu && data.is_rsu === true;

export const isCommentNotModerated = (
  comment: IComment,
  escalationLevel?: string,
  treatRsuAsNew?: boolean,
) => {
  // User could delete comment before it was moderated. Thus, need check explicitly.
  return (
    !comment.is_deleted_by_user &&
    (escalationLevel
      ? escalationLevel in comment &&
        comment[escalationLevel] &&
        [ModerationStatus.New, ModerationStatus.Assigned].includes(
          comment.status,
        )
      : [ModerationStatus.New, ModerationStatus.Assigned].includes(
          comment.status,
        ) ||
        (treatRsuAsNew && comment.on_rsu))
  );
};

export const isCommentAssigned = (
  comment: IComment,
  escalationLevel: string = undefined,
) => {
  if (comment.is_deleted_by_user) {
    return false;
  }
  if (escalationLevel) {
    return (
      comment.status === ModerationStatus.Assigned && comment[escalationLevel]
    );
  }
  return (
    comment.status === ModerationStatus.Assigned &&
    !comment.is_l1 &&
    !comment.is_l2
  );
};

export const isAllCommentsSubmited = (
  alertId: number,
  alertsWithComments: IStoredAlert[],
) => {
  const findedAlertWithComments = alertsWithComments.find(
    (alert) => alert.alert_id === alertId,
  );

  if (findedAlertWithComments) {
    return (
      findedAlertWithComments.comments.findIndex(
        (e) =>
          !e.is_deleted_by_user &&
          (e.status === ModerationStatus.New ||
            e.status === ModerationStatus.Assigned),
      ) < 0
    );
  }

  return false;
};

export const getCommentAlertsIds = (data: IComment[]): number[] => {
  return data.map((e) => e.alert_id);
};

export const getUnAssignedComments = (
  comments: IComment[],
  escalationLevel: string = undefined,
): IComment[] => {
  if (escalationLevel) {
    return comments.filter(
      (comment) => !comment.is_deleted_by_user && comment[escalationLevel],
    );
  }

  return comments.filter(
    (comment) =>
      comment.status === ModerationStatus.New &&
      !comment.is_l1 &&
      !comment.is_l2 &&
      !comment.is_deleted_by_user,
  );
};

const getTimeForComparison = (comment: IComment): number | null => {
  if (comment.time_received_for_moderation) {
    return comment.time_received_for_moderation;
  }

  if (!comment.flag) {
    return comment.time_created || null;
  }

  return null;
};

export const timeReceivedForModerationComparator = (
  comment: IComment,
  otherComment: IComment,
): number => {
  const time = getTimeForComparison(comment);
  const otherTime = getTimeForComparison(otherComment);

  if (!time && otherTime) {
    return 1;
  }

  if (time && !otherTime) {
    return -1;
  }

  if (!time && !otherTime) {
    return 0;
  }

  return time - otherTime;
};
