import css from 'classnames';
import React from 'react';
import { Checkbox } from 'src/components/checkbox';
import styles from './style.module.css';

export class AlertModerateOptions extends React.Component<
  AlertModerateOptionsProps
> {
  public render() {
    return (
      <div className={css(styles.container)}>
        <Checkbox
          className={css(styles.checkboxContainer)}
          title="Interesting for Ads"
          onChange={this.props.interestingOnChangeAction}
          checked={this.props.interestingState}
        />
      </div>
    );
  }
}

interface AlertModerateOptionsProps {
  interestingState?: boolean;
  interestingOnChangeAction?: Function;
}
