import { message } from 'antd';
import { action, computed, flow, observable, set, toJS } from 'mobx';
import pluralize from 'pluralize';
import {
  FETCH_NEW_NEWS_INTERVAL,
  NEWS_HISTORY_LIMIT,
  NEWS_INIT_OPTIONS,
  SHOW_NEW_NEWS_COUNT,
} from '../pages/NewsCurationPage/constants';
import {
  INews,
  INewsSearchOptions, INewsService,
  NewsId,
  NewsSortDirections,
} from '../restApi/newsService';
import { InjectRootStore } from './injectRootStore';
import { logError } from './utils/errorLogger';
import {RootStore} from "./rootStore";

export class NewsListStore extends InjectRootStore {
  private readonly _deletingNews = observable.box(false);
  private readonly _loadingNews = observable.box(false);
  private readonly _loadingNewNews = observable.box(false);
  private readonly _newNewsCount = observable.box(0);
  private readonly _news = observable.array([] as INews[]);
  private readonly _searchOptions = observable.box(NEWS_INIT_OPTIONS);
  private readonly _scrollTop = observable.box<number | null>(null);
  private readonly _hasNews = observable.box(true);
  public readonly newsService: INewsService;

  private _fetchTimer: number | null = null;
  private _indexTime: number | null = null;
  private _fetchNews: () => Promise<INews[]>
  private _fetchNewNews: () => Promise<INews[]>


  constructor(rootStore: RootStore, newsService: INewsService) {
    super(rootStore)
    this.newsService = newsService
  }
  // Getters

  @computed get deletingNews() {
    return this._deletingNews.get();
  }

  @computed get hasNews() {
    return this._hasNews.get();
  }

  @computed get initiated() {
    return Boolean(this._indexTime);
  }

  @computed get loadingNewNews() {
    return this._loadingNewNews.get();
  }

  @computed get loadingNews() {
    return this._loadingNews.get();
  }

  @computed get newNewsCount(): number {
    return this._newNewsCount.get();
  }

  @computed get news() {
    return toJS(this._news.slice(this.newNewsCount));
  }

  @computed get scrollTop(): number | null {
    return this._scrollTop.get();
  }

  @computed get searchOptions(): INewsSearchOptions {
    return toJS(this._searchOptions.get());
  }

  @action public getNewsIdByIndex = (index: number) => {
    return this.news[index] ? this.news[index].news_id : undefined;
  };

  // Actions

  @action public clear = () => {
    try {
      this.clearNews();
      this._setSearchOptions(NEWS_INIT_OPTIONS);
    } catch (error) {
      logError({
        description: error.message,
        exception: error,
        title: 'Failed to clear news store',
      });
    }
  };

  @action private clearNews = () => {
    this._indexTime = null;
    this._news.clear();
    this._fetchNews = undefined;
    this._fetchNewNews = undefined;
    this.abortScheduledLoadNewNews();
    this.setDeletingNews(false);
    this.setHasNews(true);
    this.setLoadingNewNews(false);
    this.setLoadingNews(false);
    this.setNewNewsCount(0);
    this.setScrollTop(null);
    this._setSearchOptions(NEWS_INIT_OPTIONS);
  };

  @action private abortScheduledLoadNewNews = () => {
    clearTimeout(this._fetchTimer!);
    this._fetchTimer = null;
  };

  @action private addNews = (news: INews[], isNew = false) => {
    if (isNew) {
      const reverseNews = news.slice().reverse();
      const count = reverseNews.length;
      this._news.unshift(...reverseNews);
      this.setNewNewsCount(this.newNewsCount + count);
    } else {
      this._news.push(...news);
    }
  };

  @action private scheduleLoadNewNews = (isDeferred = true) => {
    const delay = isDeferred ? FETCH_NEW_NEWS_INTERVAL : 0;
    this._fetchTimer = setTimeout(this.loadNewNewsFlow, delay);
  };

  @action private setDeletingNews = (isLoading: boolean) => {
    this._deletingNews.set(isLoading);
  };

  @action private setHasNews = (hasNews: boolean) => {
    this._hasNews.set(hasNews);
  };

  @action private setLoadingNewNews = (isLoading: boolean) => {
    this._loadingNewNews.set(isLoading);
  };

  @action private setLoadingNews = (isLoading: boolean) => {
    this._loadingNews.set(isLoading);
  };

  @action private setNewNewsCount = (count: number) => {
    this._newNewsCount.set(count);
  };

  @action private _setSearchOptions = (searchOptions: INewsSearchOptions) => {
    this._searchOptions.set(
      JSON.parse(JSON.stringify(searchOptions)) as INewsSearchOptions,
    );
  };

  @action public setSearchOptions = (searchOptions: INewsSearchOptions) => {
    this.clearNews();
    this._setSearchOptions(searchOptions);
  };

  @action public setScrollTop = (scrollTop: number | null) => {
    this._scrollTop.set(scrollTop);
  };

  @action public showNewNews = () => {
    if (!this.newNewsCount) {
      return;
    }

    let showNewNewsCount: number = this.newNewsCount - SHOW_NEW_NEWS_COUNT;
    if (showNewNewsCount < 0) {
      showNewNewsCount = 0;
    }

    this.setNewNewsCount(showNewNewsCount);
  };

  @action public updateItem = (updatedNews: INews) => {
    const newsId = updatedNews.news_id;

    return this._news.some((item: INews) => {
      if (newsId !== item.news_id) {
        return false;
      }

      set(item, updatedNews);
      return true;
    });
  };

  // Flows

  public deleteNewsFlow = flow<boolean, [NewsId[]]>(
    function* (this: NewsListStore, ids: NewsId[]) {
      let success;

      try {
        if (!ids || !ids.length) {
          logError({
            skipMessage: true,
            title: 'News alerts ids should not be empty',
          });
          return false;
        }

        this.setDeletingNews(true);

        const news = yield Promise.all(
          ids.map(
            (id: NewsId): Promise<INews> => this.newsService.deleteNewsAlert(id),
          ),
        );

        news.map(this.updateItem);

        success = true;

        message.success(
          `News ${pluralize('alert', ids.length)} has been deleted`,
        );
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to delete news alerts',
        });

        success = false;
      } finally {
        this.setDeletingNews(false);
      }

      return success;
    }.bind(this),
  );

  public loadNewsFlow = flow(
    function* (this: NewsListStore) {
      try {
        if (!this.hasNews) {
          logError({
            skipMessage: true,
            skipSentry: true,
            title: 'No more news',
          });
          return;
        }

        if (!this._indexTime) {
          this._indexTime = Math.ceil(Date.now() / 1000);
        }

        this.setLoadingNews(true);

        if (!this._fetchNews) {
          this._fetchNews = yield this.newsService.fetchNewsFactory({
            ...(this.searchOptions || {}),
            from_time: 0,
            limit: NEWS_HISTORY_LIMIT,
            order: NewsSortDirections.Desc,
            to_time: this._indexTime,
          });
        }

        const news = yield this._fetchNews()
        this.addNews(news);

        if (news.length < NEWS_HISTORY_LIMIT) {
          this.setHasNews(false);
        }
      } catch (error) {
      } finally {
        this.setLoadingNews(false);

        if (!this._fetchTimer) {
          this.scheduleLoadNewNews();
        }
      }
    }.bind(this),
  );

  public loadNewNewsFlow = flow(
    function* (this: NewsListStore) {
      let news: INews[];
      try {
        if (!this._indexTime) {
          logError({
            exception: new Error(
              'Failed to fetch new news. Index time is not set.',
            ),
            title: 'Failed to fetch new news. Index time is not set.',
          });
          return;
        }

        this.abortScheduledLoadNewNews();

        this.setLoadingNewNews(true);

        if (!this._fetchNewNews) {
          this._fetchNewNews = yield this.newsService.fetchNewsFactory({
            ...(this.searchOptions || {}),
            from_time: this._indexTime,
            limit: NEWS_HISTORY_LIMIT,
            order: NewsSortDirections.Asc,
            to_time: Math.ceil(Date.now() / 1000) + 360,
          });
        }

        news = yield this._fetchNewNews();
        this.addNews(news, true);
      } catch (error) {
        logError({
          description: error.message,
          exception: error,
          title: 'Failed to fetch new news',
        });
      } finally {
        // Schedule to load new items.
        // If amount of loaded news is equal to the requested limit, then make the request right away.
        if (this._indexTime) {
          this.scheduleLoadNewNews(!news || news.length < NEWS_HISTORY_LIMIT);
        }

        this.setLoadingNewNews(false);
      }
    }.bind(this),
  );
}
