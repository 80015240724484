import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'src/stores';
import s from './style.module.css';

export const AlertModerateModalHeader = observer(() => {
  const { uiStore } = useStores();

  return <div className={s.modalHeader}>{uiStore.modal.header.text}</div>;
});
