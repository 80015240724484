import React from 'react';

export type InputEvent = React.ChangeEvent<HTMLInputElement>;
export type KeyboardEvent = React.KeyboardEvent<HTMLInputElement>;

export enum AddressMapControllerMode {
  AddressChange,
  GeoPointChange,
  Init,
  MapGeoPointChange,
  Render,
}

export enum Field {
  Address,
  Latitude,
  Longitude,
  Map,
}

export interface ILocation {
    latitude: number;
    longitude: number;
}

export interface IMapFieldProps {
  location?: ILocation;
  onClick: ({ latitude, longitude }) => void;
}

export interface ILocationFieldProps {
  address?: string;
  clearId?: number;
  isControllable?: boolean;
  location?: ILocation;
  onBlur: (value) => void;
  onChangeSelection: (location: any) => void;
  onFocus: () => void;
  selectedItem?: string;
  useInitialData?: boolean;
}

export interface IGeopointFieldProps {
  onBlur: () => void;
  onChange: (event: InputEvent) => void;
  onFocus: () => void;
  onKeyUp: (event: KeyboardEvent) => void;
  value: string | undefined;
}

export interface IAddressMapControllerProps {
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface IAddressMapControllerChangePayload {
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface IChangeProps {
  address?: string;
  latitude?: number;
  latitudeTmp?: number;
  longitude?: number;
  longitudeTmp?: number;
  selectedItem?: string;
}

export enum ChangePropValuePermissions {
  Clear,
  Update,
}
