import React from 'react';
import { AlertType, IAlert } from 'src/restApi/alertsService';
import { MediaViewer } from '../../../../components/MediaViewer';
import { MediaViewerSourceType } from '../../../../interfaces';
import { SearchAlertMultimediaViewer } from 'src/pages/search/components/search-alert-multimedia-viewer';
import { Box } from 'src/components/Box';

interface IAlertMedia {
  alert: IAlert;
  className?: string;
  hideNoVisualContentText?: boolean;
}

export const AlertMedia = ({
  alert,
  className,
  hideNoVisualContentText,
}: IAlertMedia) => {
  const sourceType = getAlertSourceType(alert);
  const { alert_id, media_assets } = alert;
  const hasMultipleMedia = media_assets?.length > 0;

  return hasMultipleMedia ? (
    <Box width={'600px'} maxHeight={'525px'}>
      <SearchAlertMultimediaViewer
        multimediaAssets={media_assets}
        showHistory
        alertId={alert_id}
      />
    </Box>
  ) : (
    <MediaViewer
      className={className}
      hideNoVisualContentText={hideNoVisualContentText}
      posterURL={alert.image_url}
      source={sourceType}
    />
  );
};

const getAlertSourceType = (alert: IAlert) => {
  const alertType = getAlertType(alert);

  switch (alertType) {
    case AlertType.Video:
      return { src: alert.src, type: MediaViewerSourceType.Video };
    case AlertType.Image:
      return { src: alert.image_url, type: MediaViewerSourceType.Image };
    default:
      return { src: '', type: MediaViewerSourceType.Other };
  }
};

const getAlertType = ({ alert_type, src, image_url }: IAlert) => {
  if (alert_type !== AlertType.Ring) return alert_type;
  if (src) return AlertType.Video;
  if (image_url) return AlertType.Image;

  return AlertType.Text;
};
