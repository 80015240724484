import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useStores } from 'src/stores';
import styles from './Timer.module.css';

interface TimerProps {
  /* Show yellow marker if edit mode and blue if not */
  editMode?: boolean;
  /* Additional css class for the wrapper */
  wrapperClass?: string;
  /* Whether to show time */
  showTime?: boolean;
  /* This value will be substituted from current time to show time, which is left till timeout  */
  createdTime: number;
  /* Maximum timer value in seconds to count percentage of left time till timeout */
  maxDuration: number;
  /* Amount of dashes */
  markersCount: number;
  /* Call handler if timeout is reached */
  endTimeAction?: () => void;
  /* Additional css class for time block, if it is shown */
  wrapperTimeClass?: string;
  /* property to be identified in automation */
  testID?: string;
}

export const Timer = observer<FC<TimerProps>>(
  ({
    createdTime,
    endTimeAction,
    maxDuration,
    showTime,
    wrapperClass,
    wrapperTimeClass,
    markersCount,
    editMode,
    testID,
  }) => {
    const { uiStore } = useStores();
    const [isTriggered, setIsTiggered] = useState(false);

    const passedTime = moment.duration(moment().diff(createdTime)).asSeconds();
    const remainingTime = maxDuration - passedTime;
    const oneMarkerMaxDuration = maxDuration / markersCount;
    const markersArray = Array(markersCount).fill(0);

    const renderTimerStatus = (passedTime: number) => {
      const timeLeft = maxDuration - passedTime;
      const minutesLeft = Math.ceil(timeLeft / 60);
      const isTextRed = timeLeft < oneMarkerMaxDuration;
      const isLessOneMinute = timeLeft < 60;

      return (
        <div
          className={classNames(isTextRed && styles.redText)}
          data-test-id={testID}
        >
          {isLessOneMinute ? '~ 1' : minutesLeft} min
        </div>
      );
    };

    const getDividedDurations = (passedTime: number) => {
      return markersArray.map((marker, idx) => {
        const markerDuration =
          maxDuration - passedTime - oneMarkerMaxDuration * idx;
        const isFirstMarker = idx === 0;
        const isLastMarker = idx + 1 === markersCount;

        return (
          <div
            key={idx}
            className={classNames(styles.timeMarkerContainer, {
              [styles.rightContainer]: isLastMarker,
              [styles.leftContainer]: isFirstMarker,
              [styles.centerContainer]: !isLastMarker && !isFirstMarker,
              [styles.blueMarker]: !editMode && markerDuration > 0,
              [styles.yellowMarker]: editMode && markerDuration > 0,
              [styles.redMarker]:
                isFirstMarker && markerDuration < oneMarkerMaxDuration,
            })}
          />
        );
      });
    };

    useEffect(() => {
      if (remainingTime > 0 || isTriggered || !endTimeAction) return;

      setIsTiggered(true);
      endTimeAction();
    }, [remainingTime, endTimeAction, isTriggered]);

    return (
      <div className={classNames(styles.container, wrapperClass)}>
        {showTime && (
          <div className={classNames(wrapperTimeClass)} data-test-id={testID}>
            {renderTimerStatus(passedTime < 0 ? 0 : passedTime)}
          </div>
        )}
        <div className={classNames(styles.markersContainer, 'top-column')}>
          {uiStore.timerUniqIndex &&
            getDividedDurations(passedTime < 0 ? 0 : passedTime)}
        </div>
      </div>
    );
  },
);
