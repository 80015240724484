import React from 'react';

interface IHyperText {
  preventClickPropagation?: boolean;
}

export class HyperText extends React.Component<IHyperText> {
  public handleClick = (e) => {
    if (this.props.preventClickPropagation) {
      e.stopPropagation();
    }
  };

  public render() {
    let contents;

    if (typeof this.props.children === 'string') {
      const tokens = this.props.children.split(/\s/);

      contents = tokens.map((token, i) => {
        const hasSpace = i !== tokens.length - 1;
        const maybeSpace = hasSpace ? ' ' : '';

        if (token.match(/^https?:\//)) {
          return (
            <a
              key={i}
              target="_blank"
              rel="noopener noreferrer"
              href={token}
              onClick={this.handleClick}
            >
              {token}
              {maybeSpace}
            </a>
          );
        }
        return token + maybeSpace;
      });
    } else {
      return this.props.children;
    }
    return contents;
  }
}
