import { action, observable } from 'mobx';
import {
  IConfirmPopup,
  IModal,
  IPageScrollPos,
  IPopup,
  IPopupType,
} from 'src/interfaces';
import { InjectRootStore } from './injectRootStore';

export class UiStore extends InjectRootStore {
  public timer = undefined;

  @observable public timerUniqIndex = Date.now();
  @observable public isModalShow = false;
  @observable public notifiedTabs: string[] = [];
  @observable public popup: IPopup;
  @observable public pageScrollPos: IPageScrollPos = { x: 0, y: 0 };
  @observable public pageLoading = false;
  @observable public confirmPopup: IConfirmPopup = {
    cancelText: 'Cancel',
    confirmText: 'Remove',
    infoText: null,
    visible: false,
  };

  @action
  public setPageLoading = (value) => {
    this.pageLoading = value;
  };

  @action
  public clearConfirmPopup = () => {
    this.confirmPopup = {
      cancelText: 'Cancel',
      confirmText: 'Remove',
      infoText: null,
      visible: false,
    };
  };

  @action public showPopup = (text: string, type: IPopupType) => {
    this.popup = {
      text,
      type,
      visible: true,
    };
  };

  @action public hidePopup = () => {
    this.popup = {} as IPopup;
  };

  @action public notifyTab = (tabPath: string) => {
    if (this.notifiedTabs.indexOf(tabPath) < 0) {
      this.notifiedTabs.push(tabPath);
    }
  };

  @action public removeTabNotification = (tabPath: string) => {
    if (this.notifiedTabs.indexOf(tabPath) >= 0) {
      this.notifiedTabs = this.notifiedTabs.filter((e) => e !== tabPath);
    }
  };

  @action public startTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.timerUniqIndex = Date.now();
      }, 1000);
    }
  };

  @action public stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  };

  @action public savePageScrollPos = (position: IPageScrollPos) => {
    this.pageScrollPos = position;
  };

  @action public toggleModal = (flag?: boolean) => {
    this.isModalShow = flag !== undefined ? flag : !this.isModalShow;

    if (!this.isModalShow) {
      this.modal = {
        footer: {
          cancelAction: () => undefined,
          cancelText: 'Cancel',
          confirmAction: () => undefined,
          confirmText: 'Confirm',
          isButtonEnable: true,
          isInterestingCheckbox: true,
          submitButtonColor: 'green',
          hasBackground: true,
        },
        header: {
          text: '',
        },
        content: {
          element: null,
          showSelect: true,
        },
      } as IModal;
    }
  };

  @observable public modal = {
    footer: {
      cancelAction: () => undefined,
      cancelText: 'Cancel',
      confirmAction: () => undefined,
      confirmText: 'Confirm',
      isButtonEnable: true,
      isInterestingCheckbox: true,
      submitButtonColor: 'green',
      hasBackground: true,
    },
    header: {
      text: '',
    },
    content: {
      element: null,
      showSelect: true,
    },
  } as IModal;
}
