import css from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useToggle } from 'react-use';
import { PopupSimpleMenu } from 'src/components/popup-simple-menu';
import { UserIcon } from 'src/components/user-icon';
import { IPopupSimpleMenu } from 'src/interfaces';
import { useStores } from 'src/stores';
import { NavigationTabs } from '../../constants';
import { PermissionLevel } from '../../restApi/interfaces';
import styles from './UserBar.module.css';

export const UserBar = observer<FC>(() => {
  const { authStore } = useStores();
  const [isMenuOpened, toggleIsMenuOpened] = useToggle(false);

  const closeMenu = () => toggleIsMenuOpened(false);

  const {
    user: { name: userName, email: userEmail, picture: userPicture },
  } = authStore;

  const menuItems: IPopupSimpleMenu[] = [
    { title: authStore.user.name, visible: true },
    {
      title: 'Permissions',
      devided: true,
      visible: authStore.checkUserPermission(PermissionLevel.Root),
      linked: true,
      linkPath: NavigationTabs.PERMISSIONS.path,
    },
    {
      title: 'Logout',
      action: authStore.logout,
      visible: true,
    },
  ];

  return (
    <div className={css(styles.container, 'right-row')}>
      <div className={styles.decorateContainer} onClick={toggleIsMenuOpened}>
        <div className={css(styles.userNameField, 'right-row')}>
          <UserIcon userEmail={userEmail} src={userPicture} size={20} />
        </div>
        <div className={css(styles.userNameField, 'row')}>{userName}</div>
        <div className={css(styles.dropdownArrow, 'row')} />
      </div>
      {isMenuOpened && (
        <PopupSimpleMenu
          onClose={closeMenu}
          className={styles.menuContainer}
          menuItems={menuItems}
        />
      )}
    </div>
  );
});
