import {
  IComment,
  IStoredAlert,
  ModerationStatus,
} from 'src/restApi/interfaces';
import { ConfigStore } from 'src/stores';
import {
  ApprovalContentType,
  ApproveCategoryReason,
  escalationLevelPropertiesArray,
  TopLevelAlertApprovalCategory,
} from '../constants';

export const isAlertAutoModerated = (alert: IStoredAlert) => {
  return [
    ModerationStatus.AutoApproved,
    ModerationStatus.AutoDeclined,
  ].includes(alert.status);
};

export const isAlertAssigned = (alert: IStoredAlert) =>
  alert.status === ModerationStatus.Assigned;

export const isAlertThreadAssigned = (
  alert: IStoredAlert,
  escalationLevel: string = undefined,
) => {
  if (escalationLevel) {
    return (
      alert.comments &&
      alert.comments.some(
        (comment: IComment) =>
          comment &&
          comment.status === ModerationStatus.Assigned &&
          comment[escalationLevel],
      )
    );
  }

  return (
    alert.comments &&
    alert.comments.some(
      (comment: IComment) =>
        comment &&
        comment.status === ModerationStatus.Assigned &&
        !comment.is_l1 &&
        !comment.is_l2,
    )
  );
};

export const isCurrentUserAssigned = (
  alert: IStoredAlert,
  currentUserEmail: string,
  escalationLevel: string = undefined,
) => {
  if (
    alert.status === ModerationStatus.Assigned &&
    alert.email === currentUserEmail
  ) {
    return true;
  }

  if (escalationLevel) {
    return (
      alert.comments &&
      alert.comments.some(
        (comment: IComment) =>
          comment &&
          comment.status &&
          comment.status === ModerationStatus.Assigned &&
          comment.email === currentUserEmail &&
          comment[escalationLevel],
      )
    );
  }

  return (
    alert.comments &&
    alert.comments.some(
      (comment: IComment) =>
        comment &&
        comment.status &&
        comment.status === ModerationStatus.Assigned &&
        comment.email === currentUserEmail &&
        !comment.is_l1 &&
        !comment.is_l2,
    )
  );
};

export const getAssignedUserEmail = (
  alert: IStoredAlert,
  currentUserEmail: string,
  escalationLevel: string = undefined,
) => {
  if (
    alert.status === ModerationStatus.Assigned &&
    alert.email === currentUserEmail
  ) {
    return alert.email;
  }

  if (escalationLevel) {
    const assignedComment =
      alert.comments &&
      alert.comments.find(
        (comment: IComment) =>
          comment &&
          comment.status === ModerationStatus.Assigned &&
          comment[escalationLevel],
      );

    return assignedComment && assignedComment.email
      ? assignedComment.email
      : undefined;
  }

  const assignedComment =
    alert.comments &&
    alert.comments.find(
      (comment: IComment) =>
        comment &&
        comment.status === ModerationStatus.Assigned &&
        !comment.is_l1 &&
        !comment.is_l2,
    );

  return assignedComment && assignedComment.email
    ? assignedComment.email
    : undefined;
};

export const checkIsAlertEscalated = (data) => {
  return (
    escalationLevelPropertiesArray.findIndex(
      (level) => level in data && data[level] === true,
    ) >= 0
  );
};

export const getAlertEscalationLevel = (data) => {
  return escalationLevelPropertiesArray.find(
    (level) => level in data && data[level] === true,
  );
};

export const getAlertsIds = (data: IStoredAlert[]): number[] => {
  return data.filter((e) => Boolean(e)).map((e) => e && e.alert_id);
};

export const isAlertModerateOptionsChanged = (
  newAlert: IStoredAlert,
  prevAlert: IStoredAlert,
) => {
  return (
    newAlert.status !== prevAlert.status ||
    (newAlert.reason && newAlert.reason.join()) !==
      (prevAlert.reason && prevAlert.reason.join()) ||
    newAlert.interesting !== prevAlert.interesting ||
    newAlert.free_note !== prevAlert.free_note
  );
};

export const reasonsToText = (reasonsList: string[]) =>
  reasonsList &&
  reasonsList.length &&
  reasonsList.join(', ').split('_').join(' ');

export const getReasonGroupIndex = (
  configStore: ConfigStore,
  status: number,
  categoryIds: number[],
): number => {
  const [
    hasSubCategories,
    categories,
  ] = configStore.getApprovalCategoriesWithType(
    ApprovalContentType.Alert,
    status,
  );

  return hasSubCategories
    ? categories.findIndex((e) =>
        e.subcategory.find((ee) => categoryIds.includes(ee.id)),
      )
    : categories.findIndex((e) => categoryIds.includes(e.id));
};

export const getTopLevelApprovalCategory = (
  configStore: ConfigStore,
  alert: IStoredAlert,
): TopLevelAlertApprovalCategory => {
  return getReasonGroupIndex(
    configStore,
    ApproveCategoryReason.Approve,
    alert.category_ids,
  );
};

export const hasAlertReasons = (alert: IStoredAlert) =>
  Boolean(alert.reason && alert.reason.length);

export const hasAlertCaseInfo = ({
  is_crime,
  agency_name,
  case_number,
}: IStoredAlert) => {
  return is_crime && Boolean(agency_name) && Boolean(case_number);
};

export const isAlertEscalated = (alert: IStoredAlert) =>
  alert.is_l1 || alert.is_l2;

export const isAlertModerated = (alert: IStoredAlert) =>
  (alert.uiStates && alert.uiStates.moderated) || alert.is_deferred;
