import css from 'classnames';
import { uniq } from 'lodash';
import { transaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as commentHelper from 'src/helpers/commentsHelper';
import { URLService } from 'src/services/urlService';
import { Stores } from 'src/stores';
import { AdminContentMessageType, SearchTypeKeys } from '../../../../constants';
import { AdminService } from '../../services/adminService';
import { InputContainer } from '../input-container';
import { Selector } from '../selector';
import s from './style.module.css';

@inject('adminStore', 'adminHeaderSearchStore')
@observer
export class AdminSelectorSearch extends React.Component<Partial<Stores>> {
  public state = {
    focus: false,
  };

  public fetchCommentById = async () => {
    const id = this.getInputValue();
    const comment = await AdminService.getCommentByID(id);
    const alert =
      comment && (await AdminService.getMissedAlert(comment.alert_id));
    this.props.adminStore.commentId = id;

    transaction(() => {
      this.props.adminStore.alertId = null;
      this.props.adminStore.setComments(comment ? [comment] : []);
      this.props.adminStore.setAlerts(alert ? [alert] : []);
    });

    URLService.deleteAllSearchParams();
    URLService.addSearchParams({ commentId: id });
  };

  public fetchAlertById = async () => {
    const id = this.getInputValue();
    const alert = await AdminService.fetchAlertAndComments(id);
    this.props.adminStore.alertId = id;
    const alertComments = alert ? alert.comments : [];

    if (alert) {
      delete alert.comments;
    }

    transaction(() => {
      this.props.adminStore.commentId = null;
      this.props.adminStore.setAlerts(alert ? [alert] : []);
      this.props.adminStore.setComments(alertComments);
    });

    URLService.deleteAllSearchParams();
    URLService.addSearchParams({ alertId: id });
  };

  public fetchByKeywords = async () => {
    const words = this.getInputValue();
    const commentIds = await AdminService.fetchCommentsIdsByKeywords(words);
    const comments = await AdminService.getCommentsByIds(commentIds);
    const commentsIds = commentHelper.getCommentAlertsIds(
      comments.filter((e) => e),
    );
    let alertIds = await AdminService.fetchAlertsIdsByKeywords(words);
    alertIds = uniq([...alertIds, ...commentsIds]);
    const alerts = await AdminService.getAlertsByIds(alertIds);

    transaction(() => {
      this.props.adminStore.commentId = null;
      this.props.adminStore.words = words;
      this.props.adminStore.alertId = null;
      this.props.adminStore.setComments(comments ? comments : []);
      this.props.adminStore.setAlerts(alerts ? alerts : []);
    });

    URLService.deleteAllSearchParams();
    URLService.addSearchParams({ word: words });
  };

  public getAlertOrCommentById = async () => {
    const { adminHeaderSearchStore } = this.props;

    try {
      if (adminHeaderSearchStore.type === SearchTypeKeys.COMMENT) {
        await this.fetchCommentById();
        this.props.adminStore.setContentMessageType(
          AdminContentMessageType.NotFoundByCommentId,
        );
      } else if (adminHeaderSearchStore.type === SearchTypeKeys.KEYWORD) {
        await this.fetchByKeywords();
        this.props.adminStore.setContentMessageType(
          AdminContentMessageType.NotFoundByKeyword,
        );
      } else {
        await this.fetchAlertById();
        this.props.adminStore.setContentMessageType(
          AdminContentMessageType.NotFoundByAlertId,
        );
      }
    } catch (error) {
      console.error('AdminPage :: getAlertOrCommentById', error);
    }
  };

  public getInputValue = () => this.props.adminHeaderSearchStore.value.trim();

  public isValueValid = () => {
    return (
      this.getInputValue() !== '' &&
      this.props.adminHeaderSearchStore.isValueValid
    );
  };

  public onClickSearch = async () => {
    const { adminStore, adminHeaderSearchStore } = this.props;

    adminHeaderSearchStore.checkIfValueValid();

    if (!this.isValueValid()) return;

    adminStore.isLoaderVisible = true;

    URLService.deleteSearchParam('endDate');
    URLService.deleteSearchParam('startDate');
    URLService.deleteSearchParam('flagged');

    transaction(async () => {
      adminStore.resetStore();
      await this.getAlertOrCommentById();
      adminStore.selectedFilters.flagged = false;
      adminStore.isLoaderVisible = false;
    });
  };

  public render() {
    return (
      <div className={s.container}>
        <Selector />
        <InputContainer onEnter={this.onClickSearch} />
        <div
          className={css(s.button, { [s.disable]: !this.isValueValid() })}
          onClick={this.onClickSearch}
        >
          Search
        </div>
      </div>
    );
  }
}
