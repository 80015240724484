import React from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../../componentsNew/Icon';
import { copyValueToClipboard } from '../../helpers';

export { IconType };

export interface IAlertPropertyRow {
  /** Value to show */
  children: React.ReactNode;
  /** Icon */
  iconType?: IconType;
  /** If set, then on click on the line this value will be copied to clipboard */
  copyValue?: number | string;
  /** Additional class name */
  className?: string;
  /** Additional class for value */
  valueClassName?: string;

  testID?: string;
}

/** Row with icon to render Alert property */
export const AlertPropertyRow = ({
  children,
  iconType = IconType.Location,
  copyValue,
  className,
  valueClassName,
  testID,
}: IAlertPropertyRow) => (
  <Wrapper className={className}>
    <span className="imageBlock">
      <Icon iconType={iconType} />
      <span className="heightExpander">|</span>
    </span>
    <span className={valueClassName} data-test-id={testID}>
      {children}
    </span>
    &nbsp;
    {copyValue && (
      <Icon
        iconType={IconType.Copy}
        onClick={() => copyValueToClipboard(copyValue)}
      />
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  word-break: break-word;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey65};
  padding-bottom: 8px;

  & > :not(:first-child) {
    padding-left: 12px;
  }

  .imageBlock {
    display: flex;
  }

  .heightExpander {
    overflow: hidden;
    width: 0;
  }
`;
