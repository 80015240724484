import cx from 'classnames';
import React from 'react';
import { BadgeColors, StatusBadge } from '../Badge';

export enum CaseInfoStatusBadgeStatus {
  Resolved = 'resolved',
}

export interface ICaseInfoStatusBadgeProps {
  className?: string;

  status: CaseInfoStatusBadgeStatus;
}

const states = new Map();
states.set(CaseInfoStatusBadgeStatus.Resolved, {
  color: BadgeColors.Green,
  text: 'RESOLVED',
});

export const CaseInfoStatusBadge: React.SFC<ICaseInfoStatusBadgeProps> = ({
  className,
  status,
}) => {
  const badgeProps = { ...states.get(status) };

  return (
    <StatusBadge
      {...badgeProps}
      className={cx('CaseInfoStatusBadge', className)}
    />
  );
};
