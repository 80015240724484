import css from 'classnames';
import React from 'react';
import s from './style.module.css';

export class AlertModerateCheckbox extends React.Component<
  IAlertModerateCheckboxProps
> {
  public render() {
    const { type, handler, title, hasSubcategory, id, category } = this.props;
    const [, ctg, ...subcategories] = this.props.reasons;
    const isChecked =
      ctg === category &&
      (!hasSubcategory || subcategories.some((e) => e === id));

    return (
      <div className={css({ [s.labelGrp]: type === 'checkbox' })}>
        <label className={css(s.label)}>
          <input checked={isChecked} type={type} onChange={handler} />
          <div
            className={css(
              { [s.fakeCheckbox]: type === 'checkbox' },
              { [s.fakeRadio]: type === 'radio' },
            )}
          />
          <span>{title}</span>
        </label>
      </div>
    );
  }
}

export interface IAlertModerateCheckboxProps {
  reasons?: any;
  handler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  title: string;
  hasSubcategory?: boolean;
  category: number;
  id: number;
}
