import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';

export const NoContentView = (props: BoxProps) => (
  <Box textAlign="center" {...props} data-test-id="noComments">
    <H1>No content for moderation</H1>
    <H3>We will notify you as soon as it appears</H3>
  </Box>
);

const H1 = styled.h1`
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #354052;
  font-size: 17px;
  margin: 0 0 12px;
`;

const H3 = styled.h3`
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;
  margin: 0;
`;
