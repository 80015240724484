import css from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { AlertModerateOptions } from 'src/components/alert-moderate-interesting';
import { Button } from 'src/components/button';
import { useStores } from 'src/stores';
import s from './style.module.css';

interface AlertModerateToolProps {
  alertsListStore: any;
  isButtonEnable?: boolean;
}

export const AlertModerateModalFooter = observer<FC<AlertModerateToolProps>>(
  ({ alertsListStore }) => {
    const { uiStore } = useStores();

    const cancelModalAction = () => {
      uiStore.toggleModal(false);
    };

    const renderDefaultButtons = () => {
      const {
        footer: {
          cancelAction,
          cancelText,
          confirmAction,
          confirmText,
          isButtonEnable,
          submitButtonColor,
        },
      } = uiStore.modal;

      return (
        <div className={s.buttonContainer}>
          <div
            className={css(s.button, s.cancel, 'left-row')}
            onClick={() =>
              cancelAction ? cancelAction() : cancelModalAction()
            }
          >
            {cancelText}
          </div>
          <div
            className={css(s[`${submitButtonColor}SubmitButton`], {
              [s.disabled]: !isButtonEnable && !isButtonEnable,
            })}
            onClick={() =>
              isButtonEnable || isButtonEnable ? confirmAction() : null
            }
          >
            {confirmText}
          </div>
        </div>
      );
    };

    const renderSubmitButtons = () => {
      const {
        footer: { cancelAction, cancelText, confirmAction, confirmText },
      } = uiStore.modal;

      return (
        <div className={s.buttonContainer}>
          <Button
            color="orange"
            height={49}
            action={() => (cancelAction ? cancelAction() : cancelModalAction())}
          >
            <div className={s.buttonTitle}>{cancelText}</div>
          </Button>
          <Button
            color="blue"
            height={49}
            additionalClassName="right-row"
            action={() => confirmAction()}
          >
            <div className={s.buttonTitle}>{confirmText}</div>
          </Button>
        </div>
      );
    };

    const {
      footer: { isInterestingCheckbox, submitButtons, hasBackground },
    } = uiStore.modal;

    return (
      <div className={css(s.footer, hasBackground && s.footerBackground)}>
        {isInterestingCheckbox && (
          <AlertModerateOptions
            interestingOnChangeAction={alertsListStore.likeVideo}
            interestingState={alertsListStore.moderateOptions.interesting}
          />
        )}
        {submitButtons ? renderSubmitButtons() : renderDefaultButtons()}
      </div>
    );
  },
);
