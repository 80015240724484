import * as commentsHelper from 'src/helpers/commentsHelper';
import { ModerationStatus } from 'src/restApi/interfaces';
import { AlertService, NotificationService } from 'src/services';
import { rootStore } from 'src/stores';
import { WsConnector } from 'src/wsConnector';
import {
  NavigationTabs,
  tabsByStore,
  tabsStores,
  WebsocketEntityType,
  WebsocketMessageType,
} from '../constants';
import { CommentService } from '../restApi/commentService';
import { IComment, IStoredAlert } from '../restApi/interfaces';
import { auto_assign_enabled } from '../helpers';

const { navigationStore, alertsListStore } = rootStore;

export const startListeners = () => {
  if (auto_assign_enabled) {
    // new comments will not be received by WS when the auto-assign feature will be already deployed.
    return;
  }
  WsConnector.addListener(
    WebsocketMessageType.New,
    WebsocketEntityType.Comment,
    incomeNewComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.AutoAssigned,
    WebsocketEntityType.Comment,
    incomeNewComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Assigned,
    WebsocketEntityType.Comment,
    incomeAssignedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.SendToRsu,
    WebsocketEntityType.Comment,
    incomeRSUComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.RemovedFromRsu,
    WebsocketEntityType.Comment,
    incomeRemovedFromRsuComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.L1Escalated,
    WebsocketEntityType.Comment,
    incomeEscalatedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.L2Escalated,
    WebsocketEntityType.Comment,
    incomeEscalatedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.AutoApproved,
    WebsocketEntityType.Comment,
    incomeAutoApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Approved,
    WebsocketEntityType.Comment,
    incomeApprovedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Rejected,
    WebsocketEntityType.Comment,
    incomeRejectedComment,
  );

  WsConnector.addListener(
    WebsocketMessageType.Edited,
    WebsocketEntityType.Comment,
    incomeEditedComment,
  );
};

export const fetchAlertsWithComments = () => {
  if (auto_assign_enabled) {
    return CommentService.assignThreads();
  }
  return AlertService.getAlertsWithComments();
};

export const incomeAutoApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.AutoApproved);
};

export const incomeApprovedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Approved);
};

export const incomeRemovedFromRsuComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Approved);
};

export const incomeRejectedComment = (data: any) => {
  incomeUpdatedComment(data, ModerationStatus.Declined);
};

export const incomeNewComment = (data: any) => {
  const commentId = data.comment_id || null;
  const alertId = data.alert_id || null;
  const { checkIsCommentRSU, checkIsCommentEscalated } = commentsHelper;
  const isTabActive = checkIsCurrentTabActive();

  const isSimpleComment =
    !checkIsCommentRSU(data) && !checkIsCommentEscalated(data);

  const isCommentOnAlert = checkIsCommentInAlert(
    data.comment_id,
    data.alert_id,
  );

  const isAlertInStore = checkIsAlertInStore(data.alert_id);

  if (isTabActive && isSimpleComment && isAlertInStore && !isCommentOnAlert) {
    commentId && fetchComment(commentId);
  } else if (
    isTabActive &&
    isSimpleComment &&
    isAlertInStore &&
    isCommentOnAlert
  ) {
    commentId && updateComment(commentId);
  } else if (isTabActive && isSimpleComment && !isAlertInStore) {
    alertId && fetchAlertWithComments(alertId);
  }

  if (isSimpleComment) {
    sendNotificationForTab();
  }
};

export const incomeAssignedComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const isCommentOnAlert = checkIsCommentInAlert(
    data.comment_id,
    data.alert_id,
  );

  if (isTabActive && isCommentOnAlert) {
    updateComment(data.comment_id);
  }
};

export const incomeEditedComment = (data: any) => {
  if (checkIsCurrentTabActive()) {
    updateComment(data.comment_id);
  }
};

export const incomeUpdatedComment = (data: any, status: number) => {
  const isTabActive = checkIsCurrentTabActive();
  const isCommentOnAlert = checkIsCommentInAlert(
    data.comment_id,
    data.alert_id,
  );

  if (!isTabActive || !isCommentOnAlert) return;

  const alert = alertsListStore.getAlert(tabsStores.COMMENTS)(data.alert_id);

  if (!alert) return;

  alert.comments = updateCommentFields(data.comment_id, alert.comments!, {
    status,
    on_rsu: false,
    reason: data.reason || [],
  });

  const isThreadModerated = checkIsThreadModerated(alert);

  if (isThreadModerated) {
    alertsListStore.removeAlert(tabsStores.COMMENTS)(alert.alert_id);
  } else {
    alertsListStore.updateAlert(tabsStores.COMMENTS)(alert);
  }
};

export const incomeRSUComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const isCommentOnAlert = checkIsCommentInAlert(
    data.comment_id,
    data.alert_id,
  );

  if (!isTabActive || !isCommentOnAlert) return;

  const alert = alertsListStore.getAlert(tabsStores.COMMENTS)(data.alert_id);

  if (!alert) return;

  alert.comments = updateCommentFields(data.comment_id, alert.comments!, {
    on_rsu: true,
    status: ModerationStatus.Approved,
  });

  const isThreadModerated = checkIsThreadModerated(alert);

  isThreadModerated
    ? alertsListStore.removeAlert(tabsStores.COMMENTS)(alert.alert_id)
    : alertsListStore.updateAlert(tabsStores.COMMENTS)(alert);
};

export const incomeEscalatedComment = (data: any) => {
  const isTabActive = checkIsCurrentTabActive();
  const isCommentOnAlert = checkIsCommentInAlert(
    data.comment_id,
    data.alert_id,
  );

  if (!isTabActive || !isCommentOnAlert) return;

  const isEscalationLevel2 = Boolean(data.is_l2);
  const newCommentData = {
    is_l1: !isEscalationLevel2,
    is_l2: isEscalationLevel2,
    status: ModerationStatus.New,
  };

  const alert = alertsListStore.getAlert(tabsStores.COMMENTS)(data.alert_id);

  if (!alert) return;

  alert.comments = updateCommentFields(
    data.comment_id,
    alert.comments!,
    newCommentData,
  );

  const isThreadModerated = checkIsThreadModerated(alert);

  isThreadModerated
    ? alertsListStore.removeAlert(tabsStores.COMMENTS)(alert.alert_id)
    : alertsListStore.updateAlert(tabsStores.COMMENTS)(alert);
};

const updateCommentFields = (
  commentId: string,
  comments: IComment[],
  fields: {},
): IComment[] => {
  return comments.map((comment) => {
    return comment.comment_id === commentId
      ? {
          ...comment,
          ...fields,
        }
      : comment;
  });
};

const fetchComment = async (commentId: string) => {
  const comment = await CommentService.getAlertComment(commentId);

  comment &&
    alertsListStore.addCommentToAlert(tabsStores.COMMENTS)(
      comment.alert_id,
      comment,
    );
};

const updateComment = async (commentId: string) => {
  const comment = await CommentService.getAlertComment(commentId);

  if (!comment) return;

  alertsListStore.updateAlertComment(tabsStores.COMMENTS)(comment);
};

const fetchAlertWithComments = async (alertId: number) => {
  const alertWithComments = await AlertService.getAlertWithComments(alertId);

  alertWithComments &&
    alertsListStore.addAlert(tabsStores.COMMENTS)(alertWithComments);
};

const checkIsAlertInStore = (alertId: number) => {
  return alertsListStore.isAlertInStore(tabsStores.COMMENTS)(alertId);
};

const checkIsCommentInAlert = (commentId: string, alertId: number) => {
  return alertsListStore.isCommentOnAlert(tabsStores.COMMENTS)(
    alertId,
    commentId,
  );
};

const checkIsCurrentTabActive = () =>
  navigationStore.currentTab === NavigationTabs.COMMENTS.path;

const sendNotificationForTab = () => {
  NotificationService.sendNotification(
    'New comment',
    "Hey there! You've been notified!",
    tabsByStore[tabsStores.COMMENTS],
  );
};

const checkIsThreadModerated = (alert: IStoredAlert) => {
  if (!alert.comments?.length) return true;

  return alert.comments
    .filter((comment) => !comment.is_l1 && !comment.is_l2)
    .some((comment) => commentsHelper.isCommentNotModerated(comment));
};

export const clearTabStore = () => {
  alertsListStore.setAlerts(tabsStores.COMMENTS)([]);
};
