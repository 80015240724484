import React from 'react';
import { INewsHistory } from 'src/restApi/newsService';
import { CollapsibleList } from 'src/componentsNew/CollapsibleList';
import { History } from 'src/componentsNew/History';
import { NewsHistoryItem } from 'src/componentsNew/NewsHistoryItem';
import {inject, observer} from "mobx-react";
import {Stores} from "../../../../stores";

enum LoadingState {
  NotLoaded,
  Loading,
  Loaded,
  Failed,
}

export interface INewsAlertHistoryProps extends Partial<Stores> {
  newsAlertId: string;
}

export interface INewsAlertHistoryState {
  history: INewsHistory[];
  loadingState: LoadingState;
}

@inject('newsStoreV2', 'navigationStore')
@observer
export class NewsAlertHistory extends React.PureComponent<
  INewsAlertHistoryProps,
  INewsAlertHistoryState
> {
  public state = {
    history: [],
    loadingState: LoadingState.NotLoaded,
  };

  public componentDidMount() {
    const { loadingState } = this.state;
    if (loadingState === LoadingState.NotLoaded) {
      this.loadHistory();
    }
  }

  private loadHistory = async () => {
    this.setState(() => ({
      loadingState: LoadingState.Loading,
    }));

    try {
      const { newsAlertId, newsStoreV2 } = this.props;

      let history: INewsHistory[]
      history = await newsStoreV2.newsService.getNewsAlertHistory(newsAlertId,);

      this.setState(() => ({
        history,
        loadingState: LoadingState.Loaded,
      }));
    } catch {
      this.setState(() => ({
        loadingState: LoadingState.Failed,
      }));
    }
  };

  private renderHistoryItem = (items: INewsHistory[]) => (
    <History<INewsHistory>
      history={items}
      historyItemRender={NewsHistoryItem}
    />
  );

  public render() {
    const { history, loadingState } = this.state;

    return (
      <CollapsibleList<INewsHistory>
        collapsedAmount={4}
        collapseText="Collapse news alert history"
        items={history}
        renderItems={this.renderHistoryItem}
        showLoader={loadingState === LoadingState.Loading}
        title="News Alert History"
        viewAllText="View all news alert history"
      />
    );
  }
}
