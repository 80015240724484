import css from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { escalateTabActions } from 'src/actions';
import { AlertContainer } from 'src/components/alert-container';
import { AlertModerateModal } from 'src/components/alert-moderate-modal';
import { PageWrapper } from 'src/components/page-wrapper';
import { PaginationController } from 'src/components/pagination-controller';
import { isArrayNotEmpty } from 'src/helpers';
import * as paginationHelper from 'src/helpers/paginationHelper';
import { IExpandedComments } from 'src/interfaces';
import { NotificationService } from 'src/services';
import { Stores } from 'src/stores';
import { toggleCommentHistory } from '../../actions/commentsActions';
import { Loader } from '../../components/Loader';
import { NoContentView } from '../../components/no-content-view';
import { VerticalAligner } from '../../components/VerticalAligner';
import { NavigationTabs, tabsStores } from '../../constants';
import { IStoredAlert } from '../../restApi/interfaces';
import styles from './style.module.css';

interface AlertsEscalationStates {
  currentPage: number;
  expandedComments: IExpandedComments;
}

@inject('alertsListStore', 'uiStore', 'authStore')
@observer
export class AlertsEscalation extends React.Component<
  Stores,
  AlertsEscalationStates
> {
  public itemsPerPage = 3;
  public tab = tabsStores.ESCALATED;

  public state = {
    currentPage: 1,
    expandedComments: {},
  };

  public async componentDidMount() {
    const { alertsListStore, uiStore } = this.props;

    this.setLoaderVisible(true);

    NotificationService.removeNotifications(NavigationTabs.ESCALATED.path);

    try {
      const alertsWithComments =
        await escalateTabActions.fetchAlertsWithComments();
      alertsListStore.setAlerts(this.tab)(alertsWithComments);
      uiStore.startTimer();
    } catch (e) {
      console.error(e);
      alertsListStore.setAlerts(this.tab)([]);
    }
    this.setLoaderVisible(false);
  }

  public componentWillUnmount() {
    escalateTabActions.clearTabStore();
    NotificationService.removeNotifications(NavigationTabs.ESCALATED.path);
  }

  public componentDidUpdate(prevProps, prevState) {
    const { alertsListStore } = this.props;

    let changed = false;
    const { expandedComments } = prevState;

    Object.keys(expandedComments).forEach((alertId) => {
      if (
        -1 ===
        alertsListStore[this.tab].findIndex(
          (alert) => alert.alert_id === Number(alertId),
        )
      ) {
        changed = true;
        delete expandedComments[alertId];
      }
    });

    if (changed) {
      this.setState({ expandedComments });
    }
  }

  public setLoaderVisible = (visible: boolean) =>
    this.props.uiStore.setPageLoading(visible);
  public loaderVisible = () => this.props.uiStore.pageLoading;

  public onThreadExpanded = (alert: IStoredAlert, value?: boolean) => {
    this.setState((prevState) => ({
      expandedComments: {
        ...prevState.expandedComments,
        [alert.alert_id]: value || !prevState.expandedComments[alert.alert_id],
      },
    }));
  };

  public onToggleCommentHistory = async (
    alertId: number,
    commentId: string,
    isShown: boolean,
  ) => {
    return toggleCommentHistory(this.tab, alertId, commentId, isShown);
  };

  public getAlertContainer = (alert: IStoredAlert, isDeferred: boolean) => {
    const { authStore } = this.props;

    const hasAlertComments = Boolean(alert.comments);
    const alertKey = `alert_${alert.alert_id}${
      hasAlertComments && '_comments'
    }_${isDeferred && '_deffered'}`;

    return (
      <AlertContainer
        alert={alert}
        key={alertKey}
        isEscalated
        canAlertModerated={false}
        minimized={hasAlertComments}
        canEdited={false}
        hideNoVisualContentText={hasAlertComments || alert.is_deferred}
        onToggleCommentHistory={this.onToggleCommentHistory}
        onThreadExpanded={this.onThreadExpanded}
        expandedComments={this.state.expandedComments}
        tabStore={this.tab}
        commentsTabFlag={authStore.userEscalationLevel}
        titleReasonShown
      />
    );
  };

  public render() {
    const { uiStore } = this.props;
    const alerts = this.props.alertsListStore[this.tab];
    const deferredAlerts = alerts.filter((alert) => alert.is_deferred);
    const notDeferredAlerts = alerts.filter((alert) => !alert.is_deferred);
    const isDeferredAlertsInList = isArrayNotEmpty(deferredAlerts);
    const isAlertsInList = isArrayNotEmpty(notDeferredAlerts);
    const pages = paginationHelper.pagesCount(
      this.itemsPerPage,
      notDeferredAlerts.length,
    );
    const alertByPage = paginationHelper.getItemsByPage(
      notDeferredAlerts,
      this.state.currentPage,
      this.itemsPerPage,
    );

    if (this.loaderVisible()) {
      return (
        <PageWrapper>
          <Loader />
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        {!isAlertsInList && !isDeferredAlertsInList && (
          <VerticalAligner>
            <NoContentView />
          </VerticalAligner>
        )}
        <div className={css(styles.container, 'top-column-double')}>
          <div className={css(styles.alertsItemsContainer)}>
            {isDeferredAlertsInList &&
              deferredAlerts.map((alert) => {
                return this.getAlertContainer(alert, true);
              })}
          </div>
          <div className={css(styles.alertsItemsContainer)}>
            {isAlertsInList &&
              alertByPage.map((alert) => {
                return this.getAlertContainer(alert, false);
              })}
          </div>
          <PaginationController
            wrapperClassName={css('column-double', styles.pagination)}
            active={isAlertsInList}
            pagesPerChunk={5}
            pageCount={pages}
            currentPage={this.state.currentPage}
            onChangePage={(pageNumber) =>
              this.setState({ currentPage: pageNumber })
            }
          />
          <AlertModerateModal show={uiStore.isModalShow} />
        </div>
      </PageWrapper>
    );
  }
}
