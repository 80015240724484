import { Button, Col, Row, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AutoSizer,
  CellMeasurer,
  Index,
  InfiniteLoader,
  List,
  OnScrollParams,
} from 'react-virtualized';
import {
  contextCleanupOnUnmount,
  MainLayoutContext,
} from 'src/components/layouts/MainLayout';
import { AlignedLoader } from '../../../../components/AlignedLoader';
import { NoContentView } from '../../../../components/no-content-view';
import { VerticalAligner } from '../../../../components/VerticalAligner';
import { NavigationTabs } from '../../../../constants';
import { INews, NewsId } from '../../../../restApi/newsService';
import { logError } from '../../../../stores/utils/errorLogger';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { Footer, FOOTER_TYPES } from '../Footer';
import { NewsCurationItem, NewsCurationWrapper } from '../NewsCurationItem';
import s from './news-list.module.css';
import {Stores} from "../../../../stores";

export interface INewsListProps extends Partial<Stores> {
  getNewsItemHref: (id: NewsId) => string;
  hasMoreNews: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  onDeleteNews: (ids: NewsId[]) => Promise<boolean>;
  onLoadMoreNews: () => Promise<void>;
  news: INews[];
  scrollTop: number | null;
}

interface INewsListState {
  confirmationVisible: boolean;
  deleting: boolean;
}

@inject('newsListUIStore')
@observer
export class NewsList extends React.Component<INewsListProps, INewsListState> {
  private list: List;
  private scrollTop: number = 0;
  public state = {
    confirmationVisible: false,
    deleting: false,
  };

  public componentWillUnmount() {
    const { newsListUIStore } = this.props;
    contextCleanupOnUnmount(this);
    newsListUIStore.setScrollTop(this.scrollTop);
  }

  private showModal = () => {
    this.setState({
      confirmationVisible: true,
    });
  };

  private hideModal = () => {
    this.setState({
      confirmationVisible: false,
    });
  };

  private handleDeleteToggle = (newsId: NewsId) => {
    const { newsListUIStore } = this.props;

    const isSetToDelete = !newsListUIStore.hasNewsToDelete(newsId);

    newsListUIStore.setNewsToDelete([newsId], isSetToDelete);
  };

  private handleScroll = ({
    clientHeight,
    scrollHeight,
    scrollTop,
  }: OnScrollParams) => {
    this.scrollTop = scrollTop;
  };

  private renderAlert = (width: number) => ({ index, key, style, parent }) => {
    const { hasMoreNews, getNewsItemHref, news, newsListUIStore } = this.props;
    const newsItem = news[index];

    return (
      <CellMeasurer
        key={key}
        cache={newsListUIStore.getCache()}
        parent={parent}
        columnIndex={0}
        width={width}
        rowIndex={index}
      >
        <div style={style} className="newsCuration-row">
          {newsItem && (
            <NewsCurationItem
              key={newsItem.news_id}
              newsItem={newsItem}
              getNewsItemHref={getNewsItemHref}
              flaggedDeleted={newsListUIStore.hasNewsToDelete(newsItem.news_id)}
              flaggedFollowed={false}
              onDeletedChange={this.handleDeleteToggle}
            />
          )}
          {!newsItem && (
            <NewsCurationWrapper>
              {hasMoreNews && <AlignedLoader />}
              {!hasMoreNews && "You've reached the end"}
            </NewsCurationWrapper>
          )}
        </div>
      </CellMeasurer>
    );
  };

  private renderAlerts(): React.ReactNode {
    const { news, newsListUIStore, onLoadMoreNews } = this.props;
    const cache = newsListUIStore.getCache();

    const listOptions: { scrollTop?: number } = {};

    // Set list scroll top if the value was saved before
    if (newsListUIStore.scrollTop !== null) {
      listOptions.scrollTop = newsListUIStore.scrollTop;

      // immediately reset scrollTop value to not stick in the one place
      newsListUIStore.setScrollTop(null);
    }

    return (
      <div className={s.NewsList__wrapper}>
        <InfiniteLoader
          isRowLoaded={({ index }: Index) => Boolean(news[index])}
          loadMoreRows={onLoadMoreNews}
          rowCount={news.length + 1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => {
                // Clear size cache if size was changed
                newsListUIStore.clearCacheIfResized(width, this.resizeAll);

                return (
                  <List
                    {...listOptions}
                    deferredMeasurementCache={cache}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    onScroll={this.handleScroll}
                    overscanRowCount={10}
                    ref={(ref) => {
                      registerChild(ref);
                      this.list = ref;
                    }}
                    rowCount={news.length + 1}
                    rowHeight={cache.rowHeight}
                    rowRenderer={this.renderAlert(width)}
                    width={width}
                  />
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    );
  }

  private resizeAll = () => {
    if (this.list) {
      this.list.recomputeRowHeights();
    }
  };

  // TODO: put into global footer
  private onDeleteSubmit = async () => {
    const { newsListUIStore, onDeleteNews } = this.props;

    if (!newsListUIStore.newsToDelete.length) {
      logError({
        skipSentry: true,
        title: 'No news specified to delete',
      });
      return;
    }

    this.setState({
      deleting: true,
    });

    const result = await onDeleteNews(newsListUIStore.newsToDelete);

    if (result) {
      newsListUIStore.resetNewsToDelete();
      this.hideModal();
    }

    this.setState({
      deleting: false,
    });
  };

  private getFooter(type) {
    const { newsListUIStore } = this.props;

    return (
      <Footer>
        {type === 'deleted' ? (
          <>
            <Button
              htmlType="button"
              onClick={newsListUIStore.resetNewsToDelete}
            >
              Deselect
            </Button>
            <Button htmlType="button" type="danger" onClick={this.showModal}>
              {`Delete ${this.getDeletedPluralized()}`}
            </Button>
          </>
        ) : (
          <Link
            key="navigateToNewsAlertCreation"
            to={NavigationTabs.CURATION_NEW.path}
          >
            <Button htmlType="button" type="primary">
              Create news alert
            </Button>
          </Link>
        )}
      </Footer>
    );
  }

  private renderNoResults(): React.ReactNode {
    return (
      <VerticalAligner>
        <Row>
          <Col span={20}>
            <NoContentView />
          </Col>
        </Row>
      </VerticalAligner>
    );
  }

  private getDeletedPluralized(single = 'article', customAmount?) {
    const { newsListUIStore } = this.props;
    const amount = customAmount || newsListUIStore.newsToDelete.length;

    if (amount === 1) {
      return single;
    }

    return `${amount} ${pluralize(single, amount)}`;
  }

  public render() {
    const deletedPluralized = this.getDeletedPluralized();
    const { isLoading, news, newsListUIStore } = this.props;
    const { deleting } = this.state;

    const showLoader = isLoading && !news.length;

    return (
      <>
        <MainLayoutContext.Consumer>
          {({ setById, types }) => {
            setById(
              types.footer,
              this,
              this.getFooter(
                newsListUIStore.newsToDelete.length
                  ? FOOTER_TYPES.deleted
                  : FOOTER_TYPES.create,
              ),
            );
          }}
        </MainLayoutContext.Consumer>

        <DeleteConfirmationModal
          cancelButtonProps={{ disabled: deleting }}
          cancelText="Cancel"
          closable={!deleting}
          confirmLoading={deleting}
          maskClosable={!deleting}
          onCancel={this.hideModal}
          onOk={this.onDeleteSubmit}
          okText={`Delete ${deletedPluralized}`}
          okType="danger"
          title={`Select reason to delete ${deletedPluralized}`}
          visible={this.state.confirmationVisible}
        />

        {showLoader && (
          <VerticalAligner>
            <Row>
              <Col style={{ textAlign: 'center' }} span={20}>
                <Spin />
              </Col>
            </Row>
          </VerticalAligner>
        )}

        {!showLoader &&
          (news.length ? this.renderAlerts() : this.renderNoResults())}
      </>
    );
  }
}
