import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, Switch, Typography } from 'antd';
import styled from 'styled-components';

import { ManageUsersFormUserViolations } from '../manage-users-form-user-violations';
import { ManageUsersFormNotes } from '../manage-users-form-notes';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';

import { useStores } from 'src/stores';

const { Item } = Form;
const { Text } = Typography;

const Bold = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

const Normal = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

export const ManageUsersForm = observer(
  ({
    categoriesCount,
    getFieldDecorator,
    handleAddUserViolation,
    handleRemoveUserViolation,
    handleSelectCategory,
    handleSelectSubcategory,
    selectedCategories,
    selectedSubcategories,
  }) => {
    const { manageUsersStore } = useStores();

    const handleUserIdChange = (e) => {
      manageUsersStore.setBanUserId(e.target.value);
    };

    return (
      <Form id="manage-users-modal-form">
        <Item label="User ID" style={{ marginBottom: '10px' }}>
          {getFieldDecorator('userId', {
            rules: [{ required: true, message: 'Please input user id' }],
            initialValue: manageUsersStore.editingUserId,
          })(
            <Input
              name="userId"
              placeholder="Enter User ID"
              onChange={handleUserIdChange}
              autoComplete="off"
              disabled={manageUsersStore.isEditingUser}
            />,
          )}
        </Item>

        {manageUsersStore.areCategoriesAvailable && (
          <>
            <Box>
              <Bold>Classify User Violations</Bold>
              <br />
              <Normal>Category and Sub-Categories for user violations</Normal>
            </Box>
            <ManageUsersFormUserViolations
              categoriesCount={categoriesCount}
              getFieldDecorator={getFieldDecorator}
              handleAddUserViolation={handleAddUserViolation}
              handleRemoveUserViolation={handleRemoveUserViolation}
              handleSelectCategory={handleSelectCategory}
              handleSelectSubcategory={handleSelectSubcategory}
              selectedCategories={selectedCategories}
              selectedSubcategories={selectedSubcategories}
            />
          </>
        )}

        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          borderTop={'1px solid #f1f1f1'}
          pt={3}
          mb={3}
        >
          <Box>
            <Bold>Ban user</Bold>
            <br />
            <Normal>Turn on to apply ban. Turn off to remove ban.</Normal>
          </Box>
          <Item style={{ marginBottom: '10px' }}>
            {getFieldDecorator('banUser', {
              rules: [{ required: false }],
              initialValue: manageUsersStore.editingUserStatus === 'banned',
              valuePropName: 'checked',
            })(<Switch onChange={manageUsersStore.setBanUser} />)}
          </Item>
        </Flex>

        <Flex>
          <ManageUsersFormNotes getFieldDecorator={getFieldDecorator} />
        </Flex>
      </Form>
    );
  },
);
